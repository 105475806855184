import { Formik, Form as FormikForm } from 'formik';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ErrorFocus from '../../../components/Form/ErrorFocus';
import FormError from '../../../components/Form/FormError';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmitPro from '../../../components/Form/FormSubmitPro';
import { formats } from '../../../components/Form/Input';
import InputCheckbox from '../../../components/Form/InputCheckbox';
import InputGroupPro from '../../../components/Form/InputGroupPro';
import InputNumber from '../../../components/Form/InputNumber';
import InputPhone from '../../../components/Form/InputPhone';
import { modalTermsConditions } from '../../../components/ModalMessage';
import ButtonContainerPro from '../../../components/UI/ButtonContainerPro';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import NavLink from '../../../components/UI/NavLink';
import { submitTrap } from '../../../helpers/formHelpers';
import IconEmail from '../../../images/icon_cedula.png';
import IconLock from '../../../images/icon_pass.png';
import IconPhone from '../../../images/icon_phone.png';
import logo from '../../../images/twice.jpg';
import fieldValidationPost from '../../../services/fieldValidationPost';
import { Params } from '../../../services/useSignUpPost';
import { signup } from '../../../store/reducers/signupReducer';
import { getUtm } from '../../../store/selectors/pixelSelector';
import styled from '../../../styles/styled-components';
import Omit from '../../../types/Omit';
import validationSchema from './ValidationSchema';
//import { validateName } from '../../../trackers/zipps';
const breakpointLarge = '992px';

const Form = styled(FormikForm)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items:center;
  @media (min-width: ${breakpointLarge}) {
    align-items:start;
  }
`;

const MainContainer= styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
`

const Tyc = styled.a`
  margin-left: 4px;
  margin-right: 4px;
  text-decoration: underline;
  color: ${(props) => props.theme.blueDark};
  &,
  &:link,
  &:visited {
    color: ${(props) => props.theme.blueDark};
  }
`;

const Ptd = styled.a`
  display: block;
  margin-left: 4px;
  text-align: left;
  text-decoration: underline;
  color: ${(props) => props.theme.blueDark};
  &,
  &:link,
  &:visited {
    color: ${(props) => props.theme.blueDark};
  }
`;
const ContentHeader= styled.div`
  top:80px;
  position:absolute;
  width:100%;
  display:flex;
  background-color:${(props) => props.theme.yellow} 
  margin:0;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  @media (min-width: ${breakpointLarge}) {
    flex-direction:row;
    align-items:start;
  }
`
const TextHeader= styled.div`
  margin-top:1.5rem;
  display:flex;
  text-align:center;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  @media (min-width: ${breakpointLarge}) {
    align-items:start;
    margin-top:2rem;
  }
`
const Title= styled.h3`
  max-width:230px;
  font-size:2rem;
  margin:0.3rem;
  font-weigth:800;
  line-height: 0.9;
  @media (min-width: ${breakpointLarge}) {
    font-size:2.4rem;
    max-width:400px;
    text-align:start;
  }
`
const Description= styled.p`
  max-width:250px;
  font-size:1rem;
  margin:0.3rem;
  @media (min-width: ${breakpointLarge}) {
    max-width:400px;
    text-align:start;
  }
`
const ContentImg= styled.div`
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: 100% 40%;
  background-size: cover;
  display:flex;
  height:290px;
  width:350px;
  @media (min-width: ${breakpointLarge}) {
    height:240px;
    width:400px;
  }
`
const GeneralContent = styled.div`
  position:relative;
  margin:23rem 1rem 0 1rem;
  padding:1rem 0.8rem;
  background-color:white;
  border-radius: 2rem;
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;
  color: ${props => props.theme.blueDark};

  > div {
    max-width: 305px;
    flex-grow: 1;
    @media (min-width: 700px) {
      max-width: 400px;
    }
  }
  .red {
    color: red;
  }
  > p {
    max-width: 305px;
    flex-grow: 1;
    @media (min-width: 700px) {
      max-width: 400px;
    }
  }
  @media (min-width: 790px) {
    padding:1rem 4.5rem;
  }
  @media (min-width: ${breakpointLarge}) {
    margin:10rem 0 0 0;
    padding:1rem 2.5rem;
  }
`
const InitSesion = styled.div`
  color: black;
  font-weight:500;
  margin: 0.4rem auto 20px auto;
  max-width:135px;
  text-align: center;
  background-color:${props => props.theme.gray100};
  border-radius:1.2rem;
  padding:0.6rem;
  @media (min-width: ${breakpointLarge}) {
    margin: 0.5rem auto 1.3rem 10px;
  }
`
const DescriptionSecound = styled.p`
  text-align:center;
  color: black;
  max-width:350px;
  @media (min-width: ${breakpointLarge}) {
    text-align:start;
  }

`
const SectionInputs = styled.div`
  display:flex;
  flex-direction:column;
  @media (min-width: ${breakpointLarge}) {
    flex-direction:row;
    gap:1rem;
  }
`
const IconContent = styled.div`
  position:relative;
  margin-bottom:1rem;
  @media (min-width: ${breakpointLarge}) {
    margin-bottom:0;
  }
`
const IconContentSimple = styled.div`
  position:relative;
`
const ButtonGroup = styled.div`
  display:flex;
  align-items:center;
  flex-direction:column;
  @media (min-width: ${breakpointLarge}) {
    align-items:start;
  }

`
const CheckboxContent = styled.div`
  font-size:15px;
  @media (min-width: ${breakpointLarge}) {
    display:flex;
  }
`
const IconInput = styled.img`
  position:absolute;
  top:9px;
  right:8px;
  width:17px;
  margin:6px;
`
const IconInputBig = styled.img`
  position:absolute;
  top:6px;
  right:8px;
  width:17px;
  margin:6px;
`
const Message = styled.p`
  font-size:15px;
  margin: 18px 0 0 5px;
`
const ContentBotom = styled.div`
  display:flex;
  flex-direction:column;

  @media (min-width: ${breakpointLarge}) {
  display:flex;
  flex-direction:row;
}
`

export interface FormValues extends Omit<Params, 'uuid'> {
  confirmEmail: string;
  confirmPassword: string;
}

const initialValues: FormValues = {
  givenname: '',
  lastname: '',
  surname: '',
  email: '',
  confirmEmail: '',
  password: '',
  confirmPassword: '',
  idType: 'CC',
  id: '',
  phone: '',
  acceptedTerms: false,
  tradeAgreementCode: '',
};

export interface Props extends RouteComponentProps {
  preSubmit(values: FormValues): void;
}

const SignUpView: React.FC<Props> = ({ history, preSubmit }) => {
  const utms = useSelector(getUtm);
  const [modal, openModal] = useModalC();

  return (
    <MainContainer>
      <ModalC props={modal} />
      <ContentHeader>
        <TextHeader>
          <Title>
            Te prestamos hasta 2 veces tus ingresos
          </Title>
          <Description>
            Crea una cuenta para solicitar tu crédito.
          </Description>
        </TextHeader>
        <ContentImg>
        </ContentImg>
      </ContentHeader>
      {/* <Hero
        title="Te prestamos hasta 2 veces tus ingresos"
        info="Necesitamos que 
      crees una cuenta con nosotros."
      /> */}
        <GeneralContent>
          <Formik
          
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={submitTrap(async (values) => {
              values.phone = values.phone.replace(/\s/g, '');
              values.id = values.id.replace(/\s/g, '');
              if (utms.utmSource) {
                if ( values.tradeAgreementCode.length ) {
                  //console.log('Ya tiene texto')
                } else {
                  values.tradeAgreementCode = `${values.tradeAgreementCode}-${utms.utmSource}`;
                  //console.log('values ==>', values.tradeAgreementCode.length) 
                }
              }
              await fieldValidationPost(values);
              preSubmit(values);
              history.push('/signup/phone-validation');
            })}
          >
            <Form>
                <DescriptionSecound>
                  Escribe nombres y apellidos como aparecen en tu documento de identidad:
                </DescriptionSecound>
                <SectionInputs>
                  <InputGroupPro
                    name="givenname"
                    format={formats.wordUpper}
                    placeholder='Nombres'
                  />
                  <InputGroupPro
                    name="surname"
                    format={formats.wordUpper}
                    placeholder= "Primer Apellido"
                  />
                </SectionInputs>
                <SectionInputs>
                  <InputGroupPro
                    name="lastname"
                    help="* Requerido si tienes segundo apellido"
                    format={formats.wordUpper}
                    placeholder='Segundo Apellido'
                  />
                  <IconContent>
                    <InputNumber
                        name="id"
                        delimiter=" "
                        maxLength={13}
                        placeholder='Cédula de ciudadanía'
                      />
                  </IconContent>
                </SectionInputs>
                <SectionInputs>
                  <IconContentSimple>
                    <InputGroupPro
                      type="email"
                      name="email"
                      placeholder="Correo"
                      format={formats.email}
                    />
                    <IconInputBig src={IconEmail} alt="icono de correo"/>
                  </IconContentSimple>
                  <IconContentSimple>
                    <InputGroupPro
                      name="confirmEmail"
                      type="email"
                      format={formats.email}
                      autocomplete="off"
                      placeholder="Confirma tu correo"
                    />
                    <IconInputBig src={IconEmail} alt="icono de correo"/>
                  </IconContentSimple>
                </SectionInputs>
                <SectionInputs>
                    <IconContentSimple>
                      <InputGroupPro
                        name="password"
                        placeholder="Contraseña"
                        type="password"
                        help="* Elige una contraseña nueva al menos de 4 caracteres"
                      />
                      <IconInputBig src={IconLock} alt="Icono de candado"/>
                    </IconContentSimple>
                  <IconContentSimple>
                    <InputGroupPro
                      name="confirmPassword"
                      type="password"
                      placeholder="Confirma tu contraseña"
                    />
                    <IconInputBig src={IconLock} alt="Icono de candado"/>
                  </IconContentSimple>
               </SectionInputs>
               <SectionInputs>
                <InputGroupPro
                    name="tradeAgreementCode"
                    placeholder="Código de convenio"
                    help="* Este dato no es obligatorio"
                  />
                  <IconContent>
                    <InputPhone name="phone" placeholder="Número de celular" />
                    <IconInput src={IconPhone} alt="icono de telefono"/>
                  </IconContent>
               </SectionInputs>
              <InputCheckbox name="acceptedTerms">
                <CheckboxContent>
                Acepto los
                <Tyc
                  href="https://juanchotepresta.com/img/terminos_y_condiciones.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Términos y condiciones"
                >
                  Términos y Condiciones
                </Tyc>
                y
                <Ptd
                  href="#"
                  onClick={() => openModal(modalTermsConditions)}
                  rel="noopener noreferrer"
                  title="Politica de Tratamiento de Datos"
                >
                  Politica de Tratamiento de Datos
                </Ptd>
                </CheckboxContent>
              </InputCheckbox>
              <FormGroup>
                <FormError />
              </FormGroup>
              <ButtonGroup>
                <ButtonContainerPro>
                  <FormSubmitPro>Enviar</FormSubmitPro>
                </ButtonContainerPro>
                <ContentBotom>
                  <Message>
                  ¿Ya estás registrado en Juancho?
                  </Message>
                  <NavLink to="/login">
                    <InitSesion>Iniciar sesión</InitSesion>
                  </NavLink>
                </ContentBotom>
              </ButtonGroup>
              <ErrorFocus />
            </Form>
          </Formik>
        </GeneralContent>
    </MainContainer>
  );
};

export default connect(
  (_, ownProps) => ownProps,
  (dispatch) => ({
    preSubmit(values: FormValues) {
      dispatch(signup.actions.preRequest(values));
    },
  })
)(SignUpView);
