import { BasicInfo } from '../../services/basicInfoPost';
import { labelToValue, Selectors } from '../../services/selectorsGet';
import { createRequestSlice } from './createRequestReducer';

const isNone = (v: string) => v.toLowerCase().includes('ningun');

export const basicInfo = createRequestSlice({
  name: 'basicInfo',
  prepareRequest(
    { values: formValues, mifos }: { values: BasicInfo; mifos: Selectors },
    meta
  ) {
    // copy values
    const values: BasicInfo = JSON.parse(JSON.stringify(formValues));

    // fix: cast to number
    values.personal.educationalLevel *= 1;
    values.personal.maritalStatus *= 1;
    values.personal.genderId *= 1;
    values.residential.stayTime *= 1;
    values.residential.housingType *= 1;
    values.laboral.bank *= 1;
    values.laboral.jobType *= 1;
    values.laboral.monthlySalary *= 1;
    values.laboral.paymentPeriodicity *= 1;
    values.laboral.economicActivity *= 1;
    values.laboral.economicSector *= 1;
    values.laboral.otherIncome *= 1;
    values.laboral.paymentMethod *= 1;

    const employee = labelToValue(
      mifos.jobType,
      'Empleado',
      'Empleado temporal'
    );
    const independent = labelToValue(mifos.jobType, 'Independiente');
    const unemployed = labelToValue(mifos.jobType, 'Desempleado');

    if (!employee.includes(values.laboral.jobType)) {
      values.laboral.company = '';
    }

    if (!independent.includes(values.laboral.jobType)) {
      values.laboral.nit = '';
      values.laboral.otherIncome = 0;
      delete values.laboral.economicActivity;
    }

    if (unemployed.includes(values.laboral.jobType)) {
      values.laboral.bank = mifos.bank
        .filter((o) => isNone(o.label))
        .map((o) => Number(o.value))[0];
      values.laboral.paymentPeriodicity = mifos.paymentPeriodicity
        .filter((o) => isNone(o.label))
        .map((o) => Number(o.value))[0];
      values.laboral.monthlySalary = 0;
    }

    // fix: remove phone spaces
    // prettier-ignore
    // prettier-ignore
   

    return { payload: values, meta };
  },
});
