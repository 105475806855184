/* eslint react-hooks/exhaustive-deps: 0 */
import React, { ChangeEvent } from 'react';
import clamp from '../../helpers/clamp';
import moneyFormat from '../../helpers/moneyFormat';
import styled from '../../styles/styled-components';
import RangeInput from '../UI/RangeInput';
import { LoanSimulatorContext } from './LoanSimulatorContext';

export interface Props {
  step: number;
}
const AmountSliderStyled = styled.div`
  margin: 10px 2px;
  > label {
    font-size: 18px;
    padding: 10px 0;
  }
`;
const Amount = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
`;

export const LoanSimulatorAmountSlider: React.FC<Props> = ({ step }) => {
  const context = React.useContext(LoanSimulatorContext);

  const onAmountChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = Number(e.target.value);
      context.update({
        type: 'amount',
        value: clamp(context.minAmount, value, context.maxAmount),
      });
    },
    [context.minAmount, context.maxAmount]
  );

  React.useEffect(() => {
    if (
      context.amount > context.maxAmount ||
      context.amount < context.minAmount
    ) {
      context.update({
        type: 'amount',
        value: clamp(context.maxAmount, context.amount, context.minAmount),
      });
    }
  }, [context.minAmount, context.maxAmount]);

  return (
    <AmountSliderStyled>
      <label>¿Cuánto vas a prestar?</label>
      <Amount>{moneyFormat(context.amount || 0)}</Amount>
      {context.minAmount < context.maxAmount && (
        <>
          <RangeInput
            value={context.amount}
            min={context.minAmount}
            max={context.maxAmount}
            onChange={onAmountChange}
            step={step}
          />
          <Row>
            <div>{moneyFormat(context.minAmount || 0)}</div>
            <div>{moneyFormat(context.maxAmount || 0)}</div>
          </Row>
        </>
      )}
    </AmountSliderStyled>
  );
};
