import { Formik } from 'formik';
import React, { useRef } from 'react';
import { number, object, string } from 'yup';
import Form from '../../../../components/Form/Form';
import FormGroup from '../../../../components/Form/FormGroup';
import FormSubmit from '../../../../components/Form/FormSubmit';
import { formats } from '../../../../components/Form/Input';
import InputGroup from '../../../../components/Form/InputGroup';
import InputNumber from '../../../../components/Form/InputNumber';
import InputSelect from '../../../../components/Form/InputSelect';
import Label from '../../../../components/Form/Label';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../../components/UI/ButtonGroup';
import GeneralContent from '../../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import { submitTrap } from '../../../../helpers/formHelpers';
import postRefinance from '../../../../services/management/refinance';
import styled from '../../../../styles/styled-components';
import toBase64 from './tobase64';
import { selectSchema } from './ValidationSchema';

const initialValues = {
  reliefReason: '',
  justification: '',
  amountToCancel: '',
  paymentSupport: '',
  fileMimeType: '',
  clientEmail: '',
};

const InputFile = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Styled = styled.div`
  .card {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${(props) => props.theme.white};
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    margin-bottom: 10px;

    .circle {
      background-color: white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`

const options = [
  { label: 'Enfermedad', value: 'Enfermedad' },
  { label: 'Desempleo', value: 'Desempleo' },
  { label: 'Accidente', value: 'Accidente' },
];

const ErrorModal = {
  main: (
    <p>No fue posible enviar la información, por favor intentelo más tarde.</p>
  ),
  accept: 'Aceptar',
};
const Refinance: React.FC = () => {
  const [modal, openModal] = useModalC();
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const reset = () => {
    if (ref.current) ref.current.value = '';
  };
  return (
    <GeneralContent>
      <ModalC props={modal} />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={object({
          reliefReason: selectSchema,
          justification: string().required('Requerido'),
          amountToCancel: number().min(1, 'Requerido').required('Requerido'),
          paymentSupport: string().required('Requerido'),
          clientEmail: string()
            .email(
              'Ingresa un correo válido para que puedas continuar con el proceso.'
            )
            .required('Requerido'),
        })}
        onSubmit={submitTrap(async (values, { resetForm }) => {
          try {
            await postRefinance(values);
            reset();
            resetForm(initialValues);
          } catch (error) {
            openModal(ErrorModal);
          }
        })}
        render={({ setFieldValue }) => {
          return (
            <Form>
              <Styled>
                <div className="card">
                  <Label>
                    Si tienes tu crédito en mora podemos refinanciarlo con un pago anticipado de 2 cuotas vencidas.
                  </Label>
                </div>
              </Styled>
              <FormGroup>
                <Label title="Escoja una razón por la cual solicita el alivio">
                  Razón del alivio
                </Label>
                <InputSelect name="reliefReason" options={options} />
              </FormGroup>
              <FormGroup>
                <Label title="Haga un breve resumen del motivo de la refinanciación, este solo se podrá otorgar si posee mas de dos cuotas en mora y su situación no permite continuar con las condiciones actuales">
                  Justificación
                </Label>
                <InputGroup type="textarea" name="justification" size={90} />
              </FormGroup>
              <FormGroup>
                <Label title="Para acceder a una refinanciación es necesario el pago de por lo menos una de las cuotas mas vencidas, ingrese el monto que cancelará">
                  Monto a cancelar
                </Label>
                <InputNumber icon="dollar-sign" name="amountToCancel" />
              </FormGroup>
              <FormGroup>
                <InputFile>
                  <Label title="Adjunte el soporte de pago del monto que canceló para acceder a la refinanciación">
                    Soporte de pago
                  </Label>
                  <input
                    type="file"
                    name="paymentSupport"
                    id="paymentSupport"
                    ref={ref}
                    accept="image/*, application/pdf"
                    onChange={async (event) => {
                      setFieldValue('paymentSupport', '');
                      setFieldValue('fileMimeType', '');
                      let file: File | null = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        try {
                          let paymentSupport = await toBase64(file);
                          setFieldValue('paymentSupport', paymentSupport);
                          setFieldValue('fileMimeType', file.type ?? '');
                        } catch (error) { }
                      }
                    }}
                  />
                </InputFile>
              </FormGroup>
              <FormGroup>
                <Label title="Ingrese el correo electrónico al que enviaremos la respuesta a su solicitud">
                  Correo electrónico
                </Label>
                <InputGroup
                  type="email"
                  icon="envelope"
                  format={formats.email}
                  placeholder="example@mail.com"
                  name="clientEmail"
                />
              </FormGroup>
              <ButtonGroup>
                <ButtonContainer>
                  <FormSubmit>Agregar</FormSubmit>
                </ButtonContainer>
              </ButtonGroup>
            </Form>
          );
        }}
      ></Formik>
    </GeneralContent>
  );
};

export default Refinance;
