import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export interface Params {}

export interface ResponseSignatures {
  [loanId: string]: {
    loan_id: string;
    Salario: string;
    Direccion: string;
    Ciudad: string;
    Firma_Documento: string;
    Firma_IP: string;
    Firma_Timestamp: string;
  };
}

export type ResponseSignaturesItem = {
  loan_id: string;
  firma_ip: string;
  firma_timestamp: string;
};

const signaturesService = serviceBuilder<None, ResponseSignatures>('get', {
  url: 'sec/loans/signaturereport',
  auth: true,
});

export default async () => {
  return Object.values((await signaturesService()).payload)
    .map(({ loan_id, Firma_IP, Firma_Timestamp }) => ({
      loan_id,
      firma_ip: Firma_IP,
      firma_timestamp: Firma_Timestamp,
    }))
    .filter(({ loan_id }) => loan_id);
};
