import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../components/UI/Button';
import styled from '../styles/styled-components';

const NotFoundStyled = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 40px;
    color: ${props => props.theme.gray600};
  }
  p {
    font-weight: 500;
    font-size: 25px;
    color: ${props => props.theme.gray500};
  }
`;

const NotFound: React.FC = () => (
  <NotFoundStyled>
    <h1>404</h1>
    <p>No pudimos encontrar la pagina que estabas buscando.</p>
    <Button as={NavLink} outline to="/home">
      Volver atras
    </Button>
  </NotFoundStyled>
);

export default NotFound;
