import React from 'react'
const Account = props =>
<svg xmlns="http://www.w3.org/2000/svg" width="20.575" height="20.575" viewBox="0 0 20.575 20.575">
  <g id="Group_709" data-name="Group 709" transform="translate(0.6 0.6)">
    <path id="Path_2" className="stroke-color" data-name="Path 2" d="M934.186,65.607a9.687,9.687,0,1,0,9.687,9.687,9.716,9.716,0,0,0-9.687-9.687" transform="translate(-924.498 -65.607)" fill="none" strokeMiterlimit="10" strokeWidth="1.2"/>
    <circle id="Ellipse_1" className="stroke-color" data-name="Ellipse 1" cx="3.283" cy="3.283" r="3.283" transform="translate(6.405 2.361)" fill="none" strokeWidth="1.2"/>
    <path id="Path_3" className="stroke-color" data-name="Path 3" d="M942.735,112.268a7.983,7.983,0,0,1-6.565-3.5c0-2.188,4.377-3.392,6.565-3.392s6.565,1.2,6.565,3.392A7.982,7.982,0,0,1,942.735,112.268Z" transform="translate(-933.048 -94.736)" fill="none" strokeWidth="1.2"/>
  </g>
</svg>

export default Account