interface IQueryParams {
  [key: string]: string;
}

export const getQueryParams = (querystring: string): IQueryParams => {
  const formattedQueries = {} as IQueryParams;
  if (querystring !== '') {
    const splitString = querystring.replace('?', '').split('&');
    splitString.forEach((query) => {
      const [key, value] = query.split('=');
      Object.assign(formattedQueries, {
        [key]: value,
      });
    });
  }

  return formattedQueries;
};
