import { SagaIterator } from 'redux-saga';
import { call, delay, put } from 'redux-saga/effects';
import buroGet, { BuroResponse } from '../../services/buroGet';
import mareiguaGet, { MrgResponse } from '../../services/mareiguaGet';
import socialSecurityGet, {
  SocialSecurityResponse,
} from '../../services/socialSecurityGet';
import ApiError from '../../types/ApiError';
import { ActionPromise } from '../asyncDispatch';
import loan, { LoanState } from '../reducers/loanReducer';
import { loanState } from './loanState';
import { refreshTokenSaga } from './session';

function* buroRequest(): SagaIterator {
  yield call(refreshTokenSaga);
  while (true) {
    try {

      const response: BuroResponse = yield call(buroGet);
      if (response.code__ === 200) {
        yield put(loan.actions.update({ 
          score: parseInt(response.score, 10),
          product:response.product,
          simUserMsg:response.simUserMsg
         }));
        return { hasBuro: true, ...response };
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.code === 400) {
          return { hasBuro: false, code__: e.code, ...e.payload };
        }
      }
    }
    yield delay(1000);
  }
}

function* mareiguaRequest(): SagaIterator {
  while (true) {
    try {
      const response: MrgResponse = yield call(mareiguaGet);
      if (response.code__ === 200) {
        return { mrg: true };
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.code === 400 || e.code === 410) {
          return { mrg: false, code__: e.code };
        }
      }
    }
    yield delay(1000);
  }
}

function* socialSecurityRequest(): SagaIterator {
  while (true) {
    try {
      const response: SocialSecurityResponse = yield call(socialSecurityGet);
      if (response.code__ === 200) {
        return { userFound: response.userFound };
      }
    } catch (e) {
      if (e instanceof ApiError) {
        if (e.code === 400 || e.code === 410) {
          return { userFound: false, code__: e.code };
        }
      }
    }
    yield delay(1000);
  }
}

export function* checkCreditHistorySaga(_: ActionPromise<void>): SagaIterator {
  /********************************************* */
  const { hasBuro, ...restBuro } = yield call(buroRequest);
  if (restBuro.code__ === 400) {
    throw new Error('Service not available');
  }
  let loan: LoanState = yield call(loanState);
  if (loan.status === 'Rejected') {
    throw new Error('Loan was rejected');
  }

  /********************************************* */

  const { mrg, ...restMrg } = yield call(mareiguaRequest);
  if (restMrg.code__ === 400) {
    throw new Error('Service not available');
  }
  loan = yield call(loanState);
  if (loan.status === 'Rejected') {
    throw new Error('Loan was rejected');
  }
  if (loan.status === 'Submitted and awaiting request') {
    return { hasBuro, buro: restBuro, mrg };
  }

  /********************************************* */

  const { userFound, ...restSocial } = yield call(socialSecurityRequest);
  if (restSocial.code__ === 400) {
    throw new Error('Service not available');
  }
  loan = yield call(loanState);
  if (loan.status === 'Rejected') {
    throw new Error('Loan was rejected');
  }
  if (loan.status === 'Submitted and pending approval') {
    throw new Error('Service not available');
  }

  return { userFound, hasBuro, buro: restBuro, mrg };
}
