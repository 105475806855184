import serviceBuilder from './serviceBuilder';

export type RiskMatrix = {
  [finalPath: string]: {
    maxAmount: number;
    minAmount: number;
    term: number[];
  };
};

export type RiskMatrixPath = {
  [risk: string]: RiskMatrix;
};

export const url = 'sec/loans/riskLevelMatrix';

const service = serviceBuilder<void, RiskMatrixPath>('get', {
  url,
  auth: true,
});

export default async () => {
  try {
    return (await service()).payload;
  } catch (e) {
    throw e;
  }
};
