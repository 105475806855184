import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from '../../styles/styled-components';
import Input, { Icon, Props as InputProps } from './Input';

const breakpointLarge = '992px';
export const InputIconStyled = styled.div`
  position: relative;
  min-width: 290px;
  @media (min-width: ${breakpointLarge}) {
    min-width: 350px;
  }
`;
const InputStyled = styled(Input)`
  padding-right: 36px;
  background: ${props => props.theme.gray100};
  border-radius: 20px;
  height: 45px;
`;

export interface Props extends InputProps {
  icon: IconProp;
}

const InputIcon: React.FC<Props> = ({ icon, ...restProps }) => (
  <InputIconStyled>
    <InputStyled {...restProps} />
    <Icon>
      <FontAwesomeIcon icon={icon} />
    </Icon>
  </InputIconStyled>
);

export default InputIcon;
