import { ServiceLoaded } from '../types/Service';
import serviceBuilder from './serviceBuilder';

interface SimulatorTerm {
  months: number;
  incomeMultiplier: number;
  minScore: number;
}

interface SimulatorWorkType {
  workingStatus: string;
  minIncome: number;
  terms: SimulatorTerm[];
}

export interface SimulatorAuthParams {
  minAmount: number;
  maxAmount: number;
  iva: number;
  technology: number;
  electronicSignature: number;
  guaranteeConstant: number;
  guaranteeVariableWoman: number;
  guaranteeVariableMan: number;
  guaranteeFurniture: number;
  interest: number;
  VAT: number;
  options: SimulatorWorkType[];
}

type Result = SimulatorAuthParams;

const url = 'sec/loans/sim';
const service = serviceBuilder<void, Result>('get', {
  url,
  auth: true,
});

export default async function (): Promise<ServiceLoaded<Result>> {
  try {
    const { payload, status } = await service();

    payload.guaranteeConstant = payload.guaranteeConstant * 0.01;
    payload.guaranteeVariableMan = parseFloat(
      (payload.guaranteeVariableMan * 0.01).toFixed(5)
    );
    payload.guaranteeVariableWoman = payload.guaranteeVariableWoman * 0.01;
    payload.interest = payload.interest * 0.01;
    payload.iva = payload.iva * 0.01;

    return { payload, status };
  } catch (e) {
    throw e;
  }
}
