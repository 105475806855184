import React from 'react';
import styled from 'styled-components';
const JustifyContent = styled.div`
  text-align: justify;
`;
export const QuoteInfo = () => (
  <>
    <h2>Información de cuota mensual</h2>
    <JustifyContent>
    <p>En tu cuota mensual se incluyen los siguientes valores:</p>
    <p>
      <b>•Amortización de capital:</b> corresponde a la porción de capital que
      abonas en tu cuota fija mensual. Nuestro modelo utiliza la fórmula de
      cuota fija.
    </p>
    <p>
      <b>•Intereses:</b> es el costo que pagas mensualmente por usar el dinero
      solicitado.
    </p>
    <p>
      <b>•Aval (opcional):</b> el aval es una garantía en la que un tercero
      asegura el cumplimiento de tu obligación en caso de incumplimiento. La
      selección del aval es opcional, en caso que no lo quieras tomar nos debes
      enviar un certificado de una póliza, fianza o aval que represente
      garantías para el pago, tomada de manera individual con otra entidad
      legalmente constituida cuyo beneficiario debe ser JUANCHO TE PRESTA SAS.
      Puedes visitar las páginas de nuestros proveedores:{' '}
      <a href="www.coberturascrediticias.com">www.coberturascrediticias.com</a>{' '}
      y <a href="www.novafianza.com">www.novafianza.com</a>
    </p>
    <p>
      <b>•Servicio de firma electrónica (opcional):</b>
      te permite firmar electrónicamente el acuerdo económico,
      contrato de garantía crediticia, aviso de privacidad de 
      la compañía avaladora, contrato de garantía mobiliaria, 
      autorización de débito automático, poder irrevocable para
      suscribir libranza, pagaré y carta de instrucciones;
      todos estos documentos son necesarios para la aceptación
      y desembolso de tú crédito. Si no quieres incurrir 
      en dicho costo y cuentas con un certificado de firma digital 
      vigente debes enviar toda la documentación al correo:{' '}
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="ayuda@juanchotepresta.com"
        href="mailto:ayuda@juanchotepresta.com"
      >
        ayuda@juanchotepresta.com
      </a>
    </p>
    <p>
      <b>Solo</b> si no pudiste contratar una firma digital con un 
        ente certificador, podrás enviarnos todos los documentos de
        manera física y autenticados ante Notario a la dirección
        estipulada en el acuerdo económico.
    </p>
    </JustifyContent>
  </>
);

export const BlogInfo = () => (
  <p>Blog: En esta sección tenemos artículos diseñados especialmente para ti</p>
);

export const AvalInfo = () => (
  <>
    <h2>Información de desembolso</h2>
    <JustifyContent>
    <p>
      Tu monto desembolsado es diferente al valor del crédito, ya que se
      descuentan el valor del aval y el registro de garantías mobiliarias.
    </p>
    <p>
      Si tu solicitud es aprobada te ofrecemos proveedores con los cuales puedes
      contratar tu aval. Nuestros proveedores cobran un aval fijo que
      corresponde a un porcentaje del monto aprobado de tu crédito y se te
      debita antes del desembolso; y un porcentaje variable dependiendo del
      plazo de tu crédito, valor que pagas dentro de tu cuota mensual.
    </p>
    </JustifyContent>
  </>
);

export const LegalInfo = () => (
  <>
    <h2>Información legal</h2>
    <JustifyContent>
    <p>
      <a href="JuanchoTePresta.com">JuanchoTePresta.com</a> , pone a tu
      disposición el presente Simulador en los términos de Ley, el cual tiene
      fines informativos y no comporta oferta o promesa de contratar. Este
      simulador es indicativo y está destinado a proporcionarte información y
      estimaciones de carácter general. El resultado de esta simulación no
      comprende una certificación o recomendación comercial, contable,
      tributaria o legal. Los términos de esta simulación son suministrados con
      base en las condiciones comerciales y de mercado que han sido establecidas
      para la fecha en que realizas esta simulación. La tasa de interés
      remuneratoria corresponderá a la que JUANCHO TE PRESTA SAS tenga
      establecida al momento de tu utilización. Otros componentes del crédito
      dependen de terceros y pueden variar según tu nivel de riesgo y capacidad
      de pago.
    </p>
    </JustifyContent>
  </>
);

export const InterestRate = () => (
  <>
    <h2>Información Tasa de interés</h2>
    <JustifyContent>
    <p>
      Costo mensual que pagas por usar el dinero que pediste prestado.
    </p>
    </JustifyContent>
  </>
);

export const ElectronicSignature = () => (
  <>
    <h2>Servicio de firma electrónica (opcional)</h2>
    <JustifyContent>
    <p>
      Te permite firmar electrónicamente el acuerdo económico,
      contrato de garantía crediticia, aviso de privacidad de 
      la compañía avaladora, contrato de garantía mobiliaria, 
      autorización de débito automático, poder irrevocable para
      suscribir libranza, pagaré y carta de instrucciones;
      todos estos documentos son necesarios para la aceptación
      y desembolso de tú crédito. Si no quieres incurrir 
      en dicho costo y cuentas con un certificado de firma digital 
      vigente debes enviar toda la documentación al correo:{' '}
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="ayuda@juanchotepresta.com"
        href="mailto:ayuda@juanchotepresta.com"
      >
        ayuda@juanchotepresta.com
      </a>
    </p>
    <p>
      <b>Solo</b> si no pudiste contratar una firma digital con un 
        ente certificador, podrás enviarnos todos los documentos de
        manera física y autenticados ante Notario a la dirección
        estipulada en el acuerdo económico.
    </p>
    </JustifyContent>
  </>
);