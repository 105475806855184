import React from 'react';
import styled from '../styles/styled-components';

interface AlertProps {
  color: 'success' | 'danger';
}

const Alert = styled.small<AlertProps>`
  white-space: pre-line;
  display: block;
  color: ${props => props.theme[props.color]};
  padding: 5px 4px;
  font-weight: bold;
  max-width: 500px;
`;

export interface Props {
  validate: boolean
  visible?: boolean;
  success?: string;
  error?: string;
}

const MessageStatus: React.FC<Props> = ({
  validate,
  success,
  error,
  visible = true,
}) => {
  if (visible) {
    if (validate === true) {
      return (
        <Alert color="danger">
          {error === undefined ? 'No fue posible enviar tu codigo intentalo mas tarde' : error}
        </Alert>
      );
    }

    if (success && validate === false) {
      return <Alert color="success">{success}</Alert>;
    }
  }

  return null;
};

export default MessageStatus;
