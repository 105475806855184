import React from 'react';
import range from '../../helpers/range';
import styled from '../../styles/styled-components';
import { Wizard } from './useWizard';

const WizardTrackerStyled = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const WizardMarkStyled = styled.div<{ active: boolean; finished: boolean }>`
  height: 15px;
  width: 15px;
  margin: 0 2px;
  border: none;
  border-radius: 50%;
  display: inline-block;
  background-color: ${props =>
    props.finished ? props.theme.gray400 : props.theme.gray200};
  opacity: ${props => (props.active ? 1 : 0.5)};
`;

export interface Props {
  wizard: Wizard;
}

const WizardTracker: React.FC<Props> = ({ wizard }) => {
  return (
    <WizardTrackerStyled>
      {range(1, wizard.steps).map(i => (
        <WizardMarkStyled
          key={i}
          active={wizard.step === i}
          finished={wizard.step >= i}
        />
      ))}
    </WizardTrackerStyled>
  );
};

export default WizardTracker;
