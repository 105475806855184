import Cookies from 'js-cookie';
import { httpMethod } from '../services/api';

const askrobinService = httpMethod('https://robinacademia.com');

export async function askrobin(user: string) {
  const pixel_r_parameter = Cookies.get('pixel_r_parameter');

  if (pixel_r_parameter && pixel_r_parameter !== 'null') {
    try {
      await askrobinService(
        'get',
        `p.ashx?o=166&e=2&f=pb&t=:t&r=:r`,
        false,
        {
          t: user, // Unique user identification
          r: pixel_r_parameter, // cookie r parameter of the Campaingn
        },
        true,
        {}
      );
    } catch (e) {
      console.warn('[ASKROBIN]', e);
    }
  }
}
