import { JTPState } from '..';

export default (state: JTPState) => state.pixel;

export const getUtm = (state: JTPState) => state.pixel.utm;

export const GetListPixel = (state: JTPState) => state.pixel.listPixel;

export const pixelScreen = (state: JTPState, screen: String) =>
  state.pixel.listPixel.filter((pixel) => pixel.screen === screen) || undefined;
