import serviceBuilder from './serviceBuilder';

export const url = 'sec/users/pm';

export type UserPaymentMethods = {
  code: string;
  name: string;
  active: boolean;
};
export interface Result {
  upm: UserPaymentMethods[];
}

export default serviceBuilder<void, Result>('get', {
  url,
  auth: true,
});
