import Cleave from 'cleave.js/react';
import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from '../../styles/styled-components';
import Error from './Error';
import { InputCSS } from './Input';

const InputStyled = styled(Cleave)`
  ${InputCSS}
`;

const InputDateComponent: React.FC<FieldProps> = ({
  form,
  field,
  ...restProps
}) => {
  return (
    <InputStyled
      placeholder="DD-MM-YYYY"
      invalid={
        form.touched[field.name]! && !!form.errors[field.name]
          ? ('invalid' as any)
          : undefined
      }
      id={field.name}
      options={{ date: true, delimiter: '-', datePattern: ['d', 'm', 'Y'] }}
      {...restProps}
      {...field}
    />
  );
};

export interface Props {
  name: string;
}

const InputDate: React.FC<Props> = ({ name, ...restProps }) => (
  <>
    <Field name={name} {...restProps} component={InputDateComponent} />
    <Error name={name} />
  </>
);

export default InputDate;
