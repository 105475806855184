import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { object } from 'yup';
import FormGroup from '../../../../components/Form/FormGroup';
import FormSubmit from '../../../../components/Form/FormSubmit';
import InputSelect from '../../../../components/Form/InputSelect';
import Label from '../../../../components/Form/Label';
import Button from '../../../../components/UI/Button';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../../components/UI/ButtonGroup';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import loadingGif from '../../../../images/loading.gif';
import {
  activeStatus,
  cancelStatus,
  isLoanActive,
  isLoanCancel,
} from '../../../../services/creditListGet';
import download, { Response } from '../../../../services/management/download';
import { JTPState } from '../../../../store';
import { breakpointLarge } from '../../../../styles/breakpoints';
import { selectSchema } from './ValidationSchema';

interface Props {
  loansList: any;
}

const DownloadPage: React.FC<Props> = ({ loansList }) => {
  const LoadingDiv = styled.div`
    text-align: center;
  `;

  const LoanSelector = styled.div`
    margin-top: 40px;

    .hidde {
      display: none;
    }
  `;

  const Loading = styled.img`
    margin-top: 10px;
    width: 4.5rem;
    @media (min-width: ${breakpointLarge}) {
      width: 9rem;
    }
  `;

  const ErrorDownload = {
    main: (
      <>
        <p>Error al intentar descargar archivo</p>
      </>
    ),
    cancel: 'Aceptar',
  };

  const initialValues = {
    loandIdSelect: '',
  };

  const [modal, openModal] = useModalC();
  const [state, setState] = useState({
    loading: false,
    typeUrl: '',
    fileName: '',
    list: [],
    years: [] as any,
  });

  const resetState = () => {
    setState({
      loading: false,
      typeUrl: '',
      fileName: '',
      list: [],
      years: [],
    });
  };

  const downloadBase64File = (
    contentType: string,
    base64Data: string,
    fileName: string
  ) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = 'blank';
    downloadLink.click();
  };

  const downloadBalancesdue = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });
      let url = 'balancesdue';
      let fileName = 'Certificado de deuda';
      let { loanId } = loansList.filter((loan: any) =>
        activeStatus.includes(loan.statusId)
      )[0];
      let res: Response = await download({
        loanId,
        url,
      });
      downloadBase64File('application/pdf', res.pdfFile, fileName);
      resetState();
    } catch (error) {
      resetState();
      openModal(ErrorDownload);
    }
  };

  const downloadPaymentschedule = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });
      let url = 'paymentschedule';
      let fileName = 'Calendario de pagos';
      let { loanId } = loansList.filter((loan: any) =>
        activeStatus.includes(loan.statusId)
      )[0];
      let res: Response = await download({
        loanId,
        url,
      });
      downloadBase64File('application/pdf', res.pdfFile, fileName);
      resetState();
    } catch (error) {
      resetState();
      openModal(ErrorDownload);
    }
  };

  const downloadPeaceandsave = async () => {
    try {
      setState({
        ...state,
        loading: true,
      });
      let loans = loansList
        .filter((loan: any) => cancelStatus.includes(loan.statusId))
        .map((loan: any) => ({
          value: loan.loanId,
          label: `Credito ${loan.loanId}`,
        }));
      if (loans.length === 0) {
        throw new Error('No tiene creditos cerrados');
      }
      setState({
        typeUrl: 'peaceandsave',
        fileName: 'Paz y salvo',
        list: loans,
        loading: false,
        years: [],
      });
    } catch (error) {
      resetState();
      openModal(ErrorDownload);
    }
  };

  // const downloadIncomestatement = async () => {
  //   try {
  //     let loans = loansList
  //       .filter(
  //         (loan: any) =>
  //           activeStatus.includes(loan.statusId) ||
  //           cancelStatus.includes(loan.statusId)
  //       )
  //       .map((loan: any) => ({
  //         value: loan.loanId,
  //         label: `Credito ${loan.loanId}`,
  //       }));
  //     if (loans.length === 0) {
  //       throw new Error('No tiene creditos cerrados ni activos');
  //     }
  //     setState({
  //       typeUrl: 'incomestatement',
  //       fileName: 'Certificación declaración de renta',
  //       list: loans,
  //       loading: false,
  //       years: [{ label: '2021', value: 2021 }],
  //     });
  //   } catch (error) {
  //     resetState();
  //     openModal(ErrorDownload);
  //   }
  // };

  return state.loading ? (
    <LoadingDiv>
      <Loading src={loadingGif} alt="cargando" />
    </LoadingDiv>
  ) : state.list.length === 0 ? (
    <div className="loan-item">
      <ModalC props={modal} />
      <Button
        sm
        disabled={!isLoanActive(loansList)}
        onClick={() => downloadBalancesdue()}
      >
        Certificado de deuda, saldos adeudados
      </Button>
      <Button
        sm
        disabled={!isLoanActive(loansList)}
        onClick={() => downloadPaymentschedule()}
      >
        Calendario de pagos
      </Button>
      <Button
        disabled={!isLoanCancel(loansList)}
        onClick={() => downloadPeaceandsave()}
        sm
      >
        Paz y salvo
      </Button>
      {/* <Button
        sm
        disabled={!isLoanActive(loansList) && !isLoanCancel(loansList)}
        onClick={() => downloadIncomestatement()}
      >
        Certificación declaración de renta
      </Button> */}
    </div>
  ) : (
    <LoanSelector>
      <Formik
        initialValues={initialValues}
        onSubmit={async ({ loandIdSelect }) => {
          try {
            setState({
              ...state,
              loading: true,
            });
            let res: Response = await download({
              loanId: parseInt(loandIdSelect),
              url: state.typeUrl,
            });
            downloadBase64File('application/pdf', res.pdfFile, state.fileName);
            resetState();
          } catch (error) {
            resetState();
            openModal(ErrorDownload);
          }
        }}
        validationSchema={object({
          loandIdSelect: selectSchema,
        })}
      >
        <Form>
          <FormGroup className={state.years.length === 0 ? 'hidde' : ''}>
            <Label>Seleccione el año</Label>
            <InputSelect name="year" options={state.years} />
          </FormGroup>
          <FormGroup>
            <Label>Seleccione el credito</Label>
            <InputSelect name="loandIdSelect" options={state.list} />
          </FormGroup>
          <ButtonGroup>
            <ButtonContainer>
              <FormSubmit>Aceptar</FormSubmit>
              <Button onClick={() => resetState()}>Cancelar</Button>
            </ButtonContainer>
          </ButtonGroup>
        </Form>
      </Formik>
    </LoanSelector>
  );
};

export default connect((state: JTPState) => ({
  loansList: Object.values(state.loans),
}))(DownloadPage);
