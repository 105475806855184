/* eslint react-hooks/exhaustive-deps: 0 */
import { PDFDownloadLink } from '@react-pdf/renderer';
import { unix } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import Button from '../../../components/UI/Button';
import moneyFormat from '../../../helpers/moneyFormat';
import { getQueryParams } from '../../../helpers/queryString';
import timeout from '../../../helpers/timeout';
import useKushki from '../../../hooks/useKushki';
import useService from '../../../hooks/useService';
import LogoInfo from '../../../images/info.png';
import logo from '../../../images/logo-juancho-te-presta.png';
import repaymentGet from '../../../services/repaymentGet';
import transactionInfoGet, {
  ProcessorState
} from '../../../services/transactionInfoGet';
import styled from '../../../styles/styled-components';
import PDFfile from './PDFfile';

const TransactionView = styled.article`
  display: flex;
  flex-direction: column;
  width: 310px;
  /* height: 490px; */
  margin-top: 25px;
  padding-top: 5px;
  margin-bottom: 5px;
  box-shadow: 11px 9px 44px -13px rgba(0, 0, 0, 0.75);
  @media (min-width: 750px) {
    /* height: 600px; */
    width: 400px;
  }
`;
const Title = styled.title`
  display: flex;
  margin-left: 20px;
  div {
    margin-top: 18px;
    height: 20px;
    width: 20px;
    background-image: url(${LogoInfo});
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 7px;
  }
  h3 {
    color: ${(p) => p.theme.blueDark};
    font-size: 17px;
  }
  @media (min-width: 750px) {
    margin-left: 55px;
  }
`;
const Logo = styled.img`
  display: none;
  @media (min-width: 750px) {
    display: inline;
    margin-left: 150px;
    width: 6rem;
    height: 6rem;
  }
`;

const Item = styled.section`
  display: flex;
  text-align: left;
  margin-left: 20px;
  height: 30px;
  @media (min-width: 750px) {
    margin-left: 35px;
  }
`;
const ItemHelp = styled.section`
  display: flex;
  a {
    color: ${(p) => p.theme.blueDark}
  }
  text-align: center;
  margin:0 20px 0 20px;
  @media (min-width: 750px) {
    margin:0 35px 0 35px;
  }
`;

const Bluetext = styled.p`
  color: ${(p) => p.theme.blueDark};
  font-size: 12px;
`;
const Graytext = styled.p`
  color: #707070;
  margin-right: 5px;
  font-size: 12px;
`;
const Redtext = styled.p`
  color: ${(p) => p.theme.red};
  font-size: 12px;
`;
const PDFprint = styled.div`
  display: flex;
  height: 70px;
  justify-content: center;
`;

const PDFbuttons = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  height: 40px;
`;
function money(value: number) {
  return moneyFormat(value, true, false);
}

function translateState(state: ProcessorState) {
  return {
    SUCCESS:'Pendiente',
    OK: 'Aprobado',
    PENDING: 'Pendiente',
    NOT_AUTHORIZED: 'Rechazada',
    FAILED: 'Fallida',
  }[state];
}

const LoanPaymentfeedback: React.FC<
  RouteComponentProps<Record<'loanId' | 'paymentId' | 'code', string>>
> = ({ match }) => {
  const [repayment, getRepayment] = useService(repaymentGet);
  const [details, setDetails] = useState<any>();
  const { bankList, requestBankList } = useKushki();

  useEffect(() => {
    Promise.all([
      requestBankList(),
      getRepayment({
        paymentId: match.params.paymentId,
      }),
    ]).then(async ([_, quota]) => {
      if (quota.status === 'loaded') {
        while (true) {
          const kushkiToken =
            getQueryParams(window.location.search)['token'] || '';
          // const kushkiToken = new URLSearchParams(window.location.search).get(
          //   'token'
          // );
          const result = await transactionInfoGet({
            kushkiToken: kushkiToken || quota.payload.kushkiToken,
          });

          if (result.status === 'loaded') setDetails(result.payload);

          if (
            result.status === 'error' ||
            (result.status === 'loaded' &&
              result.payload.processorState !== 'PENDING')
          ) {
            return;
          }
          await timeout(1000 * 60 * 3);
        }
      }
    });
  }, []);

  return (
    <TransactionView>
      <Title>
        <div />
        <h3>Información de la transacción</h3>
      </Title>
      <Logo src={logo} alt="Juancho te presta" />
      <Item>
        <Graytext>Razón social:</Graytext>
        <Bluetext>Juancho Te Presta S.A.S</Bluetext>
      </Item>
      <Item>
        <Graytext>NIT:</Graytext>
        <Bluetext>901.200.575-1</Bluetext>
      </Item>
      {!details && (
        <>
          <Item>
            <Redtext>
              Espere un momento, se esta cargando la información de la
              transacción.
            </Redtext>
          </Item>

          <PDFprint>
            <PDFbuttons>
              <Button
                as={NavLink}
                to={`/loans/quotas/${match.params.loanId}/${match.params.code}`}
                color="blueDark"
                outline
                sm
              >
                Regresar
              </Button>
            </PDFbuttons>
          </PDFprint>
        </>
      )}
      {details && details.amount && (
        <>
          <Item>
            <Graytext>Valor del pago:</Graytext>
            <Bluetext>{money(details.amount.subtotalIva0)}</Bluetext>
          </Item>
          <Item>
            <Graytext>Fecha de la transacción:</Graytext>
            <Bluetext>
              {unix(details.created / 1000).format('DD-MM-YYYY')}
            </Bluetext>
          </Item>
          <Item>
            <Graytext>Estado de transacción:</Graytext>
            <Bluetext>{translateState(details.processorState)}</Bluetext>
          </Item>
          <Item>
            <Graytext>Banco: </Graytext>
            <Bluetext>
              {bankList.values.find(
                // eslint-disable-next-line
                ({ value }) => value == details.bankId
              )?.label ?? details.bankId}
            </Bluetext>
          </Item>
          <Item>
            <Graytext>CUS: </Graytext>
            <Bluetext>{details.trazabilityCode}</Bluetext>
          </Item>
          <Item>
            <Graytext>Número de transacción: </Graytext>
            <Bluetext>{details.trazabilityCode}</Bluetext>
          </Item>
          <Item>
            <Graytext>Descripción del pago:</Graytext>
            <Bluetext>Pago procedente de la </Bluetext>
          </Item>
          {repayment.status === 'loaded' && (
            <>
              <Item>
                <Bluetext>
                  cuota Nº {repayment.payload.paymentId} del crédito Nº
                  {repayment.payload.loanId}.
                </Bluetext>
              </Item>
                <>
                  <ItemHelp>
                    <Graytext>
                      Si tienes algún inconveniente con tu pago y necesitas
                       verificar el estado de tu transacción, contáctanos en{' '} 
                       <a
                        href="mailto:ayuda@juanchotepresta.com"
                        title="ayuda@juanchotepresta.com"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ marginLeft: '4px', marginRight: '4px' }}
                      >
                        ayuda@juanchotepresta.com
                      </a>{' '}o llámanos al 018000413811.
                    </Graytext>
                  </ItemHelp>
                </>
              <PDFprint>
                <PDFbuttons>
                  <Button
                    as={NavLink}
                    to={`/loans/quotas/${match.params.loanId}/${match.params.code}`}
                    color="blueDark"
                    outline
                    sm
                  >
                    Regresar
                  </Button>
                </PDFbuttons>

                <PDFDownloadLink
                  document={
                    <PDFfile
                      amount={
                        money(details.amount) &&
                        money(details.amount.subtotalIva0)
                      }
                      date={unix(details.created / 1000).format('DD-MM-YYYY')}
                      transactionState={translateState(details.processorState)}
                      bank={
                        bankList.values.find(
                          (value) => value.value === details.bankId
                        )
                          ? bankList.values.find(
                              (value) => value.value === details.bankId
                            )!.label
                          : details.bankId
                      }
                      CUS={details.trazabilityCode}
                      ticketNumber={details.trazabilityCode}
                      paymentId={repayment.payload.paymentId}
                      loanId={repayment.payload.loanId}
                    />
                  }
                  fileName="transaccionJTP.pdf"
                  style={{
                    textDecoration: 'none',
                    color: '#4a4a4a',
                    backgroundColor: 'transparent',
                    height: '40px',
                    border: '1px solid transparent',
                  }}
                >
                  <PDFprint>
                    <PDFbuttons>
                      <Button color="red" outline sm>
                        Descargar PDF
                      </Button>
                    </PDFbuttons>
                  </PDFprint>
                </PDFDownloadLink>
              </PDFprint>
            </>
          )}
        </>
      )}
    </TransactionView>
  );
};

export default LoanPaymentfeedback;
