import { FormFieldsError } from './FormFieldsError';
import serviceBuilder from './serviceBuilder';

export interface Params {
  newPassword: string;
  token: string;
}

export interface Result {
  changed: boolean;
  message?: string;
}

export const url = 'users/password';

const service = serviceBuilder<Params, Result>('post', {
  url,
  auth: false,
});

export default async (params: Params) => {
  try {
    await service(params);
  } catch {
    throw new FormFieldsError({
      'form-error': 'Su contraseña no pudo ser actualizada',
      hidden: true,
    });
  }
};
