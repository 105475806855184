import React from 'react';
export const Aval = {
  main: (
    <p>
      Garantía en la que un tercero asegura el cumplimiento de tu obligación en
      caso de incumplimiento. Recuerda que es opcional si traes un codeudor.
    </p>
  ),
  accept: 'Aceptar',
};
