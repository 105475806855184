import normalize from './normalize';
import { createGlobalStyle } from './styled-components';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  html {
    font-family: Poppins, sans-serif;
    color: ${(props) => props.theme.gray900};
    margin: 0;
    padding: 0;
    height: 100%;
  }
  h1 {
    font-size: 36px;
  }
  a, a:visited {
    color: ${(props) => props.theme.primary500};
    text-decoration: none;
  }


  body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #root {
    height: 100%;
  }
  
  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10,10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }

    &:active:after {
      transform: scale(0,0);
      opacity: .2;
      transition: 0s;
    }
  }
}
`;

export default GlobalStyle;
