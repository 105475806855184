import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoanList } from '../../services/loansPayment';

const initialState: LoanList[] = [];

const loan = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    update(state, action: PayloadAction<Partial<LoanList>>) {
      return { ...state, ...action.payload };
    },
    clear() {
      return initialState;
    },
  },
});

export default loan;
