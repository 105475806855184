export default ({
  amount,
  term,
  interest,
  technology,
  electronicSignature,
  guaranteeVariable,
  VAT,
}: {
  [v: string]: number;
}) => {
  const termDays = term * 30;

  /* Iva */
  const valueElectronicSignature = electronicSignature / termDays;

  const vat = valueElectronicSignature * 30;

  return vat;
};
