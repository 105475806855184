import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressCard,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faChevronDown,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFrownOpen,
  faInfoCircle,
  faLock,
  faPhone,
  faPrint,
  faSearch,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faAddressCard,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faChevronDown,
  faCreditCard,
  faDollarSign,
  faEnvelope,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faInfoCircle,
  faLock,
  faPhone,
  faPrint,
  faSearch,
  faUser,
  faFrownOpen
);
