import styled from '../../styles/styled-components';
import { Wizard } from './useWizard';
import WizardStep from './WizardStep';

export interface Props {
  wizard: Wizard;
}

const WizardSteps = styled.div<Props>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  align-content: center;
  justify-content: start;
  position: relative;
  width: 100%;
  min-width: 0;
  overflow: hidden;

  ${WizardStep} {
    min-width: 100%;
    visibility: hidden;
    transition: transform 0.25s ease-in-out;
    transform: translateX(
      ${props => `calc(${100 * (1 - props.wizard.step)}%)`}
    );
  }

  ${WizardStep}:nth-child(${props => props.wizard.step}) {
    position: relative;
    visibility: visible;
  }
`;

export default WizardSteps;
