import { Form as FormikForm } from 'formik';
import styled from '../../styles/styled-components';

const Form = styled(FormikForm)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export default Form;
