import serviceBuilder from './serviceBuilder';

interface Params {
  url: string;
}

export const url = 'sec/loans/signature';

export default serviceBuilder<Params>('post', {
  url,
  auth: true,
});
