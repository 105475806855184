import React from 'react';
import { connect } from 'react-redux';
import Mount from '../../../components/Mount';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import basicInfoPatch from '../../../services/basicInfoPatch';
import { departmentGet } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import { updateBasicInfo } from '../../../store/actions/user';
import { asyncDispatch } from '../../../store/asyncDispatch';
import UpdatePersonalInformationView, {
  UserInfo,
} from './UpdatePersonalInformationView';

interface Props extends UserInfo {
  updateBasicInfo(): Promise<void>;
}

export const ErrorMessage = {
  main: (
    <p>
      ¡Lo sentimos! Por el momento no es posible actualizar su informacion
      personal. Inténtalo mas tarde.
    </p>
  ),
  accept: true,
};

const UpdatePersonalInformationContainer: React.FC<Props> = ({
  departmentCode,
  cityCode,
  address,
  cellPhone,
  neighborhood,
  email,
  updateBasicInfo,
}) => {
  const [modal, openModal] = useModalC();

  return (
    <>
      <ModalC props={modal} />
      <Mount
        before={async () => {
          const departments = await departmentGet();
          console.log("vamos a ctualizar datos personales")

          const userInfo = {
            departmentCode,
            cityCode,
            neighborhood,
            address,
            email,
            cellPhone,
          };

          return {
            departments,
            userInfo,
            async submit(values: UserInfo) {
              try {
                
                await basicInfoPatch(values);
                try {
                  await updateBasicInfo();
                } catch (e) {}
              } catch (error) {
                openModal(ErrorMessage);
              }
            },
          };
        }}
        props={{}}
        component={UpdatePersonalInformationView}
      />
    </>
  );
};

export default connect(
  (state: JTPState) => ({
    departmentCode: state.user.residential.departmentCode,
    cityCode: state.user.residential.cityCode,
    neighborhood: state.user.residential.neighborhood,
    address: state.user.residential.address,
    email: state.user.user.email,
    cellPhone: state.user.user.cellPhone,
  }),
  (dispatch) => ({
    updateBasicInfo: asyncDispatch(dispatch, updateBasicInfo),
  })
)(UpdatePersonalInformationContainer);
