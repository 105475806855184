import serviceBuilder from './serviceBuilder';

type ScreenType = 'Predisburse' | 'smsCode'|'simulator';

export interface Pixel {
  requestMethod: 'GET' | 'POST';
  url: string;
  body: string;
  screen: ScreenType;
  headers: string;
}

export interface Result {
  code: number;
  pixels: Pixel[];
}

const url = 'sec/events/pixel';
export default async () => {
  const response = await serviceBuilder<void, Result>('get', {
    url,
    auth: true,
    silent: true,
  })();
  return response.status !== 'error' ? response.payload : undefined;
};
