import Cookie from 'js-cookie';

export const getUtmCookie = (() => {
  return Cookie.get('utm') ? Cookie.get('utm') : '';
})();

export const getCookieAccept = () => {
  return Cookie.get('jtp_cookie') ? true : false;
};

export const getCookieRequest = () => {
  let cookie = Cookie.get('jtp_cookie');
  return cookie ? JSON.parse(cookie).request : false;
};

export const getCookieTimestamp = () => {
  let cookie = Cookie.get('jtp_cookie');
  return cookie ? JSON.parse(cookie).timestamp : false;
};

export const setCookieRequest = (value: boolean) => {
  let cookie = Cookie.get('jtp_cookie');
  if (cookie) {
    createCookie('jtp_cookie', {
      ...JSON.parse(cookie),
      request: value,
    });
  }
};

export const createCookie = (name: string, value: string, days = 365) => {
  let REACT_APP_ENV = process.env.REACT_APP_ENV;
  let NODE_ENV = process.env.NODE_ENV;
  let domain =
    NODE_ENV === 'production'
      ? REACT_APP_ENV === 'test'
        ? '.vertical.com.co'
        : '.juanchotepresta.com'
      : '';
  Cookie.set(name, value, { expires: days, path: '/', domain });
};
