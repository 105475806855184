/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Column from '../../../components/Grid/Column';
import { RejectMessage } from '../../../components/ModalMessage';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import VideoID from '../../../components/VideoID';
import preventDefault from '../../../helpers/preventDefault';
import useService from '../../../hooks/useService';
import useVideoVerif from '../../../hooks/useVideoVerif';
import loanDesistPost from '../../../services/loanDesistPost';
import videoIDGet from '../../../services/videoIDGet';
import { ResolveNextPath, resolveNextPath } from '../../../store/actions/navigation';
import { sumCountEid } from '../../../store/actions/session';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { JTPState } from '../../../store/index';
import styled from '../../../styles/styled-components';

const ErrorVideoIDMessage = {
  main: <p>No fue posible iniciar la validación de identidad.</p>,
  accept: 'Abandonar',
  noClosable: true,
};

// const ErrorVideoEnableWhatsapp = (fullName: String) => ({
//   main: (
//     <p>
//       Para validar tu identidad, agenda una cita de videollamada con uno de
//       nuestros asesores, haz clic{' '}
//       <a
//         href={href(fullName)}
//         rel="noopener noreferrer"
//         target="_blank"
//         title="WhatsApp"
//         style={{ marginLeft: '4px', fontWeight: 'bold' }}
//       >
//         aquí.
//       </a>
//     </p>
//   ),
//   accept: 'Abandonar',
//   noClosable: true,
// });

const ErrorMessage = {
  main: (
    <div>
      <p>
        No fue posible verificar tu identidad. Por favor comunícate con nosotros
        a la línea nacional gratuita 018000413811,
        <a
          href="mailto:ayuda@juanchotepresta.com"
          title="ayuda@juanchotepresta.com"
          rel="noopener noreferrer"
          target="_blank"
          style={{ marginLeft: '4px', marginRight: '4px' }}
        >
          ayuda@juanchotepresta.com
        </a>{' '}
        o
        <a
          href="https://wa.me/5744807833"
          rel="noopener noreferrer"
          target="_blank"
          title="WhatsApp"
          style={{ marginLeft: '4px' }}
        >
          https://wa.me/5744807833
        </a>
      </p>
    </div>
  ),
  accept: 'Abandonar',
  noClosable: true,
};

const cancelProcessModal = {
  main: (
    <>
      <p>
        Cancelaste tu solicitud de crédito. Recuerda que puedes realizar una
        nueva en cualquier momento.
      </p>
    </>
  ),
  cancel: '¡Hasta pronto!',
};
const GeneralContent = styled.div`
  display: flex;
  flex-grow: 0;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
  color: ${props => props.theme.blueDark};

  > div {
   
    max-width: 305px;
    @media (min-width: 700px) {
      max-width: 450px;
    }
  }
  .red {
    color: red;
  }
  > p {
    margin:0;
    max-width: 305px;
    @media (min-width: 700px) {
      max-width: 450px;
    }
  }
  > h2 {
    margin-top:50px;
  }
`;

const ContentStyled = styled.div`
  text-align: left;
  padding-top: 15px;

  div {
    padding-top: 8px;
    text-align:justify;
  }
`;
const ContentId = styled.div`
  margin-top:16px;
  margin-bottom:16px;
`

interface Props extends RouteComponentProps {
  resolveNextPath: ResolveNextPath;
  loans: any;
  countEid: number;
  fullName: String;
  setCountEid(): void;
}

const ValidateIdentityViewPre: React.FC<Props> = ({
  history,
  resolveNextPath,
  loans,
  countEid,
  setCountEid,
  fullName,
}) => {
  const [videoIDVisible, setVideoIDVisible] = React.useState(false);
  const [modal, openModal] = useModalC();
  const [verifyDesistCodeStatus, postLoanDesist] = useService(loanDesistPost);

  const [videoID, startVideo] = useVideoVerif();

  const [iv, getIv] = useService(videoIDGet);

  // //test
  // useEffect(() => {
  //   console.log(videoID, 'videoID');
  // }, [videoID]);

  useEffect(() => {
    console.log(iv, 'xd');
  }, [iv]);

  //valid error
  useEffect(() => {
    if (videoID.status === 'error') {
      openModal(ErrorVideoIDMessage).then(() => history.push('/logout'));
    }
    if (videoID.status === 'videoid-cancel') {
      history.push('/logout');
    }
    if (videoID.status === 'videoid-completed') {
      (async () => {
        try {
          let iv = await getIv();
          if (iv.payload.sucessfully) {
            if(iv.payload.statusClient==="reject"){
              await openModal(RejectMessage);
              history.push("/logout");
            }else{
              let signatur=false
              while(signatur===false){
                let { next } = await resolveNextPath();
                if(next ==='/loans/signature'){
                  signatur=true
                  history.push(next);
                }
              }
            }  
          } else if (iv.payload.whatsapp === true) {
            await openModal(ErrorMessage);
            history.push('/logout');
          } else {
            while (
              iv.payload.sucessfully===false
            ) {
              iv = await getIv();
              if (iv.payload.sucessfully) {
                if(iv.payload.statusClient==="reject"){
                  await openModal(RejectMessage);
                  history.push("/logout");
                }else{
                  let signatur=false
                  while(signatur===false){
                    let { next } = await resolveNextPath();
                    if(next ==='/loans/signature'){
                      signatur=true
                      history.push(next);
                    }
                  }
                } 
              } else if (iv.payload.whatsapp === true) {
                await openModal(ErrorMessage);
                history.push('/logout');
              }
            }
          }
        } catch (e) {
          console.warn('ERROR EN resolveNextPath', e);
          history.push('/logout');
        }
      })();
    }
  }, [videoID.status]);

  //valid successfull
  // useEffect(() => {
  //   (async () => {
  //     if (videoID.status === 'videoid-completed') {
  //       try {
  //         const { next } = await resolveNextPath();
  //         //if (next === '/users/disclaimer') {
  //           //veriffFrame.close();
  //           history.push(next);
  //         //}
  //         console.log('Redireccion a: ' + next);
  //       } catch (e) {
  //         console.warn('ERROR EN resolveNextPath', e);
  //         if (e instanceof ApiError) {
  //           // if (
  //           //   videoID.status === 'videoid-error' &&
  //           //   videoID.error === 'FacesMismatch.Detected'
  //           // ) {
  //           //   const event =
  //           //     Object.values(loans || []).length > 1
  //           //       ? 'RechazoRecurrente'
  //           //       : 'RechazoNuevo';
  //           //   //sendEventToGTM(event);
  //           //   await openModal(RejectMessage);
  //           //   history.push('/logout');
  //           //} else {
  //           await openModal(ErrorMessage);
  //           history.push('/logout');
  //           //}
  //         }
  //       }
  //     }
  //   })();
  // }, []);

  /*
  useEffect(() => {
    if (countEid > 1) {
      openModal(ErrorVideoEnableWhatsapp(fullName)).then(() =>
        history.push('/logout')
      );
    }
  }, [countEid]);

  useEffect(() => {
    if (videoID.status === 'ready') {
      createVideoID();
    }
    if (videoID.status === 'error') {
      openModal(ErrorVideoIDMessage).then(() => history.push('/logout'));
    }
  }, [videoID.status]);

  useEffect(() => {
    (async () => {
      if (
        videoID.status === 'videoid-completed' ||
        (videoID.status === 'videoid-error' &&
          videoID.error !== 'Connection.Lost')
      ) {
        try {
          await videoIDPost({ videoId: videoID.id });
          if (videoID.status === 'videoid-error') {
            const { next } = await resolveNextPath();
            if (next === '/users/security-questions') {
              offVideoID();
              history.push(next);
            }
          }

          if (videoID.status === 'videoid-completed') {
            history.push('/users/security-questions');
          }
        } catch (e) {
          if (e instanceof ApiError) {
            if (
              videoID.status === 'videoid-error' &&
              videoID.error === 'FacesMismatch.Detected'
            ) {
              const event =
                Object.values(loans || []).length > 1
                  ? 'RechazoRecurrente'
                  : 'RechazoNuevo';
              sendEventToGTM(event);
              await openModal(RejectMessage);
              history.push('/logout');
            } else {
              await openModal(ErrorMessage);
              history.push('/logout');
            }
          }
        }
      }
    })();
  }, [videoID]);*/

  return (
    <>
      <ModalC props={modal} />
      <VideoID visible={videoIDVisible} />
      <Column fluid>
        <GeneralContent>
          {!videoIDVisible && (
            <Column fluid>
              <GeneralContent>
                <h2>Verificación de identidad requerida.</h2>
                <ContentStyled>
                  <div>
                    <b>1.</b> Asegúrate de tener una conexión a internet,
                    preferiblemente a través de Wifi.
                  </div>
                  <div>
                    <b>2.</b> Toma tu cedula física.
                  </div>
                  <div>
                    <b>3.</b> Ubícate en un lugar bien iluminado.
                  </div>
                  <div>
                    <b>4.</b> Autoriza el acceso a la cámara para tomar la foto. 
                  </div>
                  <ContentId>
                    Si vas a utilizar la cédula digital, haz clic{' '}
                    <a
                      href="https://wa.me/5744807833"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="WhatsApp"
                      style={{ color: '#083863', fontWeight: 'bold' }}
                    >
                      aquí
                    </a>{' '}para ponerte en contacto con nosotros.
                  </ContentId>
                </ContentStyled>
                <div className="red">
                  <p>
                  Al hacer clic en “verificar mi identidad” estás aceptando
                  el tratamiento de tus datos sensibles; puedes cancelar 
                  la solicitud si no estás de acuerdo.
                  </p>
                </div>
              </GeneralContent>
              <ButtonContainer>
{/*                 <Button
comentdo
                  onClick={() => {
                    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
                      console.log("Stream ==> ", stream)
                    }).catch((err) => console.log("Error en ==> ", err))
                  }}
                  loading={videoID.status === 'loading'}
                  expand
                >
                  validar permisos
                </Button> */}
                <Button
                  onClick={() => {
                    startVideo();
                    // startVideoID();
                    setVideoIDVisible(true);
                  }}
                  loading={videoID.status === 'loading'}
                  expand
                >
                  VERIFICAR MI IDENTIDAD
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  onClick={preventDefault(async () => {
                    try {
                      await postLoanDesist();
                    } catch { }
                    await openModal(cancelProcessModal).then(({ cancel }) => {
                      cancel && history.push('/logout');
                    });
                  })}
                  loading={verifyDesistCodeStatus.status === 'loading'}
                  flat
                >
                  Cancelar solicitud de Crédito
                </Button>
              </ButtonContainer>
            </Column>
          )}
        </GeneralContent>
      </Column>
    </>
  );
};

export default connect(
  (state: JTPState) => ({
    loans: state.loans,
    countEid: state.session.countEid,
    fullName: state.user.user.fullName,
  }),
  (dispatch) => ({
    resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
    setCountEid() {
      dispatch(sumCountEid());
    },
  })
)(ValidateIdentityViewPre);
