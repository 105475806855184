import serviceBuilder from './serviceBuilder';

export interface Result {
  sucessfully: boolean;
  whatsapp: true;
  statusClient: String;
}

export const url = 'sec/users/iv';

export default serviceBuilder<void, Result>('get', {
  url,
  auth: true,
});
