import { createAction } from '@reduxjs/toolkit';
import { UserAttrs } from '../../helpers/tokenHelpers';
import { LoanStatus } from '../../services/loanChangeStatusPut';
import { PathName } from '../../views/Workflow';
import { ActionMeta } from '../asyncDispatch';

export type NavigationRequirePayload = {
  loanStatus?: LoanStatus | LoanStatus[];
  attrs?: Partial<UserAttrs>;
};

export const navigationRequires = createAction(
  'navigation/requires',
  (payload: NavigationRequirePayload, meta: ActionMeta) => ({
    payload,
    meta,
  })
);

export const resolveNextPath = createAction(
  'navigation/resolveNextPath',
  (payload?: PathName, meta?: ActionMeta) => ({ payload, meta })
);

export const pathChanged = createAction<{ previous: string; current: string }>(
  'navigation/nextPath'
);

export type ResolveNextPath = (
  current?: PathName
) => Promise<{ next: PathName }>;
