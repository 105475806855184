import { Method } from './api';
import servicesJSON from './servicesDescriptions.json';

interface serviceUrlBase {
  [idx: string]: {
    method: Method;
    description: string;
  }[];
}

const services: serviceUrlBase = JSON.parse(JSON.stringify(servicesJSON));

export const servicesDescriptions = (path: string, methodParam: Method) => {
  return services[path]?.find(({ method }) => method === methodParam) ?? '';
};
