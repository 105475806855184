import { LoanSimulatorAmountSlider as AmountSlider } from './LoanSimulatorAmountSlider';
import {
  Props,
  LoanSimulatorComponent as Simulator,
} from './LoanSimulatorComponent';
import { LoanSimulatorContext as Context } from './LoanSimulatorContext';
import { LoanSimulatorLabel as Label } from './LoanSimulatorLabel';
import { LoanSimulatorTermOptions as TermOptions } from './LoanSimulatorTermOptions';

export type LoanSimulatorProps = Props;

const Loan = {
  Context,
  AmountSlider,
  TermOptions,
  Label,
  Simulator,
};

export default Loan;
