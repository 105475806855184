import Fingerprint2 from 'fingerprintjs2';

export const fingerprint = new (class {
  hash = 'unknown';
  webgl = 'unknown';
  canvas = 'unknown';
  deviceInfo = {};

  async compute() {
    await this.idleBrowser();

    const components = await Fingerprint2.getPromise({
      excludes: {
        fonts: true,
        adBlock: true,
        audio: true,
        enumerateDevices: true,
      },
    });

    const componentsReduce = components.reduce(
      (obj: Record<string, string>, { key, value }) => {
        obj.hash = obj.hash + [value].join('');

        if (key in obj) {
          obj[key] = [value].join('');
        }

        return obj;
      },
      {
        webgl: '',
        canvas: '',
        hash: '',
        userAgent: '',
        language: '',
        screenResolution: '',
        timezone: '',
        platform: '',
        webglVendorAndRenderer: '',
      }
    );

    this.deviceInfo = {
      userAgent: componentsReduce.userAgent,
      language: componentsReduce.language,
      screenResolution: componentsReduce.screenResolution,
      timezone: componentsReduce.timezone,
      platform: componentsReduce.platform,
      webglVendorAndRenderer: componentsReduce.webglVendorAndRenderer,
    };
    this.hash = Fingerprint2.x64hash128(componentsReduce.hash, 31);
    this.webgl = Fingerprint2.x64hash128(componentsReduce.webgl, 31);
    this.canvas = Fingerprint2.x64hash128(componentsReduce.canvas, 31);
  }

  private idleBrowser() {
    return new Promise((resolve) => {
      if ('requestIdleCallback' in window) {
        (window as any).requestIdleCallback(resolve);
      } else {
        setTimeout(resolve, 500);
      }
    });
  }
})();
