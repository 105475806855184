import serviceBuilder from './serviceBuilder';

export interface LoanValues {
  amount: number;
  repaymentsStartingFromDate: string;
  term: number;
  loanPurpose: number;
  termsAndConditions: boolean;
}

export const url = 'sec/loans';

export default serviceBuilder<LoanValues>('patch', {
  url,
  auth: true,
});
