/* eslint react-hooks/exhaustive-deps: 0 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import noop from '../../helpers/noop';
import noticeIcon from '../../images/campanaicon.png';
import closeIcon from '../../images/cerraricon.png';
import styled from '../../styles/styled-components';

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  #z-index: 1000;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom:0;
  @media (min-width: 600px) {
    padding-bottom:160px;
    }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`;
const MainContent = styled.div`
  position: relative;
  max-height: 95%;
  z-index: 1001;
  border-radius: 10px;
`;
const Circle = styled.div`
display:flex;
align-items:center;
justify-content:center;
border-radius: 50%;
background-color: white;
border:2px solid rgb(255, 205, 0);
width: 313px;
height: 363px;
@media (min-width: 700px) {
    width: 413px;
    height: 413px;
    }
`;
const CloseIcon = styled.img`
  width: 18px;
  position:absolute;
  top:10px;
  right:10px;
  cursor:pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2); 
  }
`;
const SecondContent = styled.div`
background-color: rgb(255, 205, 0);
position:relative;
display:flex;
flex-direction:column;
border-radius:5px; 
align-items:center;
justify-content:center;
border-radius: 50%;
width: 300px;
height: 350px;
@media (min-width: 700px) {
    width: 400px;
    height: 400px;
    }
`
const IconContainer = styled.div`
  position:absolute;
  background-color: white;
  display:flex;
  align-items:center;
  justify-content:center;
  top:-40px;
  right:45px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  @media (min-width: 700px) {
    width: 150px;
    height: 150px;
    }
`;
const NoticeContent = styled.img`
    width: 70px;
    @media (min-width: 700px) {
        width: 90px;
        }
`;
const Textcontainer = styled.div`
color: #000;
disply:flex;
align-items: center;
justify-content: center;
> h4 {
  margin: 90px 0 0 0;
  font-weight: 600;
  font-size: 22px;
}
`;
const Textblock = styled.div`
padding:0 20px 0 20px;
text-align: justify;
@media (min-width: 700px) {
    padding:0 50px 0 50px;
    }
`;
const Button3 = styled.button`
  background-color: rgb(255, 205, 0);
  color: #000;
  border: none;
  padding: 6px 20px;
  border-radius: 10px;
  margin:20px 0 16px;
  cursor:pointer;
  > p {
    margin: 3px;
    font-size: 16px;
    font-weight: 700;
  }
  &:hover {
    background-color: rgb(255, 205, 0); 
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

// modal 2
type Fulfill = (value: { accept?: boolean; cancel?: boolean }) => void;

interface ModalContent {
  title?: ReactNode;
  main: ReactNode;
  accept?: string | boolean;
  cancel?: string | boolean;
  noClosable?: boolean;
}

type ModalAsync = (
  c: ModalContent
) => Promise<{ accept?: boolean; cancel?: boolean }>;

export const useModalA = (): [
  { content?: ModalContent; fulfill: Fulfill; close: () => void },
  ModalAsync
] => {
  const [content, setContent] = useState<ModalContent>();
  const [fulfill, setFulfill] = useState<Fulfill>(noop);

  const modal = useCallback((c: ModalContent) => {
    setContent(c);
    const promise = new Promise((resolve: Fulfill) => {
      setFulfill(() => resolve);
    });
    return promise;
  }, []);

  const close = useCallback(() => {
    setContent(undefined);
  }, []);

  return [{ content, fulfill, close }, modal];
};

interface ModalProps {
  props: {
    fulfill: Fulfill;
    content?: ModalContent;
    close(): void;
  };
  container?: HTMLElement;
}

export const ModalA: React.FC<ModalProps> = ({
  props: { content, fulfill, close },
  container = document.getElementById('modal'),
}) => {
  const cancel = useCallback(() => {
    fulfill({ cancel: true });
    close();
  }, [fulfill]);

  useEffect(() => {
    return () => {
      if (fulfill) fulfill({ cancel: true });
      close();
    };
  }, []);

  return (
    <>
      {container &&
        content &&
        ReactDOM.createPortal(
          <ModalStyled>
            <Background onClick={() => !content.noClosable && cancel()} />
            <MainContent>
              <CloseIcon 
                onClick={cancel}
                src={closeIcon}
                alt="Vigilado Superintendencia Financiera de Colombia"
              />
              <Circle>
                <SecondContent>
                  <IconContainer>
                    <NoticeContent
                      src={noticeIcon}
                      alt="Vigilado Superintendencia Financiera de Colombia"
                    />
                  </IconContainer>
                  <Textcontainer>
                    <h4> !Buenas noticias!</h4>
                    <Textblock>
                    {content.main && <div className="main">{content.main}</div>}
                    </Textblock>
                    <Textblock>
                      <p>
                        Completa los últimos pasos para el desembolso de tu préstamo.
                      </p>
                    </Textblock>
                  </Textcontainer>
                  {content.cancel && (
                    <Button3  onClick={cancel}>
                      {content.cancel === true ? 'Cancelar' :(<p>{content.cancel}</p>) }
                    </Button3>
                  )}
                </SecondContent>
              </Circle>
            </MainContent>
          </ModalStyled>,
          container
        )}
    </>
  );
};
