import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import NotFound from '../NotFound';
import SignUpView from './SignUp/SignUpView';
import SmsCodeView from './SmsCode/SmsCodeView';

const SignUpRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    <Route exact path="/signup" component={SignUpView} />
    <Route path="/signup/phone-validation" component={SmsCodeView} />
    <Route component={NotFound} />
  </Switch>
);

export default SignUpRoutes;
