/* eslint react-hooks/exhaustive-deps: 0 */

import serviceBuilder from './serviceBuilder';

export interface Params {
  phone: string;
}

export interface Retry {
  (): void;
}

export interface Result {
  sendType: string;
  status: boolean;
}

export const url = 'users/sms/:phone';

export default serviceBuilder<Params, Result>('post', {
  url,
  auth: false,
  silent: true,
  query: true,
});
