import { SagaIterator } from 'redux-saga';
import { call, race, take } from 'redux-saga/effects';
import { logoutRequest } from '../actions/session';
import { sentryConfigureContext } from './Sentry';
import { logout, tokenExpWatcher } from './session';

export function* logoutFlow(): SagaIterator {
  try {
    yield race([call(tokenExpWatcher), take(logoutRequest)]);
  } finally {
    yield call(logout);
    yield call(sentryConfigureContext);
  }
}
