/* eslint react-hooks/exhaustive-deps: 0 */
import serviceBuilder from './serviceBuilder';

export interface Params {
  givenname: string;
  surname: string;
  lastname: string;
  email: string;
  password: string;
  idType: string;
  id: string;
  phone: string;
  acceptedTerms: boolean;
  uuid: string;
  tradeAgreementCode: string;
}

export interface Result {
  token: string;
}

export const url = 'users';

export const signupPost = serviceBuilder<Params, Result>('post', {
  url,
  auth: false,
});
