import { object, ref, string } from 'yup';

export default object({
  newPassword: string()
    .min(4, 'Mínimo 4 caracteres')
    .required('Campo requerido'),

  confirmPassword: string().oneOf(
    [ref('newPassword')],
    'La contraseña no coincide'
  ),
});
