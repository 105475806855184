import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from '../../styles/styled-components';

interface StyledProps {
  invalid?: boolean;
}

const RadioStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
`;

const DescriptionStyled = styled.div`
  color: #38761d;
  font-size: 11px;
`;

const Input = styled.input<StyledProps>`
  margin-right: 8px;
  appearance: none;
  border: 5px solid ${(props) => props.invalid?props.theme.danger: props.theme.gray200};
  border-radius: 50%;
  min-height: 28px;
  min-width: 28px;
  position: relative;
  outline: none;

  :checked:after {
    content: '';
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.blue};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  :focus {
    box-shadow: 0 0 5px
      ${(props) =>
        props.invalid ? props.theme.danger : props.theme.inputFocus};
  }

  + label {
    color: ${(props) => (props.invalid ? props.theme.danger : 'inherit')};
  }
`;

const InputRadioComponent: React.FC<
  FieldProps & { radioValue: any } & { disabled: boolean } & {
    description: string;
  }
> = ({ field, form, radioValue, children, disabled, description }) => (
  <RadioStyled>
    <Input
      id={field.name + radioValue}
      invalid={form.touched[field.name]! && !!form.errors[field.name]}
      {...field}
      disabled={disabled}
      value={radioValue}
      checked={radioValue.toString() === field.value}
      type="radio"
    />
    <label htmlFor={field.name + radioValue}>
      {children}
      <DescriptionStyled>{description}</DescriptionStyled>
    </label>
  </RadioStyled>
);

export interface Props {
  name: string;
  value: string | number;
  label?: string | React.ReactNode;
  disabled?: boolean;
  description?: string;
}

const InputRadio: React.FC<Props> = ({
  name,
  value,
  label,
  children,
  disabled = false,
  description,
}) => (
  <Field
    name={name}
    disabled={disabled}
    description={description}
    component={InputRadioComponent}
    radioValue={value}
    children={children || label}
  />
);

export default InputRadio;
