import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { idleLogout } from './helpers/idleLogout';
import { initSentry } from './helpers/sentry';
import setVersion from './helpers/setVersion';
import * as serviceWorker from './serviceWorker';
import { fingerprint } from './trackers/fingerprint';

initSentry();
setVersion();
idleLogout();
fingerprint.compute();

// (window as any).setFixtures(true);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration: any) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event: any) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
