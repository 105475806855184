let t: number = 0;

export function idleLogout() {
  window.onload = resetTimer;
  window.onmousemove = resetTimer;
  window.onmousedown = resetTimer; // catches touchscreen presses as well
  window.ontouchstart = resetTimer; // catches touchscreen swipes as well
  window.onclick = resetTimer; // catches touchpad clicks as well
  window.onkeypress = resetTimer;

  function toLogoutPage() {
    window.location.pathname = '/logout';
  }

  function resetTimer() {
    clearTimeout(t);
    t = setTimeout(toLogoutPage, 15 * (60 * 1000)); // time is in milliseconds
  }
}
