import serviceBuilder from './serviceBuilder';

export interface Params {
  paymentId: string;
}

export interface Repayment {
  totalInterest: any;
  lastRepayment: boolean;
  complete: boolean;
  loanId: string;
  paymentId: string;
  kushkiToken: string;
  trazabilityCode: string;
  date: string;
  initialAmount: number;
  balance: number;
  interest: number;
  fees: number;
  feeChargesAtDisbursementCharged: number;
  penalties: number;
  repaymentAmount: number;
  capital: number;
}

export const url = 'sec/loans/rs/:paymentId';

export default serviceBuilder<Params, Repayment>('get', {
  auth: true,
  url,
});
