import React from 'react';
export const Plataforma = {
  main: (
    <div>
      <p>
        Te permite firmar electrónicamente tu cupo de endeudamiento para
        desembolso. Si no quieres incurrir en dicho costo y cuentas con un
        certificado digital vigente debes enviar toda la documentación al
        correo:{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          title="ayuda@juanchotepresta.com"
          href="mailto:ayuda@juanchotepresta.com"
        >
          ayuda@juanchotepresta.com
        </a>
      </p>
      <p>
        <strong>Solo</strong> si no pudiste contratar una firma digital con un
        ente certificador, podrás enviarnos los documentos de manera física a la
        dirección estipulada en el contrato.
      </p>
    </div>
  ),
  accept: 'Aceptar',
};
