import { createAction } from '@reduxjs/toolkit';
import { ActionMeta } from '../asyncDispatch';

export const updateSimulatorStart = createAction(
  'simulator/update-start',
  (payload: void, meta: ActionMeta) => ({
    meta,
    payload,
  })
);

export type UpdateSimulatorStart = () => Promise<void>;
