import React from 'react';
import styled from '../styles/styled-components';
import { Service } from '../types/Service';

interface AlertProps {
  color: 'success' | 'danger';
}

const Alert = styled.small<AlertProps>`
  white-space: pre-line;
  display: block;
  color: ${props => props.theme[props.color]};
  padding: 5px 4px;
  font-weight: bold;
  max-width: 500px;
`;

export interface Props {
  service: Service<any>;
  visible?: boolean;
  success?: string;
  error?: string;
}

const MessageStatus: React.FC<Props> = ({
  service,
  success,
  error,
  visible = true,
}) => {
  if (visible) {
    if (service.status === 'error') {
      return (
        <Alert color="danger">
          {error === undefined ? service.error.message : error}
        </Alert>
      );
    }

    if (success && service.status === 'loaded') {
      return <Alert color="success">{success}</Alert>;
    }
  }

  return null;
};

export default MessageStatus;
