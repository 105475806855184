import { Formik } from 'formik';
import React, { useRef } from 'react';
import { object, string } from 'yup';
import Form from '../../../../components/Form/Form';
import FormError from '../../../../components/Form/FormError';
import FormGroup from '../../../../components/Form/FormGroup';
import FormSubmit from '../../../../components/Form/FormSubmit';
import InputDate from '../../../../components/Form/InputDate';
import InputSelect from '../../../../components/Form/InputSelect';
import Label from '../../../../components/Form/Label';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../../components/UI/ButtonGroup';
import GeneralContent from '../../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import { submitTrap } from '../../../../helpers/formHelpers';
import postPayment from '../../../../services/management/payment';
import styled from '../../../../styles/styled-components';
import toBase64 from './tobase64';
import { dateSchema, selectSchema } from './ValidationSchema';

const InputFile = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const initialValues = {
  paymentDate: '',
  applyTo: '',
  attachment: '',
  fileMimeType: '',
};

const options = {
  applyTo: [
    { label: 'Reducción plazo', value: 'Reducción plazo' },
    {
      label: 'Reducción de cuota mensual',
      value: 'Reducción de cuota mensual',
    },
  ],
};

const ErrorModal = {
  main: (
    <p>No fue posible enviar la información, por favor intentelo más tarde.</p>
  ),
  accept: 'Aceptar',
};

const Payment: React.FC = () => {
  const [modal, openModal] = useModalC();
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const reset = () => {
    if (ref.current) ref.current.value = '';
  };
  return (
    <GeneralContent>
      <ModalC props={modal} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          paymentDate: dateSchema() as any,
          applyTo: selectSchema,
          attachment: string().required('Requerido'),
        })}
        onSubmit={submitTrap(async (values, { resetForm }) => {
          try {
            await postPayment(values);
            reset();
            resetForm(initialValues);
          } catch (error) {
            openModal(ErrorModal);
          }
        })}
        render={({ setFieldValue }) => {
          return (
            <Form>
              <FormGroup>
                <Label title="Ingrese la fecha en que realizó el abono a capital">
                  Fecha del abono
                </Label>
                <InputDate name="paymentDate" />
              </FormGroup>
              <FormGroup>
                <Label title="Elija si desea aplicar a disminución de cuota mensual o de plazo">
                  Aplicar a
                </Label>
                <InputSelect name="applyTo" options={options.applyTo} />
              </FormGroup>
              <FormGroup>
                <InputFile>
                  <Label title="adjunte el comprobante de pago">Adjunto</Label>
                  <input
                    type="file"
                    name="attachment"
                    id="attachment"
                    ref={ref}
                    accept="image/*, application/pdf"
                    onChange={async (event) => {
                      setFieldValue('attachment', '');
                      setFieldValue('fileMimeType', '');
                      let file: File | null = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        try {
                          let attachment = await toBase64(file);
                          setFieldValue('attachment', attachment);
                          setFieldValue('fileMimeType', file.type ?? '');
                        } catch (error) {}
                      }
                    }}
                  />
                </InputFile>
              </FormGroup>
              <FormGroup>
                <FormError />
              </FormGroup>
              <ButtonGroup>
                <ButtonContainer>
                  <FormSubmit>Agregar</FormSubmit>
                </ButtonContainer>
              </ButtonGroup>
            </Form>
          );
        }}
      ></Formik>
    </GeneralContent>
  );
};

export default Payment;
