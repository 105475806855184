import React from 'react';
export const Garantias = {
  main: (
    <p>
      Garantía sobre un porcentaje de tu salario o ingreso para amparar la
      obligación que adquieres con nosotros.
    </p>
  ),
  accept: 'Aceptar',
};
