import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoanStatus } from '../../services/loanChangeStatusPut';

export type LoanState = {
  id: string;
  status: LoanStatus | 'unknown';
  amount: number;
  term: number;
  score: number;
  hasSocialSecurity: boolean;
  product:string;
  simUserMsg:string;
  purpose: {
    value: number;
    label: string;
  };
};

const initialState: LoanState = {
  id: '',
  status: 'unknown',
  amount: 0,
  term: 0,
  purpose: {
    label: '',
    value: 0,
  },
  score: 0,
  product:"JTD",
  simUserMsg:"jtdAcceptMsg",
  hasSocialSecurity: false,
};

const loan = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    update(state, action: PayloadAction<Partial<LoanState>>) {
      return { ...state, ...action.payload };
    },
    clear() {
      return initialState;
    },
  },
});

export default loan;
