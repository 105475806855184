import { Formik } from 'formik';
import React, { useRef } from 'react';
import { object, string } from 'yup';
import Form from '../../../../components/Form/Form';
import FormGroup from '../../../../components/Form/FormGroup';
import FormSubmit from '../../../../components/Form/FormSubmit';
import { formats } from '../../../../components/Form/Input';
import InputGroup from '../../../../components/Form/InputGroup';
import InputSelect from '../../../../components/Form/InputSelect';
import Label from '../../../../components/Form/Label';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../../components/UI/ButtonGroup';
import GeneralContent from '../../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import { submitTrap } from '../../../../helpers/formHelpers';
import postReliefs from '../../../../services/management/reliefs';
import styled from '../../../../styles/styled-components';
import toBase64 from './tobase64';
import { emailSchema, selectSchema } from './ValidationSchema';

const InputFile = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Styled = styled.div`
  .card {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${(props) => props.theme.white};
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    margin-bottom: 10px;

    .circle {
      background-color: white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`

const initialValues = {
  reliefReason: '',
  justification: '',
  supports: '',
  fileMimeType: '',
  clientEmail: '',
};

const options = {
  reliefReason: [
    { label: 'Enfermedad', value: 'Enfermedad' },
    { label: 'Desempleo', value: 'Desempleo' },
    { label: 'Accidente', value: 'Accidente' },
  ],
};

const ErrorModal = {
  main: (
    <p>No fue posible enviar la información, por favor intentelo más tarde.</p>
  ),
  accept: 'Aceptar',
};


const Reliefs: React.FC = () => {
  const [modal, openModal] = useModalC();
  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
  const reset = () => {
    if (ref.current) ref.current.value = '';
  };
  return (

    <GeneralContent>
      <ModalC props={modal} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          reliefReason: selectSchema,
          justification: string().required('Requerido'),
          supports: string().required('Requerido'),
          clientEmail: emailSchema,
        })}
        onSubmit={submitTrap(async (values, { resetForm }) => {
          try {
            await postReliefs(values);
            reset();
            resetForm(initialValues);
          } catch (error) {
            openModal(ErrorModal);
          }
        })}
        render={({ setFieldValue }) => {
          return (
            <Form>
              <Styled>
                <div className="card">
                  <Label>
                    Juancho te ofrece periodo de gracia o ampliación de plazo cuando estás al día con tu crédito, pero tienes problemas para pagar tu próxima cuota.
                  </Label>
                </div>
              </Styled>
              <FormGroup>
                <Label title="Escoja una razón por la cual solicita el alivio">
                  Razón del alivio
                </Label>
                <InputSelect
                  name="reliefReason"
                  options={options.reliefReason}
                />
              </FormGroup>
              <FormGroup>
                <Label title="Haga un breve resumen del motivo del alivio, este solo se podrá otorgar si posee hasta dos cuotas en mora, para moras mayores solicite una refinanciación">
                  Justificación
                </Label>
                <InputGroup type="textarea" name="justification" />
              </FormGroup>
              <FormGroup>
                <InputFile>
                  <Label title="Adjunte documentos que certifiquen su situación)">
                    Soporte
                  </Label>
                  <input
                    type="file"
                    name="supports"
                    id="supports"
                    ref={ref}
                    accept="image/*, application/pdf"
                    onChange={async (event) => {
                      setFieldValue('supports', '');
                      setFieldValue('fileMimeType', '');
                      let file: File | null = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;

                      if (file) {
                        try {
                          let supports = await toBase64(file);
                          setFieldValue('supports', supports);
                          setFieldValue('fileMimeType', file.type ?? '');
                        } catch (error) { }
                      }
                    }}
                  />
                </InputFile>
              </FormGroup>
              <FormGroup>
                <Label title="Ingrese el correo electrónico al que dese que enviemos la respuesta a su solicitud">
                  Correo eléctronico
                </Label>
                <InputGroup
                  type="email"
                  icon="envelope"
                  name="clientEmail"
                  placeholder="example@mail.com"
                  format={formats.email}
                />
              </FormGroup>
              <ButtonGroup>
                <ButtonContainer>
                  <FormSubmit>Agregar</FormSubmit>
                </ButtonContainer>
              </ButtonGroup>
            </Form>
          );
        }}
      ></Formik>
    </GeneralContent>
  );
};

export default Reliefs;
