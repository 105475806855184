/* eslint react-hooks/exhaustive-deps: 0 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import noop from '../../helpers/noop';
import PositiIcon from '../../images/Positivoicon.png';
import noticeIcon from '../../images/advertenciaicon.png';
import closeIcon from '../../images/cerraricon.png';
import styled from '../../styles/styled-components';
// import NavLink from '../UI/NavLink';

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  #z-index: 1000;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom:0;
  @media (min-width: 600px) {
    padding-bottom:160px;
    }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`;
const Content = styled.div`
  background-color: rgb(48, 92, 255);
  position:relative;
  display:flex;
  flex-direction:column;
  padding: 45px 0px 20px 0px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  max-height: 95%;
  z-index: 1001;
  width: 320px;
  border-radius: 10px;
  align-items:flex-end;
  overflow: hidden;
`;
const Textcontainer = styled.div`
color:white;
margin:8px 0 0 0;
padding:10px 10px 3px 65px;
text-align:end;
> p {
    font-size: 17px;
    margin:0; 
    padding:0;
    font-weight:500; 
    line-height:1;
  }
`;
const CloseIcon = styled.img`
  width: 18px;
  position:absolute;
  top:10px;
  right:10px;
  cursor:pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2); 
  }
`;
const NoticeContent = styled.img`
  width: 70px;
  position:absolute;
`;
const IconContainer = styled.div`
  position:absolute;
  background-color: #fff;
  display:flex;
  align-items:center;
  justify-content:center;
  bottom:-11px;
  left:-11px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;
const Info = styled.div`
background-color: #fff;
border-radius: 5px 0 0 5px ; 
justify-content:flex-end;
align-items:flex-end;
width:270px;
color: #000;
padding:0 12px 0 5px;
> p {
    font-size: 17px;
    margin: 6px 0 6px 6px; 
    font-weight:700; 
  }
`
const Button3 = styled.button`
  background-color: rgb(199, 221, 241);
  color: #000;
  border: none;
  padding: 6px 20px 4px 20px;
  border-radius: 10px 0 0 10px;
  margin:9px 0 0px;
  cursor:pointer;
  width:75px;
  > p {
    margin: 3px;
    font-size: 14px;
    font-weight:700;
  } 
  &:hover {
    background-color: white; 
    color:#000;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
`;

// modal 2
type Fulfill = (value: { accept?: boolean; cancel?: boolean }) => void;

interface ModalContent {
  title?: ReactNode;
  main: ReactNode;
  accept?: string | boolean;
  cancel?: string | boolean;
  noClosable?: boolean;
  icone?:boolean;
}

type ModalAsync = (
  c: ModalContent
) => Promise<{ accept?: boolean; cancel?: boolean }>;

export const useModalF = (): [
  { content?: ModalContent; fulfill: Fulfill; close: () => void },
  ModalAsync
] => {
  const [content, setContent] = useState<ModalContent>();
  const [fulfill, setFulfill] = useState<Fulfill>(noop);

  const modal = useCallback((c: ModalContent) => {
    setContent(c);
    const promise = new Promise((resolve: Fulfill) => {
      setFulfill(() => resolve);
    });
    return promise;
  }, []);

  const close = useCallback(() => {
    setContent(undefined);
  }, []);

  return [{ content, fulfill, close }, modal];
};

interface ModalProps {
  props: {
    fulfill: Fulfill;
    content?: ModalContent;
    close(): void;
  };
  container?: HTMLElement;
}

export const ModalF: React.FC<ModalProps> = ({
  props: { content, fulfill, close },
  container = document.getElementById('modal'),
}) => {  
  const accept = useCallback(() => {
    fulfill({ accept: true });
    close();
  }, [fulfill]);

  useEffect(() => {
    return () => {
      if (fulfill) fulfill({ cancel: true });
      close();
    };
  }, []);

  return (
    <>
      {container &&
        content &&
        ReactDOM.createPortal(
          <ModalStyled>
            <Background/>
            <Content>
                <CloseIcon
                  onClick={accept}
                  src={closeIcon}
                  alt="Vigilado Superintendencia Financiera de Colombia"
                />
              <IconContainer>
                {content.icone===true?(<NoticeContent
                  src={PositiIcon}
                  alt="Vigilado Superintendencia Financiera de Colombia"
                />):(<NoticeContent
                  src={noticeIcon}
                  alt="Vigilado Superintendencia Financiera de Colombia"
                />)}
              </IconContainer>
              <Info>
              {content.title && <p className="title">{content.title}</p>}
              </Info>
              <Textcontainer>
              {content.main && <div className="main">{content.main}</div>} 
              </Textcontainer>
              {content.accept && (
                      <Button3 onClick={accept}>
                        {content.accept === true ? 'Aceptar' : (<p>{content.accept}</p>)}
                      </Button3>
                  )}
            </Content>
          </ModalStyled>,
          container
        )}
    </>
  );
};
