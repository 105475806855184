/**
 * PMT returns the payment amount for a loan based on an interest rate and a constant payment
 * @param interest The interest rate for the loan.
 * @param term  The number of payments for the loan.
 * @param presentValue  The present value or principal of the loan.
 */
export default (interest: number, term: number, presentValue: number) => {
  return (
    ((interest * presentValue * Math.pow(1 + interest, term)) /
      (1 - Math.pow(1 + interest, term))) *
    -1
  );
};
