import serviceBuilder from './serviceBuilder';

type ScreenType = 'Predisburse' | 'smsCode'|'simulator';

export interface Pixel {
  requestMethod: 'GET' | 'POST';
  url: string;
  body: string;
  screen: ScreenType;
  headers: string[];
}

export interface Result {
  code: number;
  pixels: Pixel;
}

interface Params {
  tokenUuid: string;
}

const url = 'events/pixel/:tokenUuid';
export default serviceBuilder<Params, Result>('get', {
  auth: false,
  url,
  silent: true,
});
