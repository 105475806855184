import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: left;
  ol {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
  }
  li {
    display: block;
    margin-bottom: 0.2em;
    margin-left: 2em;
  }
  li::before {
    display: inline-block;
    content: attr(value) ') ';
    counter-increment: item;
    width: 2em;
    margin-left: -2em;
  }
`;

export default (props: Record<'username', string>) => {
  return (
    <Container>
      <p>
        Hola <i>{props.username}</i> como no quieres hacer uso de nuestra
        tecnología para facilitar el trámite de tu solicitud de crédito, debes
        enviarnos suscritos los siguientes documentos, por email con certificado
        digital:
      </p>
      <ol>
        <li value="I">Copia de tu cédula ampliada.</li>
        <li value="II">Certificado de ingresos.</li>
        <li value="III">
          Carta laboral expedida por tu empleador con menos de 30 días o
          certificado de ingresos emitido por contador público o ante notario.
        </li>
        <li value="IV">Extracto bancario.</li>
        <li value="V">Copia de tus servicios públicos.</li>
      </ol>
      <p>Si eres independiente deberás anexar:</p>
      <ol>
        <li value="VI">Certificación de pagos a la seguridad social.</li>
        <li value="VII">Copia de la matrícula de tu vehículo.</li>
      </ol>
      <p>ADICIONALMENTE deberás imprimir, firmar y escanear:</p>
      <ol>
        <li value="VIII">El presente contrato de mutuo.</li>
        <li value="IX">El contrato de garantías mobiliarias.</li>
        <li value="X">El poder.</li>
        <li value="XI">El pagaré.</li>
        <li value="XII">Carta de instrucciones.</li>
      </ol>
      <p>
        Solo en casos excepcionales, cuando te sea imposible contratar una firma
        digital, podrás enviarnos todo lo anterior notarizado a la dirección de
        nuestras oficinas que aparece en nuestra página web{' '}
        <strong>
          <a
            href="https://www.JuanchoTePresta.com"
            title="www.JuanchoTePresta.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.JuanchoTePresta.com
          </a>
        </strong>
        .
      </p>
      <p>
        Para consultar proveedores de firma digital consulta en:{' '}
        <strong>
          <a
            href="https://onac.org.co/servicios/entidades-de-certificacion-digital/"
            title="https://onac.org.co/servicios/entidades-de-certificacion-digital/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://onac.org.co/servicios/entidades-de-certificacion-digital/
          </a>
        </strong>
      </p>
    </Container>
  );
};
