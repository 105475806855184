import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export type SocialSecurityResponse =
  | { running: true; code__: number; userFound?: boolean }
  | {
      running: false;
      userFound: true;
      code__: number;
    };

export interface Error {
  userFound: false;
  message: 'string';
}

export const url = 'sec/loans/ss';

export default async () => {
  const response = await serviceBuilder<None, SocialSecurityResponse>('get', {
    url,
    auth: true,
  })();

  return response.payload;
};
