import { ServiceLoaded } from '../types/Service';
import { LoanList } from './loansPayment';
import serviceBuilder from './serviceBuilder';

// const url = 'loans/status/:token?command=allLoans';
// export default serviceBuilder<{ uuidToken: string }, LoanList>('get', {
//   query: true,
//   url,
//   auth: false,
// });

const url = 'loans/status/:token?command=allLoans';
const service = serviceBuilder<{ token: string }, LoanList>('get', {
  query: true,
  url,
  auth: false,
});

export default async function (
  token: string
): Promise<ServiceLoaded<LoanList>> {
  try {
    const { payload, status } = await service({ token });
    for (let k in payload) {
      if (!payload[k].statusId) {
        delete payload[k];
      }
    }

    return { payload, status };
  } catch (e) {
    throw e;
  }
}
