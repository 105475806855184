import { SagaIterator } from 'redux-saga';
import { all, call, delay, spawn } from 'redux-saga/effects';
import { requestWatchers } from './requestWatchers';
import { controlSessionSaga } from './session';

export function* rootSaga(): SagaIterator {
  yield all(
    [requestWatchers, controlSessionSaga].map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            yield delay(3000);
          }
        }
      })
    )
  );
}
