import periodicPaymentAmount from './periodicPaymentAmount';

export default ({
  amount,
  term,
  interest,
}: {
  [v: string]: number;
}) => {
  const interestMonthly = Math.pow(1 + interest, 1 / 12) - 1;

  const redem = periodicPaymentAmount(interestMonthly, term, amount);

  return redem;
};
