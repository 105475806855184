//import uuid from 'uuid';
import Uuid from '../../helpers/uuid';
import { Params, Result } from '../../services/useSignUpPost';
import { createRequestSlice } from './createRequestReducer';

export const signup = createRequestSlice<Params, Result, any>({
  name: 'signup',
  reducers: {
    preRequest: {
      reducer(state, action) {
        return {
          ...state,
          params: action.payload,
        };
      },
      prepare(payload: any) {
        delete payload.confirmPassword;
        delete payload.confirmEmail;
        for (let k in payload) {
          if (typeof payload[k] === 'string') {
            payload[k] = `${payload[k]}`.trim();
          }
        }
        return { payload, uuid: Uuid.user };
        //return { payload, uuid: uuid.v4() };
      },
    },
  },
});
