import styled from 'styled-components';

const WizardStep = styled.div<{ step: number }>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  left: ${props => `${(props.step - 1) * 100}%`};
  color: ${props => props.theme.blueDark};
`;

export default WizardStep;
