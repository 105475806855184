import { PayloadAction } from '@reduxjs/toolkit';
import updateLoanStatusPatch from '../../services/updateLoanStatusPatch';
import callService from './callService';

export function* updateLoanSaga(
  action: PayloadAction<{
    uuid: string;
  }>
) {
  yield callService(updateLoanStatusPatch, action.payload);
}
