import styled from '../../styles/styled-components';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  color: ${props => props.theme.blueDark};
  width: 300px;
  text-align: left;
  @media (min-width: 700px) {
    width: 350px;
  }
`;

export default FormGroup;
