import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: left;
`;

export default (props: Record<'username', string>) => {
  return (
    <Container>
      <p>
        Hola <i>{props.username}</i> como no deseas hacer uso de los proveedores
        de Aval con los que JuanchoTePresta trabaja, y para poder continuar con
        el proceso de firma de tus contratos y el desembolso de tu préstamo,
        debes cumplir el siguiente requisito:
      </p>
      <ul>
        <li>Tener un codeudor con propiedad raíz libre de hipoteca.</li>
        <li>
          Certificado de tradición vigente con no menos de 30 días de
          expedición.
        </li>
        <li>Certificaciones laborales del codeudor solidario.</li>
        <li>
          certificado de ingresos expedido por contador, y pagaré y carta de
          instrucciones firmados por el codeudor solidario debidamente
          notarizados.
        </li>
      </ul>
      <p>
        También podrás presentar pólizas, fianza o avales (cualquiera de ellas)
        que representen garantías para el pago, tomadas de manera individual con
        otras entidades legalmente constituidas cuyo beneficiario deberá
        aparecer a nombre JUANCHO TE PRESTA SAS, las cuales deben ser aprobadas
        previamente por nosotros.
      </p>
      <p>
        Si no quieres incurrir en dicho costo y cuentas con un certificado
        digital vigente debes enviar toda la documentación al correo:{' '}
        <a
          href="mailto:ayuda@juanchotepresta.com"
          title="ayuda@juanchotepresta.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          ayuda@juanchotepresta.com
        </a>
        .
      </p>
      <p>
        <strong>Solo</strong> si no pudiste contratar una firma digital con un
        ente certificador, podrás enviarnos los documentos de manera física a la
        dirección estipulada en el contrato.
      </p>
    </Container>
  );
};
