import { object, string } from 'yup';
import { FormValues } from './LogInView';

export default object<FormValues>({
  id: string()
    .trim()
    .required('Campo requerido'),
  password: string()
    .min(4, 'Mínimo 4 caracteres')
    .required('Campo requerido'),
});
