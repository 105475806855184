/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useContext } from 'react';
import styled from '../../styles/styled-components';
import Button from '../UI/Button';
import { LoanSimulatorContext } from './LoanSimulatorContext';

const TermButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TermButton = styled(Button)<{ selected: boolean }>`
  margin-left: 3px;
  background-color: ${(props) =>
    props.selected ? props.theme.blueDark : props.theme.gray100};

  color: ${(props) => (props.selected ? 'white' : props.theme.gray600)};

  border: 1px solid ${(props) => props.theme.gray100};
  margin-right: -1px;
  border-radius: 0;
  flex-grow: 0;
  font-size: 18px;
  font-weight: bold;
  padding: 8px 30px;

  label {
    display: ${(props) => (props.selected ? 'block' : 'none')};
    font-size: 12px;
  }

  :first-child {
    margin-left: 4px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  :last-child {
    margin-right: 4px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const TermsStyled = styled.div`
  margin: 10px 2px;
  > label {
    font-size: 18px;
  }
`;

type MouseEventHandler = React.MouseEventHandler<HTMLButtonElement>;

export interface Props {}

export const LoanSimulatorTermOptions: React.FC<Props> = () => {
  const context = useContext(LoanSimulatorContext);

  const onTermChange: MouseEventHandler = React.useCallback(
    (e) => {
      e.preventDefault();
      const value = Number(e.currentTarget.name);
      context.update({ type: 'term', value });
    },
    [context.terms, context.term]
  );

  return (
    <TermsStyled>
      <label>¿Cuál es tu plazo?</label>
      <TermButtonContainer>
        {context.terms.map((term) => (
          <TermButton
            key={term}
            name={term.toString()}
            selected={term === context.term}
            onClick={onTermChange}
          >
            {term}{' '}
            <div>
              <label>Meses</label>
            </div>
          </TermButton>
        ))}
      </TermButtonContainer>
    </TermsStyled>
  );
};
