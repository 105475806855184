/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import FormGroup from '../../../components/Form/FormGroup';
import Input from '../../../components/Form/Input';
import SubmitPro from '../../../components/Form/SubmitPro';
import MessageStatus from '../../../components/MessageStatus';
import MessageStatusO from '../../../components/MessageStatusO';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import { submitTrap } from '../../../helpers/formHelpers';
import sendEventToGTM from '../../../helpers/tagManager';
import useService from '../../../hooks/useService';
import { Pixel } from '../../../services/pixelGet';
import verifySmsCodePost, { Params } from '../../../services/smsCodePost';
//import useSendSmsCodePost from '../../../services/useSmsCodePost';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import uuid from '../../../helpers/uuid';
import useSmsCodePostResult from '../../../services/useSmsCodePostResult';
import { JTPState } from '../../../store';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { signup } from '../../../store/reducers/signupReducer';
import Omit from '../../../types/Omit';
import Hero from '../../Hero';
import validationSchema from './ValidationSchema';

export interface FormValues extends Omit<Params, 'phone'> {}
const RejectMessage = {
  main: (
    <p>
      Estamos teniendo algunas dificultades en el proceso.
      Por favor, contáctanos en nuestra línea gratuita
      nacional al 018000413811 para brindarte ayuda.
    </p>
  ),
  accept: true,
};
interface Props extends RouteComponentProps {
  phone: '';
  signup: JTPState['requests']['signup'];
  signupRequest<T>(): Promise<T>;
}

const SmsCodeView: React.FC<Props> = ({
  history,
  phone,
  signup,
  signupRequest,
}) => {
  const [modal, openModal] = useModalC();
  const [verifySmsCodeStatus, postVerifySmsCode] =
    useService(verifySmsCodePost);

  //const [sendSmsCodeStatus, sendSmsCodeRetry] = useSendSmsCodePost(phone);

  const [message, setText] = useState(true)

  const [error, setError] = useState(false)
  const [loading,setLoading] =useState(false)

  const service = useSmsCodePostResult

  useEffect(() => {
   
    if( error ){
      //console.log('No se envio')
    } else {
      msg()
    }
  }, [error, loading]);

  function msg () {
    (async () => {
      try {
        const result = (await service({phone})).payload
        if (result.sendType === 'wa') {
          setText(true)
        } else if(result.sendType === 'sms') {
          setText(false)
        } else {
          setError(true)
        }
      } catch (error) {
        console.log(error)
        setError(true)
      }
    })();
  }


  useEffect(() => {
    if (!phone) {
      history.push('/signup');
    }
  }, [signup]);

  return (
    <>
    <ModalC props={modal} />
    <div>

      <Hero
        title="Te prestamos hasta 2 veces tus ingresos"
        info="Ayúdanos a verificar tu cuenta para continuar con el proceso"
      />
      <GeneralContent>
        
        <React.Fragment>
          {message &&           
          <p>
            Ingresa el código que enviamos por <strong> Whatsapp </strong>  a tu celular para
            que actives tu cuenta y empieces a diligenciar tu solicitud de
            crédito.
          </p>}
          {!message &&           
          <p>
            Ingresa el código que enviamos por <strong>  Mensaje de texto </strong> a tu celular para
            que actives tu cuenta y empieces a diligenciar tu solicitud de
            crédito.
          </p>}
        </React.Fragment>
        
        <p>Es un proceso muy fácil y rápido.</p>

        <Button
          disabled={verifySmsCodeStatus.status === 'loaded'}
          //loading={verifySmsCodeStatus.status === 'loading'}
          outline
          sm
          color="blueDark"
          onClick={msg}
        >
          Reenviar código.
        </Button>

        <MessageStatus
          service={verifySmsCodeStatus}
          success="Tu código fue ingresado correctamente."
          error="Código incorrecto"
        />
        <MessageStatusO
          validate={error}
          error="No fue posible enviar el mensaje de SMS."
        />

        <Formik
          initialValues={{ code: '' }}
          validationSchema={validationSchema}
          onSubmit={submitTrap(async (values,{ setSubmitting }) => {
            setLoading(true)
            const r = await postVerifySmsCode({ 
              code: values.code.trim(),
              phone: phone,
            });

           if (r.status !== 'error') {
            for(let retry=0; retry<=3;retry++ ){
              let result = await signupRequest();
              // let result:Pixel[] =[]

              if (Array.isArray(result)) {
                retry=4;
                const listPixel: Pixel[] = result;
                listPixel.forEach((pixel: Pixel) => {
                  if (pixel.screen === 'smsCode') {
                    let img = document.createElement('img');
                    img.src = pixel.url;
                    document.body.appendChild(img);
                  }
                });
                sendEventToGTM('Registro');
                history.push('/users/basic-info');
              }else if (typeof result === 'string') {
                setLoading(false)
                if (result !=="U-001"||retry>=2){ 
                  retry=4;
                  await openModal(RejectMessage);
                  history.push('/logout');
                }else{
                  uuid.newUser();
                }
              }
            } 
            await openModal(RejectMessage);
            history.push('/logout'); 
           }else{
            setLoading(false)
           }
           setSubmitting(false)
          })}
        >{({ isSubmitting }) => (
          <Form>
            <FormGroup>
              <Input name="code" placeholder="Ingresa tu código" />
            </FormGroup>
            <ButtonContainer>
              <SubmitPro
                loading={
                  verifySmsCodeStatus.status === 'loading' || signup.loading || isSubmitting || loading 
                }
                disabled={loading}
              >
                Enviar
              </SubmitPro>
            </ButtonContainer>
          </Form>
          )}
        </Formik>
      </GeneralContent>
    </div>
    </>
  );
};

export default connect(
  (state: JTPState, ownProps: any) => ({
    signup: state.requests.signup,
    phone: state.requests.signup?.params?.phone || '',
    ...ownProps,
  }),
  (dispatch) => ({
    signupRequest: asyncDispatch(dispatch, signup.actions.request),
  })
)(SmsCodeView);
