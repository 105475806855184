/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Column from '../../../components/Grid/Column';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { Spinner } from '../../../components/UI/Spinner';
import sendEventToGTM from '../../../helpers/tagManager';
import timeout from '../../../helpers/timeout';
import useBelvo, {
  onEventResponse,
  onExitResponse,
} from '../../../hooks/useBelvo';
import useService from '../../../hooks/useService';
import scrappingGet from '../../../services/scrappingGet';
import scrappingPost, {
  Params as ParamsScraping,
} from '../../../services/scrappingPost';
import { JTPState } from '../../../store';
import {
  ResolveNextPath,
  resolveNextPath,
} from '../../../store/actions/navigation';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { breakpointLarge } from '../../../styles/breakpoints';
import ApiError from '../../../types/ApiError';

const P = styled.p`
  text-align: justify;
  font-size: 17px;
`;

const Container = styled.div`
  text-align: left;
  margin-top: 2em;

  ul {
    margin-top: 2em;
  }

  li {
    margin-bottom: 0.6em;
  }
`;

const RejectMessage = {
  main: (
    <p>
      ¡Lo sentimos! Por el momento no es posible tramitar tu crédito. Inténtalo
      nuevamente en al menos 90 días.
    </p>
  ),
  accept: true,
};

const ScrappingFormError = {
  main: (
    <P>
      ¡Los datos no coinciden! Recuerda que debes usar el nombre de usuario y la
      clave que usas en tu banco.
    </P>
  ),
  accept: 'Aceptar',
  noClosable: true,
};

const ScrappingError = {
  main: (
    <P>
      No logramos verificar tus ingresos, envíanos tus extractos bancarios de
      los últimos 3 meses al correo: <b>ayuda@juanchotepresta.com</b>
    </P>
  ),
  accept: 'Salir',
  noClosable: true,
};

const ScrappingErrorMaximumAttempts = {
  main: (
    <P>
      Has superado el número máximo de intentos, por favor revisa tus datos o
      comunícate con nosotros a 018000413811,{' '}
      <a
        href="https://wa.me/5744807833"
        rel="noopener noreferrer"
        target="_blank"
        title="WhatsApp"
      >
        https://wa.me/5744807833
      </a>{' '}
      o escríbenos a ayuda@juanchotepresta.com.
    </P>
  ),
  accept: 'Salir',
  noClosable: true,
};

const ScrappingStartError = {
  main: (
    <P>
      Ocurrió un error mientras se iniciaba el procesos de validación, por favor
      vuelva a intentarlo más tarde.
    </P>
  ),
  accept: 'Salir',
  noClosable: true,
};

const LoadingStyled = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 15px;

  .desc {
    padding: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: ${(props) => props.theme.gray500};
    position: absolute;
    top: 33%;
    @media (min-width: ${breakpointLarge}) {
      top: 34%;
      font-size: 25px;
    }
    & > .title {
      padding-bottom: 10px;
    }
  }
`;

interface Props extends RouteComponentProps {
  resolveNextPath: ResolveNextPath;
  loans: any;
}

let countScraping = 0;

const ScrappingViewBelvo: React.FC<Props> = ({
  history,
  resolveNextPath,
  loans,
}) => {
  const [, getScrapping] = useService(scrappingGet);
  const [modal, openModal] = useModalC();
  const [loading, setloading] = useState(false);
  const [showInfo, setshowInfo] = useState(true);

  const initScrapping = useCallback(async (payload: ParamsScraping) => {
    const runScrapping = async (payload: ParamsScraping) => {
      const startScrapping = await scrappingPost(payload);
      if (startScrapping.status === 'loaded') {
        const { executionId } = startScrapping.payload;
        while (true) {
          let {
            payload: { status, code },
          } = await getScrapping({ executionId });

          if (status === 'finalized') return code;
          await timeout(1200);
        }
      } else {
        throw new Error('Error starting scrapping');
      }
    };

    try {
      setloading(true);
      const code = await runScrapping(payload);
      setloading(false);
      if ('0' === code) {
        try {
          setloading(true);
          const { next } = await resolveNextPath();
          setloading(false);
          history.push(next);
        } catch {
          setloading(false);
          const event =
            Object.values(loans || []).length > 1
              ? 'RechazoRecurrente'
              : 'RechazoNuevo';
          sendEventToGTM(event);
          await openModal(RejectMessage);
          history.push('/logout');
        }
      } else if ('1' === code) {
        await openModal(RejectMessage);
        history.push('/logout');
      } else if ('2' === code) {
        await openModal(ScrappingFormError);
        history.push('/logout');
      } else if (['3', '4', '5', '6'].includes(code)) {
        await openModal(ScrappingError);
        history.push('/logout');
      } else {
        throw new Error('Error unknown scrapping');
      }
    } catch (e) {
      setloading(false);
      if (e instanceof ApiError) {
        if (e.code === 429) {
          if ((await openModal(ScrappingErrorMaximumAttempts)).accept) {
            history.push('/logout');
          }
        } else if (e.code === 400 && e.message === 'Bad Request') {
          await openModal(ScrappingFormError);
          history.push('/logout');
        } else {
          if ((await openModal(ScrappingStartError)).accept) {
            history.push('/logout');
          }
        }
      }
    }
  }, []);

  const successCallbackFunction = async (link: string, institution: string) => {
    await initScrapping({
      scrapping_account_link_result: 'success',
      scrapping_account_link_id: link,
      scrapping_account_institution: institution,
      scrapping_event_id: '',
      scrapping_error_code: '',
      scrapping_error_message: '',
      scrapping_step: '',
      scraping_error_count: 0,
    });
  };

  const onExitCallbackFunction = async (data: onExitResponse) => {
    await initScrapping({
      scrapping_account_link_result: 'exit',
      scrapping_account_link_id: '',
      scrapping_account_institution: data.meta_data.institution_name ?? '',
      scrapping_event_id: '',
      scrapping_error_code: '',
      scrapping_error_message: '',
      scrapping_step: data.meta_data.step,
      scraping_error_count: 0,
    });
  };

  const onEventCallbackFunction = async (data: onEventResponse) => {
    if (data.eventName === 'ERROR' || data.eventName === 'WARNING') {
      try {
        let payload: ParamsScraping = {
          scrapping_account_link_result: 'error',
          scrapping_account_link_id: '',
          scrapping_account_institution: data.meta_data.institution_name ?? '',
          scrapping_event_id: data.eventName,
          scrapping_error_code: data.meta_data.error_code ?? '',
          scrapping_error_message: data.meta_data.error_message ?? '',
          scrapping_step: '',
          scraping_error_count: countScraping,
        };
        if (countScraping >= 2) {
          await initScrapping(payload);
        } else {
          await scrappingPost(payload);
        }
        countScraping = countScraping + 1;
      } catch (error) {}
    }
  };

  const [belvo, start] = useBelvo(
    successCallbackFunction,
    onEventCallbackFunction,
    onExitCallbackFunction
  );

  useEffect(() => {
    if (belvo.status === 'error') {
      (async () => {
        if ((await openModal(ScrappingStartError)).accept) {
          history.push('/logout');
        }
      })();
    }
  }, [belvo]);

  return (
    <div>
      {showInfo && (
        <Column>
          <GeneralContent>
            <Container>
              <p>¡Hola! no pudimos confirmar tus ingresos.</p>
              <p>Conecta tu cuenta bancaria para que puedas continuar:</p>
              <ul>
                <li>Agilizas tu solicitud de crédito.</li>
                <li>Solo nos das acceso para conocer tus ingresos.</li>
                <li>No podemos realizar operaciones con tus datos.</li>
                <li>No vemos tus contraseñas ni quedan guardadas.</li>
              </ul>
              <ButtonContainer>
                <Button
                  loading={loading || belvo.status === 'loading'}
                  onClick={() => {
                    setshowInfo(false);
                    start();
                  }}
                >
                  Continuar
                </Button>
              </ButtonContainer>
            </Container>
          </GeneralContent>
        </Column>
      )}
      {loading && (
        <LoadingStyled>
          <Spinner size={34} />
          <div className="desc">
            <div className="title">¡Tus datos están seguros con nosotros!</div>
            <div>
              Estamos verificando tu información, esto podría tardar unos
              minutos, no cierres tu navegador, ni te desconectes.
            </div>
            <div></div>
          </div>
        </LoadingStyled>
      )}
      <ModalC props={modal} />
      <div id="belvo"></div>
    </div>
  );
};

export default connect(
  (state: JTPState) => ({
    loans: state.loans,
  }),
  (dispatch) => ({
    resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
  })
)(ScrappingViewBelvo);
