import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import { BasicInfo } from '../../../services/basicInfoPost';
import { departmentGet, Selectors } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { basicInfo } from '../../../store/reducers/basicInfoReducer';
import { minIncome } from '../../../store/selectors/simulatorSelector';
import PersonalInformationView from './PersonalInformationView';

interface Props extends RouteComponentProps {
  mifos: Selectors;
  basicInfo: BasicInfo;
  minIncome: Record<string, number>;
  basicInfoRequest(payload: {
    values: BasicInfo;
    mifos: Selectors;
  }): Promise<any>;
}

const PersonalInformationContainer: React.FC<Props> = ({
  mifos,
  basicInfo,
  basicInfoRequest,
  minIncome,
  ...routeProps
}) => {
  return (
    <Mount
      before={async () => {
        const initialValues: BasicInfo = JSON.parse(JSON.stringify(basicInfo));
        const departments = await departmentGet();

        return {
          initialValues,
          departments,
          push(Url: string) {
            routeProps.history.push(Url);
          },
          async submit(values: BasicInfo) {
            await basicInfoRequest({ values, mifos });
            //routeProps.history.push('/users/validate-identity');
            routeProps.history.push('/loans/credit-history');
          },
        };
      }}
      props={{ mifos, minIncome }}
      component={PersonalInformationView}
    />
  );
};

export default connect(
  (state: JTPState, ownProps) => ({
    mifos: state.mifos,
    basicInfo: state.user,
    minIncome: minIncome(state),
    ...ownProps,
  }),
  (dispatch) => ({
    
    basicInfoRequest: asyncDispatch(dispatch, basicInfo.actions.request),
  })
)(PersonalInformationContainer);
