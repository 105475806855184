/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { JTPState } from '../store';
import { logoutPixelClear, logoutRequest } from '../store/actions/session';

interface Props extends RouteComponentProps<Record<'to', string>> {
  logout(): void;
  clearUtm(): void;
  loggedIn: boolean;
  ready: boolean;
}

const LogOut: React.FC<Props> = ({
  logout,
  loggedIn,
  history,
  match,
  ready,
  clearUtm,
}) => {
  useEffect(() => {
    if (ready) {
      if (loggedIn) {
        clearUtm();
        logout();
      }
    }
  }, [ready, loggedIn]);

  useEffect(() => {
    if (ready && !loggedIn) {
      if (match.params.to === 'home') {
        history.replace('/home');
      } else {
        history.push('');
      }
    }
  }, [ready, loggedIn]);

  return null;
};

export default connect(
  (state: JTPState, ownProps) => ({
    loggedIn: state.session.loggedIn,
    ready: state.ready,
    ...ownProps,
  }),
  (dispatch) => ({
    logout() {
      dispatch(logoutRequest());
    },
    clearUtm() {
      dispatch(logoutPixelClear());
    },
  })
)(LogOut);
