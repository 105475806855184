import ApiError from '../types/ApiError';
import { FormFieldsError } from './FormFieldsError';
import serviceBuilder from './serviceBuilder';

export interface Params {
  email: string;
  departmentCode?: string;
  cityCode?: string;
  neighborhood?: string;
  address?: string;
  cellPhone?: string;
}

export const url = 'sec/users/pi';

const service = serviceBuilder<Params>('patch', {
  url,
  auth: true,
});

export default async (params: Params) => {
  try {
    await service(params);
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.code === 410) {
        throw new FormFieldsError({
          email:
            'Este email, ya se encuentra registrado, por favor ingrese uno diferente',
        });
      }

      if (e.code === 400) {
        throw new FormFieldsError({
          email:
            'No fue posible enviar el mensaje, por favor intente nuevamente',
        });
      }
    }
    throw new FormFieldsError({
      email: 'Ocurrio un error desconocido',
    });
  }
};
