const red = {
  primary050: '#FFEEEE',
  primary100: '#FACDCD',
  primary200: '#F29B9B',
  primary300: '#E66A6A',
  primary400: '#D64545',
  primary500: '#BA2525',
  primary600: '#A61B1B',
  primary700: '#911111',
  primary800: '#780A0A',
  primary900: '#610404',
};

// const redVivid = {
//   primary050: '#FFE3E3',
//   primary100: '#FFBDBD',
//   primary200: '#FF9B9B',
//   primary300: '#F86A6A',
//   primary400: '#EF4E4E',
//   primary500: '#E12D39',
//   primary600: '#CF1124',
//   primary700: '#AB091E',
//   primary800: '#8A041A',
//   primary900: '#610316',
// };

const spacer = 16;

const theme = {
  ...red,
  gray050: '#F7F7F7',
  gray100: '#E1E1E1',
  gray200: '#CFCFCF',
  gray300: '#B1B1B1',
  gray400: '#9E9E9E',
  gray500: '#7E7E7E',
  gray600: '#626262',
  gray700: '#515151',
  gray800: '#3B3B3B',
  gray900: '#222222',

  grayLight: '#f4f4f4',
  gray202: '#e8e8e8',
  gray: '#616E81',
  grayDark: '#323232',
  blueLight: '#009ded',
  blue: '#305CFF',
  blueDark: '#083863',
  red: '#FF2C00',
  redMenu: '#FF2C00',
  yellow: '#FFCD00',

  white: '#fff',

  'bg-success': '#18981D',
  'bg-warning': '##FFCD00',
  'bg-danger': '#BA2525',
  success: '#28a745',
  warning: '#FFCD00',
  danger: '#ff7375',
  inputFocus: '#CFCFCF',
  borderRadius: '3px',

  spacing: {
    xs: `${spacer * 0.25}px`,
    sm: `${spacer * 0.5}px`,
    md: `${spacer}px`,
    lg: `${spacer * 1.5}px`,
    xl: `${spacer * 3}px`,
  },
};

export default theme;
