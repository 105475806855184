import React from 'react'
const Important = props=>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="19.203" viewBox="0 0 21 19.203">
  <g id="warning" transform="translate(0 0)">
    <g id="Group_728" data-name="Group 728">
      <path id="Path_714" className="fill-color" data-name="Path 714" d="M139.393,103.1a.585.585,0,0,0-.619.619v4.46a.619.619,0,1,0,1.239,0v-4.46A.585.585,0,0,0,139.393,103.1Z" transform="translate(-128.893 -96.66)" />
      <path id="Path_715" className="fill-color" data-name="Path 715" d="M139.765,210.306a.6.6,0,0,0-.867,0,.745.745,0,0,0-.124.5c0,.248,0,.372.124.5a.745.745,0,0,0,.5.124c.124,0,.372,0,.248-.124a.939.939,0,0,0,.248-.5C139.889,210.554,139.889,210.43,139.765,210.306Z" transform="translate(-128.893 -196.058)" />
      <path id="Path_716" className="fill-color" data-name="Path 716" d="M20.535,26.989l-7.31-12.761A3.078,3.078,0,0,0,10.5,12.617a3.229,3.229,0,0,0-2.726,1.611L.464,26.989a3.024,3.024,0,0,0,0,3.221A3.078,3.078,0,0,0,3.19,31.82H17.809a2.942,2.942,0,0,0,2.726-1.611A3.024,3.024,0,0,0,20.535,26.989Zm-.991,2.726a1.66,1.66,0,0,1-1.611.991H3.19a1.933,1.933,0,0,1-1.611-.991,1.9,1.9,0,0,1,0-1.982l7.31-12.761A1.66,1.66,0,0,1,10.5,13.98a1.977,1.977,0,0,1,1.735.991l7.31,12.761A1.9,1.9,0,0,1,19.543,29.714Z" transform="translate(0.001 -12.617)" />
    </g>
  </g>
</svg>
export default Important
