/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Form from '../../../components/Form/Form';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmit from '../../../components/Form/FormSubmit';
import InputSelect from '../../../components/Form/InputSelect';
import Label from '../../../components/Form/Label';
import Column from '../../../components/Grid/Column';
import AvalInfoIndicator from '../../../components/Indicators/AvalInfoIndicator';
import ElectronicInformation from '../../../components/Indicators/ElectronicSignature';
import Indicator from '../../../components/Indicators/Indicator';
import InteresRate from '../../../components/Indicators/InteresRate';
import LegalInformation from '../../../components/Indicators/LegalInfo';
import QuoteInfoIndicator from '../../../components/Indicators/QuoteInfoIndicator';
import Loan from '../../../components/LoanSimulator';
import { SimulatorValuesHandler } from '../../../components/LoanSimulator/LoanSimulatorComponent';
import Mount from '../../../components/Mount';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { ModalD, useModalD } from '../../../components/UI/ModalDecition';
import { ModalF, useModalF } from '../../../components/UI/ModalFirst';
import firstDatePayment from '../../../helpers/financial/firstDatePayment';
import { submitTrap } from '../../../helpers/formHelpers';
import moneyFormat from '../../../helpers/moneyFormat';
import preventDefault from '../../../helpers/preventDefault';
import sendEventToGTM from '../../../helpers/tagManager';
import useService from '../../../hooks/useService';
import useSimRiskMatrix from '../../../hooks/useSimRiskMatrix';
import noticeIcon from '../../../images/informacionicon.png';
import { LoanValues } from '../../../services/changeLoanValuesPatch';
import jtdClientDecisionPost from '../../../services/jtdClientDecisionPost';
import loanDesistPost from '../../../services/loanDesistPost';
import { Pixel } from '../../../services/pixelGet';
import { RiskMatrix } from '../../../services/riskMatrixGet';
import { Option } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import { ResolveNextPath } from '../../../store/actions/navigation';
import styled from '../../../styles/styled-components';
import LogOut from '../../LogOut';
import validationSchema from './ValidationSchema';

const SimulatorResults = styled.div`
  display: flex;
  flex-direction:column;
  align-items: stretch;
  text-align: left;
  margin: 24px 0 16px;
`;
const MessageContent = styled.div`
  background-color: rgb(237, 237, 237);
  position:relative;
  display:flex;
  justify-content:center;
  border-radius: 20px;
  margin-bottom:30px;
`;
const Textcontainer = styled.div`
color: #000;
padding:40px 55px 40px 55px;
text-align: justify;
> p {
    font-size: 16px;
    margin:0; 
    padding:0;
    font-weight:500; 
  }
`;
const NoticeContent = styled.img`
  width: 52px;
  position:absolute;
`;
const IconContainer = styled.div`
  position:absolute;
  background-color: white;
  display:flex;
  align-items:center;
  justify-content:center;
  bottom:-25px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const Interes = styled.div`
position: relative;
margin-bottom:18px;

`
const Rate = styled.div`
position: absolute;
top:-16px;

`
export interface FormValues {
  loanPurpose: number;
}

const cancelProcessModal = {
  main: (
    <>
      <p>
        Cancelaste tu solicitud de crédito. Recuerda que puedes realizar una
        nueva en cualquier momento.
      </p>
    </>
  ),
  cancel: '¡Hasta pronto!',
};

export interface Props extends RouteComponentProps {
  gender: string;
  submit(params: LoanValues): Promise<void>;
  resolveNextPath: ResolveNextPath;
  loanPurpose: Option<string>[];
  risk: string;
  riskMatrix: RiskMatrix;
  loans: any;
  finalPath: number;
  incomeFactor: number;
  disposableIncome: number;
  mark: string;
  maxRiskQuota: number;
  product:string;
  simUserMsg:string;
  pixels:[];
}

const ConfirmLoanView: React.FC<Props> = ({
  history,
  submit,
  loanPurpose,
  gender,
  risk,
  riskMatrix,
  loans,
  incomeFactor,
  finalPath,
  disposableIncome,
  mark,
  maxRiskQuota,
  product,
  simUserMsg,
  pixels,
}) => {
  const [modal, openModal] = useModalC();
  const [verifyDesistCodeStatus, postLoanDesist] = useService(loanDesistPost);

  const sim = useSimRiskMatrix();
  const [modalF, openModalF] = useModalF();
  const [modalD, openModalD] = useModalD();
  const [modalC, openModalC] = useModalC(); 
  const MessageDesition = {
    main: (
        <p>
          decide que quieres hacer
        </p>
    ),
    accept: 'SI QUIERO',
    cancel:'NO ME INTERESA',
    noClosable: true,
    
  };
  const DesitionDecline = {
    main: (
      <p>
       Tu crédito con juancho solo es posible a través
       del convenio con tu empresa.
      </p> 
    ),
    accept: 'OK',
    noClosable: true,
    icone:false,
    title:'No podemos continuar'
  };
  const DesitionAccept = {
    main: (
      <p>
      En cualquier momento te contactaremos.
     </p> 
    ),
    accept: 'OK',
    noClosable: true,
    icone:true,
    title:'No descuides tu correo y celular'
  };
  const messageError={
    main: (
      <div>
          <p>
        Inicia sesión nuevamente para continuar tu solicitud o comunícate con
        nosotros a:
      </p>
      <p>
        <b>WhatsApp: </b>
        <a
          href="https://wa.me/5744807833"
          rel="noopener noreferrer"
          target="_blank"
          title="WhatsApp"
        >
          https://wa.me/5744807833
        </a>
      </p>
      <p>
        <b>Línea gratuita nacional: </b> 018000413811
      </p>
      <p>
        <b>Correo: </b> ayuda@juanchotepresta.com
      </p>
      </div>
    ),
    noClosable: false,
  }
  const shuffle = (array:any) => {
    let currentIndex = array.length;
    let randomIndex;
  
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // Swap
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
  };
  const [shuffledLoanPurpose, setShuffledLoanPurpose] = useState([]);

  useEffect(() => {
    setShuffledLoanPurpose(shuffle([...loanPurpose]));
    riskMatrix[finalPath]['maxAmount'] =
      mark !== 'NA' ? maxRiskQuota : riskMatrix[finalPath]['maxAmount'];

    sim.setParams((prev) => ({
      ...prev,
      riskMatrix: riskMatrix[finalPath],
      risk,
      incomeFactor,
      disposableIncome,
    }));
    sim.setState((prev) => ({
      ...prev,
      gender,
    }));
    const fetchData = async () => {
      try {
        pixels.forEach((pixel: Pixel) => {
          if (pixel.screen === 'simulator') {
            let img = document.createElement('img');
            img.src = pixel.url;
            document.body.appendChild(img);
          }
        });
      } catch (error) {
        console.log("algo salio mal")
      }
    };
    fetchData();
  }, []);

  const setValues: SimulatorValuesHandler = useCallback(({ amount, term }) => {
    sim.setState((prev) => ({
      ...prev,
      amount,
      term,
    }));
    
  }, []);

  return (
    <>{simUserMsg==="jtdAcceptMsg"&&product==="JTD"?(sim.ready && (
      <>
      <ModalF props={modalF}/>
      <ModalD props={modalD}/>
      <ModalC props={modalC}/>
      <Mount
      before={async () => {
        console.log("info jtd ==>>",sim.JtdSimFinfo)
            try {
              const { cancel, accept} = await openModalD(MessageDesition);
              if (cancel) {
                try{
                  await jtdClientDecisionPost({
                    'jtd_client_decision':false,
                    'jtd_sim_info':sim.JtdSimFinfo
                })
                }
                catch(e){
                  await openModalC(messageError).then(
                    ({ cancel }) => {
                      cancel && history.push('/logout');
                    }
                  );
                  }
                const {accept} = await openModalF(DesitionDecline)
                   accept && history.push('/logout');   
              }
              if (accept) {
                try{
                  await jtdClientDecisionPost({
                    'jtd_client_decision':true,
                    'jtd_sim_info':sim.JtdSimFinfo})
                }
                catch(e){
                  await openModalC(messageError).then(
                    ({ cancel }) => {
                      cancel && history.push('/logout');
                    }
                  );
                  }
               const {accept} = await openModalF(DesitionAccept)
                accept && history.push('/logout'); 
              }
            } catch (error) {
              console.error(error); 
        }
        return null;
        ;
      }}
      props={null}
      component={LogOut}
      /></> )
    ):(
    <Column fluid>
      <GeneralContent>
        <h3>Confirma los datos de tu crédito</h3>
        {sim.ready && (
          <Loan.Simulator
            initialAmount={sim.state.amount}
            initialTerm={sim.state.term}
            minAmount={sim.state.minAmount}
            maxAmount={sim.state.maxAmount[sim.state.term]}
            terms={sim.state.terms}
            onChange={setValues}
          >
            <Loan.TermOptions />
            <Loan.AmountSlider step={50000} />
            <SimulatorResults>
              {product==="JTD" ? (
              <MessageContent>
                <IconContainer>
                  <NoticeContent
                    src={noticeIcon}
                    alt="icono informativo"
                  />
                </IconContainer>
                <Textcontainer>
                  <p>
                     Los préstamos por nómina ofrecen un descuento del 50%
                      en el aval variable.
                  </p>  
                </Textcontainer>
              </MessageContent>):("")}

              <Loan.Label
                label="Total desembolsado:"
                calculate={() => moneyFormat(sim.computed.totalDisbursed)}
                component={AvalInfoIndicator}
              />
              <Loan.Label
                label="Cuota mensual:"
                calculate={() => moneyFormat(sim.computed.monthlyQuote)}
                component={QuoteInfoIndicator}
              />
              <Loan.Label
                label="Amortización crédito:"
                calculate={() => moneyFormat(sim.computed.monthlyRedemption)}
                component={LegalInformation}
              />
              <Loan.Label
                label="Servicio de Firma Electrónica (opcional):"
                calculate={() => moneyFormat(sim.computed.monthlyVAT)}
                component={ElectronicInformation}
              />
              <Loan.Label
                      label="Tasa de interés:"
                      component={InteresRate}
                    />
                    <Interes>
                      <Rate>
                      27.99% EA
                      </Rate>
                    </Interes>
              <Loan.Label
                label="Aval variable de terceros (opcional):"
                calculate={() => moneyFormat(sim.computed.monthlyAval)}
                component={Indicator}
              />
              <br />
              <Loan.Label
                label="Primera fecha de pago:"
                calculate={() => sim.computed.firstDatePayment}
                component={Indicator}
              />
            </SimulatorResults>
            <Formik
              initialValues={{ loanPurpose: '' }}
              validationSchema={validationSchema}
              onSubmit={submitTrap(async (values) => {
                let date_format = await firstDatePayment();
                await submit({
                  termsAndConditions: true,
                  loanPurpose: +values.loanPurpose,
                  amount: sim.state.amount,
                  term: sim.state.term,
                  repaymentsStartingFromDate: date_format.format('DD-MM-YYYY'),
                });
                let contador = 0;
                for (const key in loans) {
                    if (loans.hasOwnProperty(key)) {
                        const objeto = loans[key];
                        if (objeto.statusId === 600 || objeto.statusId === 700) {
                            contador++;
                        }
                    }
                }
                const event =
                  contador >= 1
                    ? 'AprobacionRecurrente'
                    : 'AprobacionNuevo';
                sendEventToGTM(event);
                console.log("evento de tag manager =>",event)
                history.push('/login');
              })}
            >
              <Form>
                <GeneralContent>
                  <ModalC props={modal} />
                  <FormGroup>
                    <Label>Propósito del préstamo</Label>
                    <InputSelect name="loanPurpose" options={shuffledLoanPurpose} />
                  </FormGroup>
                </GeneralContent>
                <Column fluid>
                  <ButtonContainer>
                    <FormSubmit>Solicitar Crédito</FormSubmit>
                  </ButtonContainer>
                  <ButtonContainer>
                    <Button
                      flat
                      loading={verifyDesistCodeStatus.status === 'loading'}
                      onClick={preventDefault(async () => {
                        try {
                          await postLoanDesist();
                        } catch { }
                        await openModal(cancelProcessModal).then(
                          ({ cancel }) => {
                            cancel && history.push('/logout');
                          }
                        );
                      })}
                    >
                      Cancelar solicitud de Crédito
                    </Button>
                  </ButtonContainer>
                </Column>
              </Form>
            </Formik>
          </Loan.Simulator>
        )}
      </GeneralContent>
    </Column>)}
    </>);
};

export default connect(
  (state: JTPState, ownProps: any) => ({
    signup: state.requests.signup,
    pixels: state.pixel.listPixel,
    ...ownProps,
  })
)(ConfirmLoanView);