import serviceBuilder from '../serviceBuilder';

export interface Params {
  url: string;
  loanId: number;
  year?: string;
}

export type Response = {
  pdfFile: string;
};

export default async (params: Params) => {
  const url = `sec/users/${params.url}`;
  if (params.url === 'incomestatement') {
    let resp = await serviceBuilder<any, Response>('post', {
      url,
      auth: true,
    })({ loanId: params.loanId, year: params.year });
    return resp.payload;
  } else {
    let resp = await serviceBuilder<any, Response>('post', {
      url,
      auth: true,
    })({ loanId: params.loanId });
    return resp.payload;
  }
};
