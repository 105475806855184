import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ButtonCSS } from '../components/UI/Button';
import { createCookie, getCookieAccept } from '../helpers/cookies';
import { breakpointLarge } from '../styles/breakpoints';

const Container = styled.div<{ ControlCookie: boolean }>`
  position: fixed;
  bottom: ${(props) => (props.ControlCookie ? '-122px' : '0px')};
  background-color: ${(props) => props.theme.blueDark};
  width: 100%;
  height: 122px;
  z-index: 100;
  opacity: 0.94;
  padding: 5px 12px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
`;

const TextContainer = styled.div`
  color: #fff;
  margin: 5px 0px;
  padding: 8px 0px;
  font-size: 0.75rem;
  line-height: 14.4px;
  @media (min-width: ${breakpointLarge}) {
    text-align: center;
    font-size: 1rem;
    padding: 12px 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  & > a {
    font-weight: 400;
    padding: 0.4rem 1.3rem;
    font-size: 0.75rem;
    @media (min-width: ${breakpointLarge}) {
      padding-left: 1.9rem;
      padding-right: 1.9rem;
      font-size: 1rem;
    }
  }
`;

const Button3 = styled.a`
  ${ButtonCSS}
  color: #fff;
  background-color: ${(props) => props.theme.blueLight};
  border-color: ${(props) => props.theme.blueLight};
`;

const Button4 = styled.a`
  ${ButtonCSS}
  color: ${(props) => props.theme.blueDark} !important;
  background-color: ${(props) => props.theme.yellow};
  border-color: ${(props) => props.theme.yellow};
`;

const Cookies = () => {
  const [ControlCookie, setControlCookie] = useState(true);

  const validCookie = () => {
    setControlCookie(getCookieAccept());
  };

  const acceptCookie = () => {
    createCookie(
      'jtp_cookie',
      JSON.stringify({
        timestamp: Date.now(),
        request: false,
      }),
      365
    );
    validCookie();
  };

  useEffect(() => {
    validCookie();
  }, []);

  return (
    <Container ControlCookie={ControlCookie}>
      <TextContainer>
        En Juancho usamos varios tipos de cookies para mejorar tu experiencia en
        nuestro sitio web. Al seguir navegando o hacer click en Aceptar permites
        su uso.
      </TextContainer>
      <ButtonContainer>
        <Button4 href="#" sm onClick={() => acceptCookie()}>
          ACEPTAR
        </Button4>
        <Button3
          href="https://juanchotepresta.com/img/política_de_cookies_jtp.pdf"
          target="_blank"
          title="política_de_cookies_jtp.pdf"
          rel="noopener noreferrer"
          sm
        >
          MÁS INFORMACIÓN
        </Button3>
      </ButtonContainer>
    </Container>
  );
};

export default Cookies;
