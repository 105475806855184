import styled from '../../styles/styled-components';

export const LabelMessage = styled.div`
  text-align: left;
  background: #fff;
  outline: none;
  border-radius: 10px;
  border: 1px solid #fff;
  text-decoration: underline;
  color: inherit;
  &:hover {
    color: ${props => props.theme.red};
  }
`;

export default LabelMessage;
