import { captureMessage, withScope } from '@sentry/minimal';
import { Severity } from '@sentry/types';
import { useCallback, useEffect } from 'react';
import {
  appendBelvoScript,
  openBelvoWidget,
  removeBelvoScript,
} from '../helpers/belvo';
import belvoAuthGet from '../services/belvoAuthGet';
import useSafeState from './useSafeState';
import useService from './useService';

type Status =
  | 'loading'
  | 'ready'
  | 'error'
  | 'belvo-onSuccess'
  | 'belvo-Callback'
  | 'belvo-exit';

export type BelvoIDState<T extends Status = Status> =
  T extends 'belvo-completed'
    ? { status: T; link: string; institution: string }
    : T extends 'belvo-error'
    ? { status: T; link: string }
    : { status: T };

type StartBelvo = () => void;

export type successCallback = (link: string, institution: string) => void;
export type onEventCallback = (data: onEventResponse) => void;
export type onExitCallback = (data: any) => void;

export interface onEventResponse {
  eventName: 'PAGE_LOAD' | 'ERROR' | 'CLOSE_ATTEMPT' | 'WARNING';
  meta_data: {
    error_code?: string;
    error_message?: string;
    institution_name?: string;
  };
}

export interface onExitResponse {
  last_encountered_error: {
    message: string;
    code: string;
  } | null;
  meta_data: {
    step: string;
    institution_name: string;
  };
}

const useBelvo = (
  successCallbackFunction: successCallback,
  onEventCallbackFunction: onEventCallback,
  onExitCallbackFunction: onExitCallback
): [BelvoIDState, StartBelvo] => {
  const [belvoAuth, getBelvoAuth] = useService(belvoAuthGet);
  const [state, setState] = useSafeState<BelvoIDState>({
    status: 'loading',
  });

  useEffect(() => {
    const script = appendBelvoScript(() => getBelvoAuth());
    return () => {
      removeBelvoScript(script);
    };
  }, [getBelvoAuth]);

  useEffect(() => {
    if (belvoAuth.status === 'error') {
      setState({ status: 'error' });
    } else if (belvoAuth.status === 'loaded') {
      setState({ status: 'ready' });
    }
  }, [setState, belvoAuth]);

  const start: StartBelvo = useCallback(() => {
    if (belvoAuth.status === 'loaded') {
      openBelvoWidget(
        belvoAuth.payload.access_token,
        successCallbackFunction,
        onEventCallbackFunction,
        onExitCallbackFunction
      )({
        failed({ message }) {
          setState({ status: 'error' });
          withScope((scope) => {
            scope
              .setFingerprint(['belvo/failed'])
              .setLevel(Severity.Critical)
              .setTags({
                'belvo.error': `${message}`,
                'belvo.description': 'iniciar proceso de validación con belvo',
              })
              .addBreadcrumb({
                category: 'belvo-start-failed',
                level: Severity.Critical,
              });
            captureMessage(`[BELVO] start failed`);
          });
        },
      });
    }
  }, [
    setState,
    belvoAuth,
    successCallbackFunction,
    onEventCallbackFunction,
    onExitCallbackFunction,
  ]);

  return [state, start];
};

export default useBelvo;
