import moneyFormat from '../helpers/moneyFormat';
import { ServiceLoaded } from '../types/Service';
import serviceBuilder from './serviceBuilder';

export type StatusId =
  | 100
  | 101
  | 104
  | 102
  | 103
  | 200
  | 201
  | 202
  | 203
  | 300
  | 400
  | 600
  | 601
  | 700;

export interface Repayment {
  amountPaid: number;
  balance: number;
  capital: number;
  complete: boolean;
  date: string;
  feeChargesAtDisbursementCharged: number;
  fees: number;
  initialAmount: number;
  interest: number;
  kushkiToken: string;
  lastRepayment: boolean;
  loanId: string;
  paymentId: string;
  penalties: number;
  repaymentAmount: number;
  trazabilityCode: string;
}

export interface LoanList {
  [loanId: string]: {
    loanId: string;
    statusId: StatusId;
    statusName: string;
    closedDays: number;
    balance: number | 0;
    currency?: string;
    repayments: Repayment[] | [];
    totalFees?: number;
    totalPenalties?: number;
  };
}

export interface Params {
  id: string
}

export function translateLoanState(state: LoanList[0]) {
  switch (state.statusId) {
    case 300:
    case 601:
      return moneyFormat(state.balance, true, true);
    case 700:
      return 'Cancelado';
    case 600:
      return 'Cerrado';
    default:
      return '';
  }
}

export const url = 'sec/loans/payments/:id';
const service = serviceBuilder<Params, LoanList>('get', {
  auth: true,
  url,
});

export default async function (): Promise<ServiceLoaded<LoanList>> {
  try {
    const paymentMethodId = {
      id: '001'
    }
    const { payload, status } = await service(paymentMethodId);
    for (let k in payload) {
      if (!payload[k].statusId) {
        delete payload[k];
      } else {
        payload[k].repayments.reverse();
      }
    }
    return { payload, status };
  } catch (e) {
    throw e;
  }
}
