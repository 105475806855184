import React, { useEffect, useState } from 'react';
import Label from '../../../../components/Form/Label';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import { Spinner } from '../../../../components/UI/Spinner';
import signaturesGet, {
  ResponseSignaturesItem,
} from '../../../../services/signatures';
import styled from '../../../../styles/styled-components';

const Style = styled.div`
  .quota-item {
    background-color: ${(props) => props.theme.gray050};
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 10px;
    margin: 15px 0;
    font-size: 15px;
    border-radius: 10px;

    .code {
      color: ${(props) => props.theme.gray500};
      text-align: center;
      position: relative;
      padding-bottom: 5px;

      &:after {
        content: '';
        position: absolute;
        background-color: white;
        height: 2px;
        bottom: 0px;
        left: 2px;
        right: 2px;
      }
    }

    .content {
      .row {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        background-color: white;
        padding: 2px 5px;
        margin: 3px 0;
        border-radius: 5px;

        > div {
          display: table-cell;
          flex: 1;
        }
        > div:nth-child(1) {
          color: ${(props) => props.theme.gray500};
        }
        > div:nth-child(2) {
          color: ${(props) => props.theme.blueDark};
        }
      }
    }
  }
`;

const Signature: React.FC = () => {
  const [list, setList] = useState<ResponseSignaturesItem[]>([]);
  const [loading, setloading] = useState(false);
  const [modal, openModal] = useModalC();

  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const response = await signaturesGet();
        const signatures = response.map((item) => {
          let current_datetime = new Date(parseInt(item.firma_timestamp));
          return {
            ...item,
            firma_timestamp: `${('0' + current_datetime.getDate()).substr(
              -2
            )}-${
              current_datetime.getMonth() + 1
            }-${current_datetime.getFullYear()}`,
          };
        });
        setList(signatures);
        setloading(false);
      } catch (error) {
        setloading(false);
        await openModal({
          main: (
            <div>
              <p>
                Lo sentimos! Por el momento no es posible obtener tu reporte de
                firmas.
              </p>
              <p>Por favor intentalo más tarde.</p>
            </div>
          ),
          noClosable: true,
          accept: 'Aceptar',
        });
      }
    })();
  }, [openModal]);

  return !loading ? (
    <div className="loan-item">
      <ModalC props={modal} />
      <Style>
        {list.length > 0 ? (
          list.map((signature) => (
            <div key={signature.loan_id} className="quota-item">
              <div className="code">Código : {signature.loan_id}</div>
              <div className="content">
                <div className="row">
                  <div>IP</div>
                  <div>{signature.firma_ip}</div>
                </div>
                <div className="row">
                  <div>Fecha</div>
                  <div>{signature.firma_timestamp}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <ButtonContainer>
            <Label>En este momento no tienes firmas electrónicas.</Label>
          </ButtonContainer>
        )}
      </Style>
    </div>
  ) : (
    <Spinner size={64} />
  );
};

export default Signature;
