import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export type MrgResponse = { running: boolean; code__: number };

export const url = '/sec/loans/mrg';

export default async () => {
  const response = await serviceBuilder<None, MrgResponse>('get', {
    url,
    auth: true,
  })();

  return response.payload;
};
