import serviceBuilder from './serviceBuilder';

export interface Terms {
  costsAndExpenses: boolean;
  aval: boolean;
  platform: boolean;
  promissory: boolean;
  powerIrrevocable: boolean;
  guarantees: boolean;
}

export const url = 'sec/loans/toa';

export default serviceBuilder<Terms>('post', {
  url,
  auth: true,
});
