import React from 'react';
import styled from '../../styles/styled-components';

const TitleStyled = styled.h3`
  display: block;
  margin-top: 30px;
  :nth-child(1) {
    margin-top: 10px;
  }
`;

const HrStyled = styled.hr`
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -moz-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -ms-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
  background-image: -o-linear-gradient(left, #f0f0f0, #8c8b8b, #f0f0f0);
`;

export interface Props {
  divider?: boolean;
}

const Title: React.FC<Props> = ({ children, divider }) => (
  <>
    <TitleStyled>{children}</TitleStyled>
    {divider && <HrStyled />}
  </>
);

export default Title;
