/* eslint react-hooks/exhaustive-deps: 0 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from '../../styles/styled-components';
import Error from './Error';
import { Icon, InputCSS } from './Input';

const InputAccountStyled = styled.div`
  position: relative;
`;

const InputStyled = styled.input`
  ${InputCSS};
  font-size: 20px;
  padding-left: 36px;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export interface Props {
  name: string;
  help?: string;
}

const InputAccountPhoneComponent: React.FC<FieldProps & Props> = ({
  form,
  field,
  ...restProps
}) => {
  return (
    <InputAccountStyled>
      <InputStyled
        invalid={
          form.touched[field.name]! && !!form.errors[field.name]
            ? ('invalid' as any)
            : undefined
        }
        placeholder="••• •••••••"
        id={field.name}
        type="tel"
        {...restProps}
        {...field}
        value={
          field.value
            .replace(/[^0-9]+/g, '')
        }
        onChange={e => {
          e.target.value = e.target.value.replace(/\s/g, '');
          field.onChange(e);
        }}
      />
      <Icon>
        <FontAwesomeIcon icon="phone" />
      </Icon>
    </InputAccountStyled>
  );
};

const InputAccountPhone: React.FC<Props> = ({ name, help, ...restProps }) => (
  <>
    <Field name={name} {...restProps} component={InputAccountPhoneComponent} />
    <Error help={help} name={name} />
  </>
);

export default InputAccountPhone;
