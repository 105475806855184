import { SagaIterator } from 'redux-saga';
import { all, call, put, select } from 'redux-saga/effects';
import { TokenAttrs } from '../../helpers/tokenHelpers';
import personalInformationGet, {
  BasicInfoPlainExt,
} from '../../services/basicInfoGet';
import selectorsGet, { Selectors } from '../../services/selectorsGet';
import simulatorAuthGet, {
  SimulatorAuthParams,
} from '../../services/simulatorAuthGet';
import loanSimulationGet, {
  SimulatorParams,
} from '../../services/simulatorGet';
import ApiError from '../../types/ApiError';
import { logoutRequest } from '../actions/session';
import { updateLoanStart } from '../actions/updateLoanState';
import { ActionPromise } from '../asyncDispatch';
import selectors from '../reducers/selectorsReducer';
import { simulator } from '../reducers/simulatorReducer';
import { user } from '../reducers/userReducer';
import { tokenAttrsSelector } from '../selectors';
import callService from './callService';

export function* hydrateStoreSaga(): SagaIterator {
  const [simulatorR, selectorsR]: [SimulatorParams, Selectors] = yield all([
    callService(loanSimulationGet),
    call(selectorsGet),
  ]);

  yield put(simulator.actions.updateSimulator(simulatorR));
  yield put(selectors.actions.updateSelectors(selectorsR));
}

export function* hydrateSimulator({ meta }: ActionPromise<void>): SagaIterator {
  const simulatorR: SimulatorAuthParams = yield callService(simulatorAuthGet);
  yield put(simulator.actions.updateSimulator(simulatorR));
  yield call(meta.resolve);
}

export function* hydrateUserStoreSaga(): SagaIterator {
  const attrs: TokenAttrs = yield select(tokenAttrsSelector);

  try {
    if (attrs.pi === 'DONE') {
      const basicInfo: BasicInfoPlainExt = yield callService(
        personalInformationGet
      );
      yield put(user.actions.basicInfoPlain(basicInfo));
      yield put(
        user.actions.user({
          email: basicInfo.email,
          fullName: basicInfo.fullName,
          cellPhone: basicInfo.cellPhone,
          documentKey:basicInfo.documentKey,
          documentType:basicInfo.documentType,
        })
      );

      yield put(updateLoanStart());
    }
  } catch (e) {
    if (e instanceof ApiError) {
      if (e.code === 409) {
        yield put(logoutRequest());
      } else {
        throw e;
      }
    }
  }
}
