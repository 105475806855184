import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../components/Mount';
import { JTPState } from '../../store';
import SimulateView from './SimulateView';

interface Props extends RouteComponentProps {
  ready: boolean;
}

const SimulateContainer: React.FC<Props> = ({ ready, ...routeProps }) => (
  <Mount loading={!ready} props={routeProps} component={SimulateView}></Mount>
);

export default connect((state: JTPState, ownProps) => ({
  ready: state.ready,
  ...ownProps,
}))(SimulateContainer);
