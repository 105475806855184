import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../../components/UI/Button';
import MessagesFormat from '../../components/UI/MessagesViews';
import NavLink from '../../components/UI/NavLink';
import { Spinner } from '../../components/UI/Spinner';
import sendEventToGTM from '../../helpers/tagManager';
import loanGetUuid, { Loan } from '../../services/loanGetUuid';
import loanListGet from '../../services/loanListGet';
import { LoanList } from '../../services/loansPayment';
import pixelGet from '../../services/pixelGetUuid';
import { JTPState } from '../../store';
import { asyncDispatch } from '../../store/asyncDispatch';
import { updateLoanStatus } from '../../store/reducers/updateLoanStatusReducer';
import styled from '../../styles/styled-components';

const PredisburseStyled = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  padding: 15px;

  .desc {
    padding: 15px;
    text-align: center;
    padding-bottom: 80px;
    font-weight: 500;
    font-size: 22px;
    color: ${(props) => props.theme.gray500};
  }
`;

interface Props extends RouteComponentProps<{ uuid: string }> {
  submit(payload: { uuid: string }): Promise<void>;
}

const SuccessComponent = () => (
  <MessagesFormat>
    <h3>Confirmación de préstamo</h3>
    <div />
    <p>
      Firmaste exitosamente los contratos. Ahora necesitamos un tiempo para
      desembolsarte, te avisaremos cuando todo esté listo.
    </p>
    <Button expand outline as={NavLink} to="/">
      Salir
    </Button>
  </MessagesFormat>
);

const LoadingComponent = () => (
  <>
    <Spinner size={64} />
    <div className="desc">Actualizando estado de tu préstamo...</div>
  </>
);

const ErrorComponent = ({ error }: { error: string }) => (
  <>
    <div className="desc">
      <p>{error}</p>
      <p>Recuerda hacer click en el último correo que recibas.</p>
    </div>
    <Button expand outline color="red" as={Link} to="/">
      Salir
    </Button>
  </>
);

const PredisburseView: React.FC<Props> = ({ submit, match }) => {
  const [state, setstate] = useState({
    status: 'loading',
    msj: '',
  });

  useEffect(() => {
    (async () => {
      try {
        const loan: Loan = (
          await loanGetUuid({
            uuidToken: match.params.uuid,
          })
        ).payload;

        if (loan.loanStatus === 'Approved pre-disburse') {
          setstate({
            status: 'loaded',
            msj: '',
          });
        } else if (loan.loanStatus !== 'Approved and awaiting signature') {
          setstate({
            status: 'error',
            msj: 'La solicitud de crédito no se encuentra pendiente de firma',
          });
        } else {
          try {
            await submit({ uuid: match.params.uuid });
            const loans: LoanList = (await loanListGet(match.params.uuid))
              .payload;
              let contador = 0;
              for (const key in loans) {
                  if (loans.hasOwnProperty(key)) {
                      const objeto = loans[key];
                      if (objeto.statusId === 600 || objeto.statusId === 700) {
                          contador++;
                      }
                  }
              }
              const event =
                contador >= 1
                  ? 'PredesembolsoRecurrente'
                  : 'PredesembolsoNuevo';
              sendEventToGTM(event);
              console.log("evento de tag manager =>",event)  
            setstate({
              status: 'loaded',
              msj: '',
            });

            let { pixels } = (
              await pixelGet({
                tokenUuid: match.params.uuid,
              })
            ).payload;

            Object.keys(pixels).map(function (key) {
              let pixel = pixels[key];
              if (pixel.screen === 'Predisburse') {
                let img = document.createElement('img');
                img.src = pixel.url;
                document.body.appendChild(img);
              }
              return key;
            });
          } catch (error) {
            let msj =
              error.code === 409
                ? 'Tu tiempo para firmar ha caducado, inicia sesión para solicitar un nuevo link de firma.'
                : 'Servicio no disponible, por favor vuelva a intentarlo más tarde.';
            setstate({
              status: 'error',
              msj: msj,
            });
          }
        }
      } catch (error) {
        setstate({
          status: 'error',
          msj: 'Ha ocurrido un error al intentar firmar el credito.',
        });
      }
    })();
  }, [submit, match.params.uuid]);

  return (
    <PredisburseStyled>
      {state.status === 'loading' && <LoadingComponent />}
      {state.status === 'loaded' && <SuccessComponent />}
      {state.status === 'error' && <ErrorComponent error={state.msj} />}
    </PredisburseStyled>
  );
};

export default connect(
  (_: JTPState, ownProps) => ownProps,
  (dispatch) => ({
    submit: asyncDispatch(dispatch, updateLoanStatus.actions.request),
  })
)(PredisburseView);
