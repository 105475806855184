import serviceBuilder from './serviceBuilder';

export interface Status {
  id: number;
  code: string;
  value: string;
}

export interface ClientClosureReason {
  id: number;
  value: string;
}

export interface Result {
  status: Status;
  clientClosureReason?: ClientClosureReason;
}

export const url = 'sec/users/status';

export default serviceBuilder<void, Result>('get', {
  url,
  auth: true,
});
