import React from 'react';
export const Poder = {
  main: (
    <p>
      Documento con el cual nos das un poder especial de suscribir pagarés a tu
      nombre mientras tengas obligaciones con nosotros para garantizar el pago
      de las obligaciones adquiridas por ti, sobretodo en caso de que vendamos
      la cartera a terceros.
    </p>
  ),
  accept: 'Aceptar',
};
