/* eslint react-hooks/exhaustive-deps: 0 */
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { number, object, string } from 'yup';
import Error from '../../../components/Form/Error';
import ErrorFocus from '../../../components/Form/ErrorFocus';
import Form from '../../../components/Form/Form';
import FormGroup from '../../../components/Form/FormGroup';
import InputIcon from '../../../components/Form/InputIcon';
import InputRadio from '../../../components/Form/InputRadio';
import InputSelect from '../../../components/Form/InputSelect';
import Label from '../../../components/Form/Label';
import Button from '../../../components/UI/Button';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import moneyFormat from '../../../helpers/moneyFormat';
import useKushki from '../../../hooks/useKushki';
import PseLogo from '../../../images/icons/pse.png';
import transactionPost from '../../../services/transactionPost';
import styled from '../../../styles/styled-components';
import { QuotaPayment } from '../../../types/QuotaPayment';
import { HeaderPayment, QuotaItemStyled } from './LoansPaymentInformationView';

const Styled = styled.div`
  display: flex;
  flex-flow: column;
  align-items: stretch;

  h3 {
    color: white;
    background-color: ${(props) => props.theme.blueDark};
    border-radius: 5px;
    padding: 8px 30px;
    text-align: center;
    font-size: 20px;
  }

  .card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    color: ${(props) => props.theme.gray700};
    background-color: ${(props) => props.theme.gray050};
    border-radius: 5px;
    padding: 10px;
    max-width: 350px;
    margin: 14px 0;
    .row:last-child {
      margin-top: 10px;
    }
    .row span:last-child {
      color: ${(props) => props.theme.blueDark};
      margin-left: 10px;
      font-weight: bold;
    }
  }

  form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .pse-info {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${(props) => props.theme.gray050};
      color: ${(props) => props.theme.gray500};
      border-radius: 5px;
      font-size: 14px;
      padding: 5px;
      margin-bottom: 10px;
      max-width: 350px;

      .info {
        text-align: center;
      }
      img {
        min-height: 65px;
        width: auto;
      }
    }

    .actions {
      display: flex;
      flex-flow: row nowrap;
    }
  }
`;

const InitialValues = {
  bankId: '0',
  callbackUrl: '',
  userType: '0',
  documentType: 'CC',
  documentNumber: '',
  email: '',
  amount: {
    iva: 0,
    subtotalIva: 0,
    subtotalIva0: 0,
  },
  address:"",
  currency:'COP',
};

const LoanPaymentPSEView: React.FC<
  RouteComponentProps<any, any, QuotaPayment>
> = ({ location, history }) => {
  const { bankList, requestBankList, getToken } = useKushki();
  const [loading, setLoading] = useState(false);
  const [modal, openModal] = useModalC();

  useEffect(() => { 
    if (!location.state) {
      history.goBack();
    }
    requestBankList();
  }, []);

  useEffect(()=>{
    InitialValues.documentNumber = location.state.documentKey;
    InitialValues.email=location.state.email; 
    if(location.state.documentType==="Cedula"){
      InitialValues.documentType="CC"; 
    }
    InitialValues.address=location.state.addres;

  },[])


  if (!location.state) {
    return null;
  }

  return (
    <GeneralContent>
      <ModalC props={modal} />
      <QuotaItemStyled>
        <HeaderPayment>
          <div className="icon" />
          <div className="title"> Información del pago</div>
        </HeaderPayment>
        <Styled>
          <div className="card">
            <div className="row">
              <span>Valor a pagar:</span>
              <span>{moneyFormat(location.state.amount)}</span>
            </div>
            <div className="row">
              Pago de la Cuota Nº {location.state.paymentId} del crédito Nº{' '}
              {location.state.loanId}.
            </div>
          </div>

          <Formik
            initialValues={InitialValues}
            validationSchema={object({
              documentType: string().required('Requerido'),
              documentNumber: string().required('Requerido'),
              email: string().email('Formato no valido').required('Requerido'),
              userType: string().required('Requerido'),
              bankId: number().min(1, 'Requerido'),
              address:string().required('Requerido'),
            })}
            onSubmit={async (values) => {
              setLoading(true);
              const quota = location.state;

              values.email = values.email.trim();

              values.callbackUrl =
                window.location.origin +
                `/loans/feedback/${quota.loanId}/${quota.paymentId}/001/`;
              values.amount.subtotalIva0 = quota.amount;
              values.currency='COP'
              const addressValues = values.address
              const { address,...valuesWithoutUnwantedProps } = values;
              try {
                const kushkiToken = await getToken(valuesWithoutUnwantedProps as any);
                const result = await transactionPost({
                  kushkiToken,
                  paymentId: quota.paymentId,
                  amount: quota.amount,
                  loanId: quota.loanId,
                  address:addressValues,
                });

                if (result.status === 'loaded') {

                  window.location.replace(result.payload.redirectUrl);
                } else {
                  setLoading(false);
                  await openModal({
                    main: (
                      <p>
                        Algo no salió bien. Por favor, inténtalo de nuevo haciendo clic en "Pagar"
                      </p>
                    ),
                    noClosable: true,
                    accept: 'Aceptar',
                  });
                }
              } catch (e) {
                setLoading(false);
              
                await openModal({
                  main: (
                    <p>
                      El servicio no responde, por favor vuelve a intentarlo.
                    </p>
                  ),
                  noClosable: true,
                  accept: 'Aceptar',
                });
              }
            }}
          >
            <Form>
              <FormGroup>
                <Label>Tipo de documento de identidad</Label>
                <InputSelect
                  name="documentType"
                  options={[
                    { label: 'Cédula de ciudadanía', value: 'CC' },
                    { value: 'NIT' },
                  ]}
                />
              </FormGroup>
              <FormGroup>
                <Label>Nº de identificación</Label>
                <InputIcon icon="address-card" name="documentNumber" />
                <Error name="documentNumber" />
              </FormGroup>
              <FormGroup>
                <Label>Correo electrónico</Label>
                <InputIcon icon="envelope" name="email" />
                <Error name="email" />
              </FormGroup>
              <FormGroup>
                <Label>Dirección de residencia</Label>
                <InputIcon icon="user" name="address" />
                <Error name="address" />
              </FormGroup>
              <FormGroup>
                <Label>Tipo de persona:</Label>
                <InputRadio name="userType" value="0">
                  Persona natural
                </InputRadio>
                <InputRadio name="userType" value="1">
                  Persona juridica
                </InputRadio>
              </FormGroup>
              <div className="pse-info">
                <img alt="" src={PseLogo} />
                <p className="info">
                  El pago se realizará utilizando los servicios de PSE,
                  efectuando el débito desde su cuenta corriente o ahorros.
                </p>
              </div>
              <FormGroup>
                <Label>Entidad bancaria:</Label>
                <InputSelect
                  name="bankId"
                  disabled={bankList.loading || bankList.error}
                  options={bankList.values}
                  defaultOption={false}
                />
              </FormGroup>
              <div className="actions">
                <Button
                  as={NavLink}
                  to={`/loans/payment/${location.state?.loanId}/${location.state?.paymentId}/001`}
                  flat
                >
                  Regresar
                </Button>
                <Button
                  loading={loading}
                  type="submit"
                  color="red"
                  icon="dollar-sign"
                >
                  Pagar
                </Button>
              </div>
              <ErrorFocus />
            </Form>
          </Formik>
        </Styled>
      </QuotaItemStyled>
    </GeneralContent>
  );
};

export default LoanPaymentPSEView;
