import None from '../types/None';
import { LoanStatus } from './loanChangeStatusPut';
import serviceBuilder from './serviceBuilder';

export interface Loan {
  repaymentsStartingFromDate: string;
  amount: number;
  term: number;
  loanPurpose: number;
  loanStatus: LoanStatus;
}

export const url = 'sec/loans';

export default serviceBuilder<None, Loan>('get', {
  url,
  auth: true,
});
