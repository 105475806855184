import { SagaIterator } from 'redux-saga';
import { call, cancelled } from 'redux-saga/effects';
import { L } from 'ts-toolbelt';
import { CancelledError } from '../../helpers/makeCancellable';
import noop from '../../helpers/noop';
import { ActionPromise } from '../asyncDispatch';

export function requestSaga<Fn extends (action: any, ...args: any[]) => any>(
  saga: Fn = noop as any
) {
  return {
    *['formSaga:' + saga.name](
      action: ActionPromise,
      ...args: L.Tail<Parameters<Fn>>
    ): SagaIterator {
      try {
        yield call(saga as any, action, ...args);
        yield call(action.meta.resolve);
      } catch (e) {
        yield call(action.meta.reject, e);
      } finally {
        if (yield cancelled()) {
          yield call(action.meta.reject, new CancelledError());
        }
      }
    },
  }['formSaga:' + saga.name];
}
