/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Values } from '../components/LoanSimulator/LoanSimulatorContext';
import clamp from '../helpers/clamp';
import firstDatePayment from '../helpers/financial/firstDatePayment';
import monthlyAval from '../helpers/financial/monthlyAval';
import monthlyQuote from '../helpers/financial/monthlyQuote';
import monthlyRedemption from '../helpers/financial/monthlyRedemption';
import monthlyVAT from '../helpers/financial/mounthlyVAT';
import totalDisbursed from '../helpers/financial/totalDisbursed';
import { findOption, Gender, JobType, PaymentPeriodicity } from '../services/selectorsGet';
import { JTPState } from '../store';
import { simulatorSelector } from '../store/selectors/simulatorSelector';

export interface SimulatorData {
  ready: boolean;
  initialAmount: number;
  initialTerm: number;

  amount: number;
  amountStep: number;
  term: number;

  minAmount: number;
  maxAmount: number;
  terms: number[];

  monthlyQuote: number;
  monthlyAval: number;
  monthlyRedemption: number;
  monthlyVAT: number;
  totalDisbursed: number;
  firstDatePayment: string;

  gender: Gender;
}

export interface SimulatorActions {
  setValues(values: Values): void;
  setJobType(value: JobType): void;
  setGender(value: Gender): void;
  setAmountStep(value: number): void;
  setMonthlySalary(value: number): void;
  setPaymentPeriodicity(value: PaymentPeriodicity): void;
  setScore(value: number): void;
}

const InitialData: SimulatorData = {
  ready: false,

  initialAmount: 0,
  initialTerm: 24,

  amountStep: 50000,
  amount: 0,
  term: 0,

  minAmount: 0,
  maxAmount: 0,
  terms: [],

  monthlyQuote: 0,
  monthlyAval: 0,
  monthlyRedemption: 0,
  monthlyVAT: 0,
  totalDisbursed: 0,
  firstDatePayment: '',

  gender: 'NA',
};

function normalizeMaxAmount(
  min: number,
  max: number,
  step: number,
  monthlySalary: number,
  incomeMultiplier: number
) {
  const maxAmount = clamp(
    min,
    monthlySalary * incomeMultiplier,
    max - ((max - min) % step)
  );

  return maxAmount - (maxAmount % 50000);
}

function initialValuesSelector(state: JTPState) {
  const options = findOption(state.mifos);

  const jobType = options('jobType', state.user.laboral.jobType);
  return {
    genderId: options('genderId', state.user.personal.genderId).label,
    paymentPeriodicity: options(
      'paymentPeriodicity',
      state.user.laboral.paymentPeriodicity
    ).label,
    monthlySalary: state.user.laboral.monthlySalary,
    score: state.loan.score,
    jobType: jobType.label,
    simulator: state.simulator,
  };
}

const useSimulatorCalc = (): [SimulatorData, SimulatorActions] => {
  const [values, setValues] = useState<Values>({
    amount: 0,
    term: 0,
  });
  const [jobType, setJobType] = useState<JobType>();
  const [gender, setGender] = useState<Gender>('M');
  const [amountStep, setAmountStep] = useState<number>(50000);
  const [monthlySalary, setMonthlySalary] = useState<number>();
  const [paymentPeriodicity, setPaymentPeriodicity] =
    useState<PaymentPeriodicity>();
  const [score, setScore] = useState<number>(Infinity);

  const [data, setData] = useState(InitialData);

  const initialValues = useSelector(initialValuesSelector);
  const simulator = useSelector(simulatorSelector);
  const [valueFirstDatePayment, setFirstDatePayment] = useState('');

  useEffect(() => {
    firstDatePayment().then((resp) => {
      setFirstDatePayment(resp.format('MMMM DD [de] YYYY'));
    });
  }, []);

  useEffect(() => {
    setJobType(initialValues.jobType);
    setGender(initialValues.genderId);
    setMonthlySalary(initialValues.monthlySalary);
    setPaymentPeriodicity(initialValues.paymentPeriodicity);
    setScore(initialValues.score);
  }, [initialValues]);

  useEffect(() => {
    if (!simulator || !jobType || !paymentPeriodicity || !monthlySalary) {
      return;
    }

    const jobOption = simulator.options.find((o) =>
      jobType.includes(o.workingStatus)
    );

    if (!jobOption) return;

    const incomeMultiplier =
      jobOption.terms.find((o) => o.months === values.term)?.incomeMultiplier ??
      jobOption.terms[0].incomeMultiplier;

    const initialAmount = normalizeMaxAmount(
      simulator.minAmount,
      simulator.maxAmount,
      amountStep,
      monthlySalary,
      incomeMultiplier
    );

    let terms = jobOption.terms
      .filter((o) => score >= o.minScore || true)
      .map((o) => o.months);

    if (terms.length === 0) {
      terms = [jobOption.terms[0].months];
    }

    const initialTerm = terms.find((term) => term === 24) || terms[0];

    const amount = values.amount || initialAmount;
    const term = terms.find((t) => t === values.term) ?? initialTerm;

    setData({
      ready: true,

      initialAmount,
      initialTerm,

      amountStep,
      amount,
      term,
      gender,

      minAmount: simulator.minAmount,

      maxAmount: normalizeMaxAmount(
        simulator.minAmount,
        simulator.maxAmount,
        amountStep,
        monthlySalary,
        incomeMultiplier
      ),
      terms,
      monthlyQuote: monthlyQuote({
        amount,
        term,
        interest: simulator.interest,
        technology: simulator.technology,
        electronicSignature: simulator.electronicSignature,
        guaranteeVariable:
          gender === 'F'
            ? simulator.guaranteeVariableWoman
            : simulator.guaranteeVariableMan,
        VAT: simulator.VAT,
      }),
      monthlyAval: monthlyAval({
        amount,
        term,
        interest: simulator.interest,
        technology: simulator.technology,
        electronicSignature: simulator.electronicSignature,
        guaranteeVariable:
          gender === 'F'
            ? simulator.guaranteeVariableWoman
            : simulator.guaranteeVariableMan,
        VAT: simulator.VAT,
      }),
      monthlyVAT: monthlyVAT({
        amount,
        term,
        interest: simulator.interest,
        technology: simulator.technology,
        electronicSignature: simulator.electronicSignature,
        guaranteeVariable:
          gender === 'F'
            ? simulator.guaranteeVariableWoman
            : simulator.guaranteeVariableMan,
        VAT: simulator.VAT,
      }),
      monthlyRedemption: monthlyRedemption({
        amount,
        term,
        interest: simulator.interest,
        technology: simulator.technology,
        electronicSignature: simulator.electronicSignature,
        guaranteeVariable:
          gender === 'F'
            ? simulator.guaranteeVariableWoman
            : simulator.guaranteeVariableMan,
        VAT: simulator.VAT,
      }),
      totalDisbursed: totalDisbursed({
        amount,
        guaranteeConstant: simulator.guaranteeConstant,
        guaranteeFurniture: simulator.guaranteeFurniture,
      }),
      firstDatePayment: valueFirstDatePayment,
    });
  }, [
    simulator,
    values,
    jobType,
    gender,
    monthlySalary,
    paymentPeriodicity,
    score,
    valueFirstDatePayment,
  ]);

  return [
    data,
    {
      setValues,
      setAmountStep,
      setJobType,
      setGender,
      setMonthlySalary,
      setPaymentPeriodicity,
      setScore,
    },
  ];
};

export default useSimulatorCalc;
