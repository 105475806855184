import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: left;

  ol {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
  }
  li {
    display: block;
    margin-bottom: 0.2em;
    margin-left: 2em;
  }
  li::before {
    display: inline-block;
    content: attr(value) ') ';
    counter-increment: item;
    width: 2em;
    margin-left: -2em;
  }
`;

export default (props: Record<'username', string>) => {
  return (
    <Container>
      <p>
        Hola <i>{props.username}</i> como no deseas hacer uso de nuestros
        proveedores de aval ni de nuestra tecnología, debes enviarnos suscritos
        los documentos a través de tu email con certificado digital y contar con
        un codeudor:
      </p>
      <p>
        Este <strong>codeudor solidario</strong> debe cumplir con los siguientes
        requisitos como mínimo: tener propiedad raíz libre de hipoteca,
        certificado de tradición vigente con no menos de 30 días de expedición,
        certificaciones laborales, certificado de ingresos expedido por
        contador, pagaré y carta de instrucciones firmados debidamente
        notarizados. Todos estos documentos deben ser enviados en original a
        nuestra dirección física en este contrato estipulada o escaneados por
        correo certificado si cuentas con firma digital.
      </p>
      <p>
        Sin embargo, tú también como DEUDOR podrás presentar dichas pólizas,
        fianza o avales (cualquiera de ellas) que representen garantías para el
        pago, tomadas de manera individual con otras entidades legalmente
        constituidas cuyo beneficiario deberá aparecer a nombre de JUANCHO TE
        PRESTA S.A.S.– JuanchoTePresta.com, las cuales deben ser aprobadas
        previamente por nosotros. Recuerda que el aval debe cubrir el plazo
        total hasta la fecha de pago de tu crédito.
      </p>
      <p>
        Instrumentación del cupo de endeudamiento por medio de correo
        certificado:
      </p>
      <ol>
        <li value="I">Copia de tu cédula ampliada.</li>
        <li value="II">Certificado de ingresos.</li>
        <li value="III">
          Carta laboral expedida por tu empleador con menos de 30 días o
          certificado de ingresos emitido por contador público o ante notario.
        </li>
        <li value="IV">Extracto bancario.</li>
        <li value="V">Copia de tus servicios públicos.</li>
      </ol>
      <p>Si eres independiente deberás anexar:</p>
      <ol>
        <li value="VI">Certificación de pagos a la seguridad social.</li>
        <li value="VII">Copia de la matrícula de tu vehículo.</li>
      </ol>
      <p>ADICIONALMENTE deberás imprimir, firmar y escanear:</p>
      <ol>
        <li value="VIII">El presente contrato de mutuo.</li>
        <li value="IX">El contrato de garantías mobiliarias.</li>
        <li value="X">El poder.</li>
        <li value="XI">El pagaré.</li>
        <li value="XII">La carta de instrucciones.</li>
      </ol>
      <p>
        Solo en casos excepcionales, cuando te sea imposible contratar una firma
        digital, podrás enviarnos todo lo anterior notarizado a la dirección de
        nuestras oficinas que aparece en nuestra página web{' '}
        <strong>
          <a
            href="https://www.JuanchoTePresta.com"
            title="www.JuanchoTePresta.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            www.JuanchoTePresta.com
          </a>
        </strong>
        .
      </p>
      <p>
        Para consultar proveedores de firma digital consulta en:{' '}
        <strong>
          <a
            href="https://onac.org.co/servicios/entidades-de-certificacion-digital/"
            title="https://onac.org.co/servicios/entidades-de-certificacion-digital/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://onac.org.co/servicios/entidades-de-certificacion-digital/
          </a>
        </strong>
      </p>
    </Container>
  );
};
