import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from '../../styles/styled-components';
import InputIcon, { Props } from './InputIcon';

const InputPasswordStyled = styled.div`
  position: relative;
`;
const InputIconStyled = styled(InputIcon)`
  padding-right: 36px;
  font-weight:500;
`;
const Icon = styled.i`
  position: absolute;
  right: 28px;
  top: 0;
  height: 100%;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  color: #083863;
`;

const InputPassword: React.FC<Props> = ({ ...restProps }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <InputPasswordStyled>
      <InputIconStyled
        {...restProps}
        type={showPassword ? 'text' : 'password'}
      />
      <Icon onClick={() => setShowPassword(prevValue => !prevValue)}>
        <FontAwesomeIcon icon={showPassword ? 'eye-slash' : 'eye'} />
      </Icon>
    </InputPasswordStyled>
  );
};

export default InputPassword;
