export default (input: HTMLInputElement | HTMLSelectElement) => {
  const form = input.closest('form');

  if (!form) {
    console.warn(`${input.name} has not a parent form`);
    return;
  }

  const currentFocus = Array.from(
    { length: form.length },
    (_, i) => form[i]
  ).findIndex(el => el === document.activeElement);

  if (currentFocus !== -1 && currentFocus + 1 <= form.length) {
    const el = form[currentFocus + 1] as HTMLElement;
    if (el) el.focus();
  }
};
