import { AnyAction } from '@reduxjs/toolkit';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import uuid from '../../helpers/uuid';
import { Pixel } from '../../services/pixelGet';
import {
  Result as SignupResponse,
  signupPost
} from '../../services/useSignUpPost';
import { askrobin } from '../../trackers/askrobin';
import setSeon from '../../trackers/seon_tracker';
import { setZipps } from '../../trackers/zipps';
import ApiError from '../../types/ApiError';
import { ActionPromise } from '../asyncDispatch';
import { session } from '../reducers/sessionReducer';
import { signup } from '../reducers/signupReducer';
import { sentryConfigureContext } from './Sentry';
import callService from './callService';
import { cookieSaga } from './cookie';
import { logoutFlow } from './logoutFlow';
import { pixelSaga } from './pixel';

export function* signupSaga() {
  yield takeLatest(
    signup.actions.preRequest,
    function* ({ payload }: AnyAction) {
      yield call(askrobin, payload.id);
      while (true) {
        const { meta }: ActionPromise = yield take(signup.actions.request);
        //uuid.newUser();

        yield call(sentryConfigureContext);
        try {
          const response: SignupResponse = yield callService(signupPost, {
            ...payload,
            uuid: uuid.user,
          });

          yield put(signup.actions.success(response));
          yield put(session.actions.login(response.token));
          yield fork(logoutFlow);
          const pixels: Pixel[] = yield call(pixelSaga, 'register');
          yield call(cookieSaga);
          yield call(setZipps, payload);
          yield call(setSeon, payload);
          yield call(meta.resolve, pixels);
        } catch (e) {
          if (e instanceof ApiError) {
            if (e.code === 422) {
              if (e.response.code === "T-001") {
               // console.log("Telefono no valido =>",e.response.message);
              }
              if (e.response.code === "D-001") {
               // console.log("alguno de los datos no valido =>",e.response.message);
              }
              if (e.response.code === "U-001") {
               // console.log("TerminalUuid ya esta en uso =>",e.response.message);
              }
            }
            yield call(meta.resolve,e.response.code)
          }
          yield put(signup.actions.error(e));
          yield call(meta.reject, new Error('error'));
        }
      }
    }
  );
}
