import styled from 'styled-components';
import figure from '../../images/btn-monto.png';

// https://github.com/twbs/bootstrap/blob/master/dist/css/bootstrap.css
const RangeInput = styled.input.attrs({
  type: 'range',
})`
  width: 100%;
  height: calc(2.5rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    outline: none;
  }

  &:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    position: relative;
    background-image: url(${figure});
    background-repeat: no-repeat;
    background-position: 50% 40%;
    background-size: 55px 55px;
    width: 2rem;
    height: 2rem;
    margin-top: -0.75rem;
    background-color: red;
    border: 3px solid white;
    border-radius: 2rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }

  &::-webkit-slider-thumb:active {
    background-color: red;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
  }

  &::-moz-range-thumb {
    background-image: url(${figure});
    background-repeat: no-repeat;
    background-position: 50% 40%;
    background-size: 55px 55px;
    width: 2rem;
    height: 2rem;
    margin-top: -0.55rem;
    background-color: red;
    border: 3px solid white;
    border-radius: 2rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }

  &::-moz-range-thumb:active {
    background-color: red;
  }

  &::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 2rem;
  }

  &::-ms-thumb {
    background-image: url(${figure});
    background-repeat: no-repeat;
    background-position: 50% 40%;
    background-size: 55px 55px;
    width: 2rem;
    height: 2rem;
    margin-top: -0.55rem;
    background-color: red;
    border: 3px solid white;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;
  }

  &::-ms-thumb:active {
    background-color: red;
  }

  &::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
  }

  &::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
  }

  &::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
  }

  &:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }

  &:disabled::-webkit-slider-runnable-track {
    cursor: default;
  }

  &:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }

  &:disabled::-moz-range-track {
    cursor: default;
  }

  &:disabled::-ms-thumb {
    background-color: #adb5bd;
  }
`;

export default RangeInput;
