import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export const url = 'sec/loans/buro/bi';
type BankResponse = {
  accounts: string[];
  bankNames: string[];
  walletAccounts: string[];
  walletNames: string[];
};

export default serviceBuilder<None, BankResponse>('get', {
  url,
  auth: true,
});
