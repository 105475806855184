import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { pathChanged } from '../store/actions/navigation';

const dataLayer = (window as any).dataLayer || [];

interface Props extends RouteComponentProps {
  pathChanged(...args: Parameters<typeof pathChanged>): void;
}

// https://reacttraining.com/react-router/web/guides/scroll-restoration
class ScrollToTop extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (this.props.location !== prevProps.location) {
      if (prevProps.history.action !== 'POP') {
        window.scrollTo(0, 0);
      }
      this.props.pathChanged({
        previous: prevProps.location.pathname,
        current: this.props.location.pathname,
      });

      dataLayer.push({
        event: 'PageView',
        pagePath: this.props.location.pathname,
      });
    }
  }

  render() {
    return this.props.children;
  }
}

export default connect(undefined, (dispatch) => ({
  pathChanged: (payload: any) => dispatch(pathChanged(payload)),
}))(withRouter(ScrollToTop));
