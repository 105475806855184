import { configureScope } from '@sentry/browser';
import { select } from 'redux-saga/effects';
import { TokenAttrs } from '../../helpers/tokenHelpers';
import { fingerprint } from '../../trackers/fingerprint';
import { UserInfo } from '../reducers/userReducer';
import { tokenAttrsSelector } from '../selectors';
import { basicInfoSelector } from '../selectors/userSelectors';

const date = new Date();

export function* sentryConfigureContext() {
  const attrs: TokenAttrs = yield select(tokenAttrsSelector);
  const { user }: UserInfo = yield select(basicInfoSelector);

  configureScope(scope => {
    scope
      .setUser({
        id: attrs.uuid || 'unknown',
        email: user.email || 'unknown',
        username: user.fullName || 'unknown',
        uuid: attrs.uuid || 'unknown',
        terminal: fingerprint.hash,
        canvas: fingerprint.canvas,
        webgl: fingerprint.webgl,
      })
      .setTags({
        'session.date': date.toLocaleDateString('es-CO'),
        'session.time': date.toLocaleTimeString('es-CO'),
        'session.timestamp': `${date.getTime()}`,
      });
  });
}
