export default ({
  amount,
  term,
  guaranteeVariable,
}: {
  [v: string]: number;
}) => {
  const termDays = term * 30;

  const valueAvalVariable = amount * guaranteeVariable * termDays;

  const aval = valueAvalVariable / term;

  return aval;
};
