import { LoanStatus } from './loanChangeStatusPut';
import serviceBuilder from './serviceBuilder';

export interface Loan {
  amount: number;
  term: number;
  loanPurpose: number;
  loanStatus: LoanStatus;
}

const url = 'loans/status/:uuidToken';
export default serviceBuilder<{ uuidToken: string }, Loan>('get', {
  query: true,
  url,
  auth: false,
});
