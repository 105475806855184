import dayjs from 'dayjs';
import 'dayjs/locale/es';

const dayjsCustomParser = require('dayjs-ext/plugin/customParseFormat');
dayjs.locale('es');
dayjs.extend(dayjsCustomParser);

export default (date: string, format: string) => {
  return dayjs(date, { format });
};
