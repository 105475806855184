/* eslint react-hooks/exhaustive-deps: 0 */
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import noop from '../../helpers/noop';
import infoIcon from '../../images/informacionicon-white.png';
import styled from '../../styles/styled-components';
import { SpinnerPro } from '../UI/SpinnerPro';

const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  #z-index: 1000;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom:0;
  @media (min-width: 600px) {
    padding-bottom:160px;
    }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
`;
const MainContent = styled.div`
  background-color: rgb(48, 92, 255);
  padding: 70px 20px 20px 20px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  max-height: 95%;
  overflow-y: auto;
  z-index: 1001;
  width: 400px;
  border-radius: 10px;
  @media (min-width: 800px) {
    width:600px;
    }
`;
const SecondContent = styled.div`
background-color: #fff;
position:relative;
display:flex;
flex-direction:column;
border-radius:5px; 
align-items:center;
justify-content:center;
width:100%; 
`
const Textcontainer = styled.div`
color: #000;
disply:flex;
align-items: center;
justify-content: center;
top:5px;
> h4 {
  margin: 45px 0 0 0;
  font-weight: 700;
  font-size: 22px;
}
`;
const TextFirst = styled.div`
padding:0 20px 0 20px;
@media (min-width: 800px) {
    padding:0 120px 0 120px;
    }
`
const TextSecond = styled.div`
padding:0 10px 0 10px;
text-align: justify;
@media (min-width: 800px) {
    padding:0 95px 0 95px;
    }
`
const IconContainer = styled.div`
  position:absolute;
  background-color: rgb(48, 92, 255);
  top:-40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display:flex;
  align-items:center;
  justify-content:center;
`;
const NoticeContent = styled.img`
    width: 70px;
`;
const Infocontent = styled.div`
display:flex;
justify-content:flex-end;
align-items:flex-end;
border-radius: 5px 0 0 5px ; 
font-size: 12px;
color: #000;
width:100%;
`
const Info = styled.div`
background-color:rgb(48, 92, 255);
border-radius: 5px 0 0 5px ; 
justify-content:flex-end;
align-items:flex-end;
font-size: 12px;
color: #000;
> p {
    margin: 8px 0 8px 8px;
    color:white;
  }
`
const ButtonContent = styled.div`
 display:flex;
 justify-content:center;
 align-items:center;
 gap:16px;
 height: 76px;
`
const Button3 = styled.button`
  background-color: rgb(222, 235, 247);
  color: #000;
  border: none;
  padding: 11px 20px 9px 20px;
  border-radius: 10px;
  cursor:pointer;
  margin:20px 0 16px;
  > p {
    margin: 3px;
    font-size: 12px;
    font-weight:500;
  }
  &:hover {
    background-color: rgb(48, 92, 255);
    color:#fff;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  
`;

// modal 2
type Fulfill = (value: { accept?: boolean; cancel?: boolean ; error?: boolean}) => void;

interface ModalContent {
  title?: ReactNode;
  main: ReactNode;
  accept?: string | boolean;
  cancel?: string | boolean;
  noClosable?: boolean;
}

type ModalAsync = (
  c: ModalContent
) => Promise<{ accept?: boolean; cancel?: boolean ; error?: boolean }>;

export const useModalD = (): [
  { content?: ModalContent; fulfill: Fulfill; close: () => void },
  ModalAsync
] => {
  const [content, setContent] = useState<ModalContent>();
  const [fulfill, setFulfill] = useState<Fulfill>(noop);

  const modal = useCallback((c: ModalContent) => {
    setContent(c);
    const promise = new Promise((resolve: Fulfill) => {
      setFulfill(() => resolve);
    });
    return promise;
  }, []);

  const close = useCallback(() => {
    setContent(undefined);
  }, []);

  return [{ content, fulfill, close }, modal];
};

interface ModalProps {
  props: {
    fulfill: Fulfill;
    content?: ModalContent;
    close(): void;
  };
  container?: HTMLElement;
}

export const ModalD: React.FC<ModalProps> = ({
  props: { content, fulfill, close },
  container = document.getElementById('modal'),
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const accept = useCallback(async() => {
    setIsLoading(true)
    fulfill({ accept: true });
    close();
  }, [fulfill]);

  const cancel = useCallback(async() => {
    setIsLoading(true)
    fulfill({ cancel: true });
    close();
  }, [fulfill]);

  useEffect(() => {
    return () => {
      if (fulfill) fulfill({ cancel: true });
      close();
    };
  }, []);

  return (
    <>
      {container &&
        content &&
        ReactDOM.createPortal(
          <ModalStyled>
            <Background onClick={() => !content.noClosable && cancel()} />
            <MainContent>
              <SecondContent>
                <IconContainer>
                  <NoticeContent
                    src={infoIcon}
                    alt="Vigilado Superintendencia Financiera de Colombia"
                  />
                </IconContainer>
                <Textcontainer>
                  <h4> !Tú empresa tiene un convenio con Juancho!</h4>
                  <TextFirst>
                    <p>
                      Tu crédito está exento del 50% en aval variable y se descontará por nómina.
                    </p>
                  </TextFirst>
                  <TextSecond>
                    <p>
                      Si aceptas, notificaremos a tu empresa y una vez nos
                      autorice, te contactaremos para continuar con la solicitud.
                    </p>
                  </TextSecond>
                </Textcontainer>
                <Infocontent>
                  <Info>
                    <p>Tu crédito con Juancho solo será posible a través de este convenio.</p>
                  </Info>
                </Infocontent>
               
                <ButtonContent>
                {isLoading ? (
                             <SpinnerPro size={54} /> 
                              ) : (
                  <>
                  {content.accept && (
                      <Button3  onClick={accept}>
                          {
                          content.accept === true ? 'Aceptar' : (<p>{content.accept}</p>)
                          }
                      </Button3>
                  )}
                  {content.cancel && (
                    <Button3  onClick={cancel}>
                      {
                      content.cancel === true ? 'Cancelar' :(<p>{content.cancel}</p>) 
                      }
                    </Button3>
                  )}
                  </>)}
                </ButtonContent> 
              </SecondContent>
            </MainContent>
          </ModalStyled>,
          container
        )}
    </>
  );
};
