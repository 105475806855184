import { Formik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import Form from '../../../../components/Form/Form';
import FormError from '../../../../components/Form/FormError';
import FormGroup from '../../../../components/Form/FormGroup';
import FormSubmit from '../../../../components/Form/FormSubmit';
import InputDate from '../../../../components/Form/InputDate';
import InputGroup from '../../../../components/Form/InputGroup';
import InputNumber from '../../../../components/Form/InputNumber';
import Label from '../../../../components/Form/Label';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../../components/UI/ButtonGroup';
import GeneralContent from '../../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import { submitTrap } from '../../../../helpers/formHelpers';
import postAgreements from '../../../../services/management/agreements';
import styled from '../../../../styles/styled-components';
import { dateSchema, numberSchema } from './ValidationSchema';

const initialValues = {
  proposedDate: '',
  justification: '',
  amount: '',
};

const Styled = styled.div`
  .card {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${(props) => props.theme.white};
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    margin-bottom: 10px;

    .circle {
      background-color: white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`

const ErrorModal = {
  main: (
    <p>No fue posible enviar la información, por favor intentelo más tarde.</p>
  ),
  accept: 'Aceptar',
};

const Agreements: React.FC = () => {
  const [modal, openModal] = useModalC();
  return (
    <GeneralContent>
      <ModalC props={modal} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          proposedDate: dateSchema(),
          justification: string().required('Requerido'),
          amount: numberSchema,
        })}
        onSubmit={submitTrap(async (values, { resetForm }) => {
          try {
            await postAgreements(values);
            resetForm(initialValues);
          } catch (error) {
            openModal(ErrorModal);
          }
        })}
      >
        <Form>
          <Styled>
            <div className="card">
              <Label>
                Evitas las acciones legales cuando nos contactas para llegar a un acuerdo de pago.
              </Label>
            </div>
          </Styled>
          <FormGroup>
            <Label title="Ingrese la fecha en la cual va a realizar el pago">
              Fecha Propuesta
            </Label>
            <InputDate name="proposedDate" />
          </FormGroup>
          <FormGroup>
            <Label title="Ingrese la justificación de la situación por la cual solicita el acuerdo, solo puede realizar acuerdo de pago si va a cancelar el saldo en mora en los próximos 20 días, si su situación es mas compleja debe solicitar un alivio o una refinanciación">
              Justificación
            </Label>
            <InputGroup type="textarea" name="justification" />
          </FormGroup>
          <FormGroup>
            <Label title="Ingrese el monto que cancelará en la fecha propuesta">
              Monto
            </Label>
            <InputNumber name="amount" icon="dollar-sign" />
          </FormGroup>
          <FormGroup>
            <FormError />
          </FormGroup>
          <ButtonGroup>
            <ButtonContainer>
              <FormSubmit>Agregar</FormSubmit>
            </ButtonContainer>
          </ButtonGroup>
        </Form>
      </Formik>
    </GeneralContent>
  );
};

export default Agreements;
