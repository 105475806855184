import React from 'react';
const Home =props =>
<svg xmlns="http://www.w3.org/2000/svg" width="20.531" height="18.302" viewBox="0 0 20.531 18.302">
  <g id="home" transform="translate(0)">
    <g id="Group_711" data-name="Group 711">
      <g id="Group_710" data-name="Group 710">
        <path id="Path_701" className="fill-color" data-name="Path 701" d="M20.312,35.026,10.58,27.9a.533.533,0,0,0-.63,0L.218,35.026a.533.533,0,1,0,.63.861l9.417-6.895,9.417,6.895a.533.533,0,0,0,.63-.861Z" transform="translate(0.001 -27.798)" />
      </g>
    </g>
    <g id="Group_713" data-name="Group 713" transform="translate(2.264 8.21)">
      <g id="Group_712" data-name="Group 712">
        <path id="Path_702" className="fill-color" data-name="Path 702" d="M71.922,232.543a.533.533,0,0,0-.533.533v8.491H67.121v-4.635a2.667,2.667,0,0,0-5.335,0v4.635H57.519v-8.491a.533.533,0,1,0-1.067,0V242.1a.533.533,0,0,0,.533.533H62.32a.533.533,0,0,0,.531-.492.4.4,0,0,0,0-.042v-5.169a1.6,1.6,0,1,1,3.2,0V242.1a.392.392,0,0,0,0,.041.533.533,0,0,0,.531.492h5.334a.533.533,0,0,0,.533-.533v-9.025A.534.534,0,0,0,71.922,232.543Z" transform="translate(-56.452 -232.543)" />
      </g>
    </g>
  </g>
</svg>

export default Home;
