import { FormFieldsError } from './FormFieldsError';
import serviceBuilder from './serviceBuilder';

export interface Params {
  id: string;
  forwardTo: string;
}

export interface Result {
  status: boolean;
}

export const url = 'users/password/request';

const service = serviceBuilder<Params, Result>('post', {
  url,
  auth: false,
});

export default async (params: Params) => {
  try {
    await service(params);
  } catch {
    throw new FormFieldsError({
      id: 'Verifica tu cédula',
      hidden: true,
    });
  }
};
