import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import styled from '../../styles/styled-components';
import Label from '../Form/Label';

export interface Props {
  label: string;
  value: string;
  icon?: IconProp;
  onClick?: () => any;
}

const IndicatorStyled = styled.div`
  label {
    font-weight: 700;
  }
  margin-bottom: 0.2rem;
`;

const IconStyled = styled.span`
  float: right;
  :hover {
    color: ${props => props.theme.primary600};
    cursor: pointer;
  }
`;

const Indicator: React.FC<Props> = ({ label, value, icon, onClick }) => (
  <IndicatorStyled>
    <Label>{label} </Label>
    <span>{value}</span>
    {icon && (
      <IconStyled onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </IconStyled>
    )}
  </IndicatorStyled>
);

export default Indicator;
