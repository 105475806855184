import v4 from 'uuid/v4';

interface Options {
  replace: boolean;
}

export function userUUID(options: Options) {
  if (options.replace || !localStorage.getItem('user-uuid')) {
    localStorage.setItem('user-uuid', v4());
    return localStorage.getItem('user-uuid') || 'unknown';
  }

  return localStorage.getItem('user-uuid') || 'unknown';
}

export default {
  newUser() {
    return userUUID({ replace: true });
  },
  get user() {
    return userUUID({ replace: false });
  },
};
