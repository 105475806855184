import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export const url = 'sec/users/dm';

export type UserDesenbplsingMethods = {
  dmId: number;
  selected: boolean;
};
export interface Result {
  udm: UserDesenbplsingMethods[];
}

export default serviceBuilder<None, Result>('get', {
  url,
  auth: true,
});
