import * as Sentry from '@sentry/browser';

const invalid_fingerprints = [
  'users/login',
  'users/fields',
  'users/password/request',
];

//'users/password/request' --> change password request
//users/password -> front email, change password

export function initSentry() {
  Sentry.init({
    dsn:
      'https://d92548406969459bbad97a673b420b13@sentry.tools.vertical.com.co/3',
    release: `${process.env.REACT_APP_VERSION}`,
    environment: `${process.env.REACT_APP_ENV}`,
    beforeSend(event) {
      const { fingerprint } = event;

      if (
        fingerprint &&
        invalid_fingerprints.some((invalid_fingerprint) =>
          fingerprint.includes(invalid_fingerprint)
        )
      ) {
        return null;
      }
      return event;
    },
  });
}
