import React from 'react';
export const Costos = {
  main: (
    <p>
      Asociados a la solicitud, aprobación y firma de los contratos así como al
      registro de tus garantías y los costos en los que incurres en caso de no
      pagar a tiempo las cuotas de tu crédito adicionales al interés de mora
      vigente.
    </p>
  ),
  accept: 'Aceptar',
};
