import { SagaIterator } from '@redux-saga/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import sendEventToGTM from '../../helpers/tagManager';
import activateCliente from '../../services/activateClientPost';
import personalInformationGet from '../../services/basicInfoGet';
import basicInfoPost, { BasicInfo } from '../../services/basicInfoPost';
import creatBasicLoanPost from '../../services/createBasicLoanPost';
import creditListGet from '../../services/creditListGet';
import { LoanList } from '../../services/loansPayment';
import { ActionPromise } from '../asyncDispatch';
import loans from '../reducers/allLoanReducer';
import { user } from '../reducers/userReducer';
import { sentryConfigureContext } from './Sentry';
import callService from './callService';
import { refreshTokenSaga } from './session';

export function* basicInfoSaga({ payload }: PayloadAction<BasicInfo>) {
  const plainPayload = {
    ...payload.laboral,
    ...payload.personal,
    ...payload.residential,
  };

  yield callService(basicInfoPost, plainPayload);
  const loansList: LoanList = yield callService(creditListGet);
  yield put(loans.actions.update(loansList));
  console.log("loansList",loansList)
  let contador = 0;
  let cTag =0;
  for (const key in loansList) {
      if (loansList.hasOwnProperty(key)) {
        const objeto = loansList[key];
            if (objeto.statusId === 600 || objeto.statusId === 700) {
              cTag++;
            }
          contador++
      }
  }
  if (contador===0){
      yield callService(activateCliente)
    }
  
  yield callService(creatBasicLoanPost)
  const event =
      cTag >= 1
      ? 'SolicitudRecurrente'
      : 'SolicitudNuevo';
     sendEventToGTM(event);
    // console.log("evento de tag manager =>",event)
  yield call(refreshTokenSaga);
  yield put(user.actions.basicInfo(payload));
  yield call(sentryConfigureContext);
}

export function* updateBasicInfoSaga(action: ActionPromise<any>): SagaIterator {
  try {
    const basicInfo: BasicInfo = yield callService(personalInformationGet);
    yield put(user.actions.basicInfoPlain(basicInfo));
    action.meta.resolve();
  } catch (e) {
    action.meta.reject({
      message: 'Error al intentar actualizar',
      name: 'Error',
    });
  }
}
