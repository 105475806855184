export class CancelledError extends Error {
  constructor(message = 'CancelledError') {
    super(message);
    this.name = 'CancelledError';
  }
}

export type Cancel = () => void;

interface AsyncFunction extends CallableFunction {
  (this: Record<'cancel', Cancel>, ...args: any): Promise<any>;
}

export type Cancellable<F extends AsyncFunction> = {
  cancel: Cancel;
  promise: F;
};

export function makeCancellable<F extends AsyncFunction>(
  job: F
): Cancellable<F> {
  let cancel: any;
  const cancelPromise = new Promise((_, reject) => {
    cancel = () => {
      reject(new CancelledError());
    };
  });

  return {
    cancel,
    async promise(...args: any[]) {
      return await Promise.race([cancelPromise, job.call({ cancel }, ...args)]);
    },
  } as Cancellable<F>;
}
