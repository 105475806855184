import { Formik } from 'formik';
import React from 'react';
import { object, string } from 'yup';
import Form from '../../../../components/Form/Form';
import FormError from '../../../../components/Form/FormError';
import FormGroup from '../../../../components/Form/FormGroup';
import FormSubmit from '../../../../components/Form/FormSubmit';
import InputDate from '../../../../components/Form/InputDate';
import InputGroup from '../../../../components/Form/InputGroup';
import Label from '../../../../components/Form/Label';
import ButtonContainer from '../../../../components/UI/ButtonContainer';
import ButtonGroup from '../../../../components/UI/ButtonGroup';
import GeneralContent from '../../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../../components/UI/Modal';
import { submitTrap } from '../../../../helpers/formHelpers';
import postDatechange from '../../../../services/management/datechange';
import styled from '../../../../styles/styled-components';
import { dateSchema } from './ValidationSchema';

const initialValues = {
  currentPaymentDate: '',
  desiredDate: '',
  justification: '',
};

const Styled = styled.div`
  .card {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${(props) => props.theme.white};
    border-radius: 10px;
    padding: 5px 10px;
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

    margin-bottom: 10px;

    .circle {
      background-color: white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
`

const ErrorModal = {
  main: (
    <p>No fue posible enviar la información, por favor intentelo más tarde.</p>
  ),
  accept: 'Aceptar',
};
const DateChange: React.FC = () => {
  const [modal, openModal] = useModalC();
  return (
    <GeneralContent>
      <ModalC props={modal} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          currentPaymentDate: dateSchema() as any,
          desiredDate: dateSchema() as any,
          justification: string().required('Requerido'),
        })}
        onSubmit={submitTrap(async (values, { resetForm }) => {
          try {
            await postDatechange(values);
            resetForm(initialValues);
          } catch (error) {
            openModal(ErrorModal);
          }
        })}
      >
        <Form>
          <Styled>
            <div className="card">
              <Label>
                Juancho te permite cambiar la fecha en la que debes cancelar tu cuota para evitar retrasos en tu historial de pagos.
              </Label>
            </div>
          </Styled>
          <FormGroup>
            <Label title="ingrese la fecha para la que está programada su cuota">
              Fecha de pago actual
            </Label>
            <InputDate name="currentPaymentDate" />
          </FormGroup>
          <FormGroup>
            <Label title="Elija la nueva fecha, esta debe estar dentro del mismo mes y para los meses siguientes se mantendrá el mismo día">
              Fecha deseada
            </Label>
            <InputDate name="desiredDate" />
          </FormGroup>
          <FormGroup>
            <Label title="Ingrese una justificación del cambio, el cambio quedará dentro del mismo mes solo si posee hasta 4 días de mora con la obligación, de lo contrario aplicará para el mes siguiente">
              Justificación
            </Label>
            <InputGroup type="textarea" name="justification" />
          </FormGroup>
          <FormGroup>
            <FormError />
          </FormGroup>
          <ButtonGroup>
            <ButtonContainer>
              <FormSubmit>Agregar</FormSubmit>
            </ButtonContainer>
          </ButtonGroup>
        </Form>
      </Formik>
    </GeneralContent>
  );
};

export default DateChange;
