import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getQueryParams } from './helpers/queryString';
import pixel, { Utm } from './store/reducers/pixelReducer';
import { initZipps } from './trackers/zipps';
import HomeView from './views/HomeView';
import LoanRoutes from './views/Loan/LoanRoutes';
import LogInRoutes from './views/LogIn/LogInRoutes';
import LogOut from './views/LogOut';
import NotFound from './views/NotFound';
import SignUpRoutes from './views/SignUp/SignUpRoutes';
import SimulateContainer from './views/Simulate/SimulateContainer';
import UsersRoutes from './views/Users/UsersRoutes';

interface Props {
  hidratePixel: (Params: any) => void;
}

const Routes = (props: Props) => {
  initZipps();

  const urlParams = getQueryParams(window.location.search);
  const source = urlParams['utm_source'] || '';

  const payload: Utm = {
    utmSource: source,
    utmMedium: urlParams['utm_medium'] || '',
    utmCampaign: urlParams['utm_campaign'] || '',
    utmTerm: urlParams['utm_term'] || '',
    utmContent: urlParams['utm_content'] || '',
    origin: document.referrer,
    referral: '',
  };

  if (source.toLowerCase() === 'doaff') {
    payload.visitor = urlParams['v'] || '';
  }

  if (source.toLowerCase() === 'crezu') {
    payload.utmContent = urlParams['utm_affiliate_content'] || '';
  }

  props.hidratePixel(payload);

  return (
    <>
      <Switch>
        <Route exact path={['/', '/home']} component={HomeView} />

        <Route path="/simulate" component={SimulateContainer} />

        <Route path="/login" component={LogInRoutes} />

        <Route path="/signup" component={SignUpRoutes} />

        <Route path="/users" component={UsersRoutes} />

        <Route path="/loans" component={LoanRoutes} />

        <Route exact path="/logout" component={LogOut} />

        <Route path="/logout/redirect/:to" component={LogOut} />

        <Route path="/not-found" component={NotFound} />

        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default connect(undefined, (dispatch) => ({
  hidratePixel: (params: Utm) => dispatch(pixel.actions.hidrateUtm(params)),
}))(Routes);
//export default Routes;
