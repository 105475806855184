import serviceBuilder from '../serviceBuilder';

export interface Result {
  code: number;
}

interface Params {
  paymentDate: string;
  applyTo: string;
  attachment: string;
  fileMimeType: string;
}

const url = 'sec/users/capitalcredit';
export default serviceBuilder<Params, Result>('post', {
  auth: true,
  url,
});
