import styled from '../../styles/styled-components';

const ButtonContainerPro = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  margin-top: 0.125rem;
  align-items: center;
  min-width:250px;
  justify-content: center;
  & > a {
    margin: 0.3125rem;
  }
`;

export default ButtonContainerPro;
