const moneyFormat = (value: number, sign = true, round = true): string => {
  const mantissa = value - Math.floor(value);
  const integer = (value - mantissa).toFixed(0);

  const finteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  if (round) {
    return `${sign ? '$' : ''}${finteger}`;
  }
  return `${sign ? '$' : ''}${finteger},${mantissa.toFixed(2).slice(2)}`;
};

export default moneyFormat;
