import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'cleave.js/dist/addons/cleave-phone.co';
import Cleave from 'cleave.js/react';
import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from '../../styles/styled-components';
import Error from './Error';
import { Icon, InputCSS } from './Input';
import { InputIconStyled } from './InputIcon';
const InputStyled = styled(Cleave)`
  font-weight:500;
  width: 100%;
  ${InputCSS}
  padding-right: 36px;
  appearance: none;
`;

const InputNumberComponent: React.FC<FieldProps & Props> = ({
  form,
  field,
  prefix = '',
  icon,
  delimiter = '.',
  maxLength = 11,
  ...restProps
}) => {
  return (
    <InputIconStyled>
      <InputStyled
        invalid={
          form.touched[field.name]! && !!form.errors[field.name]
            ? ('invalid' as any)
            : undefined
        }
        id={field.name}
        options={{
          numeral: true,
          delimiter,
          numeralDecimalMark: ',',
          numeralPositiveOnly: true,
          numeralDecimalScale: 0,
          prefix,
        }}
        maxLength={maxLength}
        type="tel"
        onFocus={ev => ev.target.select()}
        {...restProps}
        {...field}
        onChange={ev => {
          ev.target.value = ev.target.rawValue.replace(/\$|\./g, '') || '';
          field.onChange(ev);
        }}
      />
      {icon && (
        <Icon>
          <FontAwesomeIcon icon={icon} />
        </Icon>
      )}
    </InputIconStyled>
  );
};

export interface Props {
  name: string;
  prefix?: string;
  delimiter?: string;
  placeholder?: string;
  help?: string;
  icon?: IconName;
  maxLength?: number;
}

const InputNumber: React.FC<Props> = ({ name, help, ...restProps }) => (
  <>
    <Field name={name} {...restProps} component={InputNumberComponent} />
    <Error name={name} help={help} />
  </>
);

export default InputNumber;
