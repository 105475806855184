import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/Router/PrivateRoute';
import NotFound from '../NotFound';
import PersonalInformationContainer from './PersonalInformation/PersonalInformationContainer';
import ValidateIdentityViewPre from './ValidateIdentity/ValidateIdentityViewPre';

const UsersRoutes: React.FC<RouteComponentProps> = () => (
  <Switch>
    {console.log("enrutamiento activado")}
    <PrivateRoute
      //require={{ attrs: { iv: 'TODO' } }}
      path="/users/validate-identity"
      component={ValidateIdentityViewPre}
    />
    {/* <PrivateRoute
      require={{ attrs: { sq: 'TODO' } }}
      path="/users/security-questions"
      component={SecurityQuestionView}
    /> */}
    <PrivateRoute
      require={{ attrs: { pi: 'TODO' } }}
      path="/users/basic-info"
      component={PersonalInformationContainer}
    />
    <PrivateRoute
      path="/users/basic-info-recover"
      component={PersonalInformationContainer}
    />
    <Route component={NotFound} />
  </Switch>
);

export default UsersRoutes;
