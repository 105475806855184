import periodicPaymentAmount from './periodicPaymentAmount';

export default ({
  amount,
  term,
  interest,
  electronicSignature,
  guaranteeVariable,
}: {
  [v: string]: number;
}) => {
  const termDays = term * 30;
  /* Amortizacion */
  const interestMonthly = Math.pow(1 + interest, 1 / 12) - 1;
  /* Aval */
  const valueAvalVariable = amount * guaranteeVariable * termDays;
  /* Iva */
  const valueElectronicSignature = electronicSignature / termDays;

  const quote =
    periodicPaymentAmount(interestMonthly, term, amount) +
    valueElectronicSignature * 30 +
    valueAvalVariable / term;

  return quote;
};
