import { captureMessage, withScope } from '@sentry/minimal';
import { Severity } from '@sentry/types';
import Uuid from '../helpers/uuid';
import { Params } from '../services/useSignUpPost';


export interface FetchPayload {
  uuid: string;
  cedula: string;
  ip: string;
  email: string;
  phone: string;
  visitor_id: string;
}

export interface Values {
  givenname: string;
  lastname: string;
  surname: string;
  idType: string;
  id: string;
}


export const validateName = async (values: Values): Promise<any> => {
  const lastname = values.surname + ' ' + values.lastname
  try {
    if ((window as any).zipps) return await (window as any).zipps.validateName(values.id, values.idType, values.givenname, lastname);
  } catch (error) {
    withScope((scope) => {
      scope
        .setFingerprint(['zipps/error'])
        .setLevel(Severity.Critical)
        .setTags({
          'validation.visitor_id': `${(window as any).zipps.visitorId}` || '',
          'validation.id': values.id,
        })
        .addBreadcrumb({
          category: 'zipps-error',
          data: {
            error: error,
          },
          level: Severity.Critical,
        });
      captureMessage(`[ZIPPS] validation error name`);
    });
  }
};
export const validatePhone = async (phone: string): Promise<any> => {
  try {
    if ((window as any).zipps) return await (window as any).zipps.validatePhone(phone);
  } catch (error) {
    withScope((scope) => {
      scope
        .setFingerprint(['zipps/error'])
        .setLevel(Severity.Critical)
        .setTags({
          'validation.visitor_id': `${(window as any).zipps.visitorId}` || ''
        })
        .addBreadcrumb({
          category: 'zipps-error',
          data: {
            error: error,
          },
          level: Severity.Critical,
        });
      captureMessage(`[ZIPPS] validation error phone`);
    });
  }
};

export const validateEmail = async (email: string): Promise<any> => {
  try {
    if ((window as any).zipps) return await (window as any).zipps.validateEmail(email);
  } catch (error) {
    withScope((scope) => {
      scope
        .setFingerprint(['zipps/error'])
        .setLevel(Severity.Critical)
        .setTags({
          'validation.visitor_id': `${(window as any).zipps.visitorId}` || ''
        })
        .addBreadcrumb({
          category: 'zipps-error',
          data: {
            error: error,
          },
          level: Severity.Critical,
        });
      captureMessage(`[ZIPPS] validation error email`);
    });
  }
};

export const initZipps = async () => {
  const apiZippsURL = process.env.REACT_APP_API_ZIPPS;
  //const apiZippsURL = "https://playcdn.zipps.in/v2/index.js";
  const userObjet = {externalId:Uuid.newUser()} ;
  const zippsScript = document.createElement('script');
  zippsScript.onload = async () => {
    try {
      if ((window as any).zipps) await (window as any).zipps.init(userObjet);
    } catch (error) {
      console.error('Zipps | init failed');
    }
  };
  //zippsScript.src = 'https://cdn.zipps.in/v2/index.js';
  if( apiZippsURL){
    zippsScript.src = apiZippsURL;
  }
  document.head.appendChild(zippsScript);
};

export const fecthZipps = (payload: FetchPayload) => {
  return fetch(
    `https://1u2k9vyd51.execute-api.us-east-1.amazonaws.com/poc/files/jtp-zipps/${payload.cedula}.json`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': 'ZNucMW8F3a9S8a8i8prMU5F0ebda6dqsu2WBRUB1',
      },
      body: JSON.stringify(payload),
    }
  );
};

export const setZipps = async (user: Params) => {
  const uuid: string = Uuid.user;
  try {
    if (!uuid) throw new Error('Uuid undefined');

    if (!(window as any).zipps.visitorId)
      throw new Error('visitorId undefined');

    const external_id = uuid;
    const payload = {
      cedula: user.id,
      email: user.email,
      ip: '',
      phone: user.phone,
      uuid,
      visitor_id: (window as any).zipps.visitorId || '',
    };
    await (window as any).zipps.update({ ...payload, external_id });
    await fecthZipps(payload);
  } catch (err) {
    withScope((scope) => {
      scope
        .setFingerprint(['zipps/error'])
        .setLevel(Severity.Critical)
        .setTags({
          'validation.visitor_id': `${(window as any).zipps.visitorId}` || '',
          'validation.uuid': uuid,
          'validation.phone': user.phone,
          'validation.email': user.email,
        })
        .addBreadcrumb({
          category: 'zipps-error',
          data: {
            error: err,
          },
          level: Severity.Critical,
        });
      captureMessage(`[ZIPPS] validation error`);
    });
  }
};