import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BasicInfo,
  LaboralInfo,
  PersonalInfo,
  ResidentialInfo,
} from '../../services/basicInfoPost';

export interface UserInfo {
  user: Record<'email' | 'fullName' | 'cellPhone', string>;
  personal: PersonalInfo;
  residential: ResidentialInfo;
  laboral: LaboralInfo;
}

const initialState: UserInfo = {
  user: {
    email: '',
    fullName: '',
    cellPhone: '',
  },
  personal: {
    birthdate: '',
    expeditionDate: '',
    issueCityCode: '',
    issueDepartmentCode: '',
    genderId: '',
    dependents: '',
    educationalLevel: '',
    maritalStatus: '',
  } as any,
  residential: {
    departmentCode: '',
    cityCode: '',
    neighborhood: '',
    housingType: '',
    stayTime: '',
    address: '',
  } as any,
  laboral: {
    jobType: '',
    monthlySalary: '' as any,
    economicActivity: '',
    economicSector: '',
    company: '',
    nit: '',
    bank: '',
    paymentPeriodicity: '',
    otherIncome: '',
    paymentMethod: '',
  } as any
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    basicInfo(state, action: PayloadAction<BasicInfo>) {
      return { ...state, ...action.payload, user: state.user };
    },
    basicInfoPlain(state, { payload }: PayloadAction<any>) {
      Object.values(state).forEach((fields: any) => {
        Object.keys(fields).forEach((key) => {
          if (key in payload) {
            fields[key] = payload[key] || '';
          }
        });
      });
    },
    user(state, action: PayloadAction<UserInfo['user']>) {
      return { ...state, user: action.payload };
    },
    clearUserInfo() {
      return initialState;
    },
  },
});
