import React from 'react';
const Terms = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.626"
    height="20.794"
    viewBox="0 0 21.626 20.794"
  >
    <g id="legal-paper" transform="translate(0 0)">
      <g id="Group_717" data-name="Group 717">
        <g id="Group_716" data-name="Group 716" transform="translate(0 0)">
          <g id="Group_715" data-name="Group 715">
            <path
              id="Path_707"
              className="fill-color"
              data-name="Path 707"
              d="M287.974,61.631h0v0Z"
              transform="translate(-272.262 -58.681)"
            />
            <path
              id="Path_708"
              className="fill-color"
              data-name="Path 708"
              d="M18.675,7.627H5.581A2.955,2.955,0,0,0,2.63,10.578V22.537a2.951,2.951,0,0,0,.332,5.884H13.629A2.955,2.955,0,0,0,16.58,25.47V13.528h2.1a2.951,2.951,0,1,0,0-5.9ZM11.539,27.548H2.962a2.08,2.08,0,0,1,0-4.16h8.577A2.946,2.946,0,0,0,11.539,27.548Zm4.173-16.97V25.47a2.078,2.078,0,1,1-2.078-2.078.436.436,0,0,0,0-.873H3.521V10.578A2.082,2.082,0,0,1,5.581,8.5H16.567A2.942,2.942,0,0,0,15.711,10.577Zm2.527,2.078H16.584v-.716h1.654Zm.873-.044V11.5a.436.436,0,0,0-.436-.436H16.584v-.489a2.078,2.078,0,1,1,2.527,2.034Z"
              transform="translate(0 -7.627)"
            />
          </g>
        </g>
      </g>
      <g id="Group_719" data-name="Group 719" transform="translate(5.913 4.33)">
        <g id="Group_718" data-name="Group 718">
          <path
            id="Path_709"
            className="fill-color"
            data-name="Path 709"
            d="M115.2,86.987H108.81a.436.436,0,0,0,0,.873H115.2a.436.436,0,1,0,0-.873Z"
            transform="translate(-108.374 -86.987)"
          />
        </g>
      </g>
      <g
        id="Group_721"
        data-name="Group 721"
        transform="translate(5.913 7.446)"
      >
        <g id="Group_720" data-name="Group 720">
          <path
            id="Path_710"
            className="fill-color"
            data-name="Path 710"
            d="M115.2,144.107H108.81a.436.436,0,1,0,0,.873H115.2a.436.436,0,1,0,0-.873Z"
            transform="translate(-108.374 -144.107)"
          />
        </g>
      </g>
      <g
        id="Group_723"
        data-name="Group 723"
        transform="translate(5.913 10.563)"
      >
        <g id="Group_722" data-name="Group 722">
          <path
            id="Path_711"
            className="fill-color"
            data-name="Path 711"
            d="M115.2,201.227H108.81a.436.436,0,1,0,0,.873H115.2a.436.436,0,1,0,0-.873Z"
            transform="translate(-108.374 -201.227)"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Terms;
