import serviceBuilder from './serviceBuilder';

export interface Params {
  kushkiToken: string;
  paymentId: string;
  amount: number;
  loanId: string;
  address:string;
}

export interface Result {
  redirectUrl: string;
  trazabilityCode: string;
  bankId: string;
  bankName: string;
}

export interface Error {
  message: string;
  code: string;
}

export const url = 'sec/loans/rs/transaction';

export default async (params: Params) => {
  return await serviceBuilder<any, Result, Error>('post', {
    url: `sec/loans/rs/${params.paymentId}/transaction`,
    auth: true,
    silent: true,
  })({ amount: params.amount, kushkiToken: params.kushkiToken, address:params.address});
};
