/* eslint react-hooks/exhaustive-deps: 0 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import Column from '../../../components/Grid/Column';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';

const PredisburseInfoView: React.FC = () => {
  return (
    <Column fluid>
      <GeneralContent>
        <p>
          Firmaste tus documentos exitosamente, vamos a revisar que todo esté
          correcto. En caso de necesitar información adicional te contactaremos.
        </p>
        <p>Si todo está bien, desembolsaremos tu crédito pronto.</p>
      </GeneralContent>
      <ButtonContainer>
        <Button color="red" expand as={NavLink} to="/logout/redirect/home">
          Salir ahora
        </Button>
      </ButtonContainer>
    </Column>
  );
};

export default PredisburseInfoView;
