import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Account from '../../images/icons/account.js';
import Apply from '../../images/icons/apply-for-credit.js';
import Contact from '../../images/icons/contact.js';
import Faq from '../../images/icons/faq.js';
import { default as Home } from '../../images/icons/home.js';
import Important from '../../images/icons/important.js';
import Pay from '../../images/icons/pay-credit.js';
import Simulate from '../../images/icons/simulate-credit.js';
import Terms from '../../images/icons/terms-conditions.js';
import PayMethod from '../../images/icons/pay-method.js';
import logo from '../../images/logo-juancho-te-presta.png';
import { breakpointLarge } from '../../styles/breakpoints';
import Nav from '../UI/Nav';
import Woman from '../../images/icons/woman.js';
const breakpointExtraLarge = '1386px';

const NavbarStyled = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  z-index: 3000;
  max-width: 1360px;
`;
const ButtonContainer = styled.div`
  position: absolute;
  top: ${props => (props.visible ? '-1.4rem' : '0.3rem')};
  left: ${props => (props.visible ? '-1.875rem' : '0.3rem')};
  width: 100%;
  max-width: ${props => (props.visible ? '22rem' : '4.4rem')};
  height: ${props => (props.visible ? '43rem' : '4.4rem')};
  transition: all 0.5s ease;
  @media (min-width: ${breakpointLarge}) {
    max-width: ${props => (props.visible ? '62rem' : '4.4rem')};
    height: ${props => (props.visible ? '28rem' : '4.4rem')};
  }
  @media (min-width: ${breakpointExtraLarge}) {
    max-width: ${props => (props.visible ? '62rem' : '4.4rem')};
    height: ${props => (props.visible ? '28rem' : '4.4rem')};
  }
`;
const ButtonBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props =>
    props.visible ? props.theme.white : props.theme.blue};
  cursor: pointer;
  border: 0;
  border-radius: ${props => (props.visible ? '0' : '50%')};
  outline: 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);
  transition: all 0.5s ease;
  z-index: 3010;
`;
const ButtonTransparent = styled.button`
  position: absolute;
  background-color: red;
  opacity: 0;
  outline-width: 0;
  border-width: 0;
  top: ${props => (props.visible ? '2.75rem' : '1.5rem')};
  left: ${props => (props.visible ? 'auto' : '0.875rem')};
  right: ${props => (props.visible ? '1.25rem' : 'auto')};
  width: ${props => (props.visible ? '1.5rem' : '2.5rem')};
  height: ${props => (props.visible ? '1.5rem' : '1.5rem')};
  z-index: 3030;
  cursor: pointer;
  @media (min-width: ${breakpointExtraLarge}) {
    top: ${props => (props.visible ? '2.75rem' : '1.2rem')};
    left: ${props => (props.visible ? 'auto' : '1rem')};
    height: ${props => (props.visible ? '1.5rem' : '1.9rem')};
  }
`;
const ButtonLine = styled.div`
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
  z-index: 3020;
  width: 2rem;
  height: 0.25rem;
  margin-bottom: 0.25rem;
  transition: all 0.5s ease;
  &.top {
    top: ${props => (props.visible ? '2.5rem' : '1.5rem')};
    transform: ${props =>
    props.visible
      ? 'translate(0.1rem, 1.05rem) rotate(-45deg)'
      : 'initial'};
    @media (min-width: ${breakpointExtraLarge}) {
      top: ${props => (props.visible ? '2.5rem' : '1.5rem')};
    }
  }
  &.bottom {
    top: ${props => (props.visible ? '2.5rem' : '2.7rem')};
    transform: ${props =>
    props.visible
      ? 'translate(0.125rem, 1.05rem) rotate(45deg)'
      : 'initial'};
    @media (min-width: ${breakpointExtraLarge}) {
      top: ${props => (props.visible ? '2.5rem' : '2.7rem')};
    }
  }
  &.long {
    left: ${props => (props.visible ? 'auto' : '1.2rem')};
    right: ${props => (props.visible ? '1.25rem' : 'auto')};
    border-radius: 0.125rem;
    background-color: white};
  }
  &.short {
    top: ${props => (props.visible ? '1.4rem' : '2.1rem')};
    left: ${props => (props.visible ? '0' : '1.2rem')};
    background-color: ${props =>
      props.visible ? props.theme.gray : props.theme.white};
    border-radius: ${props => (props.visible ? '0' : '0.125rem')};
    width: ${props => (props.visible ? '100%' : '2rem')};
    height: ${props => (props.visible ? '5rem' : '0.25rem')};
    @media (min-width: ${breakpointExtraLarge}) {
      top: ${props => (props.visible ? '1.4rem' : '2.1rem')};
      
    }
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  display: ${p => (p.visible ? 'block' : 'none')};
`;
const Logo = styled.img`
  position: absolute;
  top: 0.75rem;
  width: 6.25rem;
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: none;
  transition: all 0.5s ease;
  z-index: 3050;
  @media (min-width: ${breakpointLarge}) {
    top: 1.5rem;
  }
`;
const Menu = styled(Nav)`
  position: absolute;
  top: 6rem;
  width: 21.125rem;
  opacity: ${props => (props.visible ? 1 : 0)};
  transform: translate(${p => (p.visible ? '0' : '-100%')}, 0);
  transition: all 0.5s ease;
  z-index: 3040;
  @media (min-width: 360px) {
    width: 20.125rem;
  }
  @media (min-width: ${breakpointLarge}) {
    transform: translate(0, ${p => (p.visible ? '0' : 'calc(-100% - 4rem)')});
  }
  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  li {
    width: 100%;
  }
  ul li:last-child span {
    border-width: 0;
  }
  @media (min-width: ${breakpointLarge}) {
    top: 4rem;
    left: 5.5rem;
    height: 20rem;
    width: 100%;
    max-width: 54rem;
    ul {
      width: 100%;
      height: 21rem;
    }
    li {
      width: 23rem;
      margin-left: 2rem;
    }
  }
`;
const linkStyle = css`
  display: flex;
  align-items: center;
  padding: 0 2rem 0 2.75rem;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.5s ease;
  @media (min-width: ${breakpointLarge}) {
    padding-left: 1.5rem;
  }
  span {
    flex: 1;
    color: ${props => props.theme.blueDark};
    border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    padding: 1rem;
  }
  .fill-color {
    fill: ${props => props.theme.blueDark}};
    transition: all 0.5s ease;
  }
  .stroke-color {
    stroke: ${props => props.theme.blueDark}};
    transition: all 0.5s ease;
  }
  &.active,
  &:hover {
    background-color: ${props => props.theme.blue};
    span {
      color: #fff;
    }
    .fill-color {
      fill: #fff;
    }
    .stroke-color {
      stroke: #fff;
    }
  }
`;
const NormalLink = styled.a`
  ${linkStyle}
`;
const LinkStyled = styled(NavLink).attrs(() => ({
  activeClassName: 'selectedLink',
}))`
  ${linkStyle}
`;
const Navbar = () => {
  const [visible, setVisible] = useState(false);

  return (
    <NavbarStyled>
      <ButtonContainer visible={visible}>
        <ButtonBackground visible={visible} onClick={() => setVisible(true)} />
        <ButtonTransparent
          visible={visible}
          onClick={() => setVisible((prevValue) => !prevValue)}
        />
        <ButtonLine visible={visible} className="short" />
        <ButtonLine visible={visible} className="long top" />
        <ButtonLine visible={visible} className="long bottom" />
      </ButtonContainer>
      <Overlay visible={visible} onClick={() => setVisible(true)} />
      <Logo visible={visible} src={logo} />
      <Menu visible={visible}>
        <ul>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}`}
              onClick={() => setVisible(false)}
            >
              <Home />
              <span>Inicio</span>
            </NormalLink>
          </li>
          <li>
            <LinkStyled to="/login" onClick={() => setVisible(false)}>
              <Account />
              <span>Mi cuenta</span>
            </LinkStyled>
          </li>
          <li>
            <LinkStyled to="/signup" onClick={() => setVisible(false)}>
              <Apply />
              <span>Solicita tu crédito</span>
            </LinkStyled>
          </li>
          <li>
            <LinkStyled to="/simulate" onClick={() => setVisible(false)}>
              <Simulate />
              <span>Simula tu crédito</span>
            </LinkStyled>
          </li>
          <li>
            <LinkStyled to="/login" onClick={() => setVisible(false)}>
              <Pay />
              <span>Paga tu cuota</span>
            </LinkStyled>
          </li>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}/privilegios`}
              onClick={() => setVisible(false)}
            >
              <Woman />
              <span>¿Por qué Juancho?</span>
            </NormalLink>
          </li>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}/contacto`}
              onClick={() => setVisible(false)}
            >
              <Contact />
              <span>Contáctanos</span>
            </NormalLink>
          </li>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}/faq`}
              onClick={() => setVisible(false)}
            >
              <Faq />
              <span>Preguntas frecuentes</span>
            </NormalLink>
          </li>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}/terminos-condiciones`}
              onClick={() => setVisible(false)}
            >
              <Terms />
              <span>Términos y condiciones</span>
            </NormalLink>
          </li>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}/avisos-importantes`}
              onClick={() => setVisible(false)}
            >
              <Important />
              <span>Avisos importantes</span>
            </NormalLink>
          </li>
          <li>
            <NormalLink
              href={`${process.env.REACT_APP_CMS}/medios-de-pago`}
              onClick={() => setVisible(false)}
            >
              <PayMethod />
              <span>Medios de pago</span>
            </NormalLink>
          </li>
        </ul>
      </Menu>
    </NavbarStyled>
  );
};

export default Navbar;
