import React from 'react';
import styled from '../styles/styled-components';

const Justify = styled.div`
  text-align: justify;
  margin-top: 50px;
`;

export const RejectMessage = {
  main: (
    <>
      <p>
        Desafortunadamente en este momento no cumples uno de nuestros requisitos
        y no es posible que continúes con tu solicitud de crédito.
      </p>
      <p>
        Mantendremos tu información de registro para contactarte cuando tengamos
        un producto que se ajuste a tu perfil.
      </p>
    </>
  ),
  accept: 'Salir',
  noClosable: true,
};

export const ErrorMessage = {
  main: (
    <div>
      <p>
        Lo sentimos! En este momento no ha sido posible finalizar tu solicitud
        de crédito.
      </p>
      <p>
        Inicia sesión nuevamente para continuar tu solicitud o comunícate con
        nosotros a:
      </p>
      <p>
        <b>WhatsApp: </b>
        <a
          href="https://wa.me/5744807833"
          rel="noopener noreferrer"
          target="_blank"
          title="WhatsApp"
        >
          https://wa.me/5744807833
        </a>
      </p>
      <p>
        <b>Línea gratuita nacional: </b> 018000413811
      </p>
      <p>
        <b>Correo: </b> ayuda@juanchotepresta.com
      </p>
    </div>
  ),
  accept: 'Salir',
  noClosable: true,
};

export const modalTermsConditions = {
  title: (
    <h3>
      Política de privacidad y tratamiento de datos personales en la plataforma
      JuanchoTePresta.com y sitios relacionados
    </h3>
  ),
  main: (
    <Justify>
      <div>
        <p>
          <strong>AVISO IMPORTANTE: </strong>El Usuario al acceder, usar o
          navegar en la Página Web de JUANCHO TE PRESTA S.A.S., reconoce que ha
          leído, entendido y se obliga a cumplir nuestros términos y
          condiciones, así como nuestra política de privacidad y de tratamiento
          de datos personales. El Usuario se compromete a revisar estas
          secciones para estar informado de las modificaciones que pudieran
          realizársele a las mismas, y el uso de nuestros productos y servicios
          implicará para el Usuario que ha leído y aceptado las condiciones
          plasmadas en los Términos y condiciones y en la presente Política de
          privacidad, y acepta estar vinculado por los mismos.
        </p>
        <p>
          Si el usuario no está de acuerdo con estas condiciones o con cualquier
          disposición de la Política de Privacidad, le sugerimos que se abstenga
          de acceder o navegar por el Sitio Web de nuestra empresa. Para una
          mayor ampliación de esta información, lo invitamos a consultar el
          acápite denominado “Modificaciones a las políticas”, contemplado en la
          presente política de privacidad.
        </p>
        <p>
          El presente documento se presenta en cumplimiento de las disposiciones
          de habeas data, en especial la ley 1581 del 17 de octubre de 2012 y
          sus decretos reglamentarios. En esa medida la recolección y
          tratamiento de tus datos personales, así como el de nuestros clientes,
          proveedores, contratistas, usuarios, empleados y ex empleados, entre
          otros, cuyos datos personales han sido reportados por ellos mismos, y
          que se encuentran en nuestras bases de datos, se han recopilado para
          el desarrollo de diversos procedimientos relacionados directamente con
          nuestro objeto social y está sujeta a las normas vigentes en la
          República de Colombia sobre protección de datos personales.
        </p>
      </div>
      <div>
        <h4>Responsable del tratamiento de la base de datos</h4>
        <p>
          JUANCHO TE PRESTA S.A.S, en adelante JTP empresa de carácter comercial
          identificada con NIT. 901200575-1, domiciliada en Km 2, vía El Tambo;
          Andes BPO. La Ceja - Antioquia, correo electrónico
          ayuda@juanchotepresta.com, teléfono de contacto 018000413811.
        </p>
      </div>
      <div>
        <h4>Uso de la información personal</h4>
        <p>
          Como parte de las actividades de prestación de servicios de JTP, en
          particular el estudio y otorgamiento de créditos, gestiones de
          cobranza, actualización de datos, reporte a centrales de riesgo,
          contacto a clientes y usuarios, será requerida la entrega de las
          siguientes categorías de tus datos personales: 1. Datos de carácter
          identificativo, 2. Datos de contacto, 3. Datos de características
          personales, 4. Datos de circunstancias sociales, 5. Datos académicos y
          profesionales, 6. Datos laborales, 7. Datos biométricos, 8. Datos de
          transacción y 9. Datos económicos, financieros o de seguros,, así como
          otra información relevante proveniente de ti como el titular de las
          solicitudes, créditos y tus redes sociales de internet, o la podemos
          recolectar a través de operadores de información o centrales de
          riesgo, bases de datos legalmente autorizadas, entre otras sin
          limitarse a: dependencias oficiales de catastro, IPS, EPS, AFP, Pila,
          SIMIT, RUNT, DIAN y otras bases de datos de acceso público
          habilitadas, que nos permiten conocerte mejor y facilitan el ejercicio
          de las actividades propias de JTP.
        </p>
        <p>
          El uso y manejo de los mismos, se efectúa bajo estrictos estándares de
          responsabilidad, dentro de los cuales está el respeto al debido
          proceso y a la protección de la información.
        </p>
        <p>
          En todo caso en cualquier momento tú como titular de la información,
          podrás revocar tú consentimiento y ejercer tú derecho a la supresión
          de datos personales consagrado en la Ley 1581 de 2012.
        </p>
      </div>
      <div>
        <h4>Finalidades del tratamiento de la información</h4>
        <p>
          La información será tratada por JTP de acuerdo con las siguientes
          finalidades:
        </p>
        <p>
          Realizar ante los operadores de información (Experian, Transunion y/o
          cualquier otra entidad que llegue a manejar bases de datos con los
          mismos objetivos) la consulta de la información crediticia, así como
          el reporte de la generación, modificación, extinción, cumplimiento o
          incumplimiento de las obligaciones contraídas en favor de JTP y la
          utilización indebida de los productos y/o servicios prestados u
          ofrecidos por la entidad.
        </p>
        <p>
          Compartir e intercambiar con sus entidades filiales, matrices, aliadas
          y/o con entidades financieras, la información personal contenida en
          las bases de datos de la entidad, con fines de control de riesgos,
          desembolso y pago de obligaciones, comerciales, estadísticos y la
          realización de actividades de mercadeo de sus servicios y publicidad.
        </p>
        <p>
          Utilizar los comentarios, artículos, fotos, videos y otros contenidos
          que se publican por los clientes o usuarios en nuestra página de
          internet, o que se presentan por cualquier otro medio de comunicación
          de JTP, para la realización de actividades comerciales o de mercadeo
          de JTP a través de nuestra página de internet www.juanchotepresta.com,
          Facebook y otros medios de comunicación y utilizarlos como parte de
          nuestras campañas comerciales o de mercadeo dirigidas al público en
          general a través de distintos medios de comunicación.
        </p>
        <p>
          Adelantar contactos con fines comerciales y promocionales ya sea sobre
          sus propios servicios y productos, o los de terceros, con los que JTP
          tenga relaciones comerciales o alianzas, a través de correo, teléfono,
          celular, correo electrónico o cualquier otro medio conocido o por
          conocer.
        </p>
        <p>
          Realizar actividades de gestión de cobro, aviso previo de reporte a
          las centrales de riesgo, entrega de extractos de obligaciones, a
          través de correo, teléfono, celular, correo electrónico o cualquier
          otro medio conocido o por conocer, y actualizar la información a
          través de diferentes actividades como lo son la consulta en bases de
          datos públicas, páginas de internet y redes sociales y referencias de
          terceras personas, en particular las personas que han servido de
          referencia para la utilización de los servicios de JTP.
        </p>
        <p>
          JTP podrá suministrar esta información a entidades judiciales, o
          administrativas y entidades de gobierno que ejerzan funciones de
          control de nuestra operación cuando medie requerimiento legal para
          ello. Igualmente, para procesos de auditoría interna o de auditoría
          externa por parte de empresas encargadas de este tipo de control.
        </p>
        <p>
          El tratamiento de la información podrá realizarse por JTP directamente
          o a través de terceros proveedores localizados en Colombia o en el
          exterior. Ese proceso puede implicar la recolección, archivo,
          procesamiento y transmisión de dicha información entre compañías
          vinculadas o relacionadas contractualmente con JTP tales como
          proveedores del servicio de call y contact center, proveedores del
          servicio de mensajería, empresas de cobranza y profesionales del
          derecho que colaboran con la entidad la recuperación de su cartera,
          localizadas dentro o fuera de Colombia. En todo caso, esas entidades
          estarán igualmente sujetas a las mismas obligaciones de
          confidencialidad en el manejo de la información a que está sujeto el
          JTP con las limitaciones legales impuestas por las leyes aplicables
          sobre la materia, en la jurisdicción donde ésta se recolecte, archive,
          procese o transmita.
        </p>
        <p>
          Los datos personales recolectados podrán ser usados como insumo, para
          evaluar el comportamiento de nuestros usuarios, al interior de nuestra
          plataforma, y de nuestra página web, siendo que los datos
          recolectados, pueden ser usados por JTP, para que terceros
          contratistas de la sociedad responsables en el tratamiento de los
          datos, analicen el tráfico de la página, midiendo y evaluando aspectos
          técnicos relacionados con la navegación e interacción con nuestra
          plataforma, página web y aplicaciones, como el idioma, el tipo de
          navegador, la configuración regional, la medición de la actividad del
          sitio, el tiempo de respuesta, correcciones durante el llenado de la
          información, tiempo y ritmo de entrada de la información, el intervalo
          de tiempo entre el momento en que se abrió la ventana del segmento y
          cuando la persona presionó SIGUIENTE / ATRÁS para pasar al siguiente
          segmento y en general el comportamiento de los usuarios en él mismo.
        </p>
        <p>
          Realizar actividades de extracción de datos de plataformas web,
          encargadas de realizar la recolección de información específica en
          ciertas páginas web, con la finalidad de obtener información de sus
          clientes, previa autorización otorgada por los mismos, la cual estará
          destinada a realizar y agilizar el proceso de conocimiento del cliente
          en la Plataforma.
        </p>
        <p>
          Realizar la validación y verificación de identidad del Usuario, así
          como el perfilamiento del mismo, entre otros, a través de sistemas de
          inteligencia artificial (AI), para determinar el grado de riesgo para
          el otorgamiento de créditos y demás productos o servicios que
          ofrecemos o podamos llegar a ofrecer. El análisis de tú perfil será
          realizado en el momento de tu solicitud y, también, en otras ocasiones
          y a través del tiempo, en los casos de que seas un cliente o si JTP no
          ha podido aceptar tu solicitud anteriormente.
        </p>
        <p>Este análisis incluye, sin limitarse, a lo siguiente:</p>
        <p>
          a. La consulta y reporte a listas restrictivas, listas de personas
          expuestas políticamente y a centrales de información, para la
          prevención y mitigación de lavado de activos, financiación de
          terrorismo, riesgos financieros y de suplantación y fraude.
        </p>
        <p>
          b. La consulta, siempre que sea necesario, a información sobre tu
          historial crediticio e información de contacto, datos financieros e
          información relacionada con tu situación laboral a operadores de
          información y centrales de riesgo
        </p>
        <p>
          Consultar directamente o a través de un tercero, las bases de datos
          que contienen información pública en el territorio nacional, para la
          verificación o validación entre otras, de los registros de sanciones,
          procesos judiciales disponibles al público, de las personas naturales
          y jurídicas que aparezcan reportadas en las diferentes bases de
          información pública.
        </p>
        <p>
          La información personal no será utilizada o tratada con propósitos
          diferentes a los aquí indicados.
        </p>
      </div>
      <div>
        <h4>Seguridad, grabaciones y filmaciones</h4>
        <p>
          JTP como parte de sus políticas de seguridad, monitoreo de sus
          actividades y controles de entrada y salida de sus oficinas, podrá
          realizar grabaciones de audio y video en sus oficinas y sitios comunes
          de sus instalaciones a empleados, clientes y usuarios en general.
          Tales grabaciones tendrán una duración en los archivos de la entidad
          de un máximo de dos años para posteriormente ser objeto de borrado o
          destrucción.
        </p>
        <p>
          JTP conservará, bajo condiciones de seguridad y confidencialidad, y
          para las finalidades señaladas en este documento, los datos personales
          de los proveedores, inversionistas, contratistas, empleados y
          exempleados, entre otros, durante todo el tiempo de ejecución de cada
          contrato en particular suscrito, y por diez (10) años más.
        </p>
        <p>
          En lo que respecta a los clientes cuya solicitud ha sido rechazada, la
          información se conservará durante un periodo de un año desde la
          solicitud de crédito, en el supuesto que no se hayan realizado
          consultas a la Central de Información de Riesgos, y de dos años, en
          caso de que sí se haya consultado para el estudio de su solicitud.
        </p>
      </div>
      <div>
        <h4>Datos de menores</h4>
        <p>
          JTP no captura o trata datos de menores de edad. Se requiere tener más
          de 18 años para tener acceso a nuestros productos y servicios. Sin
          embargo, en caso de llegar a hacerlo y en cumplimiento con las normas
          de protección de datos personales vigentes en Colombia, JTP brindará
          las garantías para que los menores de edad puedan ejercer su derecho
          de libertad de expresión, de libre desarrollo de la personalidad y de
          información, buscando que se respeten en su tratamiento el interés
          superior de aquellos, en aras del respeto a sus derechos fundamentales
          y en lo posible, teniendo en cuenta su opinión, como titulares de sus
          datos personales.
        </p>
        <p>
          En ese sentido, en caso de que se deba obtener una información que
          verse sobre niños, niñas, adolescentes menores de edad será
          facultativa de ser suministrada por parte de su representante legal o
          acudiente y JTP se reserva la posibilidad de poner en conocimiento de
          las autoridades situaciones que en su concepto puedan poner o pongan
          en peligro la integridad de un menor de edad.
        </p>
      </div>
      <div>
        <h4>Datos sensibles</h4>
        <p>
          JTP te informa que llevará a cabo la recolección y el tratamiento de
          tus datos personales sensibles, tales como lo son tus datos
          biométricos, los cuales incluyen la captura de imagen fija o en
          movimiento de tu rostro, huellas digitales, fotografías, videos, entre
          otros, para verificar tú identidad, confrontar tu información
          crediticia, para tu firma y aceptación de las condiciones
          contractuales contempladas en el contrato de mutuo, y para
          compartirlos con terceros interesados en validar tú identidad y la
          firma electrónica del contrato de mutuo, entre otros, avalistas,
          empleadores, contratantes, acreedores, terceros proveedores de
          servicios tecnológicos, terceros validadores de identidad digital,
          razón por la cual, en forma explícita y previa, te estamos indicando
          que haremos uso de dichos datos sensibles, en virtud de tú
          autorización y consentimiento expreso, el cual se entiende otorgado
          por ti, desde el mismo momento y por el hecho de acceder, usar o
          navegar en nuestra página web, así como tú lectura, entendimiento,
          aceptación y conocimiento expreso de nuestros términos y condiciones y
          nuestra política de privacidad, no obstante lo anterior, por tratarse
          de datos sensibles, no estás obligado a autorizar su tratamiento y
          para manifestarnos tu negativa para que los datos sensibles
          biométricos informados arriba sean usados, te rogamos nos lo indiques
          siguiendo los pasos contemplados en el acápite denominado
          “Modificaciones a las políticas”, contemplado en la presente política
          de privacidad.
        </p>
        <p>
          Igualmente, dentro de las finalidades para las cuales recolectamos los
          datos sensibles, antes descritos, contamos con los siguientes:
        </p>
        <p>
          a) Ejecutar la relación contractual existente con nuestros clientes,
          proveedores, compradores de cartera, firmas avaladoras, incluido el
          cobro de las obligaciones contractuales derivadas de este acuerdo.
        </p>
        <p>
          b) Proveer los servicios y/o los productos requeridos por los
          usuarios.
        </p>
        <p>
          c) Registrar y compartir la información del DEUDOR, con los
          empleadores y/o contratantes del mismo, con los que sea necesario
          suscribir acuerdos operativos de libranza, y que la exijan, para la
          validación electrónica de la firma que autorizó el contrato y la
          suscripción del acuerdo de pago de libranza por parte del DEUDOR.
        </p>
        <p>
          d) Suministrar, compartir, enviar o entregar los datos personales del
          DEUDOR a empresas filiales, vinculadas, o subordinadas de JUANCHO TE
          PRESTA SAS - JuanchoTePresta.com ubicadas en Colombia o cualquier otro
          país en el evento que dichas compañías requieran la información para
          los fines aquí indicados.
        </p>
        <p>
          Te recordamos que por el uso de nuestros productos y servicios
          implicará para ti que has leído y aceptado las condiciones plasmadas
          en nuestros Términos y condiciones y en la presente Política de
          privacidad, y que aceptas estar vinculado por los mismos, no obstante
          lo anterior, por tratarse de datos sensibles, no estás obligado a
          autorizar su tratamiento, y su entrega es facultativa. Sin embargo,
          ten en cuenta que no podremos aceptarte como cliente, si decides no
          entregar tus datos biométricos, los cuales son usados para
          confirmaciones de seguridad de nuestros productos, suscripción de
          documentos y la validación de tú identidad.
        </p>
        <p>
          Además de lo anterior, JTP podrá utilizar y tratar datos catalogados
          como sensibles, cuando:
        </p>
        <p>
          a. Para el tratamiento de los datos sensibles, por Ley, no se requiera
          el otorgamiento de la autorización del titular.
        </p>
        <p>
          b. El Tratamiento sea necesario para salvaguardar el interés vital del
          titular y éste se encuentre física o jurídicamente incapacitado. En
          estos eventos, los representantes legales deberán otorgar la
          autorización.
        </p>
        <p>
          c. El Tratamiento se refiera a datos que sean necesarios para el
          reconocimiento, ejercicio o defensa de un derecho en un proceso
          judicial.
        </p>
        <p>
          d. El Tratamiento tenga una finalidad histórica, estadística o
          científica o, dentro del marco de procesos de mejoramiento; este
          último, siempre y cuando se adopten las medidas conducentes a la
          supresión de identidad de los Titulares o el dato esté disociado, es
          decir, el dato sensible sea separado de la identidad del titular y no
          sea identificable o no se logre identificar a la persona Titular del
          dato o datos sensibles.
        </p>
      </div>
      <div>
        <h4>Terceros y la información personal</h4>
        <p>
          Como se establece dentro de las finalidades con las cuales se
          recolecta y trata la información personal, JTP para la adecuada
          prestación de sus servicios, acudirá en ocasiones a personas naturales
          o jurídicas para que con su colaboración se puedan realizar
          adecuadamente todas las labores y se preste el mejor servicio a sus
          clientes.
        </p>
        <p>
          Eso implica en ocasiones la necesidad de que esos terceros tengan
          acceso a determinada información personal de la almacenada en las
          bases de datos de la entidad. En tal caso, JTP velará porque: 1. Toda
          información que se entregue a un tercero esté precedida de un acuerdo
          en donde consten sus obligaciones de confidencialidad y seguridad en
          el tratamiento de la información, las cuales serán, al menos similares
          a las establecidas por la entidad en sus procesos; 2. La información
          sólo sea utilizada para el cumplimiento de las finalidades
          establecidas en esta política; 3. Sólo se comparte la información
          estrictamente necesaria para el cumplimiento de las funciones del
          tercero.
        </p>
      </div>
      <div>
        <h4>Derechos de los titulares y su ejercicio</h4>
        <p>Los titulares de los datos personales tienen derecho a:</p>
        <p>
          · Conocer, actualizar y rectificar sus datos personales. Este derecho
          se podrá ejercer, entre otros frente a datos parciales, inexactos,
          incompletos, fraccionados, que induzcan a error, o aquellos cuyo
          tratamiento esté expresamente prohibido o no haya sido autorizado;
        </p>
        <p>
          · Solicitar prueba de la autorización otorgada, salvo cuando
          expresamente se exceptúe como requisito para el tratamiento;
        </p>
        <p>
          · Ser informados, previa solicitud, respecto del uso que se les ha
          dado a sus datos personales;
        </p>
        <p>
          · Revocar la autorización y/o solicitar la supresión del dato cuando
          en el tratamiento no se respeten los principios, derechos y garantías
          constitucionales y legales. Sin embargo, tal revocatoria o la
          eliminación de la información no será procedente mientras se mantenga
          una relación de tipo comercial o legal con JTP.
        </p>
        <p>
          · Acceder en forma gratuita a los datos personales que hayan sido
          objeto de tratamiento una vez al mes o cada vez que existan
          modificaciones sustanciales a estas políticas de tratamiento de la
          información que motiven nuevas consultas.
        </p>
        <p>
          · Presentar quejas ante la Superintendencia de Industria y Comercio,
          por infracciones a lo dispuesto en la normatividad vigente.
        </p>
        <p>
          · Para el ejercicio de estos derechos, el titular podrá contactarse
          con JTP a través de comunicación escrita dirigida a nuestra área de
          atención al cliente a la siguiente dirección Km 2, vía El Tambo; Andes
          BPO. La Ceja- Antioquia, correo electrónico ayuda@juanchotepresta.com
        </p>
        <p>
          La comunicación referida deberá contener todos los datos necesarios
          para efectos de garantizar la oportuna y efectiva respuesta,
          acompañada de una descripción precisa de los datos personales respecto
          de los cuales el titular busca ejercer alguno de sus derechos,
          adjuntando fotocopia del documento de identidad del Titular interesado
          o cualquier otro documento equivalente que acredite su identidad y
          titularidad conforme a Derecho, indicando además, la dirección física
          o electrónica para remitir la respuesta e informar sobre el estado del
          trámite.
        </p>
        <p>
          Una vez verificada la identidad del titular se le suministrarán los
          datos personales requeridos.
        </p>
        <p>
          Es de anotar que la solicitud de eliminación de los datos significará
          que los mismos no podrán ser accesibles para el desarrollo de las
          operaciones normales de la entidad, sin embargo, podrán mantenerse en
          sus archivos con fines estadísticos, históricos, conocimiento de sus
          clientes o atención de requerimiento de autoridades administrativas o
          judiciales.
        </p>
        <p>
          Así mismo, el titular de la información, a través de los mismos
          canales podrá solicitar que no le envíen correspondencia, o no ser
          contactado para ofrecerle un bien o servicio ofrecido por JTP.
        </p>
        <p>
          JTP te comunicará su respuesta a tu consulta o reclamo adoptada en un
          plazo máximo de diez (10) días hábiles, cuando se trate de consultas y
          de quince (15) días hábiles cuando se trate de reclamos contados a
          partir de la fecha en la que se reciba tu solicitud. En caso de que el
          reclamo sea incompleto te comunicaremos dentro de los cinco (5) días
          hábiles para que subsanes tu solicitud. En este caso, tendrás dos (2)
          meses para subsanar tu solicitud o de lo contrario se entenderá que
          desististe de tu reclamo o consulta.
        </p>
        <p>
          Al enviar tu solicitud a ayuda@juanchotepresta.com, autorizas a JTP
          para dar respuesta por ese mismo medio, salvo que nos indiques otro
          medio de comunicación.
        </p>
        <p>
          Es tu responsabilidad comunicar cualquier cambio en tus datos
          personales para que sea tomado en cuenta y mantener tus datos
          debidamente actualizados, con la finalidad de asegurar la calidad de
          los datos personales y garantizar que sean exactos.
        </p>
      </div>
      <div>
        <h4>Información proveniente de terceros</h4>
        <p>
          JTP en desarrollo de alianzas de tipo comercial dentro de las que se
          incluye el ofrecimiento de productos o servicios de terceros previo
          acuerdo para el efecto, podrá recibir información personal. En estos
          casos JTP es el encargado del tratamiento de la información, de tal
          manera que su actuación siempre estará basada en el convencimiento que
          esos terceros cuentan con las autorizaciones necesarias para utilizar
          la información con las finalidades para la cuales es entregada a la
          entidad.
        </p>
        <p>
          En caso de que el ejercicio de los derechos que se ejercite por el
          titular de los datos, JTP hará sus mejores esfuerzos por realizar el
          traslado de la solicitud a los responsables correspondientes y porque
          se brinde una respuesta adecuada a las necesidades del peticionario,
          sin hacerse responsable por el alcance de las autorizaciones
          otorgadas, ni por el contenido de las respuestas que se otorguen a las
          solicitudes.
        </p>
      </div>
      <div>
        <h4>Información de terceros</h4>
        <p>
          La recepción de la información entregada por los clientes de JTP o
          terceras personas debe estar precedida de las debidas autorizaciones
          por parte de los titulares de la información. De esta manera, JTP
          entiende que quien realiza entrega de información a la entidad para la
          realización de sus actividades empresariales, cuenta con todas las
          autorizaciones pertinentes para su tratamiento y en ese sentido libera
          a JTP de cualquier responsabilidad por el uso que les dé a los datos
          personales, de acuerdo con las finalidades para las cuales se le hace
          entrega de la información.
        </p>
        <p>
          Si la información entregada incluye cualquier tipo de dato personal
          correspondiente a terceros, como es el caso de la entrega de personas
          que sirven de referencia comercial, tendrá que asegurarse de contar
          con todas las autorizaciones por parte de sus titulares para su
          verificación y posterior contacto.
        </p>
      </div>
      <div>
        <h4>Información publicada o trasmitida a JTP</h4>
        <p>
          Nuestra página de internet permite en muchas ocasiones compartir
          comentarios, artículos, blogs, convenios, información comercial y
          otros datos. La entrega de información de cualquiera de las formas
          mencionadas u otra permitida por la página, hace presumir a JTP que,
          previo a la inclusión o presentación de cualquier tipo de información
          personal del titular o correspondiente a terceros, cuenta con todas
          las autorizaciones por parte de sus titulares, de tal manera que JTP
          no se hace responsable por ninguno de los datos que le sean entregados
          de esa manera, aunque si se reserva los derechos para publicarlos o
          eliminarlos.
        </p>
        <p>
          Al subir contenido correspondiente a imágenes, comentarios,
          fotografías, videos etc., a nuestra página de internet o sitios
          relacionados o entregarlo por otros medios a JTP la persona que
          realiza tales acciones autoriza a JTP para presentarlo en su página de
          internet www.juanchotepresta.com y sitios relacionados, Facebook,
          Twitter, Instagram y otros medios de comunicación y utilizarlos como
          parte de campañas comerciales o de mercadeo dirigidas al público en
          general a través de distintos medios de comunicación.
        </p>
      </div>
      <div>
        <h4>Veracidad</h4>
        <p>
          JTP asume que la información entregada por sus clientes y usuarios es
          completa, actual y veraz, resultando de su entera responsabilidad
          cualquier disconformidad entre los datos entregados y la realidad.
          Como titular de los datos personales, nuestros clientes y usuarios se
          encuentran comprometidos a notificarnos de cualquier cambio en la
          información suministrada en el proceso de aplicación o durante la
          duración de la relación comercial entre las partes.
        </p>
      </div>
      <div>
        <h4>Seguridad en internet</h4>
        <p>
          En JTP la privacidad y la seguridad de los datos de nuestros clientes
          y usuarios son fundamentales y estamos conscientes de la confianza que
          depositan en nosotros al requerir nuestros servicios. Por eso, tomamos
          todas las medidas de seguridad a nuestro alcance para proteger sus
          datos. Todas las plataformas que procesan tarjetas de pago deben
          contar con el Estándar de Seguridad de Datos de la Industria de las
          Tarjetas de Pago (PCI DSS, Payment Card Industry Data Security
          Standard, en sus siglas en inglés. PCI DSS es un estándar que
          establece un conjunto de medidas, prácticas y herramientas que
          pretenden garantizar la seguridad en el tratamiento de la información
          asociada a medios de pago electrónicos. Aunque nuestro sistema no
          procesa tarjetas de pago nuestros servidores cumplen los más altos
          estándares de calidad PCI DSS. Es así como podemos garantizar la
          seguridad de la información.
        </p>
        <p>
          JTP usa cookies, una herramienta empleada por los sitios web para
          almacenar y recuperar información acerca de sus visitantes. En nuestro
          caso, las cookies solo recuperan información que esté disponible en
          nuestra página. Además, la información que se recoge nos ayuda a
          mejorar la experiencia del usuario en nuestro sitio.
        </p>
        <p>
          Nuestro sitio cuenta con certificado SSL (Secure Sockets Layer, en sus
          siglas en inglés) el cual nos permite encriptar los datos mientras
          viajan desde el computador de nuestros clientes o usuarios hasta
          nuestro servidor, de esta forma nadie podrá interceptar la
          comunicación para apoderarse de sus datos personales.
        </p>
        <p>
          No obstante lo anterior, es una responsabilidad de nuestros clientes o
          usuarios implementar los controles de seguridad necesarios, en sus
          equipos y redes privadas para su navegación hacia el/los portales de
          JTP o para él envió de correos electrónicos. De esta manera JTP no se
          responsabiliza por cualquier consecuencia derivada del ingreso
          fraudulento por parte de terceros a la base de datos y por alguna
          falla técnica en el funcionamiento que sobrepasen las actividades
          desarrolladas con la diligencia debida.
        </p>
      </div>
      <div>
        <h4>Modificaciones a las políticas</h4>
        <p>
          JTP se reserva el derecho de modificar la política de privacidad de la
          información de carácter personal en cualquier momento y de manera
          unilateral. Para el efecto realizará la publicación de un aviso en la
          página de internet con quince (15) días hábiles de antelación a su
          entrada en vigencia. En caso de no estar de acuerdo por razones
          válidas y que se constituyan en una justa causa con las nuevas
          políticas de manejo de la información personal, los titulares de la
          información o sus representantes podrán solicitar a JTP el retiro de
          su información a través del correo electrónico
          ayuda@juanchotepresta.com, sin embargo, no se podrá solicitar el
          retiro de los datos mientras se mantenga un vínculo de cualquier orden
          con JTP.
        </p>
        <p>
          Si decide no hacerlo, vencido el referido plazo, consideraremos
          autorizado el tratamiento de sus datos personales, de acuerdo con las
          nuevas políticas de manejo de la información personal.
        </p>
      </div>
      <div>
        <h4>Ley y jurisdicción</h4>
        <p>
          Toda interpretación, actuación judicial o administrativa derivada del
          tratamiento de los datos personales que conforman las bases de datos
          de JTP y la presente política de privacidad estará sujeta a las normas
          de protección personal establecidas en la República de Colombia y las
          autoridades administrativas o jurisdiccionales competentes para la
          resolución de cualquier inquietud, queja o demanda sobre las mismas
          serán las de la República de Colombia.
        </p>
        <p>Fecha de publicación y entrada en vigencia: 03 de marzo de 2021.</p>
        <p>
          Las bases de datos en las que se registrarán los datos personales
          tendrán una vigencia igual al tiempo en que se mantenga y utilice la
          información para las finalidades descritas en esta política. Una vez
          se cumpla(n) esa(s) finalidad(es) y siempre que no exista un deber
          legal o contractual de conservar su información, sus datos serán
          eliminados de nuestras bases de datos.
        </p>
      </div>
    </Justify>
  ),
  accept: 'Cerrar',
};
