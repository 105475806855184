/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { A } from 'ts-toolbelt';
import { CancelledError } from '../helpers/makeCancellable';
import noop from '../helpers/noop';
import styled from '../styles/styled-components';
import Button from './UI/Button';
import { Spinner } from './UI/Spinner';

export interface Props<T, U, P = A.Compute<T & U>> {
  before?:
    | (() => A.Promisable<U>)
    | {
        cancel(): void;
        promise(this: Record<'cancel', () => void>): A.Promisable<U>;
      };
  props: T;
  loading?: boolean;

  component?: React.ComponentType<P>;
  loadingComponent?: React.ComponentType<any>;
  errorComponent?: React.FC<P>;

  cancelled?: () => A.Promisable<void>;
}

interface State<P> {
  ready?: boolean;
  props: P;
  Component: React.ComponentType<any>;
}

const LoadingPage = () => <Spinner size={64} />;

const NullComponent: React.FC<any> = () => null;

const ErrorStyled = styled.div`
  padding: 10px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 30px;
    color: ${props => props.theme.gray600};
  }

  a {
    max-width: 200px;
  }

  .sorry {
    font-weight: 700;
    font-size: 22px;
    color: ${props => props.theme.gray500};
  }
  .desc {
    font-weight: 500;
    font-size: 20px;
    color: ${props => props.theme.gray500};
  }
`;

const ErrorComponent: React.FC<any> = () => {
  return (
    <ErrorStyled>
      <h1>Hmm...</h1>
      <p>
        <span className="sorry">Parece que algo ha salido mal.</span>
        <br />
        <span className="desc">
          No te preocupes, estamos trabajando para arreglarlo.
        </span>
      </p>
      <Button as={NavLink} outline to="/logout">
        Salir ahora
      </Button>
    </ErrorStyled>
  );
};

function Mount<T, U>({
  before = noop as any,
  cancelled = noop,
  loading,
  loadingComponent = LoadingPage,
  errorComponent = ErrorComponent,
  component = NullComponent,
  props: restProps,
}: Props<T, U, A.Compute<T & U & { [k: string]: any }>>) {
  const [{ props, Component }, setState] = useState<State<T>>({
    ready: false,
    props: {} as T,
    Component: loadingComponent,
  });

  useEffect(() => {
    if (loading) return;

    let job = before as any;
    let cancel = noop;

    if ('promise' in job && 'cancel' in job) {
      job = (job as any).promise;
      cancel = (job as any).cancel;
    }

    (async () => {
      try {
        const props = (await job()) || {};
        setState({ ready: true, props, Component: component });
      } catch (e) {
        if (e instanceof CancelledError || e.name === 'CancelledError') {
          await cancelled();
        } else {
          setState({
            ready: true,
            props: e,
            Component: errorComponent || NullComponent,
          });
        }
      }
    })();
    return () => {
      if (cancel) cancel();
      setState({ ready: false, Component: NullComponent, props: {} as any });
    };
  }, [component, loading]);

  return <Component {...restProps} {...props} />;
}

export default Mount;
