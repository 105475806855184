import { captureMessage, withScope } from '@sentry/minimal';
import { Severity } from '@sentry/types';
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useCallback, useEffect, useState } from 'react';
import VideoUrlAuthGet from '../services/VideoUrlAuthGet';
import useService from './useService';
import videoIDGet from '../services/videoIDGet';

export type Status =
  | 'loading'
  | 'ready'
  | 'running'
  | 'error'
  | 'videoid-created'
  | 'videoid-completed'
  | 'videoid-cancel'
  | 'videoid-error';

interface VideoStatus {
  status: Status;
}

type CreateVideo = () => void;

const useVideoVerif = (): [VideoStatus, CreateVideo] => {
  const [state, setState] = useState<VideoStatus>({
    status: 'loading',
  });

  const [eIDAuth, getEIDAuth] = useService(VideoUrlAuthGet);
  const  [xd, getXd] = useService(videoIDGet);

  useEffect(() => {
    getEIDAuth();
  }, [getEIDAuth]);

  useEffect(() => {
    if (eIDAuth.status === 'error') {
      console.log(eIDAuth)
      setState({ status: 'error' });
    } else if (eIDAuth.status === 'loaded') {
      setState({ status: 'ready' });
      console.log(eIDAuth)
    }
  }, [eIDAuth]);

  useEffect(() => {
    getXd();
  }, [getXd])

  useEffect(() => {
    console.log('Result: ', xd) 
  }, [xd])

  const startVideo: CreateVideo = useCallback(async () => {
    if (eIDAuth.status === 'error') {
      setState({ status: 'videoid-error' });
    } else if (eIDAuth.status === 'loaded') {
      createVeriffFrame({
        url: eIDAuth.payload.urlSession /* eIDAuth.payload.urlSession */,
        onEvent: function (msg) {
          switch (msg) {
            case MESSAGES.CANCELED:
              console.log('CANCELED - ');
              setState({ status: 'videoid-cancel' });
              withScope((scope) => {
                scope
                  .setFingerprint(['videoid/cancel'])
                  .setLevel(Severity.Info)
                  .setTags({
                    urlSession: eIDAuth.payload.urlSession,
                  })
                  .addBreadcrumb({
                    category: 'videoid-cancel',
                    //data: video,
                    level: Severity.Info,
                  });
                captureMessage(`[VIDEO-VERIFF] validation cancel`);
              });
              break;
            case MESSAGES.FINISHED:
              console.log('FINISHED');
              setState({ status: 'videoid-completed' });
              withScope((scope) => {
                scope
                  .setFingerprint(['videoVeriff/completed'])
                  .setLevel(Severity.Info)
                  .setTags({
                    urlSession: eIDAuth.payload.urlSession,
                  })
                  .addBreadcrumb({
                    category: 'videoid-completed',
                    level: Severity.Info,
                  });
                captureMessage(`[VIDEO-VERIFF] validation completed`);
              });
              break;
            case MESSAGES.STARTED:
              console.log('STARTED');
              setState({ status: 'videoid-created' });
              break;
            case MESSAGES.RELOAD_REQUEST:
              console.log('RELOAD_REQUEST');
              break;
            default:
              console.log('No controlado');
              setState({ status: 'videoid-error' });
              break;
          }
        },
      });
      setState({ status: 'running' });
    }
  }, [eIDAuth]);

  return [state, startVideo];
};

export default useVideoVerif;
