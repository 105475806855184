import serviceBuilder from './serviceBuilder';

export interface Params {
  kushkiToken: string;
}

export type ProcessorState = 'OK' | 'PENDING' | 'NOT_AUTHORIZED' | 'FAILED'| 'SUCCESS';

export interface Result {
  ticketNumber: string;
  userIp: string;
  processorType: string;
  userType: string;
  documentType: string;
  status: string;
  entityCode: string;
  reponseCode: string;
  transactionCycle: string;
  token: string;
  bankId: string;
  userId: string;
  processorState: ProcessorState;
  documentNumber: string;
  serviceCode: string;
  bankurl: string;
  created: number;
  publicMerchantId: string;
  callbackUtr: string;
  returnCode: string;
  trazabilityCode: string;
  paymentDescription: string;
  amount: {
    iva: number;
    subtotalIva: number;
    subtotalIva0: number;
  };
}

export interface Error {
  message: string;
  code: string;
}

export const url = 'sec/loans/rs/transaction/:kushkiToken';

export default serviceBuilder<Params, Result, Error>('get', {
  url,
  auth: true,
});
