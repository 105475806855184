/* eslint react-hooks/exhaustive-deps: 0 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Form from '../../../components/Form/Form';
import FormChange from '../../../components/Form/FormChange';
import FormGroup from '../../../components/Form/FormGroup';
import InputIcon from '../../../components/Form/InputIcon';
import Row from '../../../components/Grid/Row';
import Button from '../../../components/UI/Button';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import NavLink from '../../../components/UI/NavLink';
import moneyFormat from '../../../helpers/moneyFormat';
import noop from '../../../helpers/noop';
import timeout from '../../../helpers/timeout';
import BankHouse from '../../../images/bank-house.svg';
import HandMoney from '../../../images/hand-money.svg';
import { Quota } from '../../../services/creditListQuotaGet';
import { LoanList as Credit } from '../../../services/loansPayment';
import transactionInfoGet from '../../../services/transactionInfoGet';
import { JTPState } from '../../../store';
import { updateAllLoanStart } from '../../../store/actions/updateAllLoanState';
import { asyncDispatch } from '../../../store/asyncDispatch';
import styled from '../../../styles/styled-components';
const Styled = styled.div`
  h3 {
    color: ${(props) => props.theme.blueDark};
    font-size: 25px;
    display: flex;
    justify-content: center;
    img {
      padding-right: 10px;
    }
  }
  .card {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${(props) => props.theme.blueDark};
    border-radius: 10px;
    padding: 5px 10px;

    margin-bottom: 10px;

    .circle {
      background-color: white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content {
      margin: 0 10px;
      display: flex;
      flex: 1;
      flex-flow: column;

      justify-content: space-evenly;
      align-items: center;

      .title {
        color: white;
        justify-self: flex-start;
        font-size: 17px;
      }
      .value {
        color: ${(props) => props.theme.red};
        background-color: white;
        border-radius: 6px;
        justify-self: center;
        padding: 2px 10px;
        font-size: 18px;
      }
    }
  }

  .no-search {
    background-color: ${(props) => props.theme.gray050};
    color: ${(props) => props.theme.blueDark};
    align-self: center;
    padding: 10px;
    margin: 15px 0;
    text-align: center;
    max-width: 314px;
    border-radius: 5px;
    margin: 0 auto;
  }

  .quota-item {
    background-color: ${(props) => props.theme.gray050};
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 10px;
    margin: 15px 0;
    font-size: 15px;
    border-radius: 10px;

    .code {
      color: ${(props) => props.theme.gray500};
      text-align: center;
      position: relative;
      padding-bottom: 5px;

      &:after {
        content: '';
        position: absolute;
        background-color: white;
        height: 2px;
        bottom: 0px;
        left: 2px;
        right: 2px;
      }
    }
    .content {
      .row {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        background-color: white;
        padding: 2px 5px;
        margin: 3px 0;
        border-radius: 5px;

        > div {
          display: table-cell;
          flex: 1;
        }
        > div:nth-child(1) {
          color: ${(props) => props.theme.gray500};
        }
        > div:nth-child(2) {
          color: ${(props) => props.theme.blueDark};
        }
      }
    }
    .actions {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }
  }
  .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }
  .message-pending{
    max-width: 300px;
    text-align:center;
    a{
      color:${(props) => props.theme.blueDark};
    }

  }
  .message-pending p:nth-of-type(2) {
    font-size: smaller; /* O un tamaño específico, como 12px */
}
`;

interface Props extends RouteComponentProps<Record<'loanId' | 'code', string>> {
  loans: any;
  getLoans: () => Promise<void>;
}

const LoanActiveDescriptionView: React.FC<Props> = ({
  match,
  loans,
  history,
  getLoans,
}) => {
  const [quotas, setQuotas] = useState<Quota[]>([]);
  const [loan, setLoan] = useState<Credit['loanId']>(undefined as any);
  const [active, setActive] = useState(false);
  const [offset, setOffset] = useState(0);
  const [kushki, setKushki] =  useState(false);
  const [filtered, setFiltered] = useState<Quota[]>([]);
  const paymentMethodCode = match.params.code;
  const [modal, openModal] = useModalC();

  useEffect(() => {
    if (match.params.code === '001') {
      setKushki(true)
    }
  }, []);
   
  const [transactionState, setTransactionState] = useState<
    'PENDING' | 'FINISH' 
  >('PENDING');

  useEffect(() => {
    (async () => {
      if (Object.entries(loans || []).length === 0) {
        await getLoans();
      }
    })();
  }, []);

  useEffect(() => {
    if (Object.entries(loans || []).length > 0) {
      (async () => {
        let count =0
        while (true) {
          const quotas = await fetchQuotas(loans); //trae todas las cuotas completadas
          if (quotas[0].complete) {
            break;
          }
          const PSEStatus = await fetchPSEStatus(quotas[0]);

          if (PSEStatus === 'PENDING') {
            count++
            console.log("count", count)
            if(count<=2){
              await timeout(1000 * 60 * 3);
            }else{
              await openModal({
                main: (
                  <div>
                      <p>Tu pago esta en proceso, estamos trabajando para confirmarlo.</p> 
                      <p>Si tienes dudas, contáctanos en 
                       <a
                        href="mailto:ayuda@juanchotepresta.com"
                        title="ayuda@juanchotepresta.com"
                        rel="noopener noreferrer"
                        target="_blank"
                        style={{ marginLeft: '4px', marginRight: '4px' }}
                      >
                        ayuda@juanchotepresta.com
                      </a> o llámanos al 018000413811.
                      </p>
                  </div>
                ),
                noClosable: true,
                accept: 'Aceptar',
              });
              history.push('/logout')
              break
            }
            
          } else if (PSEStatus === 'FINISH') {
            break;
          }
        }
        setTransactionState('FINISH');
      })();
    }
  }, [loans]);

  const fetchQuotas = useCallback(async (loan) => {
    let quotas = loan[match.params.loanId].repayments;
    setLoan(loan[match.params.loanId]);
    setActive(loan[match.params.loanId].statusId === 300);

    if (loan[match.params.loanId].balance <= 0) {
      const allQuotas = quotas; //.reverse()
      setQuotas(allQuotas);
      setFiltered(allQuotas);
      return allQuotas;
    }

    const i = quotas
      //.reverse()
      .findIndex((q: any) => q.complete);

    const quotasSliced = quotas.slice(i === -1 ? -1 : Math.max(0, i - 1));

    setQuotas(quotasSliced);
    setFiltered(quotasSliced);
    return quotasSliced;
  }, []);

  const fetchPSEStatus = useCallback(
    async (quota?: Record<'kushkiToken' | 'complete', any>) => {
      if (!quota || !quota.kushkiToken) return 'FINISH';

      const PSEStatus = await transactionInfoGet({
        kushkiToken: quota.kushkiToken,
      });
      
      if (PSEStatus.status === 'error') {
        return 'FINISH';
      }

      if (PSEStatus.payload.processorState === 'PENDING') {
        return 'PENDING';
      }
      return 'FINISH';
    },
    []
  );

  return (
    <Row center>
      <Styled>
      <ModalC props={modal} />
        {loan && (
          <>
            <h3>
              <img alt="" src={BankHouse} />
              Descripción del crédito
            </h3>
            <div className="card">
              <div className="circle">
                <img alt="" src={HandMoney} />
              </div>
              <div className="content">
                <>
                  <div className="title">Saldo adeudado a la fecha</div>
                  <div className="value">
                    {moneyFormat(loan.balance, true, true)}
                  </div>
                </>
              </div>
            </div>

            <Formik onSubmit={noop} initialValues={{ filter: '' }}>
              <Form>
                <FormGroup>
                  <InputIcon
                    name="filter"
                    placeholder="Buscar por Código"
                    icon="search"
                  />
                  <FormChange
                    onChange={({ filter }) => {
                      setOffset(0);
                      if (!filter) {
                        setFiltered(quotas);
                      } else {
                        const filtered = quotas.filter(({ paymentId }) => {
                          if (!filter) return true;
                          return `${paymentId}`.includes(filter);
                        });
                        setFiltered(filtered);
                      }
                    }}
                  />
                </FormGroup>
              </Form>
            </Formik>

            {filtered.length === 0 ? (
              <p className="no-search">
                No se encontro ningún registro. Intente usar un <b>Código</b> de
                cuota.
              </p>
            ) : (
              ''
            )}

            {filtered.slice(offset, offset + 3).map((quota, i) => (
              <div key={i} className="quota-item">
                <div className="code">Cuota : {quota.paymentId}</div>
                <div className="content">
                  <div className="row">
                    {quota.kushkiToken || !active ? (
                      <div>Fecha de pago</div>
                    ) : (
                      <div>Próxima cuota</div>
                    )}
                    <div>{quota.date}</div>
                  </div>
                  <div className="row">
                    {quota.kushkiToken ? (
                      <div>Total pagado</div>
                    ) : (
                      <div>Total a pagar</div>
                    )}

                    <div>{moneyFormat(quota.repaymentAmount, true)}</div>
                  </div>

                  <div className="row">
                    <div>Capital</div>
                    <div>{moneyFormat(quota.capital)}</div>
                  </div>

                  <div className="row">
                    <div>Interés</div>
                    <div>{moneyFormat(quota.interest)}</div>
                  </div>
                  <div className="row">
                    <div>Otros</div>
                    <div>{moneyFormat(quota.fees)}</div>
                  </div>

                  <div className="row">
                    <div>Total pagado: </div>
                    <div>{moneyFormat(quota.amountPaid)}</div>
                  </div>
                </div>
                <div className="actions">
                  {!quota.complete && active && (
                    <Button
                      disabled={transactionState === 'PENDING'}
                      sm
                      color="red"
                      outline
                      as={transactionState !== 'PENDING' ? NavLink : 'button'}
                      to={transactionState !== 'PENDING' ? `/loans/payment/${quota.loanId}/${quota.paymentId}/${paymentMethodCode}` : undefined}
                    >
                      {transactionState === 'PENDING' ? 'Pendiente' : 'Continuar'}
                    </Button>
                  )}
                   {transactionState === 'PENDING' && !quota.complete && active &&(
                    <div className='message-pending'>
                       <p>
                       Tu pago se encuentra pendiente. Por favor, espera un momento.</p>
                       
                       <p>Si tienes dudas, contáctanos en{' '}
                        <a
                          href="mailto:ayuda@juanchotepresta.com"
                          title="ayuda@juanchotepresta.com"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          ayuda@juanchotepresta.com
                        </a>
                        {' '}o llama al 018000413811.
                      </p>
                    </div>
                  )}

                  {quota.kushkiToken && kushki && (
                    <NavLink
                      underline
                      to={`/loans/feedback/${quota.loanId}/${quota.paymentId}/${match.params.code}?token=${quota.kushkiToken}`}
                    >
                      <FontAwesomeIcon icon="info-circle" />
                      Información de la transacción
                      <FontAwesomeIcon icon="angle-right" />
                    </NavLink>
                  )}
                </div>
              </div>
            ))}
            <div className="actions">
              {offset > 0 ? (
                <Button
                  sm
                  onClick={() => {
                    setOffset((offset) => offset - 2);
                  }}
                >
                  Regresar
                </Button>
              ) : (
                <Button sm as={NavLink} to="/loans">
                  Regresar
                </Button>
              )}
              <Button
                disabled={!quotas[offset + 2] || !filtered.length}
                sm
                onClick={() => {
                  setOffset((offset) => offset + 2);
                }}
              >
                Ver más
              </Button>
            </div>
          </>
        )}
      </Styled>
    </Row>
  );
};

export default connect(
  (state: JTPState) => ({
    loans: state.loans,
  }),
  (dispatch) => ({
    getLoans: asyncDispatch(dispatch, updateAllLoanStart),
  })
)(LoanActiveDescriptionView);
