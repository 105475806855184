// version from `meta.json` - first param
// version in bundle file - second param
export default (versionA: any, versionB: any) => {
  versionA = versionA.replace('-test', '');
  versionB = versionB.replace('-test', '');

  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};
