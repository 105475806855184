import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from '../../styles/styled-components';
import Error from './Error';
import Input, { Props as InputProps } from './Input';
import InputIcon from './InputIcon';
import Label from './Label';

export interface Props extends InputProps {
  label?: string;
  icon?: IconProp;
  help?: string;
}
const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  color: ${props => props.theme.blueDark};
  text-align: left;
  min-width: 290px;
  @media (min-width: 790px) {
    min-width: 350px;
  }
`;
const ContentError = styled.div`
  max-width: 290px;
  @media (min-width: 790px) {
    max-width: 350px;
  }
`

const InputGroupPro: React.FC<Props> = ({
  label,
  help,
  name,
  icon,
  children,
  ...restProps
}) => (
  <FormGroup>
    {label && <Label htmlFor={name}>{label}</Label>}
    {icon ? (
      <InputIcon name={name} icon={icon} {...restProps} />
    ) : (
      <Input name={name} {...restProps} />
    )}
    <ContentError>
     <Error name={name} help={help} />
    </ContentError>
  </FormGroup>
);

export default InputGroupPro;
