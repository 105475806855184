import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const HeroStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  background-color: #fff;
  padding-bottom: 8px;
`;
const ImageContainer = styled.div`
  background: linear-gradient(#FFCD00 20%, #fff);
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (min-width: 600px) {
    height: 160px;
  }
`;
const Img = styled.div`
  max-width: 578px;
`;
const TitleStyle = styled.div`
  width: 100%;
  align-content: center;
  text-align: center;
  align-items: center;
  font-size: 15px;
  @media (min-width: 600px) {
    font-size: 20px;
  }
`;

const Hero = ({ title, info }) => (
  <HeroStyled>
    <ImageContainer>
      <Img>
        <TitleStyle>
          <h2>{title}</h2>
          <p>{info}</p>
        </TitleStyle>
      </Img>
    </ImageContainer>
  </HeroStyled>
);
Hero.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string,
};
export default Hero;
