import figure from '../../images/logo-juancho-te-presta.png';
import styled from '../../styles/styled-components';

const MessagesFormat = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  background-color: ${props => props.theme.grayLight};
  border: 1px solid ${props => props.theme.grayLight};
  border-radius: 1rem;
  padding: 0.75rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  font-weight: 14px;

  > h4 {
    border-radius: 5px;
    padding: 0.75rem;
    text-align: left;
  }
  > div {
    display: flex;
    width: 340px;
    height: 100px;
    background-image: url(${figure});
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: contain;
  }

  > p {
    border: 1px solid #fff;
    border-radius: 5px;
    background: #fff;
    padding: 0.75rem;
    text-align: center;
  }
  @media (min-width: 740px) {
  }
`;

export default MessagesFormat;
