import Cookies from 'js-cookie';
import { decode } from 'jsonwebtoken';
import uuid from './uuid';

export type AttrStatus = 'DONE' | 'TODO';

export interface UserAttrs {
  /**
   * sq: Security Questions
   */
  sq: AttrStatus;
  /**
   * iv: Identity Validation or EID
   */
  iv: AttrStatus;
  /**
   * ip: Personal Information
   */
  pi: AttrStatus;
  /**
   * dc: Disclaimer
   */
  dc: AttrStatus;
  /**
   * cl: Current Loan Id
   */
  cl: AttrStatus;
}
export interface TokenAttrs extends UserAttrs {
  /**
   * token: raw token
   */
  token: string;
  /**
   * valid: Is valid token
   */
  valid: boolean;
  /**
   * exp: Expiration Date
   */
  exp: number;
  /**
   * uuid: user-uuid
   */
  uuid: string;

  /**
   * loanId: current loan id
   */
  loanId: string;
}

export function removeToken() {
  return saveToken('');
}

export function saveToken(token: string = '') {
  if (isTokenValid(token)) {
    if (sessionStorage) {
      sessionStorage.setItem('token', token);
    } else {
      Cookies.set('token', token);
    }
  } else {
    if (sessionStorage) {
      sessionStorage.setItem('token', '');
    } else {
      Cookies.remove('token');
    }
  }

  const attrs = readToken();
  sessionStorage.setItem('user-uuid', attrs.uuid);

  return attrs;
}

export function isTokenValid(token: string) {
  try {
    const { exp } = decode(token) as TokenAttrs;
    if (Date.now() < exp * 1000) {
      return true;
    }
  } catch {}

  return false;
}

export function remainingExpTime(exp: number) {
  return Math.max(0, exp * 1000 - Date.now());
}

export function readToken() {
  let token: string;
  if (sessionStorage) {
    token = sessionStorage.getItem('token') || '';
  } else {
    token = Cookies.get('token') ?? '';
  }

  if (isTokenValid(token)) {
    const attrs = decode(token) as TokenAttrs;

    return { ...attrs, token, valid: true };
  }

  return { ...emptyTokenAttrs, uuid: uuid.user };
}

export const emptyTokenAttrs: TokenAttrs = {
  token: '',
  valid: false,
  exp: 0,
  cl: 'TODO',
  dc: 'DONE',
  pi: 'TODO',
  iv: 'TODO',
  sq: 'TODO',
  uuid: '',
  loanId: '',
};
