import dayjs from 'dayjs';
import { holidaysGet } from '../../services/selectorsGet';

const [SAT, SUN] = [6, 0]; // invalid days
const [_1, _16] = [1, 16]; // valid dates

const firstDatePayment = async () => {
  let data = await holidaysGet();
  let holidays = data.map((obj) => obj.label);
  let day = dayjs(); //today

  day = day.add(30, 'day'); //Next month

  if (day.date() <= _16) {
    day = day.set('date', _16);
  } else {
    day = day.add(1, 'month').set('date', _1);
  }

  let sw = false;
  while (sw === false) {
    let day_format = day.format('YYYY/MM/DD');
    if (
      !holidays.includes(day_format) &&
      SAT !== day.day() &&
      SUN !== day.day()
    ) {
      sw = true;
    } else {
      day = day.add(1, 'day');
    }
  }

  return day;
};

export default firstDatePayment;
