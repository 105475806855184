import serviceBuilder from './serviceBuilder';

export interface Params {
  code: string;
  phone: string;
}

export interface Result {
  status: boolean;
}

export const url = 'users/sms/:phone/:code';

export default serviceBuilder<Params, Result>('post', {
  url,
  auth: false,
  silent: true,
  query: true,
});
