import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import styled from '../../styles/styled-components';
import NotFound from '../NotFound';
import ChangePasswordView from './ChangePassword/ChangePasswordView';
import LogInContainer from './LogIn/LogInContainer';
import ResetPasswordView from './ResetPassword/ResetPasswordView';

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
`;

const LogInRoutes: React.FC<RouteComponentProps> = () => (
  <Content>
    <Switch>
      <Route exact path="/login/recovery" component={ResetPasswordView} />

      <Route
        path="/login/recovery/reset-password/:token"
        component={ChangePasswordView}
      />

      <Route
        exact={true}
        path={['/login', '/login/redirect/:to']}
        component={LogInContainer}
      />

      <Route component={NotFound} />
    </Switch>
  </Content>
);

export default LogInRoutes;
