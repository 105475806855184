import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  readToken,
  removeToken,
  saveToken,
  TokenAttrs,
} from '../../helpers/tokenHelpers';
import ApiError from '../../types/ApiError';

const attrs = readToken();

const initialState = {
  attrs: attrs,
  countEid: 0,
  loggedIn: attrs.valid,
  error: undefined as ApiError | undefined,
};

export const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    login: {
      reducer(_, { payload }: PayloadAction<TokenAttrs>) {
        return {
          ...initialState,
          attrs: payload,
          loggedIn: payload.valid,
          error: undefined,
        };
      },
      prepare(token: string) {
        return { payload: saveToken(token) };
      },
    },
    refreshToken: {
      reducer(_, { payload }: PayloadAction<TokenAttrs>) {
        return {
          ...initialState,
          countEid: _.countEid,
          attrs: payload,
          loggedIn: payload.valid,
          error: undefined,
        };
      },
      prepare(token: string) {
        return { payload: saveToken(token) };
      },
    },
    loginError(_, action: PayloadAction<ApiError>) {
      return { ...initialState, error: action.payload };
    },
    sumCountEid(state) {
      return {
        ...state,
        countEid: state.countEid + 1,
      };
    },
    logout: {
      reducer(_, { payload }: PayloadAction<TokenAttrs>) {
        return {
          ...initialState,
          attrs: payload,
          loggedIn: payload.valid,
          error: undefined,
        };
      },
      prepare() {
        return { payload: removeToken() };
      },
    },
  },
});
