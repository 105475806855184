import { useCallback, useEffect, useState } from 'react';
import { citiesGet } from '../services/selectorsGet';

const UseCities = (
  departmentCodeInitial: string
): [
  Record<'label' | 'value', string>[],
  (departmentCodeSelected: string) => void
] => {
  const [cities, setCities] = useState<{
    departmentCode: string;
    cities: Record<'label' | 'value', string>[];
  }>({ departmentCode: departmentCodeInitial, cities: [] });

  const fetchCities = useCallback(
    async (department) => {
      department = department.toUpperCase();
      if (department) {
        try {
          const cities = await citiesGet(department);
          setCities({
            departmentCode: department,
            cities,
          });
        } catch {
          setCities({ departmentCode: department, cities: [] });
        }
      } else {
        setCities({ departmentCode: department, cities: [] });
      }
      return true;
    },
    [setCities]
  );

  const handlerDepartmentCode = (departmentCodeSelected: string) => {
    setCities({
      departmentCode: departmentCodeSelected,
      cities: [],
    });
  };

  useEffect(() => {
    fetchCities(cities.departmentCode);
  }, [cities.departmentCode, fetchCities]);

  return [cities.cities, handlerDepartmentCode];
};

export default UseCities;
