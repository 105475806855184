import serviceBuilder from './serviceBuilder';

export interface Params {
  scrapping_account_link_result: 'success' | 'error' | 'exit';
  scrapping_account_link_id: string;
  scrapping_account_institution: string;
  scrapping_event_id: string;
  scrapping_error_code: string;
  scrapping_error_message: string;
  scrapping_step: string;
  scraping_error_count: number;
}

export interface Result {
  executionId: string;
}

const url = 'sec/loans/scrapping';

export default serviceBuilder<Params, Result>('post', {
  url,
  auth: true,
});
