import { Formik } from 'formik';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Error from '../../../components/Form/Error';
import Form from '../../../components/Form/Form';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmit from '../../../components/Form/FormSubmit';
import InputIcon from '../../../components/Form/InputIcon';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { submitTrap } from '../../../helpers/formHelpers';
import resetPasswordRequestPost, {
  Params,
} from '../../../services/resetPasswordRequestPost';
import styled from '../../../styles/styled-components';
import Omit from '../../../types/Omit';
import Hero from '../../Hero';
import validationSchema from './ValidationSchema';

export interface FormValues extends Omit<Params, 'forwardTo'> {}

const ResetPasswordContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const initialValues: FormValues = {
  id: '',
};

const Sucess = {
  main: 'Ingresa al enlace que hemos enviado a tu correo',
  accept: true,
};

const ResetPasswordView: React.FC<RouteComponentProps> = ({ history }) => {
  const [modal, openModal] = useModalC();

  return (
    <ResetPasswordContainer>
      <ModalC props={modal} />
      <Hero
        title="Te prestamos hasta 2 veces tus ingresos"
        info="Ingresa tu cédula para recuperar tu contraseña."
      />
      <GeneralContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitTrap(async (values) => {
            await resetPasswordRequestPost({
              id: values.id.replace(/\s/g, ''),
              forwardTo: `${window.location.origin}/login/recovery/reset-password`,
            });
            await openModal(Sucess);
            history.push('/login');
          })}
        >
          <Form>
            <FormGroup>
              <InputIcon icon="user" name="id" placeholder="Cédula" />
              <Error name="id" />
            </FormGroup>
            <ButtonContainer>
              <FormSubmit>Recuperar</FormSubmit>
            </ButtonContainer>
          </Form>
        </Formik>
      </GeneralContent>
    </ResetPasswordContainer>
  );
};

export default ResetPasswordView;
