import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimulatorParams } from '../../services/simulatorGet';

const initialState: SimulatorParams = {
  guaranteeConstant: 0,
  guaranteeFurniture: 0,
  guaranteeVariableMan: 0,
  guaranteeVariableWoman: 0,
  electronicSignature: 0,
  interest: 0,
  technology: 0,
  iva: 0,
  maxAmount: 0,
  minAmount: 0,
  options: [],
  VAT: 0,
};

export const simulator = createSlice({
  name: 'simulator',
  initialState,
  reducers: {
    updateSimulator(_, action: PayloadAction<SimulatorParams>) {
      return action.payload;
    },
  },
});
