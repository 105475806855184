import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import swal from 'sweetalert';
import Form from '../../components/Form/Form';
import FormSubmit from '../../components/Form/FormSubmit';
import GeneralContent from '../../components/UI/GeneralContent';
import { submitTrap } from '../../helpers/formHelpers';
import Cancel from '../../images/logo-juancho-te-presta.png';
import { asyncDispatch } from '../../store/asyncDispatch';
import { updateLoanStatus } from '../../store/reducers/updateLoanStatusReducer';
import styled from '../../styles/styled-components';
import { QuotaItemStyled } from './Loans/LoansPaymentInformationView';

const CancelIcon = styled.div`
  height: 150px;
  width: 150px;
  background-image: url(${Cancel});
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  @media (min-width: 700px) {
    height: 200px;
    width: 200px;
  }
`;

const Display = styled.div`
  display: flex;
  flex-direction: column;

  .divisor {
    width: 250px;
    height: 1px;
    background: #60606047;
    margin-bottom: 15px;

    @media (min-width: 700px) {
      width: 300px;
    }
  }

  @media (min-width: 700px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;

interface Props extends RouteComponentProps<{ uuid: string }> {
  submit(params: { uuid: string }): Promise<any>;
}

const CancelCredit: React.FC<Props> = ({ match, history, submit }) => {
  return (
    <GeneralContent>
      <Formik
        initialValues={match.params}
        onSubmit={submitTrap(async (values) => {
          await submit(values);
          await swal(
            '',
            '¡La cancelación de tu crédito fue exitosa!',
            'success',
            {
              dangerMode: true,
            }
          );
          history.push('/home');
        })}
      >
        <Form>
          <QuotaItemStyled>
            <div className="grayContainer ">
              <Display>
                <CancelIcon />
                <div className="divisor" />
                <p>
                  Si tu <strong>NO</strong> has solicitado un crédito en Juancho
                  Te Presta, haz click en “CANCELAR SOLICITUD”.
                </p>
              </Display>
            </div>
            <div className="grayContainer">
              Para mayor información escribenos a: ayuda@juanchotepresta.com
            </div>
            <FormSubmit>Cancelar solicitud</FormSubmit>
          </QuotaItemStyled>
        </Form>
      </Formik>
    </GeneralContent>
  );
};
export default connect(
  (_, ownProps) => ownProps,
  (dispatch) => ({
    submit: asyncDispatch(dispatch, updateLoanStatus.actions.request),
  })
)(CancelCredit);
