import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import buroAccountsGet from '../../../services/buroAccountsGet';
import eventTypeGet, { UserDesenbplsingMethods } from '../../../services/eventTypeGet';
import repaymentGet from '../../../services/repaymentGet';
import { Option, OptionNumber } from '../../../services/selectorsGet';
import { JTPState } from '../../../store';
import SignatureView from './SignatureView';

export interface Props extends RouteComponentProps {
  banks: Option[];
  accountTypes: Option[];
  fullName: string;
  email: string;
  cellPhone:string;
  amount: number;
  term: number;
  loanId: string;
  loanStatus: string;
  filterEventType: (value: UserDesenbplsingMethods[]) => OptionNumber[];
}

const SignatureContainer: React.FC<Props> = (props) => {
  return (
    <Mount
      before={async () => {

       
        const { status: statusEventType, payload: eventTypeList } =
          await eventTypeGet();

        const eventTypeEnabled =
          statusEventType === 'loaded'
            ? props.filterEventType(eventTypeList.udm)
            : [];
        // const payloadU= {
        //   accounts:["1295"],
        //   bankNames:["BANCOLOMBIA","BANCO BBVA"],
        //   walletAccounts:["1234","5678","9012"],
        //   walletNames:["CUENTANEQUI","DAVIPLATA"],
        // }
        const { payload } = await buroAccountsGet();
       
        const normalize = (str: string) => str.toLowerCase().replace(/[\s.,/#!$%^&*;:{}=\-_`~()]/g, '');
        const allBanks = props.banks

        let walletBanks:Option[] = []
        if(payload.walletAccounts.length>0){
          walletBanks = props.banks.filter(item => {
            const normalizedLabel = normalize(item.label);
            return  payload.walletNames.some(term => {
              const normalizedTerm = normalize(term);
              return normalizedLabel.includes(normalizedTerm) || normalizedTerm.includes(normalizedLabel);
                });
          });
        }
        let walletAccounts:Option[] = []
        if(payload.walletAccounts.length>0){
          walletAccounts = payload.walletAccounts.map((v) => ({
            value: v,
            label: `--- ---${v}`,
          }));
        }

        let bankAccounts:Option[] = []
        if(payload.accounts.length>0){
          bankAccounts = payload.accounts.map((v) => ({
            value: v,
            label: `---- ---- ---- ${v}`,
          }));
        }

        // console.log("bankNames",payload.bankNames)
        // console.log("wallet banks",walletBanks)
        // console.log("bank Accounts",bankAccounts)
        // console.log("wallet Accounts",walletAccounts)
    
        const repayment = await repaymentGet({
          paymentId: '1',
        });

        return {
          allBanks,
          eventTypeEnabled,
          bankAccounts,
          repayment: repayment.payload,
          walletBanks,
          walletAccounts
        };
      }}
      props={props}
      component={SignatureView}
    />
  );
};

export default connect((state: JTPState, ownProps) => ({
  banks: state.mifos.bank,
  filterEventType: (eventTypeEnabled: UserDesenbplsingMethods[]) => {
    const codes = eventTypeEnabled.map((evt) => evt.dmId);
    return state.mifos.eventType
      .filter((evt) => codes.includes(evt.value))
      .map((evt: OptionNumber) => ({
        ...evt,
        selected: eventTypeEnabled.filter((ev) => ev.dmId === evt.value)[0]
          .selected,
      }));
  },
  accountTypes: state.mifos.accountTypeId,
  term: state.loan.term,
  amount: state.loan.amount,
  loanId: state.loan.id,
  loanStatus: state.loan.status,

  ...state.user.user,
  ...ownProps,
}))(SignatureContainer);
