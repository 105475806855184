import serviceBuilder from './serviceBuilder';

export interface Params {
  executionId: string;
}

type Code = '0' | '1' | '2' | '3' | '4' | '5' | '6';

export interface Result {
  status: 'finalized' | 'running';
  code: Code;
  message: string;
}

export const url = 'sec/loans/scrapping/:executionId';

export default serviceBuilder<Params, Result, Result>('get', {
  silent: true,
  url,
  auth: true,
});
