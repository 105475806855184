export default ({
  amount,
  guaranteeConstant,
  guaranteeFurniture,
}: {
  [v: string]: number;
}) => {
  const valueAval = guaranteeConstant * amount;
  const total = amount - valueAval - guaranteeFurniture;
  return total;
};
