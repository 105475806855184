
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmit from '../../../components/Form/FormSubmit';
import InputRadio from '../../../components/Form/InputRadio';
import Label from '../../../components/Form/Label';
import { Block } from '../../../components/Grid/Flex';
import Button from '../../../components/UI/Button';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import { submitTrap } from '../../../helpers/formHelpers';
import Belvo from '../../../images/belvo.jpg';
import HandMoney from '../../../images/hand-money.svg';
import Kushki from '../../../images/kushki.png';
import {
  InitialStatus,
  activeStatus,
  cancelStatus,
  punishedStatus,
  rejectStatus
} from '../../../services/creditListGet';
import LoansPayment, { LoanList, translateLoanState } from '../../../services/loansPayment';
import LoansPaymentBelvo from '../../../services/loansPaymentBelvo';
import paymentMethods, { UserPaymentMethods } from '../../../services/paymentMethods';
import { tokenAttrsSelector } from '../../../store/selectors/sessionSelectors';
import styled from '../../../styles/styled-components';
import UpdatePersonalInformationContainer from '../../Users/UpdatePersonalInformation/UpdatePersonalInformationContainer';
import { typeTab } from './LoanActiveView';
import Agreements from './forms/Agreements';
import DateChange from './forms/DateChange';
import Download from './forms/Download';
import Payment from './forms/Payment';
import Refinance from './forms/Refinance';
import Reliefs from './forms/Reliefs';
import Signature from './forms/Signature';
import Support from './forms/Support';
interface Props {
  loans: LoanList;
  type: typeTab;
  fallback: (url: string) => void
}

const SpanCursor = styled.span`
  margin-left: 5px;
  :hover {
    cursor: pointer;
  }

.loan-id {
  margin-left: 40%;
}

  .card {
    display: flex;
    flex-flow: row nowrap;
    background-color: ${(props) => props.theme.blueDark};
    border-radius: 10px;
    padding: 5px 10px;

    margin-bottom: 10px;

    .circle {
      background-color: white;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content {
      margin: 0 10px;
      display: flex;
      flex: 1;
      flex-flow: column;

      justify-content: space-evenly;
      align-items: center;

      .title {
        color: white;
        justify-self: flex-start;
        text-align: justify;
        font-size: 16px;
      }
      .value {
        color: ${(props) => props.theme.red};
        background-color: white;
        border-radius: 6px;
        justify-self: center;
        padding: 2px 10px;
        font-size: 18px;
      }
    }
  }

  .no-search {
    background-color: ${(props) => props.theme.gray050};
    color: ${(props) => props.theme.blueDark};
    align-self: center;
    padding: 10px;
    margin: 15px 0;
    text-align: center;
    max-width: 314px;
    border-radius: 5px;
    margin: 0 auto;
  }

  .quota-item {
    background-color: ${(props) => props.theme.gray050};
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 10px;
    margin: 15px 0;
    font-size: 15px;
    border-radius: 10px;

    .code {
      color: ${(props) => props.theme.gray500};
      text-align: center;
      position: relative;
      padding-bottom: 5px;

      &:after {
        content: '';
        position: absolute;
        background-color: white;
        height: 2px;
        bottom: 0px;
        left: 2px;
        right: 2px;
      }
    }
    .content {
      .row {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        background-color: white;
        padding: 2px 5px;
        margin: 3px 0;
        border-radius: 5px;

        > div {
          display: table-cell;
          flex: 1;
        }
        > div:nth-child(1) {
          color: ${(props) => props.theme.gray500};
        }
        > div:nth-child(2) {
          color: ${(props) => props.theme.blueDark};
        }
      }
    }
    .actions {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }
  }
  .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }
  .image {
    width: 102px;
  }
`;
/* const modalLoanActive = {
  main: (
    <div>
      <p>
        El valor del saldo adeudado de tu crédito puede variar según la fecha de
        consulta. Para calcularlo tenemos en cuenta el saldo de capital, el
        saldo de la plataforma, los gastos de cobranza (si aplican) y, los
        intereses y el aval causados a la fecha.
      </p>
    </div>
  ),
}; */


const LoansView: React.FC<Props> = ({ loans, type, fallback }) => {
  //const [modal, openModal] = useModalC();
  const loanList = Object.values(loans);
  const attrs = useSelector(tokenAttrsSelector);

  const pmGet = paymentMethods
  const serviceKushki = LoansPayment
  const serviceBelvo = LoansPaymentBelvo
  const [pm, setPm] = useState<UserPaymentMethods[]>([])

  useEffect(() => {
    (async () => {
        const service = (await pmGet()).payload.upm
        setPm(service.filter(({ active }) => active))
      })();
  }, [pmGet]);


  let initialValues = {
    option: ''
  };

    if (pm.length) {
      pm.length === 1 ? initialValues.option = pm[0].code : initialValues.option = '';
    }

  const getUlr = useCallback(() => {
    const initial = loanList.filter((loan) =>
      InitialStatus.includes(loan.statusId)
    );
    const loan = initial[0];

    if (initial.length === 0) return;

    if (attrs.pi === 'TODO') return '/users/basic-info';

    // if (attrs.dc === 'TODO') return '/users/disclaimer';

    if (loan.statusName === 'Submitted and awaiting scrapping')
      return '/loans/finance-status';

    if (loan.statusName === 'Submitted and pending approval')
      return '/loans/credit-history';

    if (loan.statusName === 'Submitted and awaiting request')
      return '/loans/amount-and-term';

    if (loan.statusName === 'Approved') return '/loans/signature';         

    if (attrs.iv === 'TODO') return '/users/validate-identity';

    // if (attrs.sq === 'TODO') return '/users/security-questions';

    if (loan.statusName === 'Approved pre-disburse')
      return '/loans/predisburse-info';

    if (loan.statusName === 'Approved and awaiting signature')
      return '/loans/signature';

    return `/`;
  }, [attrs, loanList]);

  if (type === 'active') {
    const active = loanList.filter((loan) =>
      activeStatus.includes(loan.statusId)
    );
    

    return active.length === 0 ? (
      <ButtonContainer>
        <Label>En este momento no tienes créditos activos.</Label>
      </ButtonContainer>
    ) : (
      <div>
        {/* <ModalC props={modal} /> */}
        {active.map((loan) => (
          <div key={loan.loanId} className="loan-item">
          
            <SpanCursor>

            <div className="loan">
            <label className="loan-id">Crédito {loan.loanId}</label>
            </div><br />

            
             <div className="card">
                <div className="circle">
                  <img alt="" src={HandMoney} />
                </div>
                <div className="content">
                  <>
                    <div className="title">Elige una de nuestras plataformas disponibles y realiza el pago de tus cuotas a través de PSE</div>
                  </>
                </div>
              </div>

              <div className="quota-item"> 
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={submitTrap(async (values) => {
                  const codePaymentMethod = values.option
                      if ( codePaymentMethod ) {
                        if (codePaymentMethod === process.env.REACT_APP_BELVO_PAYMENT_METHOD) {
                          const service = (await serviceBelvo()).payload
                          console.log(service.loanId) 
                        } else {
                          const service = (await serviceKushki()).payload
                          console.log(service.loanId) 
                        }
                      }
                      if (codePaymentMethod) {                  
                        fallback(`/loans/quotas/${loan.loanId}/${codePaymentMethod}`)
                      }
                })}
              >
                <Form>
                  <FormGroup>
                    {pm.map(({ code, name }) => 
                      <InputRadio
                        key={code}
                        name={'option'}
                        value={code}
                        label={<img alt={name} className="image" src={name === 'Belvo' ? Belvo : Kushki} />}
                      />
                    )}
                  </FormGroup>
                  <Block textAlign="center">
                    <ButtonContainer>
                      <FormSubmit>Continuar</FormSubmit>
                    </ButtonContainer>
                  </Block>
                </Form>
              </Formik>
              </div>
          </SpanCursor>
          </div>
        ))}
        </div>
    );
  } else if (type === 'cancel') {
    const cancel = loanList.filter((loan) =>
      cancelStatus.includes(loan.statusId)
    );

    return cancel.length === 0 ? (
      <ButtonContainer>
        <Label>En este momento no tienes créditos cancelados.</Label>
      </ButtonContainer>
    ) : (
      <div>
        {cancel.map((loan) => (
          <div key={loan.loanId} className="loan-item">
            <label className="loan-id">Crédito {loan.loanId}</label>
            <div className="loan">
              <label>Estado</label>
              <div className="balance">{translateLoanState(loan)}</div>
            </div>
          </div>
        ))}
      </div>
    );
  } else if (type === 'new') {
    const initial = loanList.filter((loan) =>
      InitialStatus.includes(loan.statusId)
    );
    const reject = loanList.filter(
      (loan) => rejectStatus.includes(loan.statusId) && loan.closedDays < 90
    );

    return initial.length > 0 ? (
      <>
        <label className="loan-id">Crédito {initial[0].loanId}</label>
        <div className="loan">
          <Button as={NavLink} to={getUlr()}>
            Continuar
          </Button>
        </div>
      </>
    ) : (
      <div className="loan-item">
        {loanList.filter((loan) =>
          punishedStatus.concat(activeStatus).includes(loan.statusId)
        ).length === 0 && reject.length === 0 ? (
          <Button as={NavLink} to={`/users/basic-info-recover`}>
            Solicitar un nuevo crédito
          </Button>
        ) : (
          <Label>
            Para solicitar un nuevo crédito no puedes tener créditos activos,
            rechazados o castigados.
          </Label>
        )}
      </div>
    );
  } else if (type === 'reliefs') {
    return <Reliefs />;
  } else if (type === 'agreements') {
    return <Agreements />;
  } else if (type === 'dateChange') {
    return <DateChange />;
  } else if (type === 'support') {
    return <Support />;
  } else if (type === 'payment') {
    return <Payment />;
  } else if (type === 'refinance') {
    return <Refinance />;
  } else if (type === 'download') {
    return <Download />;
  } else if (type === 'signature') {
    return <Signature />;
  } else if (type === 'updateBasicInfo') {
    return <UpdatePersonalInformationContainer />;
  } else {
    return <h1>Type no encontrado</h1>;
  }
};

export default LoansView;
