import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

function useSafeState<S = undefined>(
  initialState?: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(initialState!);

  const mounted = useRef(true);

  const setSafeState = useCallback(
    (state: SetStateAction<S>) => mounted && mounted.current && setState(state),
    []
  );

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  return [state, setSafeState];
}

export default useSafeState;
