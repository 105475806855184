import React from 'react';
import styled from '../../styles/styled-components';
import Button from '../UI/Button';
import { Spinner } from '../UI/Spinner';
const breakpointLarge = '992px';

const ButtonSubmit = styled(Button)<{ loading?: boolean }>`
  color: #fff;
  background: ${props => props.theme.blue};
  border: 1px solid ${props => props.theme.blue};
  font-size:1rem;
  font-weight:700;
  display: flex;
  flex-direction: column;
  align-items: start;
  outline: none;
  max-width:280px;
  width:100%;
  padding: 0.6rem 2.5rem 0.6rem 1rem;
  &hover{
    background: ${props => props.theme.blueLight};
  }

  .spinner {
    display: ${props => (props.loading ? 'block' : 'none')};
  }
  .children {
    visibility: ${props => (props.loading ? 'hidden' : 'visible')};
  }
  @media (min-width: ${breakpointLarge}) {
    font-size:1.1rem;
  }
`;
const Icon = styled.i`
  position: absolute;
  right: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    content: ' ';
    border-radius: 0.07rem;
    position: absolute;
    width: 0.13rem;
    height: 0.9rem;
    background-color: white;
    transform: rotate(-45deg);
    transition: transform 0.25s ease, width 0.25s ease-out;
    top: 0.30rem;
    left: 0.98rem;

  }
  &:after {
    content: ' ';
    border-radius: 0.07rem;
    position: absolute;
    width: 0.13rem;
    height: 0.9rem;
    background-color: white;
    transform: rotate(45deg);
    top: 0.900rem;
    left: 0.98rem;
    transition: all 0.25s ease;
  }
`;
interface Props {
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

const SubmitPI: React.FC<Props> = ({
  children,
  className,
  loading,
  disabled,
}) => (
  <ButtonSubmit
    type="submit"
    className={className}
    disabled={disabled || loading}
    loading={loading}
  >
    <Icon />
    <Spinner className="spinner" size={28} />
    <div className="children">{children}</div>
  </ButtonSubmit>
);

export default SubmitPI;
