import React from 'react';

export const Autorizo = {
  main: (
    <p>
      Recurriremos al débito automático a tu cuenta bancaria cuando (1)
      incumplas el pago de tu cuota mensual y los acuerdos de pago hechos por
      ti, (2) no sea posible contactarte en nuestra gestión de cobranza y estés
      en mora en el pago de tu crédito, (3) sea solicitado por ti como método de
      pago de tu crédito.
    </p>
  ),
  accept: 'Aceptar',
};
