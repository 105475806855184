import { createAction } from '@reduxjs/toolkit';
import { ActionMeta } from '../asyncDispatch';

export const checkCreditHistory = createAction(
  'checkCreditHistory',
  (payload: void, meta: ActionMeta) => ({
    meta,
    payload,
  })
);
