import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Selectors } from '../../services/selectorsGet';

const selectors = createSlice({
  name: 'selectors',
  initialState: {} as Selectors,
  reducers: {
    updateSelectors(_, action: PayloadAction<Selectors>) {
      return action.payload;
    },
  },
});

export default selectors;
