import serviceBuilder from './serviceBuilder';

export interface BodyPaymentLink {
  amount: number;
  paymentId: number;
}

export interface Result {
  payment_url: string;
}

export const url = 'sec/loans/belvo/pl';


export default serviceBuilder<BodyPaymentLink, Result>('post', {
  url,
  auth: true,
});
