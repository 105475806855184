import React from 'react';
import { ModalC, useModalC } from '../UI/Modal';
import Indicator, { Props } from './Indicator';

const InfoIndicator = (modalContent: React.ReactNode, props: Props) => {
  const [modal, openModal] = useModalC();

  return (
    <>
      <ModalC props={modal} />
      <Indicator
        {...props}
        icon={'info-circle'}
        onClick={() => openModal({ main: modalContent })}
      />
    </>
  );
};

export default InfoIndicator;
