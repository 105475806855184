import serviceBuilder from './serviceBuilder';

export interface Result {
  urlSession: string;
}

export const url = 'sec/users/iv/session';

export default serviceBuilder<void, Result>('get', {
  url,
  auth: true,
  silent: true,
});
