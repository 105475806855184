import 'cleave.js/dist/addons/cleave-phone.co';
import Cleave from 'cleave.js/react';
import { Field, FieldProps } from 'formik';
import React from 'react';
import styled from '../../styles/styled-components';
import Error from './Error';
import { InputCSS } from './Input';
import { InputIconStyled } from './InputIcon';

const InputStyled = styled(Cleave)`
  width: 100%;
  ${InputCSS}
  padding-rigth: 36px;
`;

const InputPhoneComponent: React.FC<FieldProps> = ({
  form,
  field,
  ...restProps
}) => {
  return (
    <InputIconStyled>
      <InputStyled
        invalid={
          form.touched[field.name]! && !!form.errors[field.name]
            ? ('invalid' as any)
            : undefined
        }
        id={field.name}
        options={{ phone: true, phoneRegionCode: 'CO' }}
        type="tel"
        {...restProps}
        {...field}
      ></InputStyled>
      
    </InputIconStyled>
  );
};

export interface Props {
  name: string;
  placeholder?: string;
}

const InputPhone: React.FC<Props> = ({ name,...restProps }) => (
  <>
    <Field name={name} {...restProps} component={InputPhoneComponent} />
    <Error name={name} />
  </>
);

export default InputPhone;
