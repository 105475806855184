import { connect, FormikContext } from 'formik';
import React from 'react';
import { Spinner } from '../UI/Spinner';
import SubmitPI from './SubmitPI';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

interface Props {
  disabled?: boolean;
}

const FormSubmitPI: React.FC<Props & Record<'formik', FormikContext<any>>> = ({
  children,
  disabled,
  formik,
  className,
}) => (
  <SubmitPI
    className={`button ${formik.isSubmitting ? 'loading' : ''} ${
      className ?? ''
    }`}
    loading={formik.isSubmitting}
    disabled={disabled || formik.isSubmitting}
  >
    <div className="text">{children}</div>
    <div className="spinner">
      <Spinner size={16} />
    </div>
  </SubmitPI>
);

export default connect<Props>(FormSubmitPI);
