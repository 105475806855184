import serviceBuilder from './serviceBuilder';

export interface Account {
  bank: number;
  accountType: number;
  account: string;
  automaticDebit: boolean;
}

export const url = 'sec/loans/bank';

export default serviceBuilder<Account>('post', {
  url,
  auth: true,
  silent: true,
});
