/* eslint react-hooks/exhaustive-deps: 0 */
import React from 'react';
import styled from 'styled-components';
import { LoanSimulatorContext, Values } from './LoanSimulatorContext';
const Container = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
`;

const Label = styled.label`
  font-weight: 700;
  padding:0.35rem 0 0.35rem 0;
`;
const ValueContent = styled.label`
  margin-bottom:16px;
`;
interface Props {
  label: string;
  component?: 'label' | any;
  calculate?: (value: Values) => string;
}

export const LoanSimulatorLabel: React.FC<Props> = ({
  component,
  calculate = () => '',
  label,
  children,
}) => {
  const context = React.useContext(LoanSimulatorContext);
  const [value, setValue] = React.useState(
    calculate({ amount: context.amount, term: context.term })
  );

  React.useEffect(() => {
    setValue(calculate({ amount: context.amount, term: context.term }));
  }, [context]);

  if (component) {
    return (
      <>
      <Container>
        <Label>{label}</Label>
        {React.createElement(component)}
      </Container>
      <ValueContent>{value}</ValueContent>
      </>
    );
  }
  return null;
};
