import { PayloadAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import changeLoanValuesPatch, {
  LoanValues
} from '../../services/changeLoanValuesPatch';
import loanGet, { Loan } from '../../services/loanGet';
import loansPayment, { LoanList } from '../../services/loansPayment';
import { updateAllLoanFinish } from '../actions/updateAllLoanState';
import { updateLoanFinish, updateLoanStart } from '../actions/updateLoanState';
import { ActionPromise } from '../asyncDispatch';
import loans from '../reducers/allLoanReducer';
import loan, { LoanState } from '../reducers/loanReducer';
import { loanIdSelector } from '../selectors';
import { loanSelector } from '../selectors/loanSelector';
import callService from './callService';

export function* watchLoanStateSaga(): SagaIterator {
  try {
    const response: Loan = yield callService(loanGet);
    const loanId: string = yield select(loanIdSelector);
    yield put(
      loan.actions.update({
        id: loanId,
        amount: response.amount,
        term: response.term,
        status: response.loanStatus,
        repaymentsStartingFromDate: response.repaymentsStartingFromDate,
      })
    );
  } catch {}
  yield put(updateLoanFinish());
}

export function* watchAllLoanStateSaga({
  meta,
}: ActionPromise<void>): SagaIterator {
  try {
    const response: LoanList = yield callService(loansPayment);
    yield put(loans.actions.update(response));
    yield put(updateAllLoanFinish());
    meta.resolve();
  } catch (error) {
    meta.resolve();
    return meta.reject;
    
  }
}

export function* loanState(): SagaIterator<LoanState> {
  yield put(updateLoanStart());
  yield take(updateLoanFinish);
  return yield select(loanSelector);
}

export function* changeLoanValuesSaga({
  payload,
}: PayloadAction<LoanValues>): SagaIterator {
  yield callService(changeLoanValuesPatch, payload);
  yield put(
    loan.actions.update({
      amount: payload.amount,
      term: payload.term,
      repaymentsStartingFromDate: payload.repaymentsStartingFromDate,
    })
  );
}
