import serviceBuilder from '../serviceBuilder';

export interface Result {
  code: number;
}

interface Params {
  currentPaymentDate: string;
  desiredDate: string;
  justification: string;
}

const url = 'sec/users/datechange';
export default serviceBuilder<Params, Result>('post', {
  auth: true,
  url,
});
