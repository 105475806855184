import { boolean, object, ref, string, StringSchema } from 'yup';
import { FormValues } from './SignUpView';

export default object<FormValues>({
  givenname: string().trim().required('Requerido'),
  surname: string().trim().required('Requerido'),
  lastname: string().trim(),
  email: string()
    .trim()
    .email('Ingresa un correo válido para que puedas continuar con el proceso.')
    .required('Campo requerido'),
  confirmEmail: string()
    .trim()
    .when('email', (email: string, schema: StringSchema) =>
      schema.matches(new RegExp(`^${email}$`, 'gi'), 'El correo no coincide')
    )
    .required('Confirme el correo'),
  password: string()
    .trim()
    .min(4, 'Crea una contraseña mas segura')
    .required('Campo requerido'),
  confirmPassword: string()
    .oneOf([ref('password')], 'La contraseña no coincide')
    .required('Confirme la contraseña'),
  idType: string().required('Seleccione una opción'),
  id: string()
    .trim()
    .matches(
      /^\d{5,11}$/,
      'Parece que no has ingresado tu documento correctamente, revísalo para continuar'
    )
    .required('Campo requerido'),
  phone: string()
    .trim()
    .required('Campo requerido')
    .matches(
      /^3\d{2}\s*\d{7}$/,
      'Recuerda que tu número celular debe ser de Colombia, ingresa uno válido para continuar'
    ),
  acceptedTerms: boolean().oneOf(
    [true],
    'Debes aceptar los términos y condiciones'
  ),
  tradeAgreementCode: string().trim(),
});
