import React from 'react';
const Important = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="38"
    viewBox="0 0 75.000000 72.000000"
  >
    <g transform="translate(0.000000,72.000000) scale(0.100000,-0.100000)">
      <path
        className="fill-color"
        d="M414 638 l-29 -33 31 28 c32 28 58 35 70 16 4 -6 -44 -62 -112 -130 -106 -107 -122 -119 -154 -119 l-35 0 90 93 90 92 -95 -92 c-84 -82 -100 -93 -131 -93 -52 0 -59 -13 -57 -104 l2 -81 5 80 6 80 210 3 c115 1 214 1 220 -1 6 -3 9 -57 7 -151 l-3 -146 -217 2 -217 3 -6 55 -6 55 -1 -58 c-2 -54 0 -58 25 -68 17 -6 106 -9 226 -7 163 2 199 6 207 18 5 8 10 62 10 119 l0 104 65 68 c36 37 65 73 65 81 -1 21 -196 218 -217 218 -11 0 -32 -15 -49 -32z m256 -183 c0 -6 -27 -37 -60 -70 -55 -54 -60 -57 -60 -34 0 41 -17 49 -109 49 l-86 0 95 95 95 95 62 -62 c35 -34 63 -67 63 -73z"
      />
      <path
        className="fill-color"
        d="M375 328 c-12 -19 -13 -25 -1 -43 10 -15 26 -21 65 -22 47 -3 53 0 63 23 19 40 1 59 -60 61 -43 2 -54 -2 -67 -19z m64 -5 c12 -23 -4 -53 -29 -53 -25 0 -41 29 -29 52 12 23 45 23 58 1z"
      />
      <path
        className="fill-color"
        d="M168 313 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"
      />
      <path
        className="fill-color"
        d="M168 263 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"
      />
      <path
        className="fill-color"
        d="M222 123 c48 -2 129 -2 180 0 51 1 12 3 -87 3 -99 0 -141 -2 -93 -3z"
      />
    </g>
  </svg>
);
export default Important;
