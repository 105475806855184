import serviceBuilder from './serviceBuilder';

export interface Params {
  deviceInfo:
    | {
        language: string;
        platform: string;
        screenResolution: string;
        timezone: string;
        userAgent: string;
        webglVendorAndRenderer: string;
      }
    | {};
  timestamp: string;
}

const url = 'sec/events/cookies';
export default serviceBuilder<Params>('post', {
  url,
  auth: true,
});
