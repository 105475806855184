/* eslint react-hooks/exhaustive-deps: 0 */
import serviceBuilder from './serviceBuilder';

export interface Params {
  expirationTime: 'short' | 'long';
}
export interface TokenResponse {
  token: string;
}

const url = 'sec/users/token?expirationTime=:expirationTime';

export const tokenGet = serviceBuilder<Params, TokenResponse>('get', {
  url,
  auth: true,
});
