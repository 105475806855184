import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Utm = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
  origin: string;
  referral: string;
  creationScreen?: CreationScreen;
  visitor?: string;
  paramId?: string;
};

type ScreenType = 'Predisburse' | 'smsCode'|'simulator';
export type CreationScreen = 'register' | 'login';

export type Pixel = {
  requestMethod: 'GET' | 'POST';
  url: string;
  body: string;
  screen: ScreenType;
  headers: string;
};

export type PixelState = {
  utm: Utm;
  listPixel: Pixel[] | [];
};

const initialState: PixelState = {
  utm: {
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: '',
    origin: '',
    referral: '',
  },
  listPixel: [],
};

const pixel = createSlice({
  name: 'pixel',
  initialState,
  reducers: {
    hidrateUtm(state, action: PayloadAction<Utm>) {
      return {
        ...state,
        utm: action.payload,
      };
    },
    hidratePixel(state, action: PayloadAction<Pixel[]>) {
      return {
        ...state,
        listPixel: action.payload,
      };
    },
    clear() {
      return initialState;
    },
  },
});

export default pixel;
