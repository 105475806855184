import { SagaIterator } from '@redux-saga/types';
import { call, put, select } from 'redux-saga/effects';
import pixelGet, {
  Pixel,
  Result as ResultPixel,
} from '../../services/pixelGet';
import utmPost from '../../services/utmPost';
import pixelReducer, { CreationScreen, Utm } from '../reducers/pixelReducer';
import { GetListPixel, getUtm } from '../selectors/pixelSelector';

export function* pixelSaga(
  creationScreen: CreationScreen
): SagaIterator<Pixel[]> {
  //Post UTM
  try {
    const payload: Utm = {
      ...(yield select(getUtm)),
      creationScreen: creationScreen,
    };
    yield call(utmPost, payload);
    //Get pixel
    const pixel: ResultPixel = yield call(pixelGet);
    if (pixel) yield put(pixelReducer.actions.hidratePixel(pixel.pixels));
    return yield select(GetListPixel);
  } catch {
    return yield select(GetListPixel);
  }
}
