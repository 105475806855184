import { Formik } from 'formik';
import React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import Error from '../../../components/Form/Error';
import Form from '../../../components/Form/Form';
import FormError from '../../../components/Form/FormError';
import FormGroup from '../../../components/Form/FormGroup';
import FormSubmit from '../../../components/Form/FormSubmit';
import InputIcon from '../../../components/Form/InputIcon';
import InputPassword from '../../../components/Form/InputPassword';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { submitTrap } from '../../../helpers/formHelpers';
import resetPasswordPost, { Params } from '../../../services/resetPasswordPost';
import Omit from '../../../types/Omit';
import validationSchema from './ValidationSchema';

export interface FormValues extends Omit<Params, 'token'> {
  confirmPassword: string;
}

const initialValues: FormValues = {
  newPassword: '',
  confirmPassword: '',
};

const Success = {
  main: 'Su contraseña ha sido actualizada correctamente',
  accept: true,
};

const ChangePasswordView: React.FC<RouteComponentProps<{ token: string }>> = ({
  match,
  history,
}) => {
  const [modal, openModal] = useModalC();

  return (
    <GeneralContent>
      <ModalC props={modal}></ModalC>
      <h3>Ingresa tu nueva contraseña</h3>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={validationSchema}
        onSubmit={submitTrap(async values => {
          await resetPasswordPost({
            newPassword: values.newPassword,
            token: match.params.token,
          });

          await openModal(Success);
          history.push('/login');
        })}
      >
        <Form>
          <GeneralContent>
            <FormGroup>
              <InputPassword
                type="password"
                name="newPassword"
                placeholder="Contraseña"
                icon="lock"
              />
              <Error name="password" />
            </FormGroup>
            <FormGroup>
              <InputIcon
                type="password"
                name="confirmPassword"
                placeholder="Confirmar contraseña"
                icon="lock"
              />
              <Error name="confirmPassword" />
            </FormGroup>
            <FormError />
          </GeneralContent>
          <ButtonContainer>
            <FormSubmit>Cambiar contraseña</FormSubmit>
          </ButtonContainer>
        </Form>
      </Formik>
      <div>
        <span>Regresar e </span>
        <NavLink to="/login">iniciar sesión</NavLink>
      </div>
    </GeneralContent>
  );
};

export default ChangePasswordView;
