import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import React from 'react';
import LogoInfo from '../../../images/info.png';
import logo from '../../../images/logo-juancho-te-presta.png';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#f7f7f7',
  },
  section: {
    backgroundColor: '#E4E4E4',
    margin: 20,
    padding: 20,
    flexGrow: 1,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  Title: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    marginBottom: 12,
  },
  title: {
    paddingBottom: '17',
    paddingTop: '15',
    color: '#083863',
    marginLeft: 10,
    fontSize: 20,
  },
  image: {
    height: 270,
    width: 270,
    paddingBottom: '17',
  },
  information: {
    backgroundColor: '#f7f7f7',
    width: 350,
    paddingTop: 17,
    paddingBottom: 17,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    fontSize: 12,
    marginLeft: 30,
    marginBottom: 12,
  },
  label: {
    color: '#707070',
    marginRight: '10',
  },
  description: {
    color: '#083863',
  },
  logoInfo: {
    height: 15,
    width: 15,
    marginTop: '20',
  },

  aditionalInfo: {
    marginRight: '15',
    fontSize: 11,
  },

  line: {
    backgroundColor: '#E4E4E4',
    height: 0.5,
    width: 297,
    marginBottom: 5,
  },
});
interface Props {
  amount: any;
  date: any;
  transactionState: string;
  bank: any;
  CUS: string;
  ticketNumber: string;
  paymentId: string;
  loanId: string;
}

const PDFfile: React.FC<Props> = ({
  amount,
  date,
  transactionState,
  bank,
  CUS,
  ticketNumber,
  paymentId,
  loanId,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.Title}>
            <Image source={LogoInfo} style={styles.logoInfo} />
            <Text style={styles.title}>Información de la transacción</Text>
          </View>

          <Image source={logo} style={styles.image} />
          <View style={styles.information}>
            <View style={styles.row}>
              <Text style={styles.label}>Razón social: </Text>
              <Text style={styles.description}>Juancho Te Presta S.A.S</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>NIT: </Text>
              <Text style={styles.description}>901.200.575-1</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Valor del pago: </Text>
              <Text style={styles.description}>{amount}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Fecha de la transacción: </Text>
              <Text style={styles.description}>{date}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Estado de transacción:</Text>
              <Text style={styles.description}>{transactionState}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Banco: </Text>
              <Text style={styles.description}>{bank}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>CUS: </Text>
              <Text style={styles.description}>{CUS}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Número de transacción: </Text>
              <Text style={styles.description}>{ticketNumber}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Descripción del pago: </Text>
              <Text style={styles.description}> Pago procedente de la </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.description}>
                cuota Nº {paymentId} del crédito Nº{loanId}.
              </Text>
            </View>

            <View style={styles.row}>
              <View style={styles.aditionalInfo}>
                <View style={styles.line} />
                <Text style={styles.label}>
                  Si tienes alguna inquietud puedes contactarnos en nuestra
                  línea de atención al cliente{' '}
                  <Text style={styles.description}>018000413811</Text> o
                  escribirnos al correo{' '}
                  <Text style={styles.description}>
                    ayuda@juanchotepresta.com{' '}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PDFfile;
