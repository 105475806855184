import styled from '../../styles/styled-components';

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  text-align: left;
  color: ${props => props.theme.blueDark};
  max-width: 305px;
  flex-grow: 1;
  @media (min-width: 700px) {
    max-width: 400px;
  }
`;

export default Label;
