/* eslint react-hooks/exhaustive-deps: 0 */
import { PathKeyName } from '../views/Workflow';
import serviceBuilder from './serviceBuilder';

export interface UserCredentials {
  terminalUuid?: string;
  id: string;
  password: string;
}

export interface Result {
  token: string;
  path: PathKeyName;
}

export const url = 'users/login';

export const loginPost = serviceBuilder<UserCredentials, Result>('post', {
  url,
  auth: false,
});
