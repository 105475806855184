import React from 'react'
const Simulate = props => 
<svg xmlns="http://www.w3.org/2000/svg" width="19.403" height="25.507" viewBox="0 0 19.403 25.507">
  <g id="Group_925" data-name="Group 925" transform="translate(0.2 0.2)">
    <path id="Path_724" className="fill-color stroke-color" data-name="Path 724" d="M50.442,27.107H33.831a1.2,1.2,0,0,1-1.2-1.2V3.2a1.2,1.2,0,0,1,1.2-1.2H50.442a1.2,1.2,0,0,1,1.2,1.2V25.911A1.2,1.2,0,0,1,50.442,27.107ZM33.831,2.4a.8.8,0,0,0-.8.8V25.911a.8.8,0,0,0,.8.8H50.442a.8.8,0,0,0,.8-.8V3.2a.8.8,0,0,0-.8-.8Z" transform="translate(-32.635 -2)"  strokeWidth="0.4"/>
    <path id="Path_727" className="fill-color stroke-color" data-name="Path 727" d="M65.493,24.286H51.506a.7.7,0,0,1-.7-.7V19.34a.7.7,0,0,1,.7-.7H65.493a.7.7,0,0,1,.7.7v4.248A.7.7,0,0,1,65.493,24.286ZM51.506,19.042a.3.3,0,0,0-.3.3v4.248a.3.3,0,0,0,.3.3H65.493a.3.3,0,0,0,.3-.3V19.34a.3.3,0,0,0-.3-.3Z" transform="translate(-48.998 -16.985)"  strokeWidth="0.4"/>
    <path id="Path_728" className="fill-color stroke-color" data-name="Path 728" d="M70.616,105.4H69.606a.39.39,0,0,1-.392-.392.4.4,0,0,1,.392-.392h1.011v-.99a.4.4,0,0,1,.406-.406.406.406,0,0,1,.406.406v.99h1.011a.4.4,0,0,1,.392.392.39.39,0,0,1-.392.392H71.428v.99a.406.406,0,0,1-.406.406.4.4,0,0,1-.406-.406v-.99Z" transform="translate(-65.57 -93.141)"  strokeWidth="0.4"/>
    <path id="Path_729" className="fill-color stroke-color" data-name="Path 729" d="M74.2,190.362l-.715-.715a.39.39,0,0,1,0-.554.4.4,0,0,1,.554,0l.715.715.7-.7a.4.4,0,0,1,.574,0,.406.406,0,0,1,0,.574l-.7.7.715.715a.4.4,0,0,1,0,.554.39.39,0,0,1-.554,0l-.715-.715-.7.7a.406.406,0,0,1-.574,0,.4.4,0,0,1,0-.574Z" transform="translate(-69.315 -170.351)"  strokeWidth="0.4"/>
    <path id="Path_730" className="fill-color stroke-color" data-name="Path 730" d="M151.485,193.4a.373.373,0,0,1,.367-.373h2.684a.37.37,0,0,1,0,.74h-2.684A.371.371,0,0,1,151.485,193.4Z" transform="translate(-139.644 -173.992)"  strokeWidth="0.4"/>
    <path id="Path_731" className="fill-color stroke-color" data-name="Path 731" d="M151.485,205.824a.373.373,0,0,1,.367-.373h2.684a.37.37,0,0,1,0,.74h-2.684A.372.372,0,0,1,151.485,205.824Z" transform="translate(-139.644 -185.181)"  strokeWidth="0.4"/>
    <path id="Path_741" className="fill-color stroke-color" data-name="Path 741" d="M138.667,91.89h-5.889a.7.7,0,0,1-.7-.7V85.3a.7.7,0,0,1,.7-.7h5.889a.7.7,0,0,1,.7.7v5.889A.7.7,0,0,1,138.667,91.89ZM132.777,85a.3.3,0,0,0-.3.3v5.889a.3.3,0,0,0,.3.3h5.889a.3.3,0,0,0,.3-.3V85.3a.3.3,0,0,0-.3-.3Z" transform="translate(-122.172 -76.376)"  strokeWidth="0.4"/>
    <path id="Path_742" className="fill-color stroke-color" data-name="Path 742" d="M151.485,117.822a.373.373,0,0,1,.367-.373h2.684a.37.37,0,0,1,0,.74h-2.684A.372.372,0,0,1,151.485,117.822Z" transform="translate(-139.644 -105.947)"  strokeWidth="0.4"/>
    <path id="Path_743" className="fill-color stroke-color" data-name="Path 743" d="M57.4,91.89H51.506a.7.7,0,0,1-.7-.7V85.3a.7.7,0,0,1,.7-.7H57.4a.7.7,0,0,1,.7.7v5.889A.7.7,0,0,1,57.4,91.89ZM51.506,85a.3.3,0,0,0-.3.3v5.889a.3.3,0,0,0,.3.3H57.4a.3.3,0,0,0,.3-.3V85.3a.3.3,0,0,0-.3-.3Z" transform="translate(-48.998 -76.376)"  strokeWidth="0.4"/>
    <path id="Path_744" className="fill-color stroke-color" data-name="Path 744" d="M57.4,173.679H51.506a.7.7,0,0,1-.7-.7v-5.889a.7.7,0,0,1,.7-.7H57.4a.7.7,0,0,1,.7.7v5.889A.7.7,0,0,1,57.4,173.679Zm-5.889-6.886a.3.3,0,0,0-.3.3v5.889a.3.3,0,0,0,.3.3H57.4a.3.3,0,0,0,.3-.3v-5.889a.3.3,0,0,0-.3-.3Z" transform="translate(-48.998 -150.016)"  strokeWidth="0.4"/>
    <path id="Path_745" className="fill-color stroke-color" data-name="Path 745" d="M138.667,173.679h-5.889a.7.7,0,0,1-.7-.7v-5.889a.7.7,0,0,1,.7-.7h5.889a.7.7,0,0,1,.7.7v5.889A.7.7,0,0,1,138.667,173.679Zm-5.889-6.886a.3.3,0,0,0-.3.3v5.889a.3.3,0,0,0,.3.3h5.889a.3.3,0,0,0,.3-.3v-5.889a.3.3,0,0,0-.3-.3Z" transform="translate(-122.172 -150.016)"  strokeWidth="0.4"/>
  </g>
</svg>

export default Simulate
