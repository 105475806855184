import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Mount from '../../../components/Mount';
import { ModalC, useModalC } from '../../../components/UI/Modal';
import { CancelledError } from '../../../helpers/makeCancellable';
import { UserCredentials } from '../../../services/logInPost';
import { JTPState } from '../../../store';
import {
  resolveNextPath,
  ResolveNextPath,
} from '../../../store/actions/navigation';
import { loginRequest, logoutRequest } from '../../../store/actions/session';
import { asyncDispatch } from '../../../store/asyncDispatch';
import LogInView from './LogInView';

const MessageError1 = {
  main: (
    <p>
      ¡Lo sentimos! En este momento no cumples con los requisitos de ingreso.
    </p>
  ),
  accept: true,
};

const MessageError4 = {
  main: (
    <p>
      Desafortunadamente en este momento no cumples uno de nuestros requisitos. 
      Mantendremos tu información de registro para contactarte cuando tengamos
      un producto que se ajuste a tu perfil.
    </p>
  ),
  accept: true,
};

const MessageError2 = {
  main: (
    <div>
      <p>
        Necesitamos verificar tus datos. Por favor comunícate con nosotros a la
        línea telefónica: 01 8000 413 811, al{' '}
        <a
          href="https://wa.me/5744807833"
          rel="noopener noreferrer"
          target="_blank"
          title="WhatsApp"
          style={{ marginLeft: '4px' }}
        >
          WhatsApp
        </a>{' '}
        o escríbenos a{' '}
        <a
          href="mailto:ayuda@juanchotepresta.com"
          title="ayuda@juanchotepresta.com"
          rel="noopener noreferrer"
          target="_blank"
          style={{ marginLeft: '4px', marginRight: '4px' }}
        >
          ayuda@juanchotepresta.com
        </a>
      </p>
    </div>
  ),
  accept: true,
};

const MessageError3 = {
  main: (
    <p>
      ¡Lo sentimos! Por el momento no es posible tramitar tu crédito. Inténtalo
      nuevamente en al menos 90 días.
    </p>
  ),
  accept: true,
};

interface Props extends RouteComponentProps<any> {
  loggedIn: boolean;
  login(payload: UserCredentials): Promise<void>;
  resolveNextPath: ResolveNextPath;
  logout(): void;
  ready: boolean;
}

const LogInContainer: React.FC<Props> = ({
  loggedIn,
  login,
  ready,
  resolveNextPath,
  logout,
  history,
}) => {
  const [modal, openModal] = useModalC();

  return (
    <>
      <ModalC props={modal} />
      <Mount
        loading={!ready}
        before={() => {
          if (loggedIn) {
            throw new CancelledError();
          }
          return {
            submit: async (values: UserCredentials) => {
              await login(values);
              try {
                const { next } = await resolveNextPath('/login');
                history.push(next);
              } catch (err) {
                if (err.message === 'MessageError1') {
                  openModal(MessageError1);
                } else if (err.message === 'MessageError2') {
                  openModal(MessageError2);
                } else if (err.message === 'MessageError4') {
                  openModal(MessageError4);
                }
                else if (err.message === 'MessageError3') {
                  openModal(MessageError3);
                } else if (err.message === 'Loan Withdrawn by applicant') {
                  openModal(MessageError2);
                } else {
                  openModal({
                    main: <p>{err.message}</p>,
                    accept: true,
                  });
                }
                logout();
              }
            },
          };
        }}
        props={{ initialValues: { id: '', password: '' } }}
        component={LogInView}
        cancelled={async () => {
          try {
            const { next } = await resolveNextPath('/login');
            history.push(next);
          } catch (err) {
            if (err.message === 'MessageError1') {
              openModal(MessageError1); 
            } else if (err.message === 'MessageError4') {
              openModal(MessageError4);
            } else if (err.message === 'MessageError2') {
              openModal(MessageError2);
            } else if (err.message === 'MessageError3') {
              openModal(MessageError3);
            } else if (err.message === 'Loan Withdrawn by applicant') {
              openModal(MessageError2);
            } else {
              openModal({
                main: <p>{err.message}</p>,
                accept: true,
              });
            }
            history.push('/login');
          }
        }}
      />
    </>
  );
};

export default connect(
  (state: JTPState) => {
    return {
      loggedIn: state.session.loggedIn,
      ready: state.ready,
    };
  },
  (dispatch) => ({
    login: asyncDispatch(dispatch, loginRequest),
    logout: () => dispatch(logoutRequest()),
    resolveNextPath: asyncDispatch(dispatch, resolveNextPath),
  })
)(LogInContainer);
