import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from '../styles/styled-components';
import { Spinner } from './UI/Spinner';

export const containerId = 'videoid-component';

const scale = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0,0);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1,1);
  }
`;

const VideoStyled = styled.div<Record<'visible', boolean>>`
  position: fixed;
  top: 70px;
  left: 50%;
  bottom: 20px;
  width: 95vw;
  margin: 0;
  padding: 0;
  display: ${props => (props.visible ? 'block' : 'none')};
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px #000;

  transform: translate(-50%, 0);
  animation: ${scale} 250ms ease;
  animation-play-state: ${props => (props.visible ? 'running' : 'paused')};
  background-color: #222;
  z-index: 1;
  color: #fff;

  > #${containerId} {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
    position: relative;
  }
`;

interface Props {
  visible: boolean;
}

const VideoID: React.FC<Props> = ({ visible }) => {
  const container = useRef<HTMLElement>(
    document.getElementById('videoid-portal')!
  );

  return ReactDOM.createPortal(
    <VideoStyled visible={visible}>
      <Spinner size={64} enabled />
      <div id={containerId}></div>
    </VideoStyled>,
    container.current
  );
};

export const VideoIDPortal: React.FC = () => {
  return <div id="videoid-portal" />;
};

export default VideoID;
