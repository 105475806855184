/* eslint react-hooks/exhaustive-deps: 0 */
import React from 'react';
import styled from 'styled-components';
import { Action, LoanSimulatorContext, Values } from './LoanSimulatorContext';

const SimulatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

function reducer(state: Values, action: Action) {
  // eslint-disable-next-line
  if (state[action.type] != action.value) {
    return { ...state, [action.type]: action.value };
  }
  return state;
}

export type SimulatorValuesHandler = (values: Values) => void;

export interface Props {
  minAmount: number;
  maxAmount: number;
  terms: number[];
  initialAmount: number;
  initialTerm: number;
  onChange?: SimulatorValuesHandler;
}

export const LoanSimulatorComponent: React.FC<Props> = ({
  children,
  initialAmount: amount = 0,
  initialTerm: term = 0,
  onChange,
  ...restProps
}) => {
  const [values, update] = React.useReducer(reducer, {
    amount,
    term,
  });

  React.useEffect(() => {
    if (onChange) onChange(values);
  }, [values]);

  return (
    <SimulatorContainer>
      <LoanSimulatorContext.Provider
        value={{ ...restProps, ...values, update }}
      >
        {children}
      </LoanSimulatorContext.Provider>
    </SimulatorContainer>
  );
};
