import { call, CallEffect } from 'redux-saga/effects';
import { ServiceLoaded } from '../../types/Service';

function callService<Fn extends (params: void) => Promise<ServiceLoaded>>(
  fn: Fn
): CallEffect;

function callService<Fn extends () => Promise<ServiceLoaded>>(
  fn: Fn
): CallEffect;

function callService<Fn extends (params: any) => Promise<ServiceLoaded>>(
  fn: Fn,
  arg: Parameters<Fn>[0]
): CallEffect;

function callService<Fn extends (params?: any) => Promise<ServiceLoaded>>(
  fn: Fn,
  arg?: Parameters<Fn>[0]
): CallEffect {
  return call(async (params: any) => {
    return (await fn(params)).payload;
  }, arg);
}

export default callService;
