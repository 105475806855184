import { captureMessage, withScope } from '@sentry/minimal';
import { Severity } from '@sentry/types';
import uuid from '../helpers/uuid';
import { Params } from '../services/useSignUpPost';

export interface FetchPayload {
  uuid: string;
  cc: string;
  first_name: string;
  last_name: string;
  sur_name: string;
  ip: string;
  email: string;
  phone: string;
  session: string;
}

export const fecthSeon = (payload: FetchPayload) => {
  return fetch(
    `https://1u2k9vyd51.execute-api.us-east-1.amazonaws.com/poc/files/jtp-seon/${payload.cc}.json`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': 'ZNucMW8F3a9S8a8i8prMU5F0ebda6dqsu2WBRUB1',
      },
      body: JSON.stringify(payload),
    }
  );
};

async function seonConfig(uuid: string) {
  const seon = await getSeon();

  await new Promise((resolve, reject) => {
    seon.config({
      host: 'seondf.com',
      session_id: uuid,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: resolve('successfully'),
      onError: reject('Error config seon'),
    });
  });
}

async function seonSaveSession(params: Params, uuid: string) {
  const seon = await getSeon();

  await new Promise(async (resolve, reject) => {
    try {
      const session = await seon.getBase64Session();
      await fecthSeon({
        cc: params.id,
        email: params.email,
        first_name: params.givenname,
        last_name: params.lastname,
        ip: '',
        phone: params.phone,
        uuid: uuid,
        session,
        sur_name: params.surname,
      });
      resolve('successfully');
    } catch (error) {
      reject(error);
    }
  });
}

function getSeon() {
  const seonSrc = 'https://cdn.seondf.com/js/v5/agent.js';

  return new Promise<Record<'config' | 'getBase64Session', (p?: any) => any>>(
    (resolve) => {
      const script = document.querySelector(`script[src="${seonSrc}"]`);
      if (script) {
        resolve((window as any).seon);
      } else {
        const script = document.createElement('script');
        script.src = seonSrc;
        script.async = true;
        script.addEventListener('load', () => {
          resolve((window as any).seon);
        });
        document.head.appendChild(script);
      }
    }
  );
}

async function setSeon(payload: Params) {
  // if (process.env.NODE_ENV === 'production') {
  const Uuid: string = uuid.user;
  try {
    await seonConfig(Uuid);
    await seonSaveSession(payload, Uuid);
  } catch (err) {
    withScope((scope) => {
      scope
        .setFingerprint(['seon/error'])
        .setLevel(Severity.Critical)
        .setTags({
          'validation.uuid': Uuid,
          'validation.phone': payload.phone,
          'validation.email': payload.email,
        })
        .addBreadcrumb({
          category: 'seon-error',
          data: {
            error: err,
          },
          level: Severity.Critical,
        });
      captureMessage(`[SEON] validation error`);
    });
  }
  // }
}

export default setSeon;
