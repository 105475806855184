import React from 'react';
export const Mutuo = {
  main: (
    <p>
      Mutuo mercantil es un contrato en el cual un prestamista (Juancho Te
      Presta) le entrega dinero a un prestatario (tu) para que después lo
      devuelvas. El Pagaré es para garantizar en caso de incumplimiento el pago
      de tus obligaciones atrasadas.
    </p>
  ),
  accept: 'Aceptar',
};
