
import serviceBuilder from './serviceBuilder';
interface JtdSimFinfoItem {
  term: number;
  maxAmount: number;
  installmentValue: number;
}
export interface Params {
  jtd_client_decision:boolean;
  jtd_sim_info: JtdSimFinfoItem[];
}

const url = 'sec/loans/jtd/client/decision';
export default serviceBuilder<Params>('post', {
  url,
  auth: true,
})
