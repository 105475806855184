import React from 'react';
import styled from '../../styles/styled-components';
import Button from '../UI/Button';
import { Spinner } from '../UI/Spinner';

const ButtonSubmit = styled(Button)<{ loading?: boolean }>`
  color: #fff;
  background: ${props => props.theme.blue};
  border: 1px solid ${props => props.theme.blue};
  display: flex;
  flex-direction: column;
  margin-top: 0.125rem;
  align-items: center;
  outline: none;

  .spinner {
    display: ${props => (props.loading ? 'block' : 'none')};
  }
  .children {
    visibility: ${props => (props.loading ? 'hidden' : 'visible')};
  }
`;

interface Props {
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

const Submit: React.FC<Props> = ({
  children,
  className,
  loading,
  disabled,
}) => (
  <ButtonSubmit
    type="submit"
    className={className}
    disabled={disabled || loading}
    loading={loading}
  >
    <Spinner className="spinner" size={28} />
    <div className="children">{children}</div>
  </ButtonSubmit>
);

export default Submit;
