import React from 'react';
import noop from '../../helpers/noop';

export interface Values {
  amount: number;
  term: number;
}

export interface Action {
  type: 'amount' | 'term';
  value: number | string;
}

export interface LoanSimulatorContextProps {
  minAmount: number;
  maxAmount: number;
  amount: number;
  term: number;
  terms: number[];
  update: React.Dispatch<Action>;
}

export const LoanSimulatorContext = React.createContext<
  LoanSimulatorContextProps
>({
  minAmount: 0,
  maxAmount: 0,
  amount: 0,
  term: 0,
  terms: [],
  update: noop,
});
