import { SagaIterator } from 'redux-saga';
import { takeLatest, takeLeading } from 'redux-saga/effects';
import { checkCreditHistory } from '../actions/checkCreditHistory';
import { navigationRequires, resolveNextPath } from '../actions/navigation';
import { logoutPixelClear } from '../actions/session';
import { updateAllLoanStart } from '../actions/updateAllLoanState';
import { updateLoanStart } from '../actions/updateLoanState';
import { updateSimulatorStart } from '../actions/updateSimulator';
import { updateBasicInfo } from '../actions/user';
import { basicInfo } from '../reducers/basicInfoReducer';
import { changeLoanValues } from '../reducers/changeLoanValuesReducer';
import { disclaimer } from '../reducers/disclaimerReducer';
import { updateLoanStatus } from '../reducers/updateLoanStatusReducer';
import { basicInfoSaga, updateBasicInfoSaga } from './basicInfo';
import { checkCreditHistorySaga } from './checkCreditHistory';
import { disclaimerSaga } from './disclaimerSaga';
import { hydrateSimulator } from './hydrateStore';
import {
  changeLoanValuesSaga,
  watchAllLoanStateSaga,
  watchLoanStateSaga,
} from './loanState';
import { navigationRequiresSaga, resolveNextPathSaga } from './navigation';
import { requestSaga } from './requestSaga';
import { pixelClear } from './session';
import { updateLoanSaga } from './updateLoanSaga';

export function* requestWatchers(): SagaIterator {
  yield takeLatest(disclaimer.actions.request, requestSaga(disclaimerSaga));
  yield takeLatest(
    updateLoanStatus.actions.request,
    requestSaga(updateLoanSaga)
  );
  yield takeLatest(basicInfo.actions.request, requestSaga(basicInfoSaga));
  yield takeLatest(checkCreditHistory, requestSaga(checkCreditHistorySaga));
  yield takeLatest(
    changeLoanValues.actions.request,
    requestSaga(changeLoanValuesSaga)
  );

  yield takeLatest(navigationRequires, navigationRequiresSaga);
  yield takeLatest(resolveNextPath, resolveNextPathSaga);
  yield takeLatest(updateSimulatorStart, requestSaga(hydrateSimulator));
  yield takeLatest(logoutPixelClear, pixelClear);
  yield takeLatest(updateBasicInfo, updateBasicInfoSaga);
  yield takeLeading(updateLoanStart, watchLoanStateSaga);
  yield takeLeading(updateAllLoanStart, watchAllLoanStateSaga);
}
