import { SagaIterator } from 'redux-saga';
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import uuid from 'uuid';
import { Result as LoginResponse, loginPost } from '../../services/logInPost';
import { TokenResponse, tokenGet } from '../../services/tokenGet';
import { loginRequest, logoutRequest } from '../actions/session';
import { updateLoanStart } from '../actions/updateLoanState';
import { ActionPromise } from '../asyncDispatch';
import allLoan from '../reducers/allLoanReducer';
import loan from '../reducers/loanReducer';
import pixel from '../reducers/pixelReducer';
import { ready } from '../reducers/readyReducer';
import { session } from '../reducers/sessionReducer';
import { user } from '../reducers/userReducer';
import { tokenRemainingTimeSelector } from '../selectors';
import { sentryConfigureContext } from './Sentry';
import callService from './callService';
import { cookieSaga } from './cookie';
import { hydrateStoreSaga, hydrateUserStoreSaga } from './hydrateStore';
import { logoutFlow } from './logoutFlow';
import { pixelSaga } from './pixel';
import { signupSaga } from './signupSaga';

export function* controlSessionSaga(): SagaIterator {
  yield all([call(hydrateStoreSaga), call(hydrateUserStoreSaga)]);
  yield call(sentryConfigureContext);

  yield fork(logoutFlow);
  yield put(ready.actions.ready());
  yield takeLatest(loginRequest, loginSaga);

  while (true) {
    yield call(signupSaga);
  }
}

export function* loginSaga(action: ActionPromise<any>): SagaIterator {
  try {
    const credentials = action.payload;
    credentials.terminalUuid = uuid.v4();
    const { token }: LoginResponse = yield callService(loginPost, credentials);
    yield put(session.actions.login(token));
    yield fork(logoutFlow);
    yield call(pixelSaga, 'login');
    yield call(sentryConfigureContext);
    yield call(hydrateUserStoreSaga);
    yield call(sentryConfigureContext);

    yield put(ready.actions.ready());
    yield put(updateLoanStart());
    yield call(cookieSaga);

    action.meta.resolve();
  } catch (e) {
    yield put(session.actions.loginError(e));
    action.meta.reject(e);
  }
}

export function* pixelClear(): SagaIterator {
  yield put(pixel.actions.clear());
}

export function* logout(): SagaIterator {
  yield put(session.actions.logout());
  yield put(user.actions.clearUserInfo());
  yield put(allLoan.actions.clear());
  yield put(loan.actions.clear());
}

export function* tokenExpWatcher(): SagaIterator {
  const fiveMinutes = 1000 * 60 * 5;
  while (true) {
    const remaining = yield select(tokenRemainingTimeSelector);
    if (remaining - fiveMinutes < 0) return;

    yield delay(Math.max(0, remaining - fiveMinutes));
  }
}
export function* refreshTokenSaga(): SagaIterator {
  try {
    const { token }: TokenResponse = yield callService(tokenGet, {
      expirationTime: 'short',
    });
    yield put(session.actions.refreshToken(token));
  } catch (e) {
    yield call(logoutRequest);
    throw e;
  }
}
