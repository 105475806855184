import serviceBuilder from '../serviceBuilder';

export interface Result {
  code: number;
}

interface Params {
  reliefReason: string;
  justification: string;
  amountToCancel: string;
  paymentSupport: string;
  clientEmail: string;
}

const url = 'sec/users/refinancing';
export default serviceBuilder<Params, Result>('post', {
  auth: true,
  url,
});
