import { ServiceLoaded } from '../types/Service';
import { LoanList } from './loansPayment';
import serviceBuilder from './serviceBuilder';

export const activeStatus = [300]; //activo - pagando
export const cancelStatus = [600, 700]; //cabcelados
export const rejectStatus = [500]; //rechazado
export const punishedStatus = [601]; //castigado
export const InitialStatus = [100, 101, 102, 103, 104, 200, 201, 202, 203]; //en proceso

export function isLoanActive(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter((loan) => activeStatus.includes(loan.statusId)).length > 0
    ? true
    : false;
}

export function isLoanCancel(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter((loan) => cancelStatus.includes(loan.statusId)).length > 0
    ? true
    : false;
}

export function isLoanDesist(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter((loan) => [400].includes(loan.statusId)).length > 0
    ? true
    : false;
}

//loans Rejected before 90 days
export function isLoanReject(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter(
    (loan) => rejectStatus.includes(loan.statusId) && loan.closedDays < 90
  ).length > 0
    ? true
    : false;
}

//loans Rejected after 90 days
export function isLoanRejectAfterDays(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter(
    (loan) => rejectStatus.includes(loan.statusId) && loan.closedDays > 90
  ).length > 0
    ? true
    : false;
}

export function isLoanProcess(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter((loan) => InitialStatus.includes(loan.statusId)).length >
    0
    ? true
    : false;
}

export function isLoanPunished(loansList: LoanList) {
  const loans = Object.values(loansList);
  return loans.filter((loan) => punishedStatus.includes(loan.statusId)).length >
    0
    ? true
    : false;
}

export const url = 'sec/loans?command=allLoans';
const service = serviceBuilder<void, LoanList>('get', {
  auth: true,
  url,
});

export default async function (): Promise<ServiceLoaded<LoanList>> {
  try {
    const { payload, status } = await service();
    for (let k in payload) {
      if (!payload[k].statusId) {
        delete payload[k];
      }
    }

    return { payload, status };
  } catch (e) {
    throw e;
  }
}
