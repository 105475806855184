export const getVersion = () => {
  if (!(window as any).version) {
    const commit = process.env.REACT_APP_COMMIT;
    const version = process.env.REACT_APP_VERSION;
    (window as any).version = commit ? `${version}-${commit}` : version;
  }
  return (window as any).version;
};

export default () => {
  const commit = process.env.REACT_APP_COMMIT;
  const version = process.env.REACT_APP_VERSION;
  (window as any).version = commit ? `${version}-${commit}` : version;
};
