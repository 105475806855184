import serviceBuilder from './serviceBuilder';

export interface Result {
  access_token: string;
}

export const url = 'sec/loans/scrapping/tb';

export default serviceBuilder<void, Result>('get', {
  url,
  auth: true,
  silent: true,
});
