import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';
import ApiError from '../../types/ApiError';
import { ActionMeta } from '../asyncDispatch';

type Status = { success?: true; error?: true; loading?: true; init?: true };

export interface RState<P, S = void, E = ApiError> {
  loading: boolean;
  status: Status;
  response: S;
  error: ApiError<E>;
  [prop: string]: any;
}

type Options<P, Pr, S, R extends SliceCaseReducers<S>> = {
  name: string;
  reducers?: R;
  prepareRequest?(
    payload: Pr,
    meta: ActionMeta
  ): { payload: P; meta: ActionMeta };
};

export function createRequestSlice<
  Payload = any,
  Response = void,
  Error = ApiError,
  PreparePayload = Payload,
  R extends SliceCaseReducers<
    RState<Payload, Response, Error>
  > = SliceCaseReducers<RState<Payload, Response, Error>>
>({
  name,
  reducers,
  prepareRequest,
}: Options<Payload, PreparePayload, RState<Payload, Response, Error>, R>) {
  const defaultReducers = {
    request: {
      reducer(state: any, _: PayloadAction<Payload, string, ActionMeta>) {
        return {
          ...state,
          response: undefined,
          error: undefined,
          loading: true,
          status: { loading: true },
        };
      },
      prepare: prepareRequest
        ? prepareRequest
        : (
            payload: PreparePayload,
            meta: ActionMeta
          ): { payload: Payload; meta: ActionMeta } => {
            return { payload, meta } as any;
          },
    },
    success(state: any, action: PayloadAction<Response>) {
      return {
        ...state,
        loading: false,
        status: { success: true },
        error: undefined,
        response: action.payload,
      };
    },
    error(state: any, action: PayloadAction<Error>) {
      return {
        ...state,
        loading: false,
        status: { error: true },
        error: action.payload,
        response: undefined,
      };
    },
  };

  return createSlice<
    RState<Payload, Response, Error>,
    typeof defaultReducers & R
  >({
    name,
    initialState: {
      loading: false,
      status: { init: true },
      response: undefined as any,
      error: undefined as any,
    },
    reducers: {
      ...defaultReducers,
      ...reducers,
    } as any,
  });
}
