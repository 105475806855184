export const sendEventToGTM = (eventName: String) => {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({ event: eventName });
};

export default sendEventToGTM;
/*
Registro	                    --> { 'event': Registro}
Solicitud cliente nuevo	      --> { 'event': SolicitudNuevo}
Solicitud cliente recurrente  --> { 'event': SolicitudRecurrente}
Aprobación de crédito nuevo   --> { 'event': AprobacionNuevo}
Solicitud cliente recurrente	--> { 'event': AprobacionRecurrente}
Aprobación cliente nuevo	    --> { 'event': RechazoNuevo}
Aprobación cliente recurrente	--> { 'event': RechazoRecurrente}
Desembolso cliente nuevo	    --> { 'event': PredesembolsoNuevo}
Desembolso cliente recurrente	--> { 'event': PredesembolsoRecurrente}
*/
