import React from 'react'
const Faq= props=>
<svg id="question" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
  <g id="Group_714" data-name="Group 714">
    <path id="Path_703" className="fill-color" data-name="Path 703" d="M112.84,68.337a2.445,2.445,0,0,0-2.224.494,2.638,2.638,0,0,0-.988,2.1.618.618,0,0,0,1.235,0,1.341,1.341,0,0,1,.494-1.112,1.436,1.436,0,0,1,1.235-.247,1.46,1.46,0,0,1,1.112,1.112,1.35,1.35,0,0,1-.494,1.482,3.684,3.684,0,0,0-1.482,2.965.584.584,0,0,0,.618.618c.371,0,.618-.247.494-.618a2.721,2.721,0,0,1,1.112-1.976,2.677,2.677,0,0,0,.988-2.718A2.8,2.8,0,0,0,112.84,68.337Z" transform="translate(-101.846 -63.396)"/>
    <path id="Path_704" className="fill-color" data-name="Path 704" d="M139.333,208.13a.743.743,0,0,0-.124.494.454.454,0,0,0,.124.371.937.937,0,0,0,.494.247c.124,0,.371-.124.371-.247.124,0,.247-.247.247-.371a.642.642,0,0,0-.247-.494A.6.6,0,0,0,139.333,208.13Z" transform="translate(-129.328 -193.182)"/>
    <path id="Path_705" className="fill-color" data-name="Path 705" d="M10.5,0A10.5,10.5,0,1,0,21,10.5,10.491,10.491,0,0,0,10.5,0Zm0,19.765A9.332,9.332,0,0,1,1.235,10.5,9.332,9.332,0,0,1,10.5,1.235,9.332,9.332,0,0,1,19.765,10.5,9.332,9.332,0,0,1,10.5,19.765Z"/>
  </g>
</svg>

export default Faq
