import { JTPState } from '..';
import { remainingExpTime } from '../../helpers/tokenHelpers';

export const tokenAttrsSelector = (state: JTPState) => state.session.attrs;

export const loggedInSelector = (state: JTPState) => state.session.loggedIn;

export const tokenRemainingTimeSelector = (state: JTPState) =>
  remainingExpTime(state.session.attrs.exp);

export const loanIdSelector = (state: JTPState) => state.session.attrs.loanId