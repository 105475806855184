import None from '../types/None';
import { BasicInfoPlain } from './basicInfoPost';
import serviceBuilder from './serviceBuilder';

export interface BasicInfoPlainExt extends BasicInfoPlain {
  fullName: string;
  email: string;
  cellPhone: string;
  disposableIncome: number;
  documentType:string;
  documentKey:string;
}

export const url = 'sec/users/pi';

export default serviceBuilder<None, BasicInfoPlainExt>('get', {
  url,
  auth: true,
});
