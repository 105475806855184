import { date, number, string } from 'yup';
import toDayjs from '../../../../helpers/toDayjs';

export const selectSchema = string().required('Requerido') as any as any;

export const numberSchema = number()
  .min(1, 'Requerido')
  .required('Requerido')
  .typeError('Unicamente caracteres númericos');

export const emailSchema = string()
  .required('Requerido')
  .email('Ingresa un correo válido para que puedas continuar con el proceso.');

export const dateSchema = () =>
  date()
    .transform(function (value, rawValue) {
      try {
        if (this.isType(rawValue)) {
          return rawValue;
        }
        const date = toDayjs(rawValue, 'DD-MM-YYYY');
        value = date.isValid() ? date.toDate() : new Date('');
        return value;
      } catch {}
    })
    .typeError('Formato de fecha no válido')
    .required('Requerido');
