import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { object, string } from 'yup';
import FormGroup from '../../../components/Form/FormGroup';
import InputGroup from '../../../components/Form/InputGroup';
import InputPhone from '../../../components/Form/InputPhone';
import InputSelect from '../../../components/Form/InputSelect';
import Label from '../../../components/Form/Label';
import Submit from '../../../components/Form/Submit';
import Title from '../../../components/Form/Title';
import Column from '../../../components/Grid/Column';
import ButtonContainer from '../../../components/UI/ButtonContainer';
import GeneralContent from '../../../components/UI/GeneralContent';
import { submitTrap } from '../../../helpers/formHelpers';
import UseCities from '../../../hooks/UseCities';
import styled from '../../../styles/styled-components';
import { selectSchema } from '../../Loan/Loans/forms/ValidationSchema';

const Content = styled.div`
  margin-top: 20px;
  ${GeneralContent}
`;

export interface UserInfo {
  departmentCode: string;
  cityCode: string;
  neighborhood: string;
  address: string;
  email: string;
  cellPhone: string;
}

interface Props {
  departments: { label: string; value: string }[];
  userInfo: UserInfo;
  submit(values: UserInfo): Promise<void>;
}

const UpdatePersonalInformationView: React.FC<Props> = ({
  departments,
  userInfo,
  submit,
}) => {
  const [cities, setCities] = UseCities(userInfo.departmentCode);
  const [loading, setLoading] = useState(false);

  return (
    <Column fluid>
      <GeneralContent>
        <Formik
          validateOnBlur
          initialValues={userInfo}
          validationSchema={object({
            address: string().required('Requerido'),
            cityCode: selectSchema,
            departmentCode: selectSchema,
            neighborhood: string().required('Requerido'),
            cellPhone: string().required('Requerido'),
            email: string()
              .trim()
              .email('Formato no valido')
              .required('Campo obligatorio'),
          })}
          onSubmit={submitTrap(async (values) => {
            values.cellPhone = values.cellPhone.replace(/\s/g, '');
            setLoading(true);
            try {
              await submit(values);
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          })}
        >
          <Form>
            <Title divider>Actualización de Datos</Title>
            <Content>
              <FormGroup>
                <Label>Departamento</Label>
                <InputSelect
                  name="departmentCode"
                  placeholder="Selecciona tu departamento"
                  options={departments}
                  onChange={(code) => {
                    setCities(code);
                    userInfo.cityCode = '';
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Ciudad</Label>
                <InputSelect
                  name="cityCode"
                  placeholder="Selecciona tu ciudad"
                  options={cities}
                />
              </FormGroup>
              <FormGroup>
                <InputGroup label="Barrio" name="neighborhood" />
                <InputGroup label="Dirección de residencia" name="address" />
                <FormGroup>
                  <Label>Número de celular</Label>
                  <InputPhone name="cellPhone" />
                </FormGroup>
                <InputGroup icon="envelope" label="Email" name="email" />
              </FormGroup>
              <ButtonContainer>
                <Submit loading={loading}>Actualizar</Submit>
              </ButtonContainer>
            </Content>
          </Form>
        </Formik>
      </GeneralContent>
    </Column>
  );
};

export default UpdatePersonalInformationView;
