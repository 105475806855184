import None from '../types/None';
import serviceBuilder from './serviceBuilder';

interface Response {}

const url = 'sec/loans/desist';
export default serviceBuilder<None, Response>('post', {
  url,
  auth: true,
});
