import React from 'react'
const Apply = props =>
<svg xmlns="http://www.w3.org/2000/svg" width="26.455" height="30.028" viewBox="0 0 26.455 30.028">
  <g id="save-money" transform="translate(-0.36)">
    <path id="Path_922" className="fill-color" data-name="Path 922" d="M188.183,154.337a.469.469,0,0,0-.169-.006,1.12,1.12,0,0,1-.99-1.11.493.493,0,0,0-.985,0,2.107,2.107,0,0,0,1.549,2.029v.493a.493.493,0,0,0,.985,0v-.463a2.1,2.1,0,0,0-.431-4.162A1.118,1.118,0,1,1,189.26,150a.493.493,0,0,0,.985,0,2.107,2.107,0,0,0-1.672-2.059v-.448a.493.493,0,0,0-.985,0v.478a2.1,2.1,0,0,0,.554,4.132,1.118,1.118,0,0,1,.041,2.235Zm0,0" transform="translate(-174.246 -137.949)" />
    <path id="Path_923" className="fill-color" data-name="Path 923" d="M104.2,113.635a7.142,7.142,0,1,0,7.142-7.142A7.142,7.142,0,0,0,104.2,113.635Zm7.142-6.157a6.157,6.157,0,1,1-6.157,6.157A6.157,6.157,0,0,1,111.338,107.477Zm0,0" transform="translate(-97.442 -99.936)" />
    <path id="Path_924" className="fill-color" data-name="Path 924" d="M23.466,328.319l-3.858,1.823a3.143,3.143,0,0,0-2.679-1.653l-3.562-.1a3.761,3.761,0,0,1-1.619-.418l-.362-.188a6.393,6.393,0,0,0-5.921.006l.023-.821a.493.493,0,0,0-.479-.506L1.1,326.357a.492.492,0,0,0-.506.479L.36,335.406a.493.493,0,0,0,.479.506l3.906.108h.013a.493.493,0,0,0,.493-.479l.011-.41,1.015-.543a1.74,1.74,0,0,1,1.3-.142l6.059,1.7.032.008a6.483,6.483,0,0,0,1.332.137,6.571,6.571,0,0,0,2.745-.6.456.456,0,0,0,.061-.034l8.785-5.683a.493.493,0,0,0,.161-.656A2.488,2.488,0,0,0,23.466,328.319ZM1.359,334.941l.209-7.586,2.921.08-.209,7.587Zm15.942-.129a5.585,5.585,0,0,1-3.415.381l-6.043-1.7a2.724,2.724,0,0,0-2.03.222L5.294,334l.139-5.044a5.414,5.414,0,0,1,5.5-.293l.362.188a4.757,4.757,0,0,0,2.045.528l3.562.1a2.16,2.16,0,0,1,2.053,1.721l-5.306-.146a.493.493,0,1,0-.027.985l5.855.161h.014a.493.493,0,0,0,.492-.479,3.126,3.126,0,0,0-.048-.639l3.957-1.87.012-.006a1.5,1.5,0,0,1,1.7.238Zm0,0" transform="translate(0 -306.263)" />
    <path id="Path_925" className="fill-color" data-name="Path 925" d="M213.188,5.172V.493a.493.493,0,1,0-.985,0V5.172a.493.493,0,1,0,.985,0Zm0,0" transform="translate(-198.8 0)" />
    <path id="Path_926" className="fill-color" data-name="Path 926" d="M273.188,42.709V40.493a.493.493,0,0,0-.985,0v2.216a.493.493,0,0,0,.985,0Zm0,0" transform="translate(-255.106 -37.537)" />
    <path id="Path_927" className="fill-color" data-name="Path 927" d="M153.188,42.709V40.493a.493.493,0,1,0-.985,0v2.216a.493.493,0,0,0,.985,0Zm0,0" transform="translate(-142.494 -37.537)" />
  </g>
</svg>


export default Apply
