import serviceBuilder from './serviceBuilder';

export type JobType =
  | 'Empleado'
  | 'Independiente'
  | 'Pensionado'
  | 'Empleado temporal'
  | 'Estudiante'
  | 'Desempleado';

export type PaymentPeriodicity =
  | 'Semanal'
  | 'Cada 10 días'
  | 'Mensual'
  | 'Quincenal';

export type Gender = 'F' | 'M' | 'NA';

export type Option<T = string> =
  | {
      label: T;
      value: string;
      description?: string;
    }
  | {
      label: T;
      value: number;
      description?: string;
    };

export type OptionNumber = {
  label: string;
  value: number;
  description?: string;
  selected?: boolean;
};

export interface Selectors {
  jobType: Option<JobType>[];
  educationalLevel: Option[];
  genderId: Option<Gender>[];
  stayTime: Option[];
  paymentPeriodicity: Option<PaymentPeriodicity>[];
  housingType: Option[];
  bank: Option[];
  economicSector: Option[];
  maritalStatus: Option[];
  personalReference: Option[];
  familyReference: Option[];
  economicActivity: Option[];
  loanPurpose: Option[];
  otherIncome: Option[];
  accountTypeId: Option[];
  paymentMethod: Option[];
  eventType: OptionNumber[];
}

export type Result = Selectors;
export type Params = Record<'fields', (keyof Selectors)[]>;

export function findOption<T extends Selectors>(options: T) {
  return <K extends keyof Selectors>(
    key: K,
    value: number | string
  ): Selectors[K][0] => {
    return (
      options[key].find(
        (o) =>
          o.value.toString() === value.toString() ||
          o.label === value.toString()
      ) || {
        label: '',
        value: -1,
      }
    );
  };
}

export const labelToValue = <T extends Option>(
  options: Array<T>,
  ...labels: T['label'][]
): (string | number)[] =>
  options.filter((o) => labels.includes(o.label)).map((o) => o.value);

export const url = 'selectors/options/:fields';

const allFields: Array<keyof Selectors> = [
  'accountTypeId',
  'bank',
  'economicActivity',
  'economicSector',
  'educationalLevel',
  'familyReference',
  'genderId',
  'housingType',
  'jobType',
  'loanPurpose',
  'maritalStatus',
  'otherIncome',
  'paymentPeriodicity',
  'personalReference',
  'stayTime',
  'paymentMethod',
  'eventType',
];

const service = serviceBuilder<any, Result>('get', {
  url,
  auth: false,
});

export default async (params?: Params) => {
  params = params ? params : { fields: allFields };
  const fields =
    params.fields.length === 0
      ? ''
      : '?' +
        params.fields.map((field, i) => `field${i + 1}=${field}`).join('&');

  return (await service({ fields })).payload;
};

const holidaysService = serviceBuilder<
  void,
  { description: string; label: string; date: number[] }[]
>('get', {
  url: 'selectors/holidays',
  auth: false,
});

const departmentService = serviceBuilder<
  void,
  { label: string; value: string }[]
>('get', {
  url: 'selectors/options/departments',
  auth: false,
});

const cityService = serviceBuilder<
  { department: string },
  { label: string; value: string }[]
>('get', {
  url: 'selectors/options/departments/:department/cities',
  auth: false,
});

export const departmentGet = async () => {
  return (await departmentService()).payload.map(({ label, value }) => ({
    label: label.toUpperCase(),
    value,
  }));
};

export const citiesGet = async (department: string) => {
  return (await cityService({ department })).payload.map(
    ({ label, value }) => ({ label: label.toUpperCase(), value })
  );
};

export const holidaysGet = async () => {
  return (await holidaysService()).payload.map(
    ({ description, label, date }) => ({
      description,
      label,
      date,
    })
  );
};
