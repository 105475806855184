/* eslint react-hooks/exhaustive-deps: 0 */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import firstDatePayment from '../helpers/financial/firstDatePayment';
import monthlyAval from '../helpers/financial/monthlyAval';
import monthlyQuote from '../helpers/financial/monthlyQuote';
import monthlyRedemption from '../helpers/financial/monthlyRedemption';
import monthlyVAT from '../helpers/financial/mounthlyVAT';
import totalDisbursed from '../helpers/financial/totalDisbursed';
import { RiskMatrix } from '../services/riskMatrixGet';
import { simulatorSelector } from '../store/selectors/simulatorSelector';

type Values = {
  minAmount: number;
  maxAmount: { [term: number]: number };
  term: number;
  amount: number;
  terms: number[];
  gender: string;
};
interface JtdSimFinfoItem {
  term: number;
  maxAmount: number;
  installmentValue: number;
}

type Params = {
  riskMatrix?: RiskMatrix[0];
  incomeFactor: number;
  disposableIncome: number;
};

type Computed = {
  monthlyAval: number;
  monthlyQuote: number;
  monthlyRedemption: number;
  monthlyVAT: number;
  totalDisbursed: number;
  firstDatePayment: string;
};

const defaultValues: Values = {
  minAmount: 0,
  maxAmount: {},
  amount: 0,
  terms: [0],
  term: 0,
  gender: '',
};

const defaultParams: Params = {
  disposableIncome: 0,
  riskMatrix: undefined,
  incomeFactor: 0,
};

const defaultComputed: Computed = {
  monthlyQuote: 0,
  monthlyAval: 0,
  monthlyRedemption: 0,
  monthlyVAT: 0,
  totalDisbursed: 0,
  firstDatePayment: '',
};

function fiftyMultiplier(value: number) {
  return value - (value % 50000);
}

function maxAmountGen(
  interest: number,
  electronicSignature: number,
  disposableIncome: number,
  guaranteeVariable: number,
  amountMax: number,
  incomeFactor: number
) {
  return (term: number) => {
    const interestMonthly = Math.pow(1 + interest, 1 / 12) - 1;
    const valueTechnology = electronicSignature / term;
    const paymentFactor = 1 - Math.pow(1 + interestMonthly, -term);
    const income = disposableIncome * incomeFactor;

    const max =
      Number(
        Number(
          ((income - valueTechnology) *
            (paymentFactor /
              (interestMonthly + guaranteeVariable * 30 * paymentFactor))) /
          500000
        ).toFixed(1)
      ) * 500000;

    return Math.min(max, amountMax);
  };
}

const removeItemArray = (arr: number[], item: number) => {
  let i = arr.indexOf(item);
  if (i !== -1) {
    arr.splice(i, 1);
  }
  return arr;
};

const useSimRiskMatrix = () => {
  const simulator = useSelector(simulatorSelector);
  const [values, setValues] = useState(defaultValues);
  const [params, setParams] = useState(defaultParams);
  const [computed, setComputed] = useState(defaultComputed);
  const [ready, setReady] = useState(false);
  const [valueFirstDatePayment, setFirstDatePayment] = useState('');
  const [JtdSimFinfo,setJtdSimFinfo]=useState<JtdSimFinfoItem[]>([
    {
      term: 0,
      maxAmount: 0,
      installmentValue: 0,
    }]);
  useEffect(() => {
    firstDatePayment().then((resp) => {
      setFirstDatePayment(resp.format('MMMM DD [de] YYYY'));
    });
  }, []);

  useEffect(() => {
    if (!params.riskMatrix) return;

    const { riskMatrix, incomeFactor, disposableIncome } = params;
    const {
      interest,
      electronicSignature,
      guaranteeVariableWoman,
      guaranteeVariableMan,
    } = simulator;

    const max = maxAmountGen(
      interest,
      electronicSignature,
      disposableIncome,
      values.gender === 'Masculino'
        ? guaranteeVariableMan
        : guaranteeVariableWoman,
      riskMatrix.maxAmount,
      incomeFactor
    );
    const terms = [...riskMatrix.term]
    const maxAmount = riskMatrix.term.reduce<Record<number, number>>(
      (o, term) => ({ ...o, [term]: max(term) }),
      {}
    );
    const minAmount = fiftyMultiplier(riskMatrix.minAmount);
    const term = riskMatrix.term[riskMatrix.term.length - 1];

    for (let k in maxAmount) {
      if (
        terms.indexOf(parseInt(k)) !== -1 &&
        maxAmount[k] < riskMatrix.minAmount
      ) {
        delete maxAmount[k];
        riskMatrix.term = removeItemArray(riskMatrix.term, parseInt(k));
      }
    }
   

    setValues((prev) => ({
      gender: prev.gender,
      amount: maxAmount[term],
      maxAmount,
      minAmount,
      term,
      terms: riskMatrix.term,
    }));

    setJtdSimFinfo(Object.entries(maxAmount).map(([term, maxAmount]) => {    
      return {
        term: parseInt(term),
        maxAmount,
        installmentValue:Math.floor(monthlyQuote({
          amount:maxAmount,
          term: parseInt(term),
          interest,
          technology:simulator.technology,
          electronicSignature,
          guaranteeVariable:
            values.gender === 'Masculino'
              ? guaranteeVariableMan
              : guaranteeVariableWoman,
          VAT:simulator.VAT,
        }))
      };
    }))
    setReady(true);
  }, [params, setValues, setReady]);

  useEffect(() => {
    const { amount, term, gender } = values;
    const {
      interest,
      technology,
      electronicSignature,
      guaranteeConstant,
      guaranteeFurniture,
      guaranteeVariableMan,
      guaranteeVariableWoman,
      VAT,
    } = simulator;

    setComputed({
      monthlyQuote: monthlyQuote({
        amount,
        term,
        interest,
        technology,
        electronicSignature,
        guaranteeVariable:
          gender === 'Masculino'
            ? guaranteeVariableMan
            : guaranteeVariableWoman,
        VAT,
      }),
      monthlyAval: monthlyAval({
        amount,
        term,
        interest,
        technology,
        electronicSignature,
        guaranteeVariable:
          gender === 'Masculino'
            ? guaranteeVariableMan
            : guaranteeVariableWoman,
        VAT,
      }),
      monthlyVAT: monthlyVAT({
        amount,
        term,
        interest,
        technology,
        electronicSignature,
        guaranteeVariable:
          gender === 'Masculino'
            ? guaranteeVariableMan
            : guaranteeVariableWoman,
        VAT,
      }),
      monthlyRedemption: monthlyRedemption({
        amount,
        term,
        interest,
        technology,
        electronicSignature,
        guaranteeVariable:
          gender === 'Masculino'
            ? guaranteeVariableMan
            : guaranteeVariableWoman,
        VAT,
      }),
      totalDisbursed: totalDisbursed({
        amount,
        guaranteeConstant,
        guaranteeFurniture,
      }),
      firstDatePayment: valueFirstDatePayment,
    });
  }, [values, simulator, setComputed, valueFirstDatePayment]);

  return {
    ready,
    state: values,
    setState: setValues,
    params,
    setParams,
    computed,
    JtdSimFinfo,
  };
};

export default useSimRiskMatrix;
