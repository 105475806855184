import { FormikErrors } from 'formik';

type ErrorRecord = Record<string, string | React.ReactNode>;

export class FormFieldsError extends Error {
  errors: FormikErrors<any>;
  constructor(errors: ErrorRecord) {
    super('form-error');
    this.errors = errors as any;
  }
}
