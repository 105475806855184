import React from 'react'
const Pay = props =>
<svg xmlns="http://www.w3.org/2000/svg" width="20.751" height="20.751" viewBox="0 0 20.751 20.751">
  <g id="coin" transform="translate(0)">
    <g id="Group_725" data-name="Group 725">
      <g id="Group_724" data-name="Group 724">
        <path id="Path_712" className="fill-color" data-name="Path 712" d="M17.712,3.039A10.376,10.376,0,0,0,3.039,17.712,10.376,10.376,0,0,0,17.712,3.039Zm-7.337,16.5a9.16,9.16,0,1,1,9.16-9.16A9.17,9.17,0,0,1,10.376,19.535Z" transform="translate(0)" fill="#083863"/>
      </g>
    </g>
    <g id="Group_727" data-name="Group 727" transform="translate(7.163 3.907)">
      <g id="Group_726" data-name="Group 726">
        <path id="Path_713" className="fill-color" data-name="Path 713" d="M180.6,102.262h-1.3a1.346,1.346,0,1,1,0-2.691h2.6a.608.608,0,0,0,0-1.216h-1.346V97.009a.608.608,0,1,0-1.216,0v1.346H179.3a2.561,2.561,0,1,0,0,5.123h1.3a1.346,1.346,0,0,1,0,2.691h-2.6a.608.608,0,1,0,0,1.216h1.346v1.346a.608.608,0,1,0,1.216,0v-1.346h.043a2.561,2.561,0,0,0,0-5.123Z" transform="translate(-176.734 -96.401)" fill="#083863"/>
      </g>
    </g>
  </g>
</svg>

export default Pay
