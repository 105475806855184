/* eslint react-hooks/exhaustive-deps: 0 */
// @ts-nocheck
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { NumberSchema, number, object } from 'yup';
import Form from '../../../components/Form/Form';
import FormChange from '../../../components/Form/FormChange';
import FormGroup from '../../../components/Form/FormGroup';
import InputNumber from '../../../components/Form/InputNumber';
import InputSelect from '../../../components/Form/InputSelect';
import Button from '../../../components/UI/Button';
import GeneralContent from '../../../components/UI/GeneralContent';
import moneyFormat from '../../../helpers/moneyFormat';
import useKushki from '../../../hooks/useKushki';
import payment from '../../../images/icons/Payment.png';
import security from '../../../images/icons/candado.png';
import belvoPaymentLinkPost from '../../../services/belvoPaymentLinkPost';
import { LoanList } from '../../../services/creditListGet';
import { Repayment } from '../../../services/repaymentGet';
import { JTPState } from '../../../store';
import { updateAllLoanStart } from '../../../store/actions/updateAllLoanState';
import { asyncDispatch } from '../../../store/asyncDispatch';
import { QuotaPayment } from '../../../types/QuotaPayment';

const TotalNumber = styled(InputNumber)`
  color: red;
  height: 30px;
  max-width: 100px;
  padding: 4px;
  border: 1px solid ${(props) => props.theme.blueDark};
`;

export const HeaderPayment = styled.section`
  display: flex;
  height: 40px;
  width: 300px;
  background: ${(p) => p.theme.blueDark};
  border-radius: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
  @media (min-width: 700px) {
    width: 350px;
    padding-left: 55px;
  }

  .title {
    color: #ffff;
    margin-top: 10px;
  }

  .icon {
    height: 20px;
    width: 20px;
    background-image: url(${security});
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: 10px;
  }
`;

export const QuotaItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 4px;
  padding: 1rem 0;
  font-size: 15px;

  .gray {
    color: #707070;
    margin-right: 8px;
  }
  .blue {
    color: ${(p) => p.theme.blueDark};
  }
  .red {
    color: red;
  }
  .white {
    background: #ffff;
    border-radius: 9px;
  }
  .grayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin-bottom: 32px;
    background: #f7f7f7;
    border-radius: 10px;
    padding: 15px;
    @media (min-width: 700px) {
      width: 350px;
    }
  }
  .summary {
    flex-flow: column;
    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 4px;
    }
  }
`;

const ItemPayment = styled.div`
  height: 50px;
  width: 50px;
  background-image: url(${payment});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
  margin-top: 10px;
  @media (min-width: 700px) {
    height: 55px;
    width: 55px;
  }
`;

const InformationPayment = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  div {
    height: 21px;
  }
  .red > div {
    height: 30px;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .ajustItem {
    margin-bottom: 7px;
    margin-top: -23px;
    @media (min-width: 700px) {
      margin-bottom: 12px;
    }
  }
`;
const InputSelectTitle = styled.h5`
  color: #707070;
  height: 5px;
  display: flex;
  margin-top: -3px;
`;

const ButtonStyle = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

const InitialValues = {
  paymentKind: '1',
  paymentMethod: '1',
  otherValue: 50000,
};

//const paymentLink = belvoPaymentLinkGet

function getAmount(quota: Repayment, paymentKind: number, otherValue: number) {
  return ({
    '1': quota.repaymentAmount,
    '2': maxRepayment(quota),
    '3': Number(otherValue),
  } as Record<number, number>)[paymentKind];
}

function maxRepayment(credit: LoanList[0]) {
  return Math.trunc(credit.balance);
}

function money(value: number) {
  return moneyFormat(value, true, true);
}

const LoanPaymentInformationView: React.FC<RouteComponentProps<
  Record<'loanId' | 'paymentId' | 'code', string>
>> = ({ match, history, loans, getLoans, address, documentKey,documentType,email}) => {
  const [isButtonEnabled, setButtonEnabled] = useState(true);
  const [credit, setCredit] = useState<LoanList['0']>({});
  const [repayment, setRepayment] = useState({});
  const kushki = useKushki();
  const service =  belvoPaymentLinkPost
  const paymentMethodCode = match.params.code

  useEffect(() => {
    (async () => {
      if (Object.entries(loans || []).length === 0) {
        await getLoans();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (Object.entries(loans || []).length > 0) {
        setCredit(loans[match.params.loanId]);
        setRepayment(
          loans[match.params.loanId].repayments.find(
            (value: Repayment) =>
              value.paymentId === parseInt(match.params.paymentId)
          )
        );
        kushki.requestBankList();
      }
    })();
  }, [loans]);

  return (
    <GeneralContent>
      <QuotaItemStyled>
        {Object.entries(repayment || []).length > 0 && (
          <Formik
            validationSchema={object<typeof InitialValues>({
              paymentKind: number().min(1, 'Requerido'),
              paymentMethod: number().min(1, 'Requerido'),
              otherValue: number()
                .typeError('Requerido')
                .when('paymentKind', (value: number, schema: NumberSchema) => {
                  const min = 50000;
                  const max = maxRepayment(credit);
                  // eslint-disable-next-line
                  if (value == 3) {
                    return schema
                      .required(`Mínimo ${money(min)}`)
                      .min(min, `Mínimo ${money(min)}`)
                      .max(max, `Máximo ${money(max)}`);
                  }
                  return schema;
                }),
            })}
            validateOnChange
            initialValues={InitialValues}
            onSubmit={async (values) => {
              setButtonEnabled(false);
              if (paymentMethodCode === process.env.REACT_APP_BELVO_PAYMENT_METHOD) {
                const Body = {
                  amount: getAmount(
                    repayment,
                    values.paymentKind,
                    values.otherValue
                  ), 
                  paymentId: repayment.paymentId,
                }
                const url = (await service(Body)).payload.payment_url
                window.open(url,"_self")
              } else {
                history.push('/loans/pse', {
                  amount: getAmount(
                    repayment,
                    values.paymentKind,
                    values.otherValue
                  ),
                  addres:address,
                  loanId: repayment.loanId,
                  paymentId: repayment.paymentId,
                  date: repayment.date,
                  documentKey:documentKey,
                  documentType:documentType,
                  email:email,
                } as QuotaPayment);
              }
            }}
          >
            <Form>
              <HeaderPayment>
                <div className="icon" />
                <div className="title"> Información del pago</div>
              </HeaderPayment>
              <div className="grayContainer">
                <ItemPayment />
                <InformationPayment>
                  <div className="white center">
                    <p className="blue ">Crédito: {repayment.loanId}</p>
                  </div>
                  <div className="center">
                    <p className="gray">Cuota del crédito Nº </p>
                    <p className="blue">{repayment.paymentId} </p>
                  </div>
                  <div className="center">
                    <p className="gray">Fecha: </p>
                    <p className="blue">{repayment.date}</p>
                  </div>
                </InformationPayment>
              </div>

              <div className="summary grayContainer">
                <div>
                  <div className="gray ">Total a pagar: </div>
                  <div className="blue">{money(repayment.repaymentAmount)}</div>
                </div>
                <div>
                  <div className="gray ">Capital: </div>
                  <div className="blue">{money(repayment.capital)}</div>
                </div>
                <div>
                  <div className="gray ">Interés: </div>
                  <div className="blue">{money(repayment.interest)}</div>
                </div>
                <div>
                  <div className="gray">Otros:</div>
                  <div className="blue">{money(repayment.fees)}</div>
                </div>
              </div>

              <FormGroup className="form">
                <InputSelectTitle>¿Qué monto deseas pagar? </InputSelectTitle>
                <InputSelect
                  name="paymentKind"
                  defaultOption={false}
                  options={[
                    { label: 'Pago de cuota', value: '1' },
                    { label: 'Cancelación total del crédito', value: '2' },
                    {
                      label: 'Otro valor',
                      value: '3',
                    },
                  ]}
                /><br></br>
              </FormGroup>

              <div className="payment grayContainer">
                <div className="gray">Valor a pagar:</div>
                <div className="red">
                  <FormChange visible={(values) => values.paymentKind === '1'}>
                    {money(repayment.repaymentAmount)}
                  </FormChange>
                  <FormChange visible={(values) => values.paymentKind === '2'}>
                    {money(maxRepayment(credit))}
                  </FormChange>
                  <FormChange visible={(values) => values.paymentKind === '3'}>
                    <TotalNumber name="otherValue" />
                  </FormChange>
                </div>
                <FormChange
                  visible={(values) => {
                    const amount = getAmount(
                      repayment!,
                      values.paymentKind,
                      values.otherValue
                    );
                    return amount < maxRepayment(credit);
                  }}
                />
              </div>

              <div className="actions">
                <ButtonStyle>
                  <Button
                    flat
                    as={NavLink}
                    to={`/loans/quotas/${match.params.loanId}/${match.params.code}`}
                  >
                    Regresar
                  </Button>
                  <Button outline type="submit" disabled={!isButtonEnabled}>
                    Continuar
                  </Button>
                </ButtonStyle>
              </div>
            </Form>
          </Formik>
        )}
      </QuotaItemStyled>
    </GeneralContent>
  );
};

export default connect(
  (state: JTPState) => ({
    loans: state.loans,
    address:state.user.residential.address,
    documentKey:state.user.user.documentKey,
    documentType:state.user.user.documentType,
    email:state.user.user.email,
  }),
  (dispatch) => ({
    getLoans: asyncDispatch(dispatch, updateAllLoanStart),
  })
)(LoanPaymentInformationView);
