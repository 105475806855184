import { Field, FieldProps } from 'formik';
import React from 'react';
import Checkbox from './Checkbox';
import Error from './Error';

export interface Props {
  label?: string;
  name: string;
  hideError?: boolean;
}

const CheckboxComponent: React.FC<FieldProps> = ({ field, form, children }) => {
  return (
    <Checkbox
      {...field}
      id={field.name}
      name={field.name}
      checked={field.value}
      invalid={form.touched[field.name] && !!form.errors[field.name]}
    >
      {children || ''}
    </Checkbox>
  );
};

const InputCheckbox: React.FC<Props> = ({
  label,
  name,
  hideError,
  children,
}) => {
  return (
    <>
      <Field
        name={name}
        component={CheckboxComponent}
        children={children || label}
      />
      {!hideError && <Error name={name} />}
    </>
  );
};

export default InputCheckbox;
