import {
  onEventCallback,
  onEventResponse,
  onExitCallback,
  onExitResponse,
  successCallback
} from '../hooks/useBelvo';

export const appendBelvoScript = (onLoad: () => any) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = 'https://cdn.belvo.io/belvo-widget-1-stable.js';
  script.addEventListener('load', onLoad);
  document.body.appendChild(script);
  return script;
};

export const removeBelvoScript = (script: HTMLScriptElement) => {
  try {
    document.body.removeChild(script);
  } catch (error) {}
};

interface BelvoFailed {
  message: any;
}

type Handler<T> = (value: T) => any;
interface Options {
  failed: Handler<BelvoFailed>;
}
let called = false; 
export const openBelvoWidget =
  (
    access_token: string,
    successCallbackFunction: successCallback,
    onEventCallbackFunction: onEventCallback,
    onExitCallbackFunction: onExitCallback
  ) =>
  ({ failed }: Options) => {
    const config = {
      access_mode: 'single',
      country_codes: ['CO'],
      institution_types: ['retail'],
      locale: 'es',
      institutions: [
        'avvillas_co_retail',
        'bancolombia_co_retail',
        'bancodebogota_co_retail',
        'bancofalabella_co_retail',
        'bbva_co_retail',
        'bancodeoccidente_co_retail',
        'colpatria_co_retail',
        'nequi_co_retail',
        'daviplata_co_retail',
        'davivienda_co_retail'
      ],
      callback: (link: string, institution: string) => {
        if (!called) {
          successCallbackFunction(link, institution);
          called = true;
        }
      },
      onExit: (data: onExitResponse) => {
        called = false; 
        onExitCallbackFunction(data);
      },
      onEvent: (data: onEventResponse) => onEventCallbackFunction(data),
    };

    try {
      (window as any).belvoSDK.createWidget(access_token, config).build();
    } catch (error) {
      failed({ message: error });
    }
  };
