import React from 'react';
import { NavLink as RNavLink } from 'react-router-dom';
import styled, { Theme } from '../../styles/styled-components';

interface NavLinkProps {
  color?: keyof Theme;
  underline?: boolean;
  to?: string;
  href?: string;
  onClick?(): any;
  target?: string;
}

const LinkStyled = styled.div<NavLinkProps>`
  color: ${(props) => props.color || props.theme.blueDark};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  margin: 0 5px;

  > * {
    margin: auto 4px;
  }

  &:hover,
  &:active,
  &:visited {
    color: ${(props) => props.color || props.theme.blueDark};
    text-decoration: underline;
    cursor: pointer;
  }
`;

export interface Props extends NavLinkProps {}

const NavLink: React.FC<Props> = ({
  to,
  href,
  onClick,
  children,
  ...restProps
}) => {
  return (
    <LinkStyled
      as={to ? RNavLink : href ? 'a' : 'span'}
      to={to}
      href={href}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </LinkStyled>
  );
};

export default NavLink;
