import { Kushki } from '@kushki/js';
import { TransferTokenRequest } from '@kushki/js/lib/types/remote/transfer_token_request';
import {
  captureException,
  captureMessage,
  Severity,
  withScope,
} from '@sentry/browser';
import { useCallback, useState } from 'react';

const KushkiOptions = {
  merchantId:
    (process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_KUSHKI_PUBLIC_KEY
      : process.env.REACT_APP_KUSHKI_PUBLIC_KEY_DEV) || '',
  inTestEnvironment: process.env.REACT_APP_ENV !== 'production',
};

const kushkiClient = (() => {
  let _kushki: Kushki;

  return () => {
    if (_kushki) return _kushki;
    return (_kushki = new Kushki(KushkiOptions));
  };
})();

let cache: any = undefined;

interface BankList {
  error: boolean;
  loading: boolean;
  values: { label: string; value: string }[];
}

const useKushki = () => {
  const [bankList, setBankList] = useState<BankList>({
    loading: true,
    error: false,
    values: [],
  });

  const requestBankList = useCallback(() => {
    if (cache) {
      setBankList({
        error: false,
        loading: false,
        values: cache,
      });
      return;
    }
    kushkiClient().requestPseBankList((response) => {
      if (Array.isArray(response)) {
        cache = response.map(({ code, name }) => ({
          label: name,
          value: code,
        }));
        setBankList({
          error: false,
          loading: false,
          values: cache,
        });
      } else {
        withScope((scope) => {
          scope
            .setFingerprint(['Kushki', 'requestBankList'])
            .setLevel(Severity.Warning)
            .setTags({
              'location.path': window.location.pathname,
            });
          captureException(response);
        });
        setBankList({ error: true, loading: false, values: [] });
      }
    });
  }, []);

  const getToken = useCallback(async (value: TransferTokenRequest) => {
    return new Promise<string>((resolve, reject) => {
      kushkiClient().requestTransferToken(value, (response) => {
        if ('token' in response) {
          resolve(response.token);
        } else {
          withScope((scope) => {
            scope
              .setFingerprint(['Kushki', 'requestTransferToken'])
              .setLevel(Severity.Fatal)
              .setTags({
                'location.path': window.location.pathname,
              });
            captureMessage(`kushki requestTransferToken failed`);
          });
          reject(response);
        }
      });
    });
  }, []);

  return {
    bankList,
    requestBankList,
    getToken,
  };
};

export default useKushki;
