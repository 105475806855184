import React from 'react';
import styled from '../../styles/styled-components';

const CheckboxStyled = styled.div`
  display: flex;
  align-items: center;
`;
const Input = styled.input<{ invalid?: boolean }>`
  margin: 2px 8px;
  appearance: none;
  border: 6px solid ${props =>props.invalid?props.theme.danger: props.theme.gray200};
  border-radius: 8px;
  min-height: 28px;
  min-width: 28px;
  position: relative;

  + label {
    color: ${props => props.invalid && props.theme.danger};
  }

  :checked:after {
    content: '';
    width: 18px;
    height: 18px;
    background-color: ${props => props.theme.blue};
    border-radius: 4px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  :hover,
  :focus {
    box-shadow: 0 0 5px
      ${props => (props.invalid ? props.theme.danger : props.theme.inputFocus)};
  }
`;
const Label = styled.label``;

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  invalid?: boolean;
  children?: React.ReactNode;
}

const Checkbox: React.FC<Props> = ({
  name,
  invalid,
  children,
  checked,
  ...restProps
}) => (
  <CheckboxStyled>
    <Input
      name={name}
      id={name}
      invalid={invalid}
      // @ts-ignore
      checked={checked === 'true' || checked === true ? true : false}
      {...restProps}
      type="checkbox"
    />
    <Label htmlFor={name}>{children}</Label>
  </CheckboxStyled>
);
export default Checkbox;
