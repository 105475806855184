import { PayloadAction } from '@reduxjs/toolkit';
import { call, delay, select } from 'redux-saga/effects';
import { TokenAttrs } from '../../helpers/tokenHelpers';
import disclaimerPost from '../../services/disclaimerPost';
import { tokenAttrsSelector } from '../selectors';
import callService from './callService';
import { refreshTokenSaga } from './session';

export function* disclaimerSaga(action: PayloadAction<{ jobType:number, age:number}>) {
  yield callService(disclaimerPost,action.payload);

  // wait for loan id
  for (let i = 0; i < 10; ++i) {
    yield call(refreshTokenSaga);
    const attrs: TokenAttrs = yield select(tokenAttrsSelector);
    if (attrs.loanId) {
      return;
    }
    yield delay(1000);
  }
}
