import serviceBuilder from '../serviceBuilder';

export interface Result {
  code: number;
}

interface Params {
  proposedDate: string;
  justification: string;
  amount: string;
}

const url = 'sec/users/agreements';
export default serviceBuilder<Params, Result>('post', {
  auth: true,
  url,
});
