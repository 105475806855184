import { call } from '@redux-saga/core/effects';
import {
  getCookieAccept,
  getCookieRequest,
  getCookieTimestamp,
  setCookieRequest,
} from '../../helpers/cookies';
import cookiePost, { Params } from '../../services/cookiePost';
import { fingerprint } from '../../trackers/fingerprint';

export function* cookieSaga() {
  try {
    if (getCookieAccept() && !getCookieRequest()) {
      yield call(cookiePost, {
        deviceInfo: fingerprint.deviceInfo,
        timestamp: getCookieTimestamp(),
      } as Params);
      setCookieRequest(true);
    }
  } catch (e) {}
}
