import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps {}

const HomeView: React.FC<Props> = (props) => {
  if (props.location.pathname === '/') {
    props.history.push('/login');
  } /* pathname is '/home' */ else {
    window.location.assign(`${process.env.REACT_APP_CMS}`);
  }
  return null;
};

export default HomeView;
