import serviceBuilder from './serviceBuilder';

export interface MethodId {
  disbursementMethodId: number;
}

export const url = 'sec/loans/disbursement/method';

export default serviceBuilder<MethodId>('post', {
  url,
  auth: true,
  silent: true,
});
