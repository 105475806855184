import serviceBuilder from '../serviceBuilder';

export interface Result {
  code: number;
}

interface Params {
  paymentDate: string;
  attachment: string;
}

const url = 'sec/users/supports';
export default serviceBuilder<Params, Result>('post', {
  auth: true,
  url,
});
