import React from 'react';
import styled from '../../styles/styled-components';

const SpinnerStyled = styled.svg`
  padding-top:16px;
  height:60px;
`;

interface Props {
  size: number;
  enabled?: boolean;
  className?: string;
}

export const SpinnerPro: React.FC<Props> = ({
  size,
  enabled = true,
  className,
}) => (
  <>
    {enabled && (
      <SpinnerStyled
        className={className}
        style={{
          width: size,
          height: size,
          marginTop: -size / 2,
          marginLeft: -size / 2,
        }}
        viewBox={`0 0 ${size} ${size}`}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle
              stroke="currentColor"
              strokeOpacity=".5"
              cx="50%"
              cy="50%"
              r={size / 2 - 2}
            />
            <path
              stroke="currentColor"
              d={`M${size / 2},2 C ${size / 4},2 2,${size / 4} 2,${size / 2}`}
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                from={`0 ${size / 2} ${size / 2}`}
                to={`360 ${size / 2} ${size / 2}`}
                dur="1.3s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </SpinnerStyled>
    )}
  </>
);
