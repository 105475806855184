import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModalC, useModalC } from '../components/UI/Modal';
import compareVersion from '../helpers/compareVersion';
import loadingGif from '../images/loading.gif';
import { breakpointLarge } from '../styles/breakpoints';

const LoadingDiv = styled.div`
  text-align: center;
`;

const Loading = styled.img`
  margin-top: 10px;
  width: 4.5rem;
  @media (min-width: ${breakpointLarge}) {
    width: 9rem;
  }
`;

const VersionMessage = {
  main: (
    <div>
      <p>
        Estamos actualizando una nueva versión de Juancho Te Presta. Por favor
        cierra la ventana y ábrela de nuevo para ingresar ¡Gracias!
      </p>
      <LoadingDiv>
        <Loading src={loadingGif} alt="cargando" />
      </LoadingDiv>
    </div>
  ),
  noClosable: true,
};

const ValidVersion: React.FC = () => {
  const [modal, openModal] = useModalC();

  useEffect(() => {
    console.log('NODE_ENV: ' + process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          let version = process.env.REACT_APP_VERSION;
          let last_version = meta.version;
          const shouldForceRefrez = compareVersion(last_version, version);
          if (shouldForceRefrez) {
            console.log(
              `We have a new version - ${last_version}. Should force refresh the version ${version}`
            );
            openModal(VersionMessage);
            setTimeout(() => {
              navigator.serviceWorker.ready.then((registration: any) => {
                console.log('NEW: SKIP_WAITING');
                registration.active.postMessage('SKIP_WAITING');
              });
            }, 1000);
          } else {
            console.log(
              `You already have the latest version - ${last_version}. No cache refresh needed.`
            );
          }
        });
    }
  }, [openModal]);

  return (
    <>
      <ModalC props={modal} />
    </>
  );
};

export default ValidVersion;
