import None from '../types/None';
import serviceBuilder from './serviceBuilder';

export type BuroResponse =
  | {
      running: true;
      code__: number;
      score: string;
      risk: string;
      incomeFactor: number;
      finalPath: number;
      mark: string;
      maxRiskQuota: number;
      product:string;
      simUserMsg:string;
    }
  | {
      running: false;
      loanId: number;
      fineracId: number;
      status: string;
      score: string;
      risk: string;
      codResponse: string;
      incomeFactor: number;
      finalPath: number;
      mark: string;
      maxRiskQuota: number;
      code__: number;
      product:string;
      simUserMsg:string;
    }
  | {
      running: false;
      loanId: number;
      fineracId: number;
      status: string;
      score: string;
      risk: string;
      codResponse: string;
      incomeFactor: number;
      finalPath: number;
      mark: string;
      maxRiskQuota: number;
      code__: number;
      product:string;
      simUserMsg:string;
    };

export const url = 'sec/loans/buro';

export default async () => {
  const response = await serviceBuilder<None, BuroResponse>('get', {
    url,
    auth: true,
  })();

  return response.payload;
};
